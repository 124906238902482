import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { SuccessMessage } from 'network/messages/successMessages'
/**
 * Service to get user info
 */
export const getUserInfoService = () => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/user/get-user-details', {}, {
    server: microServices.SERVICE_URL_1
  })
}

/**
 * Service to update user info
//  * @param {object} data - object contains name to be updated for user
 */
export const uploadProfilePhotoService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/upload-profile-photo', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*'
    },
    successMessage: SuccessMessage.profileUpdatedSuccess
  })
}

export const updateUserInfoService = (data) => {
  return axiosInstance(METHOD_TYPES.put, 'api/user/update-user-details', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.profileUpdatedSuccess
  })
}

export const updatePhoneNumber = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/user/update-phone', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const generateQRCodeFor2fa = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/generate-secret-code', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const firstTime2faAuthenticateToken = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/firstTime-2fa-authenticate-token', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.otpVerified
  })
}

export const deactivate2faAuthenticateTokenService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/deactivate-two-factor-authentication', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.twoFADeactivated
  })
}

export const getwithdrawTransactions = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/getWithdrawTransaction', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getPlacedBets = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllBets = (data, endpoint) => {
  return axiosInstance(METHOD_TYPES.get, endpoint, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const getActiveJoiningBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/get-active-joining-bonus', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getActiveReferralBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/bonus/get-active-referral-bonus', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}
export const getLoyaltyDetailsService = () => {
  return axiosInstance(METHOD_TYPES.get, 'api/user/get-loyalty-details', {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getTransactions = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/transaction-list', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit

  })
}

export const generateWalletAddressService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/generate-address', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit

  })
}

export const generateReferralCodeService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/user/generate-referral-token', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit

  })
}

export const depositRequest = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/deposit-amount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const withdrawAmountRequest = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/wallet/withdraw-amount', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
    // successMessage: 'Withdraw request sent to Admin, Please wait for approval!,'
  })
}

export const getDepositTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/deposit-transaction', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getWithdrawTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/withdraw-transaction', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getUsersCasinoTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/casino/get-casino-transactions', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getUsersTransactionsService = (data) => {
  // if (data.type === undefined) {
  //   return axiosInstance(METHOD_TYPES.get, `api/v1/user/get-user-transactions?fromDate=${data.startDate}&endDate=${data.endDate}&pageNo=${data.pageNo}&limit=${data.limit}`, {
  //     server: microServices.SERVICE_URL_1,
  //     params: data,
  //     loader: LOADER_HANDLER_TYPES.table
  //   })
  // } else {
  return axiosInstance(METHOD_TYPES.get, 'api/v1/user/get-user-transactions', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table
  })
  // }
}

export const getUsersCustomGameTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/custom-game-transaction', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getUsersSportsbookTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/user/sports-transactions', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getUsersTotalBetsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/user/profit-loss', data, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}

export const getDocumentsLabelService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/document/get-document-label', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getDocumentsInfoService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/document/get-documents', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const updateDocVerificationService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/api/document/update-user-document', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.profileUpdatedSuccess,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const getAffiliateDataService = ({ data, loader }) => {
  return axiosInstance(METHOD_TYPES.get, 'api/v1/affiliate/get-affiliate-detail', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: loader
  })
}

export const getReferralLinkService = ({ data, loader }) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/user/refer-code-link', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: loader
  })
}

export const becomeAffiliateService = ({ data, loader }) => {
  return axiosInstance(METHOD_TYPES.post, 'api/v1/affiliate/create', data, {
    server: microServices.SERVICE_URL_1,
    loader: loader
  })
}
