import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { MediaCardStyle } from './style'
import { v4 as uuidv4 } from 'uuid'

const MediaCard = ({ image, title, description = '  ', cardBackground, buttonList }) => {
  return (
    <MediaCardStyle color={cardBackground}>
      <Card sx={{ maxWidth: 345, background: cardBackground }} className='media-card-container'>
        <CardMedia
          sx={{ height: 140, width: 345 }}
          image={image}
          title={title}
        />
        <CardContent className='card-content'>
          <Typography gutterBottom variant='h5' component='div' color='#FFF'>
            {title}
          </Typography>
          {/* <Typography variant='body2' color='#FFF'>
            {description}
          </Typography> */}
        </CardContent>
        {buttonList?.length > 0 &&
        (
          <CardActions>
            {buttonList && buttonList?.map(button => <Button key={uuidv4()} size='small'>{button?.title}</Button>)}
          </CardActions>
        )}

      </Card>
    </MediaCardStyle>

  )
}

export default MediaCard
