/* eslint-disable no-unused-vars */
import React from 'react'

import { MaintenanceModeContainer } from './MaintenanceMode.style'

const MaintenanceMode = () => {
  return (
    <MaintenanceModeContainer>
      <div className='mainen-mode-wrap '>
        <div className='logo-wrap profile-card-wrap'>
          <img
            className='logo'
            src='/assets/images/logo/brand-logo2.png'
            alt='Company Logo'
            loading='lazy'
          />
        </div>
        <h6>Site Under Maintenance</h6>
        <p>
          We are currently performing some scheduled maintenance. Please check
          back soon.
        </p>
        <p>For any urgent matters, please contact us:</p>
        <p className='contact'>
          Email: NA
          <br />
        </p>
        <div className='footer'>
          &copy; 2023 CashMachine. All Rights Reserved
        </div>
      </div>
    </MaintenanceModeContainer>
  )
}

export default MaintenanceMode
