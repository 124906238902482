import { Box, styled } from '@mui/material'

export const MainLayoutWrapper = styled(Box)(({ theme }) => {
  return {
    minHeight: '100dvh',
    background: `url("${theme.themeImages.layoutBgImage}") ${theme.palette.solidColor.layoutBg}`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    backgroundAttachment: 'fixed'
  }
})

export const MatrixLayoutWrapper = styled(Box)(({ theme }) => {
  return {
    minHeight: '100dvh',
    background: `url("${theme.themeImages.matrixBG}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundPositionY: theme.spacing(-5),
    backgroundAttachment: 'fixed'
  }
})
