import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Pagination, PaginationItem, Typography, Grid, MenuItem } from '@mui/material'
import { TransactionHistoryCashWrapper } from './style'
import CustomTable from 'components/common-ui/CustomTable/index'
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, SpinnerSVG } from 'assets/icons/index'
import { CustomFormLabel, CustomSelect } from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import CustomDateTimePicker from 'components/common-ui/CustomDateTimePicker/CustomDateTimePicker'
import { useDispatch, useSelector } from 'react-redux'
import { getUserTransactions } from 'redux-thunk/thunk/user.thunk'
import dayjs from 'dayjs'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { LOADER_HANDLER_TYPES, transactionStatus } from 'constants/index'
import { useTranslation } from 'react-i18next'

const columns = (t) => ([
  {
    id: 'transactionBankingId',
    label: <Typography textAlign='left'>#</Typography>
  },
  {
    id: 'transactionTime',
    label: <Typography textAlign='left'>{t('transactionTime')}</Typography>
  },
  {
    id: 'amount',
    label: <Typography textAlign='left'>{t('amount')}</Typography>
  },
  {
    id: 'transactionType',
    label: <Typography textAlign='left'>{t('actionType')}</Typography>
  },
  {
    id: 'status',
    label: <Typography textAlign='left'>{t('status')}</Typography>
  },
  {
    id: 'paymentProvider',
    label: <Typography textAlign='left'>{t('payments')} {t('provider')}</Typography>
  }
])

const TransactionHistory = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { usersTransactions } = useSelector((state) => state.user)
  const [transactionsData, setTransData] = useState([])
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(7, 'day'))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [transactionType, setTransactionType] = useState('all')
  const { [LOADER_HANDLER_TYPES.table]: loading } = useSelector(state => state.loader)
  const { t } = useTranslation()

  // const [search, setSearch] = useState('')

  // useEffect(() => {
  //   const params = {
  //     limit: 5,
  //     startDate: startDate.$d.toISOString().split('T')[0],
  //     endDate: endDate.$d.toISOString().split('T')[0],
  //     transactionType: transactionType,
  //     // search: search,
  //     pageNo: page
  //   }
  //   dispatch(getUserTransactions(params))
  // }, [])

  useEffect(() => {
    const myData = usersTransactions?.rows?.map((trans) => {
      return ({
        transactionBankingId: <Typography textAlign='left'>{trans.transactionBankingId}</Typography>,
        transactionTime: <Typography textAlign='left'>{new Date(trans.transactionDateTime).toLocaleDateString()} {new Date(trans.transactionDateTime).toLocaleTimeString()}</Typography>,
        amount: <Typography textAlign='left'>{trans.amount} {trans.currencyCode}</Typography>,
        transactionType: <Typography textAlign='left' className='success'>{t(`${trans?.transactionType}`)}</Typography>,
        status: [1, 5].includes(trans?.status)
          ? <Typography textAlign='left' className='success'>{t(`${transactionStatus?.[trans?.status]}`)}</Typography>
          : <Typography textAlign='left'>{t(`${transactionStatus?.[trans?.status]}`) || ''}</Typography>,
        paymentProvider: <Typography textAlign='left' className='success'>{trans.paymentProvider}</Typography>
      })
    })
    setTransData(myData)
  }, [usersTransactions?.rows])

  useEffect(() => {
    const params = {
      limit: 5,
      startDate: startDate.$d.toISOString().split('T')[0],
      endDate: endDate.$d.toISOString().split('T')[0],
      transactionType: transactionType,
      // search: search,
      pageNo: page
    }
    dispatch(getUserTransactions(params))
  }, [page])

  const handleShow = () => {
    const params = {
      limit: 5,
      startDate: startDate.$d.toISOString().split('T')[0],
      endDate: endDate.$d.toISOString().split('T')[0],
      transactionType: transactionType,
      pageNo: 1
      // search: search
    }
    dispatch(getUserTransactions(params))
  }

  const transactions = (
    <>
      <CustomTable columns={columns(t)} data={transactionsData || []} />
      <Pagination
        count={(usersTransactions?.count / 5) < 1 ? 1 : Math.ceil(usersTransactions?.count / 5)}
        onChange={(e, value) => setPage(value)}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            {...item}
          />
        )}
      />
    </>
  )

  return (
    <>
      <TransactionHistoryCashWrapper theme={theme} className='content-wrapper'>
        <Box component='form' noValidate>
          <Grid container spacing={{ md: 1.25, xs: 0.75 }}>
            <Grid item xs={6} lg={3}>
              <CustomFormLabel>{t('startDate')}</CustomFormLabel>
              <CustomDateTimePicker maxDate={dayjs(new Date())} value={startDate} handleChange={setStartDate} />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CustomFormLabel>{t('endDate')}</CustomFormLabel>
              <CustomDateTimePicker maxDate={dayjs(new Date())} value={endDate} handleChange={setEndDate} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('transactionType')}</CustomFormLabel>
              <CustomSelect
                fullWidth
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
                displayEmpty
                IconComponent={ChevronDownIcon}
              >
                <MenuItem value='all'>{t('all')}</MenuItem>
                <MenuItem value='addMoney'>{t('credit')}</MenuItem>
                <MenuItem value='removeMoney'>{t('debit')}</MenuItem>
                <MenuItem value='deposit'>{t('deposit')}</MenuItem>
                <MenuItem value='withdraw'>{t('withdraw')}</MenuItem>
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                <Grid item xs={12} lg={4}>
                  <Box className='form-btn-box'>
                    <CustomPrimaryButton fullWidth onClick={handleShow}>
                      {!loading
                        ? t('show')
                        : <SpinnerSVG style={{ fill: 'white' }} />}
                    </CustomPrimaryButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Typography className='transactions-results-text'>{t('betResults')} {startDate?.$d?.toISOString()} - {endDate?.$d?.toISOString()}</Typography>
        {usersTransactions?.count
          ? transactions
          : <Typography className='transactions-results-text'>{t('noTransactionsFoundForselectedDate')}</Typography>}
      </TransactionHistoryCashWrapper>
    </>
  )
}

export default React.memo(TransactionHistory)
