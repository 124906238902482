import React from 'react'
import { useTheme } from '@emotion/react'
import { } from '@mui/material'
import { DatePickerWrapper } from './style'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

const CustomDatePicker = ({ name, onChange, onBlur, value, maxDate }) => {
  const theme = useTheme()

  return (
    <DatePickerWrapper theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker maxDate={dayjs(maxDate)} value={dayjs(value)} onChange={(date) => onChange(date?.$d)} format='DD/MM/YYYY' views={['year', 'month', 'day']} name={name} onBlur={onBlur} />
        </DemoContainer>
      </LocalizationProvider>
    </DatePickerWrapper>
  )
}

export default React.memo(CustomDatePicker)
