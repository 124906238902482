import { styled } from '@mui/styles'
import { Box } from '@mui/material'

export const CustomTabStyle = styled(Box)(({ theme }) => {
  return {
    '& .custom-tab-container': {
      display: 'flex',
      textAlign: 'center',
      background: 'green',
      margin: theme.spacing(2),
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      '& .tab-image': {
        height: 70,
        width: 70
      },
      '& .image-title': {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-between'
      }

    }
  }
})

export const VisitedCardStyle = styled(Box)(({ theme }) => {
  return {
    '& .visted-card-container': {
      width: '71vw',
      maxWidth: theme.spacing(85.25),
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .centered-card': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .card-paper': {
          maxWidth: '100%',
          width: '100%',
          background: theme.palette.gradientColor.gameCardOverlay,
          boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0)',
          '& .card-title': {
            padding: theme.spacing(1),
            fontSize: 20,
            color: '#FFF'
          },
          '& .card-desctiption': {
            padding: theme.spacing(1),
            color: '#FFF'
          }
        },
        '& .dynamic-content': {
          padding: theme.spacing(1),
          color: '#FFF',
          '& img': {
            width: '100%',
            height: '300px',
            objectFit: 'cover',
            objectPosition: 'top'
          },
          '& h1': {
            paddingLeft: theme.spacing(1),
            color: '#fff'

          },
          '& .content-wrap': {
            paddingLeft: theme.spacing(1),
            color: '#57535E'
          },
          '& h2': {
            paddingLeft: theme.spacing(1),
            color: '#fff'

          },
          '& .list': {
            color: '#57535E'
          },
          '& .tag-list': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: theme.spacing(2),
            '& a': {
              height: 'auto',
              background: 'green',
              borderRadius: '0.5rem',
              padding: theme.spacing(1.5, 6),
              textDecoration: 'none',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkgreen'
              }
            }
          }

        }
      }
    }
  }
})
