import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { LanguageModalWrapper } from './style'
import CustomDialog from 'components/common-ui/CustomDialog/CustomDialog'
import { CustomMainButton, CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'
import { languagesMenuItem } from 'utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedLanguage } from 'redux-thunk/redux/slices/language.slice'

const LanguageModal = ({ dialogOpen, handleClose }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const { selectedLanguage } = useSelector((state) => state.language)

  const [language, setLanguage] = useState(selectedLanguage || {})
  const dispatch = useDispatch()

  const handleLanguageSelect = (language) => {
    setLanguage(language)
  }

  const handleLanguageChange = () => {
    if (['uk', 'ru'].includes(language.code)) {
      theme.typography.fontFamily = 'ChunkfiveEx'
    } else if (['pl'].includes(language.code)) {
      theme.typography.fontFamily = 'Biko'
    } else theme.typography.fontFamily = 'Heavitas'
    dispatch(setSelectedLanguage(language))
    localStorage.setItem('lang', JSON.stringify(language))
    i18n.changeLanguage(language?.code)
    handleClose()
  }

  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [maxWidth] = React.useState('customModal_xm')

  return (
    <>
      <CustomDialog
        maxWidth={maxWidth}
        // fullScreen={fullScreen}
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        aria-labelledby='customized-dialog-title'
      >
        <LanguageModalWrapper theme={theme}>
          <Box className='dialog-content-wrap'>
            <Box className='language-content-box'>
              <Box className='selected-language-box'>
                <CustomMainButton className='language-btn selected-language'>
                  <Box className='flag-box'>
                    <Box className='img-box'>
                      <img
                        src={language?.img}
                        alt='flag'
                        loading='lazy'
                      />
                    </Box>
                  </Box>
                  {language?.title}
                </CustomMainButton>
              </Box>

              <Box className='language-options-container'>
                <Box className='language-options-box'>
                  <Grid container spacing={{ md: 2, xs: 1 }}>
                    {languagesMenuItem.map((languageItem, i) => (
                      <Grid item xs={12} md={4} key={i}>
                        <CustomMainButton
                          fullWidth
                          className={`language-btn ${languageItem.code === language?.code ? 'active' : ''}`}
                          onClick={() => handleLanguageSelect(languageItem)}
                        >
                          <Box className='flag-box'>
                            <Box className='img-box'>
                              <img
                                src={languageItem.img}
                                alt='flag'
                                loading='lazy'
                              />
                            </Box>
                          </Box>
                          {languageItem.title}
                        </CustomMainButton>
                      </Grid>
                    ))}

                  </Grid>
                </Box>
              </Box>
            </Box>
            <Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={{ md: 2, xs: 1 }} justifyContent='center'>
                  <Grid item xs={6} md={4}>
                    <CustomPrimaryButton fullWidth onClick={handleLanguageChange} sx={{ width: '100%' }}>{t('save')}</CustomPrimaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className='modal-heading-box'>
            <Typography variant='h1'>{t('language')}</Typography>
          </Box>
        </LanguageModalWrapper>
      </CustomDialog>
    </>
  )
}

export default React.memo(LanguageModal)
