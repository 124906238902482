import { Box, styled } from '@mui/material'

export const LobbyBannerWrapper = styled(Box)(({ theme }) => {
  return {
    zIndex: '0',
    marginBottom: theme.spacing(2.5),
    position: 'relative',
    '&::before': {
      content: '""',
      width: '100%',
      height: `calc(100% + ${theme.spacing(10)})`,
      backgroundColor: theme.palette.solidColor.solidColor_06,
      opacity: '0.88',
      zIndex: '-1',
      position: 'absolute',
      top: theme.spacing(0.25),
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '& .lobby-img': {
      width: '100%',
      maxWidth: '100%',
      height: 'auto'
    },
    '& .slider-wrap': {
      width: '100%',
      maxWidth: '85.5%',
      aspectRatio: '584/207',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -128%)',
      '& .swiper-slide': {
        '& .text-box': {
          width: `calc(100% - ${theme.spacing(6)})`,
          backgroundColor: theme.palette.solidColor.transparent,
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.75, 1),
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '& .title-text': {
            background: theme.palette.gradientColor.bannerText,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': theme.palette.lightShade.main,
            fontFamily: 'HelloDenverDisplay-Regular',
            color: theme.palette.solidColor.bannerTextFill,
            fontSize: `clamp(${theme.spacing(3.75)}, 2.1vw, ${theme.spacing(2.5)})`,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            lineHeight: 'normal',
            letterSpacing: theme.spacing(0.125),
            textAlign: 'center',
            textShadow: theme.textShadows[4],
            padding: theme.spacing(0.5, 0),
            marginBottom: theme.spacing(4)
          },
          '& .pera-text': {
            background: theme.palette.gradientColor.bannerText,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': theme.palette.lightShade.main,
            fontFamily: 'HelloDenverDisplay-Regular',
            color: theme.palette.solidColor.bannerTextFill,
            fontSize: `clamp(${theme.spacing(3.75)}, 2.1vw, ${theme.spacing(2.5)})`,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            lineHeight: 'normal',
            letterSpacing: theme.spacing(0.125),
            textAlign: 'center',
            textShadow: theme.textShadows[4],
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '3',
            overflow: 'hidden',
            padding: theme.spacing(0.5, 0)
          },
          [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${theme.spacing(3.5)})`,
            padding: theme.spacing(0.563, 0.75),
            '& .title-text': {
              fontSize: `clamp(${theme.spacing(1.25)}, 2.1vw, ${theme.spacing(0.5)})`,
              marginBottom: theme.spacing(0.5)
            },
            '& .pera-text': {
              fontSize: `clamp(${theme.spacing(1.25)}, 2.1vw, ${theme.spacing(0.5)})`
            }
          }
        }
      },
      '& .banner-img': {
        width: '100%',
        maxWidth: '100%',
        height: 'auto'
      },
      '& .swiper': {
        position: 'static',
        overflow: 'visible'
      },
      '& .swiper-pagination': {
        display: 'flex',
        justifyContent: 'center',
        transform: `translate3d(0, ${theme.spacing(2.75)}, 0)`,
        '& .swiper-pagination-bullet': {
          width: theme.spacing(2.125),
          height: theme.spacing(2.125),
          background: theme.palette.solidColor.transparent,
          borderRadius: theme.spacing(2.125),
          opacity: '1',
          padding: theme.spacing(0.125),
          '&.swiper-pagination-bullet-active': {
            background: theme.palette.gradientColor.darkModeSwitch
          },
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            display: 'block !important',
            opacity: '1',
            margin: theme.spacing(0)
          }
        }
      }
    },
    '& .providers-dropdown-wrap, & .MuiFormControl-root': {
      width: '100%',
      maxWidth: theme.spacing(38.75),
      position: 'absolute',
      left: '50%',
      bottom: theme.spacing(1),
      transform: `translateX(calc(-50% - ${theme.spacing(2)}))`,
      '& .MuiInputLabel-root': {
        color: theme.palette.typographyText.textMain,
        fontSize: theme.spacing(1),
        fontWeight: '500',
        pointerEvents: 'none',
        transform: `translate(${theme.spacing(0.75)}, ${theme.spacing(1)}) scaleX(1)`,
        transition: '300ms all',
        '&.Mui-focused, &.has-value': {
          transform: `translate(${theme.spacing(0.75)}, ${theme.spacing(1)}) scaleX(0)`
        }
      },
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.formInput.dark,
        borderRadius: theme.spacing(0.5),
        '& .MuiInputBase-input': {
          height: theme.spacing(1.188),
          color: theme.palette.formInput.contrastTextWhite,
          fontSize: theme.spacing(1),
          fontWeight: '500'
        },
        '& svg': {
          fill: theme.palette.formInput.contrastTextWhite
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.formInput.borderDark}`
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.formInput.borderMain
          }
        },
        '&.Mui-focused': {
          '& + .atm-card-animation-wrap': {
            // opacity: '0'
            '& .inner-box .animation-img-box img': {
              transform: 'translateY(-150%) scale(0.4) !important'
            }
          }
        }
      },
      '& .atm-card-animation-wrap': {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        pointerEvents: 'none',
        padding: theme.spacing(0, 3.125, 0, 0.5),
        position: 'absolute',
        top: '50%',
        left: theme.spacing(0),
        transform: 'translateY(-50%)',
        transition: '300ms all',
        // '&.has-value': {
        //   opacity: '0'
        // },
        '& .inner-box': {
          width: `calc(100% - ${theme.spacing(7.5)})`,
          height: theme.spacing(1),
          backgroundColor: theme.palette.solidColor.dark,
          borderRadius: theme.spacing(1),
          position: 'relative',
          '& .animation-img-box': {
            width: `calc(100% - ${theme.spacing(1)})`,
            aspectRatio: '162/91',
            overflow: 'hidden',
            pointerEvents: 'all',
            cursor: 'pointer',
            position: 'absolute',
            top: theme.spacing(0.5),
            left: '50%',
            transform: 'translateX(-50%)',
            '& img': {
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              pointerEvents: 'none',
              transform: 'translateY(-150%)',
              transition: '600ms all',
              // '&.animated-img': {
              //   animationName: 'cardATM',
              //   animationDuration: '5s',
              //   animationTimingFunction: 'linear',
              //   animationIterationCount: '1',
              //   animationDirection: 'alternate',
              //   '@keyframes cardATM': {
              //     '0%': {
              //       transform: 'translateY(-150%) scale(0.4)'
              //     },
              //     '100%': {
              //       transform: 'translateY(-3%) scale(1)'
              //     }
              //   }
              // },
              '&.scroll-top-hide': {
                opacity: '0',
                transition: '300ms all'
              }
            }
          }
        }
      }
    },
    '& .providers-popup-wrap': {
      width: '100%',
      height: theme.spacing(3.25),
      maxWidth: theme.spacing(38.75),
      backgroundColor: theme.palette.formInput.dark,
      border: `1px solid ${theme.palette.formInput.borderDark}`,
      borderRadius: theme.spacing(0.5),
      color: theme.palette.formInput.contrastTextWhite,
      fontSize: theme.spacing(1),
      fontWeight: '400',
      lineHeight: theme.spacing(1.125),
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      cursor: 'pointer',
      padding: theme.spacing(0, 0.875),
      position: 'absolute',
      bottom: theme.spacing(0),
      left: '50%',
      transform: `translateX(calc(-50% - ${theme.spacing(2)}))`
    },
    [theme.breakpoints.down('md')]: {
      '& .slider-wrap': {
        transform: 'translate(-50%, -127%)',
        '& .swiper-pagination': {
          display: 'none'
          // transform: `translate3d(0, ${theme.spacing(0.875)}, 0)`,
          // '& .swiper-pagination-bullet': {
          //   width: theme.spacing(0.75),
          //   height: theme.spacing(0.75),
          // }
        }
      },
      '& .providers-popup-wrap': {
        maxWidth: '45vw',
        height: theme.spacing(2),
        fontSize: theme.spacing(0.75),
        lineHeight: theme.spacing(0.875),
        bottom: theme.spacing(-0.25),
        transform: `translateX(calc(-50% - ${theme.spacing(1)}))`
      },
      '& .providers-dropdown-wrap, & .MuiFormControl-root': {
        '& .atm-card-animation-wrap': {
          padding: theme.spacing(0, 2.625, 0, 0.5),
          '& .inner-box': {
            width: `calc(100% - ${theme.spacing(6)})`,
            height: theme.spacing(0.5),
            borderRadius: theme.spacing(0.5),
            '& .animation-img-box': {
              top: theme.spacing(0.25)
            }
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .providers-popup-wrap': {
        maxWidth: '49vw',
        height: theme.spacing(1.5),
        fontSize: theme.spacing(0.625),
        lineHeight: theme.spacing(0.75),
        transform: `translateX(calc(-50% + ${theme.spacing(0)}))`
      },
      '& .providers-dropdown-wrap, & .MuiFormControl-root': {
        bottom: '4%',
        '& .atm-card-animation-wrap': {
          padding: theme.spacing(0, 1, 0, 0.5),
          '& .inner-box': {
            width: `calc(100% - ${theme.spacing(4.5)})`,
            height: theme.spacing(0.5),
            borderRadius: theme.spacing(0.5),
            '& .animation-img-box': {
              top: theme.spacing(0.25)
            }
          }
        }
      }
    },
    [theme.breakpoints.down('sd')]: {
      '& .slider-wrap': {
        transform: 'translate(-50%, -126%)'
      },
      '& .providers-popup-wrap': {
        maxWidth: '48vw',
        height: theme.spacing(1.25),
        fontSize: theme.spacing(0.5),
        lineHeight: theme.spacing(0.6255),
        transform: `translateX(calc(-50% + ${theme.spacing(0.5)}))`
      },
      '& .providers-dropdown-wrap, & .MuiFormControl-root': {
        '& .atm-card-animation-wrap': {
          padding: theme.spacing(0, 0.5, 0, 0.5),
          '& .inner-box': {
            width: `calc(100% - ${theme.spacing(3)})`,
            height: theme.spacing(0.25),
            borderRadius: theme.spacing(0.25),
            '& .animation-img-box': {
              top: theme.spacing(0.125)
            }
          }
        }
      }
    }
  }
})
