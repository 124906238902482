import React, { useState } from 'react'
import { Buffer } from 'buffer'
import { useTheme } from '@emotion/react'
import { Box, Grid } from '@mui/material'
import { ChangePasswordWrapper } from './style'
import {
  CustomFormLabel,
  CustomPasswordInput
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { changePasswordSchema } from 'components/layout/AuthContentSection/schema'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import { useDispatch, useSelector } from 'react-redux'
import { updatePassword } from 'redux-thunk/thunk/auth.thunk'
import { SpinnerSVG } from 'assets/icons/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'

const ChangePassword = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }
  const handleMouseDownCurrentPassword = (event) => {
    event.preventDefault()
  }

  const [showNewPassword, setShowNewPassword] = useState(false)
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault()
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault()
  }

  const handleSave = (formValues) => {
    dispatch(updatePassword({ password: Buffer.from(formValues.password).toString('base64'), newPassword: Buffer.from(formValues.newPassword).toString('base64') }))
  }

  return (
    <>
      <ChangePasswordWrapper theme={theme} className='content-wrapper'>
        <Formik
          enableReinitialize
          initialValues={{
            password: '',
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={changePasswordSchema(t)}
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={(formValues) => handleSave(formValues)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors
          }) => {
            return (
              <Form autoComplete='off'>
                <Box component='form' noValidate>
                  <Grid container spacing={1.25}>
                    <Grid item xs={12} lg={6}>
                      <CustomFormLabel>{t('profileCurrentPassword')}</CustomFormLabel>
                      <CustomPasswordInput
                        fullWidth
                        showPassword={showCurrentPassword}
                        handleClickShowPassword={handleClickShowCurrentPassword}
                        handleMouseDownPassword={handleMouseDownCurrentPassword}
                        name='password'
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('profileCurrentPassword')}
                      />
                      <ErrorMessage
                        component='div'
                        name='password'
                        key='password'
                        className='error-message'
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={1.25}>
                        <Grid item xs={12} lg={6}>
                          <CustomFormLabel>{t('profileNewPassword')}</CustomFormLabel>
                          <CustomPasswordInput
                            fullWidth
                            showPassword={showNewPassword}
                            handleClickShowPassword={handleClickShowNewPassword}
                            handleMouseDownPassword={handleMouseDownNewPassword}
                            placeholder={t('profileNewPassword')}
                            name='newPassword'
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component='div'
                            name='newPassword'
                            key='newPassword'
                            className='error-message'
                          />
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          <CustomFormLabel>{t('profileConfirmPassword')}</CustomFormLabel>
                          <CustomPasswordInput
                            fullWidth
                            showPassword={showConfirmPassword}
                            handleClickShowPassword={handleClickShowConfirmPassword}
                            handleMouseDownPassword={handleMouseDownConfirmPassword}
                            placeholder={t('profileConfirmPassword')}
                            name='confirmPassword'
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component='div'
                            name='confirmPassword'
                            key='confirmPassword'
                            className='error-message'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={1.25} justifyContent='center'>
                        <Grid item xs={12} lg={4}>
                          <Box className='form-btn-box'>
                            <CustomPrimaryButton fullWidth onClick={handleSubmit}>{!loading
                              ? <>{t('save')}</>
                              : <SpinnerSVG />}
                            </CustomPrimaryButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </ChangePasswordWrapper>
    </>
  )
}

export default React.memo(ChangePassword)
