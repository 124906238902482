import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPaymentIdService, getPaymentTypeService } from 'network/services/payment.service'
import { setPaymentIdFailure, setPaymentIdSuccess } from 'redux-thunk/redux/slices/payment.slice'

export const getPaymentType = createAsyncThunk(
  '/getPaymentType',
  async (params, thunkApi) => {
    try {
      const res = await getPaymentTypeService(params)
      return thunkApi.fulfillWithValue(res?.providerDetails)
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getPaymentId = createAsyncThunk(
  '/getPaymentId',
  async (params, thunkApi) => {
    try {
      const res = await getPaymentIdService(params)
      thunkApi.dispatch(setPaymentIdSuccess(res))
    } catch (error) {
      thunkApi.dispatch(setPaymentIdFailure())
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
