export default {
  404: '404',
  vip: 'Vip',
  upcoming: 'Yaklaşan',
  finished: 'Bitmiş',
  prizePool: 'Ödül havuzu',
  joinNow: 'Şimdi Katıl',
  joined: 'Katıldı',
  startsIn: 'Içinde başlar',
  endsIn: 'Bitiş Tarihi',
  ended: 'Turnuva Sona Erdi',
  started: 'Turnuva Başladı',
  seeMore: 'Daha fazla gör',
  winPrizes: 'Heyecan verici Ödüller kazanın',
  leaderBoard: 'Liderler Sıralaması',
  prize: 'Ödül',
  aboutTournament: 'Turnuva Hakkında',
  congratulationsMessage: 'Bravo! ',
  payments: 'Ödeme',
  tournaments: 'Turnuvalar',
  shop: 'Mağaza',
  blog: 'Blog',
  referral: 'Referans',
  contacts: 'Kişiler',
  retry: 'Yeniden dene',
  incorrectCredsMessage: 'Kullanıcı adınız veya şifreniz hatalı. ',
  bonuses: 'Bonus',
  depositBonus: 'Para Yatırma Bonusu',
  voucher: 'Kupon',
  gamlings: 'Kumar',
  transactionHistory: 'İşlem Geçmişi',
  offers: 'Teklifler',
  editProfile: 'Profili Düzenle',
  continue: 'Devam etmek',
  apply: 'Uygula',
  male: 'Erkek',
  female: 'Dişi',
  register: 'Kayıt olmak',
  login: 'Giriş yapmak',
  grand: 'Büyük',
  major: 'Ana',
  minor: 'Küçük',
  mini: 'Mini',
  crashGameTitle: 'Kaza',
  betAmount: 'Bahis Tutarı',
  profit: 'Kâr',
  payout: 'Hedef Ödeme',
  chance: 'Kazanma Şansı',
  betBtn: 'Bahis',
  autoCashout: 'Otomatik Para Çekme',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Maksimum',
  wagered: 'Bahis yapıldı',
  nothingFound: 'Hiçbirşey Bulunamadı',
  lost: 'Kayıp',
  won: 'Kazanmak',
  streak: 'Rüzgâr gibi geçmek',
  highestWin: 'En Yüksek Kazanç',
  promotions: 'Promosyonlar',
  latestPromotions: 'Son Promosyonlar',
  sponsorships: 'Sponsorluklar',
  highestLost: 'En Yüksek Kayıp',
  highestBet: 'En Yüksek Bahis',
  maxStreak: 'Maksimum Seri',
  maxProfit: 'Maksimum Kar: ',
  bal: 'Bal: ',
  messagesNoBetHistory: 'Bahis geçmişiniz boş',
  messagesCopyNotSupported: 'Tarayıcınız otomatik Panoya Kopyalamayı desteklemiyor. Lütfen kodu manuel olarak kopyalamayı deneyin.',
  messagesCopied: 'Adres kopyalandı.',
  deposit: 'Depozito',
  statReset: 'İstatistikleri sıfırla',
  rollOver: 'Yuvarlanmak',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Geri',
  inputFieldBtnMax: 'maksimum',
  buttonTextPlaceBet: 'Bahis yeri',
  buttonTextCancelBet: 'Bahsi İptal Et',
  buttonTextEscapeBet: 'Param bitti',
  buttonTextStartAutoBet: 'Otomatik Bahsi Başlat',
  buttonTextCancelAutoBet: 'Otomatik Bahsi İptal Et',
  buttonTextLoading: 'Yükleniyor',
  tabsMyBets: 'Bahislerim',
  tabsDeposit: 'Depozito',
  tabsCashout: 'Para çekme',
  tabsAllbets: 'Tüm Bahisler',
  tabsTopbets: 'En İyi Bahisler',
  tabsBonus: 'Bonuslar',
  tabsjackpot: 'İkramiye',
  tabsBetHistory: 'Bahis Geçmişi',
  timerTextCurrentPayout: 'Mevcut ödeme',
  timerTextStarting: 'İçinde başlayan',
  timerTextSec: 'S',
  timerTextFlewAway: 'Çöktü',
  timerTextWait: 'Lütfen bekleyin..',
  timerTextPreparingRound: 'Tur hazırlanıyor',
  timerTextLoadingRound: 'Tur Yükleniyor',
  comingSoon: 'Yakında gelecek',
  labelsProfitOnWin: 'Galibiyetten kâr',
  inputsNoOfBets: 'Bahis sayısı',
  inputsAuto: 'Oto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Başlangıç',
  inputsCancel: 'İptal etmek',
  buttonsMin: 'Min.',
  buttonsMax: 'Maksimum',
  prediction: 'Tahmin',
  luckyNumber: 'Şanslı numara',
  multiplier: 'Çarpan',
  chanceToWin: 'Kazanma şansı',
  bets: 'Bahisler',
  depositBtn: 'Depozito',
  rollUnder: 'Altına Dön',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Tarih',
  navBarMyBets: 'Bahislerim',
  navBarAllBets: 'Tüm Bahisler',
  navBarHighRollers: 'Yüksek makaralar',
  navBarRoundHistory: 'Yuvarlak Tarih',
  navBarTotal: 'Toplam Bahisler',
  topHeaderBetId: 'Bahis Kimliği',
  topHeaderUser: 'Kullanıcı',
  topHeaderPayout: 'Ödeme',
  topHeaderAmount: 'Miktar',
  topHeaderTime: 'Zaman',
  topHeaderProfit: 'Kâr',
  topHeaderRoundCrashed: 'Çöktü',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Doğramak',
  topHeaderRoundBet: 'Bahis',
  topHeaderRoundProfit: 'Kâr',
  topHeaderRoundSignature: 'İmza',
  roundHistoryTitle: 'OYUN BİLGİLERİ',
  roundHistoryDescGame: 'Oyun',
  roundHistoryDescCrashAt: 'Kaza Yeri:',
  roundHistoryDescDate: 'Tarih :',
  roundHistoryDescBetHeader: 'Bahis',
  roundHistoryTableTitlePlayer: 'OYUNCU',
  roundHistoryTableTitleBet: 'BAHİS',
  roundHistoryTableTitleCashedOut: 'NAKDE OLDU',
  roundHistoryTableTitleProfit: 'KÂR',
  roundHistoryTableTitleBetId: 'BAHİS KİMLİĞİ',
  roundHistoryMessagesNoBetHistory: 'Bahis geçmişiniz boş',
  placedBetsTabPlaced: 'Bahis Yapıldı',
  placedBetsTabPrev: 'Önceki Bahis',
  placedBetsTitleUser: 'Kullanıcı',
  placedBetsTitleEscape: 'Çoklu.x',
  placedBetsTitleBet: 'Bahis',
  placedBetsTitleProfit: 'Kâr',
  placedBetsTitleCashout: 'Param bitti',
  placedBetsNoPlacedBet: 'Bahis konulmadı.',
  provablyFairContentCheckBtn: 'Kanıtlanabilir Adil Kontrol Edin',
  provablyFairContentTitle: 'Kesinlikle Adil',
  provablyFairContentDesc: 'Dört taraflı liderliğin sonucunun adil olduğu kanıtlanabilir. ',
  provablyFairContentInputFieldsCrashRateTitle: 'Kilitlenme Oranı',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'X olmadan kilitlenme oranı',
  provablyFairContentInputFieldsRoundHashTitle: 'Yuvarlak Karma',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'Örneğin. ',
  provablyFairContentInputFieldsSubmitBtn: 'İmza Oluştur',
  provablyFairContentSignature: 'Yuvarlak İmza',
  provablyFairContentErrorsCrashRate: 'lütfen kilitlenme oranı alanına sayıyı girin',
  resetPasswordTitle: 'Şifreyi yenile',
  resetPasswordNewPasswordTitle: 'Yeni Şifre',
  resetPasswordNewPasswordErrorsRequired: '*Şifre gereklidir.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum 8 karaktere izin verilir.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maksimum 16 karaktere izin verilir.',
  resetPasswordNewPasswordErrorsPattern: '*Şifre 8 ila 16 alfanümerik ve özel karakterden oluşmalıdır. ',
  resetPasswordConfirmPasswordTitle: 'Şifreyi Onayla',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Parolalar uyuşmuyor.',
  resetPasswordResetBtn: 'Sıfırla',
  passwordResetSuccess: 'Şifre başarıyla değiştirildi, oyun oynamanın keyfini çıkarmak için lütfen giriş yapın!',
  resetPasswordWentWrong: 'Bir şeyler yanlış gitti!',
  levelGrand: 'BÜYÜK',
  levelMajor: 'ANA',
  levelMinor: 'KÜÇÜK',
  levelMini: 'MİNİ',
  bannerContentWinDaily: 'Günlük 1000$ Kazanın ',
  bannerContentContest: 'Şimdiki yarışmalar ',
  bannerContentjoin: 'Günlük bahis yarışmamıza katılın ve Kahraman olun!  ',
  bannerContentPlayNow: 'Şimdi oyna',
  bannerContentTitle: 'İkramiyeyi Kazan',
  bannerContentContent: 'Daha fazla oyna',
  bannerContentGameName: 'KAZA OYUNU',
  bannerContentGameDesc: 'Daha fazla oyna',
  partners: 'Ortaklar',
  gamesGoldenDragon: 'altın Ejderha',
  gamesCrashGame: 'Çarpışma Oyunu',
  gamesDice: 'Zar',
  gamesHilo: 'Merhaba',
  allBetsTitle: 'Tüm Bahisler',
  allBetsBetId: 'Bahis Kimliği',
  allBetsUser: 'Kullanıcı',
  allBetsPayout: 'Ödeme',
  allBetsAmount: 'Miktar',
  allBetsTime: 'Zaman',
  allBetsProfit: 'Kâr',
  LoginwithOtp: 'Otp ile giriş yap',
  EnterLoginOtp: "Giriş Otp'sine girin",
  submit: 'Göndermek',
  footerAboutUs: 'Hakkımızda',
  footerFaq: 'SSS',
  footerSitePolicy: 'Site Politikası',
  footerResponsibleGambling: 'Sorumlu Kumar',
  footerTerms: 'Şartlar ve koşullar',
  signinTitle: 'Giriş yapmak',
  signinUsername: 'Kullanıcı adı / E-posta',
  signinPassword: 'Şifre',
  signinLoginBtn: 'Giriş yapmak',
  signinSignup: 'Üye olmak!',
  signinForgetPassword: 'Parolanızı mı unuttunuz?',
  forgotPasswordMsg: 'Hesabınızla ilişkili e-posta adresini girin; size şifrenizi sıfırlamanız için bir bağlantı göndereceğiz.',
  emailLinkMsg: 'Şifre sıfırlama bağlantısı kayıt e-posta adresinize gönderildi, lütfen e-posta bağlantısına tıklayın ve yeni şifrenizi belirleyin.',
  resetPasswardConfirm: 'Şifre başarıyla sıfırlandı',
  signinDontHaveAccount: 'Hesabınız yok mu? ',
  signinCreateAccount: 'Hesap oluşturmak',
  signinErrorsUserNameRequired: '*Lütfen Kullanıcı Adı/E-posta adresini girin.',
  signinErrorsUserNameMinLength: 'Minimum 4 karaktere izin verilir.',
  signinErrorsUserNameMaxLength: 'Maksimum 20 karaktere izin verilir.',
  signinErrorsUserNamePattern: 'Lütfen geçerli bir kullanıcı adı girin, yalnızca harf ve sayılara izin verilir.',
  signinErrorsPasswordRequired: '*Şifre gereklidir.',
  signinErrorsPasswordMinLength: 'Minimum 8 karaktere izin verilir.',
  signinErrorsPasswordMaxLength: 'Maksimum 16 karaktere izin verilir.',
  signinErrorsPasswordPattern: '*Şifre 8 ila 16 alfanümerik ve özel karakterden oluşmalıdır. ',
  signinSuccessfullyLoggedIn: 'Başarıyla oturum açıldı.',
  signinResetMailSent: 'Parolayı sıfırlayın e-postası gönderildi.',
  signinTryAgain: 'Bir şeyler ters gitti lütfen tekrar deneyin.',
  signinResetPasswordUsernameErr: 'Şifreyi sıfırlamak için lütfen kullanıcı adını/e-posta adresini girin.',
  signinResetMailSentSuccessContent: 'E-postanıza şifre sıfırlama bağlantısı gönderdik. ',
  signupTitle: 'Kayıt olmak',
  signupUsername: 'Kullanıcı adı',
  signupEmail: 'E-posta',
  signupTermsConditions: 'Şartlar',
  signupWalletConnect: 'Cüzdan ile Giriş Yapın ',
  signupPassword: 'Şifre',
  signupEighteenPlus: '18 yaşının üzerindeyim ve Hizmet Şartlarını kabul ediyorum',
  signupRegisterBtn: 'Üye olmak',
  signupCreateNewAccount: 'Yeni hesap oluştur',
  signupFullName: 'Ad Soyad',
  signupReferralCode: 'Yönlendirme Kodu',
  signupConfirmPassword: 'Şifreyi Onayla',
  signupUseSocialAccount: 'Sosyal ağlar üzerinden giriş yapın',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Hesabınız var mı? ',
  signupLoginBtn: 'Giriş yapmak',
  signupErrorsUserNameRequired: '*Lütfen kullanıcı adınızı giriniz.',
  signupErrorsUserNameMinLength: 'Minimum 4 karaktere izin verilir.',
  signupErrorsUserNameMaxLength: 'Maksimum 20 karaktere izin verilir.',
  signupErrorsUserNamePattern: 'Lütfen geçerli bir kullanıcı adı girin, yalnızca harf ve sayılara izin verilir.',
  signupErrorsFullNameRequired: '*Lütfen tam adınızı giriniz.',
  signupErrorsFullNamePattern: 'Lütfen geçerli tam adınızı girin, yalnızca harf ve sayılara izin verilir.',
  signupErrorsEmailRequired: '*Lütfen e-posta giriniz.',
  signupErrorsEmailMinLength: 'Minimum 4 karaktere izin verilir.',
  signupErrorsEmailMaxLength: 'Maksimum 20 karaktere izin verilir.',
  signupErrorsEmailPattern: 'Lütfen geçerli bir e-posta girin.',
  signupErrorsPasswordRequired: '*Şifre gereklidir.',
  signupErrorsPasswordMinLength: 'Minimum 8 karaktere izin verilir.',
  signupErrorsPasswordMaxLength: 'Maksimum Para Yatırma16 karaktere izin verilir.',
  signupErrorsPasswordPattern: '*Şifreniz 8 ila 16 haneli alfanümerik (büyük-küçük harf) ve özel karakterlerden oluşmalıdır. ',
  signupErrorsConfirmPasswordMatch: 'Şifre uyuşmalı.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'Örneğin. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: 'Bir şeyler yanlış gitti!',
  signupSignupGoogle: "Google'a başarıyla kaydolun.",
  signupRegistered: 'Başarıyla kaydedildi.',
  signupConfirmAge: 'Lütfen 18 yaşında olduğunuzu onaylayın.',
  signupSignupFacebook: "Facebook'a başarıyla kaydolun.",
  signupCookiesConsent: 'Lütfen tarayıcınızın ayarlarından üçüncü taraf çerezlerinin Sosyal girişi kullanmasına izin verin.',
  signupRegisterEmailPopupContent: 'Size bir doğrulama e-postası gönderdik. ',
  accountMenuDeposit: 'Depozito',
  accountMenuCashout: 'Param bitti',
  accountMenujackpotWinners: 'İkramiye Kazananlar',
  accountMenuTransactions: 'İşlemler',
  accountMenuPromotions: 'Promosyonlar',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Çıkış Yap',
  accountMenuSound: 'Ses',
  accountMenuMusic: 'Müzik',
  accountMenuWithdraw: 'Geri çekilmek',
  pageNotFound: 'Aradığınız sayfayı bulamadık!',
  error: 'Hata',
  somethingWentWrong: 'Bir şeyler yanlış gitti!',
  verifyEmailThankYou: 'Teşekkür ederim!',
  verifyEmailEmailIsVerified: 'E-postanız doğrulandı. ',
  verifyEmailGoToLogin: 'Giriş sayfasına gidin',
  verifyEmailWentWrong: 'Bir şeyler yanlış gitti!',
  verifyEmailTokenExpired: 'E-posta zaten doğrulandı veya bağlantının süresi doldu, lütfen kayıtlı e-posta adresinizi aşağıya sağlayarak e-posta doğrulama bağlantısını yeniden gönderin. ',
  verifyEmailGoToHome: 'Ana sayfaya git',
  verifyEmailSuccessMsg: 'E-posta başarıyla doğrulandı.',
  bonusSectionSpanText: 'en cömert ve çeşitli sunar',
  bonusSectionBonuses: 'Bonuslar',
  bonusSectionSubtitleText: 'Zevkle oynayın ve oyun sırasında ek ödüller kazanın. ',
  JoiningBonusTitle: 'Katılma Bonusu',
  JoiningBonusButton: 'Şimdi Talep Et',
  JoiningBonusDesc: 'Bu tür bonuslar, çevrimiçi kumarhane oyuncularını Bitcoin veya diğer kripto paraları kullanarak kumar oynamaya teşvik eder. ',
  depositBonusTitle: 'Para Yatırma Bonusu',
  depositBonusButton: 'Depozito',
  depositBonusDesc: 'Bu tür bonuslar, çevrimiçi kumarhane oyuncularını Bitcoin veya diğer kripto paraları kullanarak kumar oynamaya teşvik eder. ',
  refferBonusTitle: 'Referans Bonusu',
  refferBonusButton: 'Depozito',
  refferBonusDesc: 'Bu tür bonuslar, çevrimiçi kumarhane oyuncularını Bitcoin veya diğer kripto paraları kullanarak kumar oynamaya teşvik eder. ',
  aboutSectionSpanText2: 'Çevrimiçi kumarhane, favori oyunlarınızı ücretsiz veya kripto para birimiyle oynamanıza olanak tanır. ',
  aboutSectionSpanText3: ' Bitcoin kumarının bu sanal dünyasında, en sevdiğiniz casino oyunlarını oynamak, bonuslar almak, yarışmalara ve promosyonlara katılmak gibi birçok fırsattan yararlanabilirsiniz.',
  verifiedSectionTitle: 'Doğrulanmış ve Lisanslı Yazılım',
  verifiedSectionSpanText1: 'Başka web sitelerinden yazılım kopyalamayız veya benimsemeyiz. ',
  faqSectionTitle: 'Oyunculardan SSS',
  faqSectionForgetPasswordTitle: 'Şifremi Unuttum.',
  faqSectionForgetPasswordDesc: 'Hesabınız için bir kurtarma e-posta adresi sağlamadınız. ',
  faqSectionMyDepositCreditedTitle: 'Yatırdığım para yatırılmadı.',
  faqSectionMyDepositCreditedDesc: ' Sınıfı göstermeyi amaçlayan bu akordeon için yer tutucu içerik. ',
  SupportedCurrencyTitle: 'Desteklenen Para Birimi',
  SupportedCurrencySpanText1: 'Başka web sitelerinden yazılım kopyalamayız veya benimsemeyiz. ',
  CarouselSectionTitle: 'BEKLEME KAZASI',
  CarouselSectionSubTitle: ' Daha fazla oyun oynayın ve kazanma şansını yakalayın. ',
  WalletSettingTitle: 'Cüzdan Ayarı',
  WalletSettingHideZero: 'Sıfır bakiyeyi gizle',
  WalletSettingHideZeroInfo: 'Sıfır bakiyeniz cüzdanınızda görünmeyecek',
  WalletSettingDisplayFiat: 'Kriptoyu fiat cinsinden göster',
  WalletSettingDisplayFiatInfo: 'Tüm bahisler',
  WalletSettingNoteForApprox: 'Lütfen bunların para birimi cinsinden yaklaşık değerler olduğunu unutmayın.',
  WalletSettingSave: 'Kaydetmek',
  WalletSettingNoWalletFound: 'Cüzdan bulunamadı',
  jackpotWinnersNoWinnerAvailable: 'Kazanan yok',
  jackpotWinnersNew: 'Yeni',
  jackpotWinnersHeader: 'İkramiye Kazananı',
  jackpotDetailsHeader: 'İkramiye detayı',
  jackpotDetailsCampaignPeriod: 'Kampanya Dönemi',
  jackpotDetailsFrom: 'İtibaren',
  jackpotDetailsTo: 'İle',
  jackpotDetailsBettingRule: 'Bahis Kuralı',
  jackpotDetailsMinBetAmount: 'Min. bahis tutarı',
  jackpotDetailsMaxBetAmount: 'Maksimum bahis tutarı',
  jackpotDetailsBetPercentage: 'Bahis yüzdesi',
  jackpotDetailsWinningRuleHeader: 'Kazanma Kuralı',
  jackpotDetailsWinningRule: 'Bahis miktarı yüksek olan bahislerin oynanması kazanma şansını artıracaktır.',
  jackpotDetailsProfitRule: 'Bahis tutarı minBetAmount ile maxBetAmount arasındaysa, bahis tutarının betPercentage% değeri jackpot tutarına eklenecektir.',
  profileTitle: 'Kişisel detaylar',
  profileFName: 'İlk adı',
  profileLname: 'Soy isim',
  profileUserName: 'Kullanıcı adı',
  profileDob: 'Doğum tarihi',
  profileEmail: 'E-posta Adresi',
  profilePhone: 'Telefon numarası',
  profileChange: 'Değiştirmek',
  profileSave: 'Değişikliği kaydet',
  profilePassword: 'Şifre',
  profileSubmit: 'Göndermek',
  profileUploadProfile: 'Profil Yükle',
  profileCurrentPassword: 'Mevcut Şifre',
  profileNewPassword: 'Yeni Şifre',
  profileConfirmPassword: 'Şifreyi Onayla',
  profileScanQRCode: 'QR Kodunu Tara',
  profileEnterSecretCode: 'Gizli Kodu Girin',
  profileErrorsFirstNameRequired: 'Ad gereklidir.',
  profileErrorsFirstNamePattern: 'Lütfen geçerli bir ad girin.',
  profileErrorsFirstNameMinLength: 'Minimum 3 karaktere izin verilir.',
  profileErrorsFirstNameMaxLength: 'Maksimum 20 karaktere izin verilir.',
  profileErrorsLastNameRequired: 'Soyadı gerekli.',
  profileErrorsLastNamePattern: 'Lütfen geçerli bir ad girin.',
  profileErrorsLastNameMinLength: 'Minimum 3 karaktere izin verilir.',
  profileErrorsLastNameMaxLength: 'Maksimum 20 karaktere izin verilir.',
  profileErrorsChangePasswordOldRequired: 'Eski şifre gerekli.',
  profileErrorsChangePasswordOldPattern: 'Lütfen geçerli bir şifre girin.',
  profileErrorsChangePasswordOldMinLength: 'Minimum 8 karaktere izin verilir.',
  profileErrorsChangePasswordOldMaxLength: 'Maksimum 16 karaktere izin verilir.',
  profileErrorsChangePasswordNewRequired: 'Yeni şifre gerekli.',
  profileErrorsChangePasswordNewPattern: 'Lütfen geçerli bir şifre girin.',
  profileErrorsChangePasswordNewMinLength: 'Minimum 8 karaktere izin verilir.',
  profileErrorsChangePasswordNewMaxLength: 'Maksimum 16 karaktere izin verilir.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Parolalar uyuşmuyor.',
  profileErrorsChangePasswordNewPasswordMatched: 'Yeni şifre mevcut şifre ile aynı olamaz.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Şifreyi onaylamanız gerekiyor.',
  profileErrorsUserNameRequired: 'Kullanıcı adı gerekli.',
  profileErrorsUserNamePattern: 'Lütfen geçerli bir ad girin.',
  profileErrorsUserNameMinLength: 'Minimum 3 karaktere izin verilir.',
  profileErrorsUserNameMaxLength: 'Maksimum 20 karaktere izin verilir.',
  profileErrorsDobRequired: 'Doğum tarihi gereklidir.',
  profileErrorsEmailRequired: 'Email gereklidir.',
  profileErrorsPostcodeRequired: 'Posta kodu gerekli.',
  profileErrorsCityRequired: 'Şehir gereklidir.',
  profileErrorsCurrencyRequired: 'Para birimi gereklidir.',
  profileErrorsAddressRequired: 'Adres gerekli.',
  profileErrorsCountryRequired: 'Ülke gerekli.',
  profileErrorsEmailPattern: 'Lütfen geçerli bir e-posta girin.',
  profileErrorsEmailMinLength: 'Minimum 3 karaktere izin verilir.',
  profileErrorsEmailMaxLength: 'Maksimum 20 karaktere izin verilir.',
  profileMessagesProfileUpdate: 'Profil başarıyla güncellendi.',
  profileMessagesChangePassword: 'Şifre başarıyla güncellendi.',
  profileProfilePicProfilePicUpload: 'Profil resmini yükle',
  profileNoNewChanges: 'Profil güncellenemedi! ',
  profileLoggedInWithSocial: 'Sosyal giriş ile giriş yaptınız.',
  profileSideNavigatorSound: 'Ses',
  profileSideNavigatorMusic: 'Müzik',
  profileSideNavigatorProfile: 'Benim profilim',
  profileSideNavigatorBetHistory: 'Bahis Geçmişi',
  profileSideNavigatorTransactions: 'İşlemler',
  profileSideNavigatorDeposit: 'Depozito',
  profileSideNavigatorWithdraw: 'Geri çekilmek',
  profileSideNavigatorGameLimits: 'Oyun Sınırları',
  profileSideNavigatorReferral: 'Referans',
  profileSideNavigatorChangePassword: 'Şifre değiştir',
  profileSideNavigatorTermsConditions: 'Şartlar',
  profileSideNavigatorLogOut: 'Çıkış Yap',
  profileSideNavigatorProvablyFair: 'Kesinlikle Adil',
  WalletInfoSectionNotAvailable: 'Müsait değil',
  WalletInfoSectionRealBalance: 'Gerçek Denge',
  GameLimitsMinimumBet: 'Minimum bahis',
  GameLimitsMaximumBet: 'Maksimum bahis',
  GameLimitsMaxWinFor1Bet: 'Bir bahiste maksimum kazanç',
  ProvablyFairSubTitle: 'Bu oyun, oyun sonucunu belirlemek için Provively Fair teknolojisini kullanır. ',
  ProvablyFairMaximumBet: 'Maksimum bahis:',
  ProvablyFairMaxWinFor1Bet: 'Bir bahiste maksimum kazanç',
  promotionsTitle: 'Promosyonlar',
  promotionsNoLosingData: 'Üzgünüz, Kaybetme bonus promosyonları mevcut değil ',
  promotionsNoDepositData: 'Üzgünüz, Para Yatırma Bonusu Promosyonları mevcut değil ',
  promotionsCurrency: ' para birimi.',
  promotionsViewBtn: 'Görüş',
  promotionsClaimSuccess: 'Başarılı bir şekilde talepte bulundunuz',
  promotionsAvailability: 'Kullanılabilirlik: ',
  promotionsAvailabilityTabLosing: 'Bonusu kaybetmek',
  promotionsAvailabilityTabDeposit: 'Para yatırma bonusu',
  promotionsDepositBonusTableCode: 'Kod',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Yüzde',
  promotionsDepositBonusTableMaxBonus: 'Maksimum Bonus',
  promotionsDepositBonusTableRolloverMultipler: 'Rollover çarpanı',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Bahis başına maksimum rollover',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'Devretme hedefine ulaşma zamanı',
  promotionsBonusCode: 'BONUS KODU: ',
  promotionsLossesClaim: 'Zararlar (kampanya süresi içinde) için talep edilebilir: ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Yüzde',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Bonus Durumu: ',
  promotionsBonusAmount: 'Bonus Tutarı: ',
  promotionsRolloverTarget: 'Devrilme hedefi: ',
  promotionsRolloverAchieved: 'Devrilme elde edildi: ',
  promotionsActivatebtn: 'ETKİNLEŞTİR',
  promotionsClaimBtn: 'İDDİA',
  promotionsCancelBtn: 'İPTAL ETMEK',
  promotionsBackBtn: 'GERİ',
  promotionsBonus: 'Bonus',
  promotionsTermAndConditions: ' Şartlar',
  promotionsClaimDepositBonusTitle: 'Para yatırma bonusu talep etme adımları',
  promotionsClaimDepositBonusFirst: 'Bonus kodunu etkinleştir',
  promotionsClaimDepositBonusSecond: 'Cüzdana yatırılan tutar',
  promotionsClaimDepositBonusThird: 'Bonus talep etmek için devretme hedefine ulaşın',
  promotionsClaimLosingBonusTitle: 'Kaybetme bonusunu talep etme adımları',
  promotionsClaimLosingBonusFirst: 'Bonus kodunu etkinleştir',
  promotionsClaimLosingBonusSecond: 'En sevdiğiniz oyunları oynayın',
  promotionsClaimLosingBonusThird: "Kampanya dönemi içindeki kayıpları talep etmek için 'Kayıpları talep et'i tıklayın",
  promotionsWentWrong: 'Bir şeyler yanlış gitti!',
  transactionTitle: 'İşlemler',
  transactionBetId: 'Bahis Kimliği',
  transactionUser: 'Kullanıcı',
  transactionPayout: 'Ödeme',
  transactionAmount: 'Miktar',
  transactionProfit: 'Kâr',
  transactionCashout: 'Param bitti',
  transactionBet: 'Bahis',
  transactionMultix: 'Çoklu.x',
  transactionWin: 'Kazanç',
  transactionFairness: 'Adalet',
  transactionReferFriend: 'Bir Arkadaşınıza Tavsiye Edin',
  transactionTotal: 'Toplam Bahisler',
  transactionWins: 'galibiyet',
  transactionRefresh: 'Yenile',
  transactionFilterTopMultipliers: 'En Çok Çarpanlar',
  transactionFilterHugeWins: 'Büyük Kazanımlar',
  transactionFilterBiggestWins: 'En Büyük Kazanımlar',
  transactionFilterMultipliers: 'Çarpanlar',
  transactionRealBalance: 'Gerçek Denge',
  depositWithdrawTXDate: 'Tarih',
  depositWithdrawTXAmount: 'Miktar',
  depositWithdrawTXTxId: 'TxID',
  depositWithdrawTXTxType: 'İşlem tipi',
  depositWithdrawTXStatus: 'Durum',
  depositWithdrawTXNoData: 'Veri yok.',
  depositTitle: 'Depozito',
  depositHistory: 'Tarih',
  depositSubTitle: 'Kullanıcı fiat para birimini yatıramaz veya çekemez. ',
  depositCopyNotSupported: 'Tarayıcınız otomatik Panoya Kopyalamayı desteklemiyor. Lütfen kodu manuel olarak kopyalamayı deneyin.',
  depositCopied: 'Adres kopyalandı.',
  depositAddressCreated: 'Adres başarıyla oluşturuldu.',
  depositGenerateAddress: 'Adres oluştur',
  depositWarningText: 'Lütfen zincirler arası transfer yoluyla para yatırmayın',
  withdrawTitle: 'Geri çekilmek',
  withdrawSubTitle: 'Kullanıcı fiat para birimini yatıramaz veya çekemez. ',
  withdrawNoteDesc: 'Lütfen LTC para çekme adresi cüzdanları olarak BEP2, BEP20 (BSC) adreslerini girmediğinizden emin olun.',
  withdrawNoteSublist: ' Lütfen zincirler arası transfer olarak çekilmeyin',
  withdrawHistory: 'Tarih',
  withdrawWithdrawBtn: 'Geri çekme talebi',
  withdrawFees: 'Para çekme ücreti {{fees}} {{token}}',
  withdrawWithdrawPending: 'Para çekme talebiniz beklemede. ',
  withdrawEnterFields: 'Para çekme tutarını ve cüzdan adresinizi girin.',
  withdrawSelectWallet: 'Lütfen çekilecek cüzdanı seçin.',
  withdrawAmountFieldTitle: 'Tutar (Min. 0,03) {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Senin {{wallet.currency.code}} adres',
  withdrawAddressFieldPlaceholder: 'Adresinizi yazın',
  withdrawNoBalance: 'Yeterli bakiye yok.',
  withdrawMinAmount: 'Lütfen şuna eşit veya daha büyük bir tutar girin: {{min}} .',
  fiatCurrencyInfo: 'Kullanıcı fiat para birimini yatıramaz veya çekemez. ',
  paginationNextBtn: 'Sonraki',
  paginationPrevBtn: 'Öncesi',
  wentWrong: 'Bir şeyler yanlış gitti.',
  referralTitle: 'Referans',
  referralSubTitle: 'Arkadaşlarınıza Tavsiye Edin',
  referralEarn: ' Kişi başına ₹150 kazanın',
  referralReferAndEarn: 'Her yönlendirme için yönlendirin ve bonus kazanın',
  referralReferPara: ' Bu kodu herhangi bir kullanıcıya yönlendirirseniz',
  referralReferLink: 'Bağlantıya bakın',
  referralCopyReferralCode: 'Referans Kodunu Kopyala',
  referralTotalRewards: 'Toplam Ödül',
  TwoWayAuthentication: 'İki Yönlü Kimlik Doğrulama',
  LoginRequired: 'Oturum açmak gereklidir!',
  BetPlacedSuccessfully: 'Bahis başarıyla yerleştirildi',
  BetAddedInQueueSuccessfully: 'Bahis başarıyla sıraya eklendi',
  BetRemovedFromQueueSuccessfully: 'Bahis kuyruktan başarıyla kaldırıldı',
  BetCancelledSuccessfully: 'Bahis başarıyla iptal edildi',
  PlayerEscapedSuccessfully: 'Oyuncu başarıyla kaçtı',
  CopiedSuccessfull: 'Başarıyla kopyalandı',
  NotEnoughBalance: 'Yeterli bakiye yok',
  AutoBetStarted: 'Otomatik bahis başlatıldı ',
  AutoBetFinished: 'Otomatik bahis bitti ',
  IdCopied: 'Kimlik kopyalandı',
  usersConsent: 'Bu siteye erişerek 18 yaşında olduğumu ve bu siteyi incelediğimi, anladığımı ve kabul ettiğimi onaylıyorum.',
  termsOfService: 'Kullanım Şartları',
  email: 'E-posta',
  playNow: 'Şimdi oyna',
  orContinueWith: 'Veya Devam Edin',
  metamaskExtensionNotAvailable: 'Lütfen metaMask uzantısını yükleyin ve sayfayı yenileyin.',
  cashier: 'Kasiyer',
  account: 'HESAP',
  hello: 'Merhaba',
  balance: 'Denge',
  referrals: 'Tavsiyeler',
  settings: 'Ayarlar',
  withdrawals: 'Para Çekme',
  demoWallet: 'Demo Cüzdanı',
  myAccount: 'Hesabım',
  wallet: 'Cüzdan',
  user: 'Kullanıcı',
  USD: 'Amerikan Doları',
  amountIsRequired: 'Tutar gerekli',
  minDepositAmount: 'Yatırılan tutar şu miktardan büyük olmalıdır: {{min}}.',
  maxDepositAmount: 'Depozito tutarı şu miktardan az olmalıdır: {{max}}.',
  minWithdrawAmount: 'Para çekme tutarı şundan büyük olmalıdır: {{min}}',
  maxWithdrawAmount: 'Çekilecek miktar daha az olmalı {{max}}',
  withdrawMustLessThanWallet: 'Para çekme tutarı cüzdan tutarınızdan az olmalıdır',
  accountTypeIsRequired: 'Hesap Türü gerekli.',
  mobileNumberLength: 'Cep telefonu numarası şu şekilde olmalıdır: {{length}} rakamlar',
  phoneIsRequired: 'Telefon gerekli',
  cpfError: 'CPF şu şekilde olmalıdır: {{length}} karakterler',
  cpfRequiredError: 'CPF gerekli',
  cnpjError: 'CNPJ şu şekilde olmalıdır: {{length}} karakterler',
  mainMenu: 'ANA MENÜ',
  casino: 'Kumarhane',
  sports: 'Spor Dalları',
  crypto: 'Kripto',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'ÖDÜLLER KAZANIN',
  rankUp: 'Rütbe',
  inviteFriends: 'Arkadaşları davet etmek',
  partnerProgram: 'Ortak Programı',
  home: 'Ev',
  sportsbook: 'Spor Bahisleri',
  menu: 'Menü',
  liveBets: 'CANLI BAHİSLER',
  allBets: 'Tüm bahisler',
  highRollers: 'Yüksek makaralar',
  luckyBets: 'Şanslı Bahisler',
  trade: 'Ticaret',
  game: 'OYUN',
  player: 'OYUNCU',
  time: 'ZAMAN',
  wager: 'BAHİS',
  multiplierBan: 'ÇARPAN',
  payoutText: 'ÖDEME',
  bannerHeadOne: 'Gerçek yeniliği en yüksek düzeyde deneyimleyin',
  bannerHeadTwo: 'Sektördeki ödül programı.',
  registerNow: 'Şimdi üye Ol',
  liveWins: 'CANLI KAZANÇLAR',
  monthly: 'Aylık',
  weekly: 'Haftalık',
  daily: 'Günlük',
  winner1: 'Kazanan 1',
  winner2: 'Kazanan 2',
  winner3: 'Kazanan 3',
  spin: 'Döndür',
  spinsWon: 'Kazanılan Dönüşler',
  joinedOn: 'Katılma Tarihi',
  wageringAmount: 'Bahis Miktarı',
  name: 'Ad',
  allGames: 'Bütün oyunlar',
  allSports: 'Tüm Sporlar',
  cryptoFutures: 'kripto vadeli işlemleri',
  tradeNow: 'Şimdi Ticaret Yapın',
  allTables: 'Tüm Tablolar',
  nft: 'NFT',
  viewAllGames: 'Tüm Oyunları Gör',
  viewAll: 'Hepsini gör',
  rewards: 'ÖDÜLLER',
  profile: 'Profil',
  totalBets: 'Toplam Bahisler',
  totalWagered: 'Toplam Bahis',
  joinDate: 'Katılma Tarihi',
  displaySocialLinks: 'Bağlantılı Sosyal Hesapları Görüntüle',
  privateProfile: 'Özel Profil',
  rank: 'Rütbe',
  winner: 'Kazananlar',
  nextRank: 'Sonraki Sıra',
  changeUsername: 'Kullanıcı adını değiştir',
  newPassword: 'Yeni Şifre',
  changeName: 'İsmini değiştir',
  linkSocialAccount: 'Sosyal hesabı bağla',
  privateProfileTooltip: 'Profilinizi özel yaptıktan sonra diğer kullanıcılar kullanıcı adınızı bahisler, yarışlar vb. yanında görmez.',
  socialLinksTooltip: 'İçerik mevcut değil',
  rankTooltip: 'İçerik mevcut değil',
  balances: 'bakiyeler',
  createReferralBalance: 'Yönlendirme bakiyesi oluştur',
  total: 'Toplam',
  switchBalance: 'Dengeyi Değiştir',
  mainAccount: 'Ana hesap',
  tips: 'İpuçları',
  receivedAmount: 'Alınan miktar',
  dateOrTime: 'Tarih/Saat',
  view: 'Görüş',
  allChains: 'Tüm Zincirler',
  creditAmount: 'Kredi miktarı',
  setCustomReferralCode: 'Özel yönlendirme kodunu ayarlayın',
  changeEmail: 'Değişim e-posta',
  newEmail: 'Yeni E-posta',
  createPassword: 'Şifre oluştur',
  newUsername: 'Yeni kullanıcı adı',
  username: 'Kullanıcı adı',
  changePassword: 'Şifre değiştir',
  updateProfileMsg: 'Profil başarıyla güncellendi',
  twoFactorAuthentication: 'iki faktörlü kimlik doğrulama',
  twoFactorAuthenticationTitle: 'Hesabınızı hem şifrenizle hem de telefonunuzla koruduğu için iki faktörlü kimlik doğrulamanın kullanılması şiddetle tavsiye edilir.',
  twoFactorAuthenticationSubTitle: "2FA'yı etkinleştirmek için bir e-posta ve şifre ayarlanmalıdır. ",
  enable2fa: "2FA'yı ETKİNLEŞTİRİN",
  verifyIdentityByKyc: 'Kimliğinizi doğrulayın (kyc)',
  oldPassword: 'Eski Şifre',
  live: 'Canlı',
  soccer: 'Futbol',
  tennis: 'Tenis',
  fifa: 'Fifa',
  basketball: 'Basketbol',
  iceHockey: 'Buz Hokeyi',
  volleyball: 'Voleybol',
  tableTennis: 'Masa Tenisi',
  baseball: 'Beyzbol',
  rubgy: 'Ragbi',
  golf: 'Golf',
  boxing: 'Boks',
  myBets: 'Bahislerim',
  favourites: 'Favoriler',
  americanFootball: 'Amerikan futbolu',
  buyCrypto: 'Kripto Satın Al',
  oldEmail: 'Eski email',
  loggedInSuccess: 'Başarıyla giriş yaptı',
  loggedOutSuccess: 'Başarıyla çıkış yapıldı',
  wagerLimit: 'Bahis limiti başarıyla belirlendi',
  lossLimit: 'Kayıp limiti başarıyla belirlendi',
  accountDisable: 'Hesap başarıyla devre dışı bırakıldı',
  sessionTimeout: 'Oturum zaman aşımı başarıyla ayarlandı',
  signup: 'Başarıyla kaydolun',
  depositLimit: 'Para yatırma limiti başarıyla belirlendi',
  tokenVerify: 'Jeton E-postanıza gönderildi, lütfen doğrulayın',
  emailVerify: 'E-posta doğrulandı',
  emailChangedSuccess: 'E-posta başarıyla değiştirildi',
  profileUpdatedSuccess: 'Profil güncellendi',
  otpVerified: 'OTP doğrulandı',
  twoFADeactivated: '2FA Kimlik Doğrulaması devre dışı bırakıldı',
  internalServerError: 'İç Sunucu Hatası',
  unAuthorized: 'Yetkisiz',
  dataNotFound: 'Veri bulunamadı',
  setReferralCode: 'Yönlendirme kodunu ayarlayın...',
  ultimate: 'NİHAİ',
  notFound: 'Bulunamadı',
  casinoTransactions: 'Casino İşlemleri',
  sportsbookTransactions: 'Spor Bahisleri İşlemleri',
  status: 'Durum',
  success: 'Başarı',
  failed: 'Arızalı',
  pending: 'Askıda olması',
  date: 'Tarih',
  to: 'İle',
  gameName: 'Oyun Adı',
  transactionId: 'İşlem Kimliği',
  roundId: 'Yuvarlak Kimlik',
  bet: 'Bahis',
  win: 'Kazanç',
  transactionType: 'işlem tipi',
  at: 'en',
  downloadAll: 'Hepsini indir',
  gameText: 'Oyun',
  betId: 'Bahis Kimliği',
  gameId: 'Oyun Kimliği',
  actionType: 'Eylem Türü',
  searchGames: 'Oyun Ara',
  provider: 'Sağlayıcılar',
  search: 'Aramak',
  sortBy: 'Göre sırala',
  popular: 'Popüler',
  challengePool: 'Mücadele Havuzu',
  loadMore: 'Daha fazla yükle',
  bitcoin: 'Bitcoin',
  chat: 'Sohbet',
  trades: 'İşlemler',
  battles: 'Savaşlar',
  loginToChat: 'Sohbet etmek için giriş yapın',
  rules: 'Tüzük',
  chatRule1: 'Diğer kullanıcıları taciz etmeyin veya hakaret etmeyin',
  chatRule2: 'Yalvarmayın, kredi, önemsiz şeyler veya ipuçları istemeyin',
  chatRule5: 'Herhangi bir ticaret, alım veya satım hizmetinin reklamını yapmayın',
  chatRule6: 'Tavsiye kodunuzu paylaşmayın veya reklamını yapmayın',
  chatRule7: 'Personel olmayı talep etmeyin',
  chatRule8: 'sadece ingilizce',
  chatRule9: 'Modlara, yöneticilere ve diğer kullanıcılara saygı gösterin',
  send: 'Göndermek',
  expand: 'Genişletmek',
  signIn: 'Kayıt olmak',
  cross: 'Geçmek',
  collapse: 'Yıkılmak',
  emailPlaceholder: 'e-postanız@alanadi.com',
  removeFromFavourite: 'Favorilerinden kaldır',
  addToFavourite: 'Favorilere ekle',
  footerAboutSite: 'Bilim hakkında biraz konuşalım mı? Cash Machine Casino web sitesi, hepimizin aynı atomlardan oluştuğu için sizin bir parçanızdır. Onunla etkileşiminiz bu bağlantıyı doğrular.',
  footerRightsReserved: 'Сash Machine Casino tüm hakları saklıdır.',
  signupFirstName: 'İlk adı',
  signupAddress: 'Adres',
  signupCity: 'Şehir',
  signupPostcode: 'Posta kodu ',
  signupCounty: 'Ülke',
  signupCurrency: 'Para birimi',
  signupGender: 'Cinsiyet',
  signupMan: 'Adam',
  signupWomen: 'Kadınlar',
  signupOther: 'Diğer',
  signupLoginDetails: 'Giriş Yap Ayrıntılar',
  signupPersonalDetails: 'Kişisel detaylar',
  signupConfirm: 'Onaylamak',
  signupPrivacyPolicy: 'Gizlilik Politikasını okudum ve kabul ediyorum',
  signupTermAndConditions: 'Kişisel verilerimin işlenmesini kabul ediyorum. ',
  signupNewsLetter: 'Bonusları, promosyonları, haberleri veya diğer ilgili bilgileri periyodik olarak Haber Mektubu yoluyla almak istiyorum.',
  signupSms: 'Bonusları, promosyonları, haberleri veya diğer ilgili bilgileri periyodik olarak SMS yoluyla almak istiyorum.',
  initialHeaderContent: 'Muhteşem bir %100 elde edin',
  secoundaryHeaderContent: '100a kadar hoş geldin bonusu',
  playNowButton: 'Şimdi oyna',
  registerButtonContent: 'Kayıt olmak',
  results: 'Sonuçlar',
  refresh: 'Yenile',
  cancel: 'İptal etmek',
  select: 'Seçme',
  current: 'Akım',
  set: 'Ayarlamak',
  remove: 'Kaldırmak',
  save: 'Kaydetmek',
  not: 'Olumsuz',
  before: 'Önce',
  after: 'Sonrasında',
  action: 'Aksiyon',
  withdrawal: 'Para çekme',
  requested: 'Talep edilen',
  withdraw: 'Geri çekilmek',
  areYouSure: 'Emin misin',
  yes: 'Evet',
  no: 'HAYIR',
  cancelled: 'İptal edildi',
  approved: 'Onaylı',
  refunded: 'Geri ödeme yapıldı',
  chargeback: 'Geri çekme',
  processedOn: 'İşlenme Tarihi',
  transaction: 'İşlem',
  dateTime: 'Tarih/Saat',
  more: 'Daha',
  fromDate: 'İtibaren',
  toDate: 'Bugüne kadar',
  percentage: 'Yüzde',
  info: 'Bilgi',
  min: 'Min.',
  max: 'Maksimum',
  change: 'Değiştirmek',
  continueToSite: 'Siteye Devam Et',
  choose: 'Seçmek',
  enterWithdrawalAmount: 'Para Çekme Tutarınızı seçin veya girin',
  capDeposit: 'DEPOZİTO',
  capWithdrawal: 'PARA ÇEKME',
  capAmount: 'MİKTAR',
  minimum: 'Asgari',
  required: 'Gerekli',
  of: 'ile ilgili',
  isRequired: 'gereklidir',
  isAllowed: 'izin verilir',
  method: 'Yöntem',
  capWithdraw: 'GERİ ÇEKİLMEK',
  showLess: 'Daha az göster',
  clearFilter: 'Temiz filtre',
  quantity: 'Miktar',
  reset: 'Sıfırla',
  incorrect: 'Yanlış',
  load: 'Yük',
  applied: 'Uygulamalı',
  ok: 'TAMAM',
  list: 'Liste',
  all: 'Tüm',
  favorite: 'Favori',
  endPointNotFound: 'Ağ bağlantısında bir sorun var',
  category: 'Kategori',
  emailUserNameRequired: 'E-posta/Kullanıcı Adı Gerekli',
  emailUserNamePlaceHolder: 'E-postanızı veya Kullanıcı Adınızı girin',
  passwordRequired: 'Şifre gerekli',
  accountFrozen: 'Hesabınız şu tarihe kadar donduruldu:',
  resendEmail: 'Elektronik postayı tekrar gönder',
  resendLink: 'BAĞLANTIYI YENİDEN GÖNDER',
  userLoggedOut: 'Başarıyla Oturum Kapatıldı',
  emailAlreadyRegistered: 'Bu e-posta zaten kayıtlı',
  userNameAlreadyTaken: 'Bu kullanıcı adı zaten alınmış',
  fillAllFields: 'Bir sonraki adıma geçmeden önce tüm alanları doldurun',
  pleaseAccept: 'Lütfen kabul et',
  acceptPrivacyPolicy: 'Gizlilik Politikası',
  acceptTerms: 'Şartlar ve koşullar',
  modeOfComm: ' ve iletişim şekli',
  beforeNextStep: 'bir sonraki adıma geçmeden önce',
  userNamePlaceholder: 'Kullanıcı adı girin',
  min8Characters: 'En az 8 karakter',
  min1Number: 'Min 1 Adet',
  min1LowerCase: 'Min. 1 küçük harf karakter',
  min1UpperCase: 'Min 1 büyük harf karakter',
  min1SpecialChar: 'Min 1 özel karakter',
  passwordTip: 'Şifre en az bir büyük harf, bir küçük harf, bir sayı ve bir özel karakterden oluşan en az sekiz karakterden oluşmalıdır',
  passwordPlaceholder: 'Parolanı Gir',
  confirmPasswordPlaceholder: 'Şifreyi Girin Onaylayın',
  acceptAll: 'Hepsini kabul et',
  firstNamePlaceholder: 'Adını Girin',
  lastNamePlaceholder: 'Soy adını gir',
  addressPlaceholder: 'Adresi girin',
  cityPlaceholder: 'Şehir Girin',
  postCodePlaceholder: 'Posta Kodunu Girin',
  phonePlaceholder: 'Telefon Numarasını Girin',
  invalidEmail: 'Geçersiz e-posta',
  emailRequired: 'E-posta Gerekli',
  confirmPasswordRequired: 'Şifre Gerekliliğini Onaylayın',
  firstNameRequired: 'Ad Gerekli',
  lastNameRequired: 'Soyad gerekli',
  phoneRequired: 'Telefon Gerekli',
  dateOfBirthRequired: 'Doğum Tarihi Gerekli',
  genderRequired: 'Cinsiyet Gerekli',
  userNameRequired: 'Kullanıcı Adı Gerekli',
  addressRequired: 'Adres Gerekli',
  cityRequired: 'Şehir Gerekli',
  postCodeRequired: 'Posta Kodu Gerekli',
  currencyCodeRequired: 'Para Birimi Kodu Gerekli',
  countryRequired: 'Ülke Gerekli',
  matchPassword: 'Şifre uyuşmalı',
  fName3Chars: 'Ad en az 3 karakterden oluşmalıdır',
  onlyAlphabet: 'Yalnızca Alfabelere izin verilir',
  lName3Chars: 'Soyadı en az 3 karakter olmalıdır',
  mustBe18: 'Kayıt olmak için 18 yaşında olmalısınız',
  mustBeUnder200: 'Kayıt olmak için 200 Yaşının altında olmalısınız',
  validDOB: 'Lütfen geçerli bir doğum tarihi giriniz',
  max20Characters: 'Maksimum 20 Karaktere İzin Verilir',
  min3Characters: 'Minimum 3 Karakter Gerekli',
  max100Characters: 'Maksimum 100 Karaktere İzin Verilir',
  max50Characters: 'Maksimum 50 Karaktere İzin Verilir',
  invalidNumber: 'Geçersiz numara',
  preferredLanguageRequired: 'Tercih Edilen Dil Gerekli',
  invalidPass: 'geçersiz şifre',
  prevBtn: 'Öncesi',
  dayRequired: 'Gün gerekli',
  monthRequired: 'Ay gerekli',
  yearRequired: 'Yıl gerekli',
  uploadImage: 'Fotoğraf yükleniyor',
  removeImage: 'Resmi Kaldır',
  preferredLanguage: 'tercih edilen dil',
  wantToChangePass: 'Şifreyi Değiştirmek İstiyor musunuz?',
  selectCountryCode: 'Ülke Kodunu Seçin',
  selectPreferredLanguage: 'Tercih Edilen Dili Seçin',
  fName50Chars: 'Ad en fazla 50 karakterden oluşmalıdır',
  lName50Chars: 'Soyadı en fazla 50 karakter olmalıdır',
  invalidDate: 'Geçersiz tarih',
  DOBEarlyThanToday: 'Doğum Tarihi Bugünden Daha Önce Olmalı',
  enterValidPhone: 'Lütfen geçerli bir telefon numarası girin',
  accountStatus: 'hesap durumu',
  kycVerified: "KYC'niz başarıyla doğrulandı.",
  denied: 'Reddedildi',
  chooseFile: 'Dosya seçin',
  upload: 'Yüklemek',
  countryCantChange: 'KYC Doğrulamasını başlatmak üzeresiniz. ',
  proceed: 'İlerlemek',
  update: ' ',
  history: 'Tarih',
  type: 'Tip',
  amount: 'Miktar',
  forfeited: 'Müsadere edildi',
  expired: 'Günü geçmiş',
  completed: 'Tamamlanmış',
  zeroedOut: 'Sıfırlandı',
  active: 'Aktif',
  lapsed: 'Süresi dolmuş',
  yourActiveBonus: 'Aktif Bonuslarınız',
  currentActiveBonus: 'Güncel Aktif Bonuslar',
  bonusStatus: 'Durum',
  bonusWager: 'Bahis',
  bonusActivate: 'Etkinleştir',
  bonusForfeit: 'Bonus Kaybı',
  notUsableBonus: 'Cevabınız evet ise bu bonusu bir daha kullanamazsınız.',
  bonusLapsed: 'Para iadesi almaya yetecek kadar kaybınız yoktu. ',
  inProcess: 'İşlemde',
  claiming: 'Hak talebinde bulunuluyor',
  loyaltyPopover: 'Bu Sadakat Puanları para iadesi almak için kullanılabilir. ',
  loyaltyPage: 'Sadakat Sayfası',
  loyaltyPerCurrency: 'Para birimi başına sadakat',
  maxLevelReached: 'Maksimum seviyeye ulaşıldı',
  pointsToReach: 'Ulaşılacak noktalar ',
  loyaltyBannerHeading: 'Yepyeni Sadakatimizi Hak Ediyorsunuz',
  loyaltyBannerDesc: '200a kadar %100 Hoşgeldin Bonusu',
  loyaltyHeadingOne: "CashMachine'de Her Zaman Ödüllendirilirsiniz",
  loyaltyHeadingOneDesc: "CashMachine'de sadakati yepyeni bir heyecan düzeyine taşıyoruz, her dönüşte ve dönüşte size ödüller yağdırıyoruz! ",
  loyaltySubHeadOne: 'Sadakat Puanı Kazanma',
  loyaltySubHeadOneDesc: 'Kumarhanemize oynanan her 10 € nakit bahis için 1 sadakat puanı kazanacaksınız. ',
  loyaltySubHeadTwo: 'Sadakat Merdivenini Tırmanmak',
  loyaltySubHeadTwoDesc: 'Ne kadar çok puan biriktirirseniz sadakat seviyelerinde o kadar yükselirsiniz ve yol boyunca daha da muhteşem ödüllerin kilidini açarsınız. ',
  loyaltySubHeadThree: 'Seviye atla, ödüllendiril ',
  loyaltySubHeadThreeDesc: 'Her seviye atlamanın, kazanma şansınızı en üst düzeye çıkarmak için heyecan verici bir dizi ücretsiz döndürmenin kilidini açtığı cazip ödül katmanlarımızda ilerleyerek oyun deneyiminizi yükseltin. ',
  loyaltyTestimonialHeadOne: "CashMachine CASINO'DA ŞUNDAN EMİN OLUYORUZ",
  loyaltyTestimonialHeadTwo: 'Her Döndürme Önemlidir',
  loyaltyTestimonialDesc: 'Slotlarda yaptığınız her dönüş sadece eğlence amaçlı değil, aynı zamanda sizi gerçekten tatlı ödüllere bir adım daha yaklaştırıyor! ',
  loyaltyPoints: 'Puanlar',
  headerLevel: 'seviye',
  loyaltyCashback: 'Nakit para',
  loyaltyBannerBtn: 'İlerlemeyi Kontrol Et',
  loyaltyHeadingTwo: 'Üstün Oyun Deneyiminin Kilidini Açın: CashMachine VIP Programı',
  loyaltyHeadingTwoDesc: "Sadakat programımızda Seviye 6'ya ulaştığınızda, prestijli CashMachine VIP Programının bir üyesi olarak özel ayrıcalıklarla dolu bir dünyanın kilidini açacaksınız. ",
  loyaltySubHeadFour: 'Daha Yüksek Para Yatırma ve Çekme Limitleri',
  loyaltySubHeadFourDesc: 'VIP üye olarak artan para yatırma ve çekme limitlerinin esnekliğinin keyfini çıkarın.',
  loyaltySubHeadFive: 'Özel Bonuslar ve Promosyonlar',
  loyaltySubHeadFiveDesc: 'VIP oyuncularımıza özel hazırlanmış özel bonuslara ve promosyonlara erişin',
  loyaltySubHeadSix: 'Kişisel Hesap Yöneticisi',
  loyaltySubHeadSixDesc: 'Hesap yöneticinizden özel destek ve kişiselleştirilmiş yardım alın.',
  loyaltySubHeadSeven: 'Daha Hızlı Para Çekme Süreleri',
  loyaltySubHeadSevenDesc: 'VIP olarak, kazançlarınıza daha da hızlı erişmenizi sağlayacak şekilde hızlandırılmış para çekme sürelerinden yararlanacaksınız.',
  loyaltySubHeadEight: 'Aylık Nakit İade Ödülleri',
  loyaltySubHeadEightDesc: 'VIP üyelerimize özel cömert aylık nakit iadesi ödülleriyle paranızı artırın.',
  loyaltySubHeadNine: 'Özel Etkinlik ve Turnuvalara Davetler',
  loyaltySubHeadNineDesc: "Diğer VIP'lerle kaynaşabileceğiniz ve inanılmaz ödüller için yarışabileceğiniz özel etkinliklere ve turnuvalara imrenilen davetleri alın.",
  loyaltySubHeadTen: 'Doğum Günü Bonusları',
  loyaltySubHeadTenDesc: 'Özel gününüzü size özel harika bir doğum günü bonusuyla şık bir şekilde kutlayın!',
  loyaltySubHeadEleven: 'Lüks Hediyeler',
  loyaltySubHeadElevenDesc: 'En değerli oyuncularımızı ödüllendirmek için özenle seçilmiş seçkin lüks hediye seçeneklerinin keyfini çıkarın.',
  loyaltyTableHeading: 'Seviyelere ve Ödüllere Genel Bakış',
  loyaltyTableHeaderOne: 'Sadakat Düzeyi',
  loyaltyTableHeaderTwo: 'Sadakat puanları',
  loyaltyTableHeaderThree: 'Günlük Para İadesi',
  promReadMore: 'Devamını oku',
  currentPortalBlock: 'Hesabınızı yalnızca bu portal için engellemek istiyorsanız.',
  allPortalBlock: 'Hesabınızı tüm portallardan engellemek için.',
  limit24Reset: 'Bahis, Kayıp, Para Yatırma limitlerini ayarladıktan sonra 24 saat sonra düzenlenebilir ve kaldırılabilir ancak limitler hemen azaltılabilir.',
  enterAmount: 'Miktarı girin',
  limit: 'Sınır',
  loss: 'Kayıp',
  takeABreak: 'Bir Mola Verin',
  session: 'Oturum',
  selfExclusion: 'Kendini dışlama',
  used: 'Kullanılmış',
  edit: 'Düzenlemek',
  updatedAt: 'Güncelleme Tarihi:',
  cannotBeRemoved: 'Daha önce kaldırılamaz',
  timePeriod: 'Zaman dilimi',
  custom: 'Gelenek',
  hours: 'Saat',
  days: 'Günler',
  timePeriodPlaceholder: 'Gün sayısı',
  months: 'Aylar',
  permanent: 'Kalıcı',
  onlyNumbers: 'Yalnızca Numaralara izin verilir',
  notNumbers: 'Numaralara İzin Verilmiyor',
  userNameAllowed: 'Alfabeyle Başlamalı. ',
  timePeriodRequired: 'Gerekli Zaman Aralığı',
  cannotBeIncreased: 'Daha önce artırılamaz',
  amountGTZero: "Tutar 0'dan büyük olmalıdır",
  amountEqualOrLess: 'Tutar haftalık ve aylıktan eşit veya daha az olmalıdır',
  amountInBetween: 'Tutar günlük ve aylık (dahil) arasında olmalıdır',
  amountEqualOIrGreater: 'Tutar günlük ve aylık tutarlara eşit veya daha fazla olmalıdır',
  limitRemoved: 'Takip limitiniz kaldırılacaktır.',
  limitSet24Hrs: 'Hesabınıza aşağıdaki limiti ayarlamak üzeresiniz. ',
  takeABreakInfo: 'Hesabınıza erişimi önceden belirlenmiş bir süre boyunca engellemek üzeresiniz. ',
  sessionLimitInfo: 'Hesabınıza aşağıdaki limiti ayarlamak üzeresiniz. ',
  limitCantSetBefore: 'Oturum limitiniz daha önce ayarlanamaz',
  selfExclusionInfo: 'Hesabınıza erişimi önceden belirlenmiş bir süre boyunca engellemek üzeresiniz. ',
  quickSearch: 'Hızlı arama',
  cash: 'Peşin',
  nonCash: 'Nakit Dışı',
  userDetail: 'Kullanıcı Detayı',
  gameIdentifier: 'Oyun Tanımlayıcı',
  rollback: 'Geri alma',
  rollbackBeforeBetWin: 'Bahis Kazanmadan Önce Geri Alma',
  freeSpins: 'Ücretsiz döndürme',
  betInternal: 'Dahili Bahis',
  winInternal: 'Dahili Kazanma',
  addMoney: 'Para Ekle',
  removeMoney: 'Parayı Kaldır',
  addMoneyInternal: 'Dahili Para Ekle',
  removeMoneyInternal: 'Dahili Parayı Kaldır',
  depositInternal: 'Dahili Mevduat',
  withdrawInternal: 'Dahili Geri Çekme',
  promotionTitle: 'Promosyon Başlığı',
  cancelWithdrawRequest: 'Bu isteği İptal etmek istiyorsunuz',
  rowsPerPage: 'Sayfa Başına Satır Sayısı',
  availedBonus: 'Zaten aktif bir bonusunuz var. ',
  capForfeit: 'HÜKÜMET',
  itFirst: 'ilk önce o.',
  selectYourBonus: 'Bonusunuzu Seçin',
  skip: 'ATLAMAK',
  with: 'ile',
  without: 'olmadan',
  selectPaymentMethod: 'Lütfen Ödeme Yönteminizi seçin',
  volatility: 'Volatilite',
  paylines: 'Ödeme hatları',
  theme: 'Tema',
  tryForFree: 'Ücretsiz deneyin',
  resultsFound: 'Sonuçlar bulundu',
  games: 'Oyunlar',
  in: 'içinde',
  low: 'Düşük',
  medium: 'Orta',
  high: 'Yüksek',
  'medium-high': 'Orta Yüksek',
  'very-high': 'Çok yüksek',
  'low-medium': 'Düşük Orta',
  fantasy: 'Fantezi',
  ancient_civilizations: 'Antik Uygarlıklar',
  fruits: 'Meyveler',
  africa: 'Afrika',
  military: 'Askeri',
  joker: 'Joker',
  asia: 'Asya',
  luxurylife: 'Lüks hayat',
  underwater_world: 'Sualtı Dünyası',
  book_off: 'Ayırtmak',
  western: 'Batılı',
  retro: 'retro',
  egypt: 'Mısır',
  party: 'Parti',
  st_patrick_day: 'ST Patrick Günü',
  space: 'Uzay',
  easter: 'Paskalya',
  girls: 'Kızlar',
  branded: 'Markalı',
  x_mas_and_new_year: 'Noel ve Yeni Yıl',
  horrors: 'Korku',
  other: 'Diğer',
  loginFirst: 'Oyunu Favorilere Eklemek İçin Lütfen Önce Giriş Yapın',
  loginFirstPlay: 'Bu Oyunu Oynamak İçin Lütfen Önce Giriş Yapın',
  sport: 'Spor',
  pirates: 'Korsanlar',
  sweets: 'Tatlılar',
  luxury_life: 'Lüks hayat',
  st_valentines_day: 'Sevgililer Günü',
  halloween: 'Cadılar Bayramı',
  food: 'Yiyecek',
  lobby: 'lobi',
  favourite: 'Favori',
  playNGO: 'Oyna N Go',
  loyaltySystem: 'Sadakat Sistemi',
  progressionSystem: 'İlerleme sistemi',
  startPoint: 'Başlangıç ​​noktası',
  endPoint: 'Bitiş Noktası',
  loyaltyBonus: 'Sadakat bonusu',
  pageRemoved: 'Bu Sayfa mevcut değil veya kaldırıldı',
  suggestToBackHome: 'Evinize dönmenizi öneriyoruz',
  backToHome: 'Eve geri dön',
  goHome: 'Eve git',
  oopps: 'Oops',
  somethingNotWorking: 'Üzgünüz, burada bir şeyler yolunda gitmiyor!',
  tournament: 'Turnuva',
  none: 'Hiçbiri',
  validOnDays: 'Geçerli Günler',
  validTill: 'Kadar geçerli',
  yourRequest: 'İsteğin',
  withdrawalRequestPending: 'Bekleme durumunda olan bir para çekme talebiniz var. ',
  realBalance: 'Gerçek Denge',
  bonusInCasino: 'Kumarhanede Bonus',
  bonusSelected: 'Bonus Seçildi',
  payWith: 'İle ödemek',
  reached: 'Ulaşmış',
  receiptOfYour: 'Makbuzunuz',
  capEmail: 'E-POSTA',
  capCreditCard: 'KREDİ KARTI NUMARASI',
  capExpiry: 'SONA ERME',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY doğru biçimde değil',
  min13Numbers: 'Minimum 13 Sayı Gerekli',
  max19Numbers: 'Maksimum 19 Numaraya İzin Verilir',
  min3Numbers: 'Minimum 3 Sayı Gerekli',
  max4Numbers: 'Maksimum 4 Numaraya İzin Verilir',
  Other: 'Diğer',
  forfeit: 'ceza',
  withdrawRequestCreated: 'Para Çekme İsteği Başarıyla Oluşturuldu',
  noBetHistory: 'Bahis Geçmişi Bulunamadı',
  noPromotions: 'Promosyon Bulunamadı',
  noBonuses: 'Bonus Bulunamadı',
  noHistory: 'Geçmiş Bulunamadı',
  noWithdrawalHistory: 'Para Çekme Geçmişi Bulunamadı',
  noCMS: 'CMS Bulunamadı',
  noProvidersFound: 'Sağlayıcı Bulunamadı',
  noDataFound: 'Veri bulunamadı',
  countryBlocked: 'ÜLKE ENGELLENDİ',
  forbidden: 'YASAKLI',
  notAcceptingVisitors: 'Bu durum için çok üzgünüz ancak brightwilds.com ülkenizde mevcut değil.',
  contactUsAt: 'Bunun size yanlışlıkla gösterildiğini düşünüyorsanız lütfen şu adresten e-posta yoluyla destek ekibimizle iletişime geçin:',
  siteUnderMaintenance: 'Site bakımda',
  weWillBeRightBack: 'Hemen geri döneceğiz!',
  serviceUnavailable: 'Hizmetimiz geçici olarak kullanılamıyor. ',
  tryAgainLater: 'Lütfen daha sonra tekrar deneyiniz.',
  checkYourEmail: 'E-postanı kontrol et',
  sentAVerification: 'Bir doğrulama bağlantısı gönderdik',
  toYourEmail: 'e-postanıza.',
  backToLogin: 'Girişe Geri Dön',
  goToLogin: "Giriş Yap'a Git",
  accountVerified: 'Hesap Doğrulandı',
  yourAccountVerified: 'Hesabınız doğrulandı',
  successfully: 'Başarıyla',
  verificationFailed: 'Doğrulama başarısız oldu',
  emailFailedContact: 'Doğrulama Başarısız Oldu. Bu durum sürekli oluyorsa lütfen iletişime geçin ',
  setNewPass: 'Yeni bir şifre belirleyin',
  passResetLink: 'Şuraya bir şifre sıfırlama bağlantısı gönderdik:',
  toYourAccount: 'Hesabınıza',
  sendPasswordResetLink: 'Şifre Sıfırlama Talebi',
  gamePlayWagerComplete: 'Tebrikler, bahis oynamayı başarıyla tamamladınız ve kazanılan bonus para, izin verilen maksimum kazanç tutarından daha fazla. ',
  real: 'Gerçek',
  demo: 'Demo',
  money: 'Para',
  formatRquired: '.png , .jpg, .jpeg formatı gereklidir',
  chooseImage: 'Lütfen bir resim seçin',
  imageSize3MB: "Boyutu 3 MB'tan Küçük Olan Resim Girin",
  noProfileImage: 'Kaldırılacak Profil Resmi yok',
  limitSetSuccess: 'sınır başarıyla ayarlandı',
  accountDisabled: 'Hesap Başarıyla Devre Dışı Bırakıldı',
  loginSuccess: 'Giriş başarılı',
  signupSuccess: 'Kayıt başarılı',
  linkExpired: 'Bağlantının Süresi Doldu',
  linkSent: 'Bağlantı Gönderildi',
  accAlreadyVerified: 'Hesap Zaten Doğrulandı. ',
  profileImage: 'Profil Resmi',
  profileImageRemoved: 'Profil Resmi Başarıyla Kaldırıldı',
  docs: 'Belgeler',
  updatedSuccess: 'başarıyla güncellendi',
  MATCH: 'KİBRİT',
  BALANCE: 'DENGE',
  FREESPINS: 'ÜCRETSİZ DÖNDÜRME',
  MATCH_1: 'KİBRİT',
  DEPOSIT: 'DEPOZİTO',
  WAGERING: 'BAHİS',
  PROMOTION: 'TERFİ',
  CREDITCARD: 'KREDİ KARTI',
  WALLET: 'CÜZDAN',
  CRYPTO: 'KRİPTO',
  OTHER: 'DİĞER',
  INSTANTBANKING: 'ANINDA BANKACILIK',
  cookieTitle: 'Kurabiye!',
  declineButton: 'Reddetmek',
  acceptButton: 'Kabul etmek',
  cookieDeclinedMessage: 'Mümkün olan en iyi deneyim için, bu web sitesini kullanmaya devam edebilmek için çerezlerin kabul edilmesi gerekmektedir. ',
  cookieDescription: "Shinywilds.com'a hoş geldiniz! ",
  forfeitMessageFirst: 'Şu anda aşağıdaki aktif bonusa sahipsiniz:',
  forfeitMessageSecond: 'Yeni bir bonus talep etmeden önce bu bonusu kaybetmeniz gerekir.',
  forfeitButton: 'ceza',
  pleaseVerifyFirstText: 'Para çekme talebinde bulunabilmemiz için öncelikle e-posta adresinizi doğrulamamız gerekiyor. ',
  clickHere: 'Buraya tıklayın',
  pleaseVerifyLastText: 'Böylece size doğrulama e-postasını yeniden gönderip devam edebiliriz.',
  pleseWait: 'Lütfen ... için bekleyin',
  toResend: 'bağlantıyı tekrar göndermek için',
  notVerified: 'Para çekmek için e-posta adresinizi doğrulamanız gerekir',
  verification: 'Doğrulama',
  UPLOAD_IMG_MSG: 'Dosyaları buraya sürükleyip bırakın veya dosyaları seçmek için tıklayın',
  affiliate: 'Ortaklık',
  affiliateTitle: 'Ortaklık Programı',
  affiliateDescription: ' Markamızı tavsiye edin ve Para Çekme Makinası Ortaklık Programına katılarak para kazanın! ',
  refferalTitle: 'Yönlendirme Programı',
  refferalDescription: 'Bu yönlendirme bağlantısını başkalarıyla paylaşabilir ve kullanıcı sizin verdiğiniz bağlantıyı kullanarak kaydolursa bir miktar bonus kazanabilirsiniz.',
  affiliateButtonText: 'Ortak Olun',
  affiliateLink: 'Ortaklık Bağlantısı',
  affiliateStatus: 'Ortaklık isteğiniz başarıyla gönderildi',
  currentStatus: 've mevcut Ortaklık durumu: {{status}}',
  referralLink: 'Yönlendirme Bağlantısı',
  copyTitleMessage: 'Lütfen bu bağlantıyı kopyalayın',
  copiedMessage: 'Bağlantı kopyalandı',
  searchedGame: 'Aranan Oyun',
  favoriteGame: 'Favori Oyunlar',
  randomGames: 'Rastgele Oyunlar',
  poolPrize: 'Ödül havuzu',
  chooseYourWelcomeBonus: 'Hoş geldin bonusunuzu seçin',
  useNow: 'Şimdi kullan',
  readTheTerms: 'Şartları okuyun',
  proceedWithoutWelcomeBonus: 'Hoşgeldin Bonusu Olmadan Devam Edin',
  doYouAlreadyHaveAccount: 'zaten bir hesabın var mı? ',
  registerViaSocialNetworks: 'Sosyal ağlar aracılığıyla kaydolun',
  iconfirmIm18YearsOlder: '18 yaşında veya daha büyük olduğumu onaylıyorum',
  iAgreeWithTermsAndConditions: 'Kullanıcı hükümlerini kabul ediyorum',
  thankYouSuccessfullyRegisteringCashMachine: "Cash Machine'e başarıyla kaydolduğunuz için teşekkür ederiz.",
  verifyEmailMessage: 'Siteye başarılı giriş için e-postanıza gönderilen doğrulama bağlantısına tıklayarak e-postanızı doğrulamanızı rica ediyoruz.',
  gotIt: 'Anladım',
  phonecodeRequired: 'Telefon kodu gerekli',
  cpNotAllow: "kopyala/yapıştır'a izin verilmiyor",
  playFun: 'Eğlenceli Oyna',
  playReal: 'Gerçek Oyna',
  yourRealBalance: 'Gerçek Bakiyeniz:',
  chooseYourPaymentMethod: 'Ödeme yönteminizi seçin',
  balanceHistory: 'Bakiye Geçmişi',
  liveChat: 'Canlı sohbet',
  mail: 'Posta',
  chooseEnterYourDepositAmounts: 'Yatırma tutarlarınızı seçin veya girin',
  noBonusDataAvailable: 'Bonus verisi yok',
  nowTryPlayForRealBets: 'Şimdi gerçek bahislerle oynamaya çalışın',
  registerAndPlayForRealBets: 'Kayıt olun ve gerçek bahisler için oynayın',
  tournamentDataNotAvailable: 'Turnuva verileri mevcut değil',
  startDate: 'Başlangıç ​​tarihi',
  endDate: 'Bitiş tarihi',
  merchant: 'Tüccar',
  seachHere: 'Burada ara',
  show: 'Göstermek',
  betResults: 'Bahis sonuçları:',
  noTransactionsFoundForselectedDate: 'Seçilen tarih için İşlem bulunamadı',
  loginError: 'Sisteme giriş hatası',
  errorMessageIncorrectlyAuthorization: 'E-posta / kullanıcı adı veya şifre yanlış girildi Yetkilendirme hatası.',
  profileSaved: 'Profil kaydedildi',
  addInfo: 'Bilgi Ekle',
  iHaveReadAndAgreePaymentSystemRestrictions: 'Ödeme sistemleri kısıtlamalarını okudum ve kabul ediyorum*',
  paymentOptions: 'Ödeme seçenekleri',
  language: 'Dil',
  betTime: 'Bahis Zamanı',
  noActiveBonusFound: 'Aktif bonus bulunamadı',
  bonusType: 'Bonus Türü',
  requiredWageringAmount: 'Gerekli Bahis Tutarı',
  currentWageringAmount: 'Güncel Bahis Tutarı',
  validUpto: 'Şu tarihe kadar geçerli:',
  bonusCode: 'Bonus kodu',
  wageringProgressBar: 'Bahis İlerleme Çubuğu',
  noGamesFound: 'Oyun bulunamadı',
  noteYouMayOptfreeSpin: "Not: Bu oyunlardan herhangi birinde FREESPIN'i seçebilirsiniz.",
  viewOfferDetails: 'Teklif ayrıntılarını görüntüle',
  viewFreespinGames: 'Freespin Oyunlarını Görüntüle',
  depositBonusPercentage: 'Para Yatırma Bonusu Yüzdesi: ',
  validity: 'Geçerlilik',
  activated: 'Aktif',
  bonusName: 'Bonus Adı',
  freeRounds: 'Serbest Turlar',
  wagering: 'Bahis',
  ended2: 'Bitti',
  searchBonusTitle: 'Bonus başlığına göre ara',
  claimed: 'Hak talebinde bulunuldu',
  noBonusHistoryFoundselectedDates: 'Seçilen tarihler için Bonus geçmişi bulunamadı',
  noBonusDetailsFound: 'Bonus Detayı bulunamadı',
  enterCode: 'Kodu girin',
  transactionTime: 'İşlem süresi',
  credit: 'Kredi',
  debit: 'Borç',
  bonusInfoOfHeading: 'Bonuslar performansınızı ödüllendiren ekstra ödemelerdir',
  onlineCasino: 'çevrimiçi kumarhane',
  isTheTop: ' en üstte ',
  bonusInfoPara0: 'Çevrimiçi en iyi casino oyunlarını mı arıyorsunuz? ',
  tournamentsHistory: 'Turnuva geçmişi',

  loginWelcomeBonusInfo: `Para Çekme Makinesi yeni oyuncuları hoş geldin bonusuyla karşılıyor - %100
  Depozito + "Pat’s Heroes" (Platipus) oyununda 100 freespin.
  Minimum Depozito tutarı 20 EUR / ARS 900 / 30 AUD / 500 CZK'dir
  / DKK 150 / NOK 200 / NZD 30 / PLN 80 / USD 20 / ZAR 300 / GEL 70
  / KZT 9 000 / TJS 200 / UZS 200 000 / TL 130`,
  loginDepositBonusInfo: `Maksimum bonus tutarı: Yatırılan tutarın %100'ü, ancak daha fazlası değil
  100 EUR / ARS 4000 / 150 AUD / 2500 CZK / 750 DKK / 1000 NOK'tan fazla
  / 150 NZD / 400 PLN / 100 USD / 1500 ZAR / 350 GEL / 45 000 KZT /
  TJS 1000 / UZS 1 000 000 / 600 TL,`,
  loginFreespinBonusInfo: `Bonus aktivasyonundan itibaren 5 gün içinde bedava çevirmeler verilir,
  Günlük 20 bedava dönüş. Ücretsiz çevirmelerde kazanılan tüm paralar hesaba aktarılır
  Bonus hesabında oynamak/bahis oynamak için kullanılabilir
  bonus. X40 bahis oynadıktan sonra para çekilebilir. Bonus bahis süresi
  7 gün.`,
  loginMaximumBetLimitInfo: `Bahis sırasında maksimum bahis limiti: 1 EUR / ARS 45 / 1,5 AUD / CZK
  25 / DKK 7 / NOK 10 / NZD 1,5 / PLN 5 / USD 1 / ZAR 15 / GEL 3,5 /
  KZT 450 / TJS 10 / UZS 10 000 / 6 TL`,

  bonusInfoPara1: ' , çok çeşitli fantastik oyunlar ve göz kamaştırıcı özel tekliflerle. Slot oyunlarımız, oyunculara büyük bir kazanç elde etmek için kazançlı bir şans sunan aşamalı jackpot slot oyunlarıyla birlikte çok çeşitli temalara sahiptir. Ayrıca kartlarınızın gerçek zamanlı olarak karıştırıldığını∂ ve dağıtıldığını görebileceğiniz heyecan verici canlı krupiyer oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin; gerçek anlamda oynamayı seçmeden önce her oyun hakkında fikir sahibi olmak için öncelikle demo modunda pratik yapabilirsiniz.',
  bonusInfoPara2: 'Slot oyunlarımız geniş bir tema yelpazesine sahiptir ve artan jackpot slot oyunları büyük bir kazanç elde etmek için kazançlı bir şans sunar. Ayrıca kartlarınızın gerçek zamanlı olarak karıştırıldığını ve dağıtıldığını görebileceğiniz heyecan verici canlı krupiyer oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin; gerçek anlamda oynamayı seçmeden önce her oyun hakkında fikir sahibi olmak için ilk olarak demo modunda pratik yapabilirsiniz. Slot oyunlarımız çok çeşitli temalara sahiptir ve artan jackpot slot oyunları oyunculara kazançlı bir puan kazanma şansı sunar. büyük bir galibiyet. Ayrıca kartlarınızın gerçek zamanlı olarak karıştırıldığını ve dağıtıldığını görebileceğiniz heyecan verici canlı krupiyer oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin; gerçek anlamda oynamayı seçmeden önce her oyun hakkında fikir sahibi olmak için öncelikle demo modunda pratik yapabilirsiniz.',
  bonusInfoPara3: 'Slot oyunlarımız çok çeşitli temalara sahiptir ve artan jackpot slot oyunları, oyunculara büyük bir kazanç elde etmek için kazançlı bir şans sunar. Ayrıca kartlarınızın gerçek zamanlı olarak karıştırıldığını ve dağıtıldığını görebileceğiniz heyecan verici canlı krupiyer oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin; gerçek anlamda oynamayı seçmeden önce her oyun hakkında fikir sahibi olmak için öncelikle demo modunda pratik yapabilirsiniz.',
  bonusInfoPara4: 'Slot oyunlarımız çok çeşitli temalara sahiptir ve artan jackpot slot oyunları, oyunculara büyük bir kazanç elde etmek için kazançlı bir şans sunar. Ayrıca kartlarınızın gerçek zamanlı olarak karıştırıldığını ve dağıtıldığını görebileceğiniz heyecan verici canlı krupiyer oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin; gerçek anlamda oynamayı seçmeden önce her oyun hakkında fikir sahibi olmak için ilk olarak demo modunda pratik yapabilirsiniz. Slot oyunlarımız çok çeşitli temalara sahiptir ve artan jackpot slot oyunları oyunculara kazançlı bir puan kazanma şansı sunar. büyük bir galibiyet. Ayrıca kartlarınızın gerçek zamanlı olarak karıştırıldığını ve dağıtıldığını görebileceğiniz heyecan verici canlı krupiyer oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin; gerçek anlamda oynamayı seçmeden önce her oyun hakkında fikir sahibi olmak için öncelikle demo modunda pratik yapabilirsiniz.',
  cashMachineFooterPara1: 'En iyi online casino oyunlarını mı arıyorsunuz? O zaman doğru yere geldiniz.',
  cashMachineFooterPara2: 'ile muhteşem bir oyun yelpazesi ve göz kamaştırıcı özel teklifler. Yuvalarımız geniş bir tema çeşitliliğinde, oyunculara büyük bir kazanç elde etme şansı sunan ilerici jackpot slot oyunları. Ayrıca, kartlarınızın karıldığını ve dağıtıldığını gerçek zamanlı olarak görebileceğiniz heyecan verici canlı krupiye oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin - gerçek para ile oynamadan önce her birini denemek için önce demo modunda pratik yapabilirsiniz.',
  tournamentDescTitle: 'Canlı Casino, Yuvalar ve Düşür ve kazan casino Turnuvaları',
  tournamentPara1: 'En iyi online casino oyunlarını mı arıyorsunuz? O zaman doğru yere geldiniz.',
  tournamentPara2: ' ile muhteşem bir oyun yelpazesi ve göz kamaştırıcı özel teklifler. Yuvalarımız geniş bir tema çeşitliliğinde, oyunculara büyük bir kazanç elde etme şansı sunan ilerici jackpot slot oyunları. Ayrıca, kartlarınızın karıldığını ve dağıtıldığını gerçek zamanlı olarak görebileceğiniz heyecan verici canlı krupiye oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin - gerçek para ile oynamadan önce her birini denemek için önce demo modunda pratik yapabilirsiniz.',
  tournamentPara3: 'Yuvalarımız geniş bir tema çeşitliliğinde sunulmaktadır, ilerici jackpot slot oyunları oyunculara büyük bir kazanç elde etme şansı sunuyor ilerici. Ayrıca, kartlarınızın karıldığını ve dağıtıldığını gerçek zamanlı olarak görebileceğiniz heyecan verici canlı krupiye oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin - gerçek para ile oynamadan önce her birini denemek için önce demo modunda pratik yapabilirsiniz. Yuvalarımız geniş bir tema çeşitliliğinde sunulmaktadır, ilerici jackpot slot oyunları oyunculara büyük bir kazanç elde etme şansı sunuyor ilerici. Ayrıca, kartlarınızın karıldığını ve dağıtıldığını gerçek zamanlı olarak görebileceğiniz heyecan verici canlı krupiye oyunları da sunuyoruz! Hangi oyunun sizin için uygun olduğundan emin değil misiniz? Endişelenmeyin - gerçek para ile oynamadan önce her birini denemek için önce demo modunda pratik yapabilirsiniz.',
  'New Games': 'Yeni Oyunlar',
  'Popular Games': 'Popüler Oyunlar',
  'Games Of The Month': 'Ayın Oyunları',
  Exclusive: 'Özel',
  'Bonus Buy': 'Bonus Satın Alma',
  'Jackpot Games': 'İkramiye Oyunları',
  lightMode: 'Işık Modu',
  darkMode: 'Karanlık Mod',
  searchProvider: 'Arama Sağlayıcı',
  tournamentHistoryDataNotAvailable: 'Turnuva geçmişi verileri mevcut değil',
  viewMore: 'Daha fazla göster',
  description: 'Tanım',
  codeCopied: 'Kod Kopyalandı',
  tournamentGames: 'Turnuva Oyunları',
  freeSpinGames: 'Ücretsiz Döndürme Oyunları',
  promoCode: 'Promosyon Kodu',
  paymentNote: 'Not: İşlem sürerken lütfen pencereyi kapatmayın veya yenilemeyin',
  liveGames: 'Canlı Oyunlar',
  play: 'OYNA',
  demo1: 'DEMO',
  registration: 'KAYIT'
}
