import { createSlice } from '@reduxjs/toolkit'
import { getPaymentType } from 'redux-thunk/thunk/payment.thunk'

const initialState = {
  paymentProviderType: null,
  loading: false,
  paymentIdLoading: false,
  paymentIdDetails: null
}

const {
  actions: {
    setPaymentIdSuccess,
    setPaymentIdFailure
  },
  reducer
} = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentIdSuccess: (state, { payload }) => {
      return {
        ...state,
        paymentIdLoading: false,
        paymentIdDetails: payload
      }
    },
    setPaymentIdFailure: (state, { payload }) => {
      return {
        ...state,
        paymentIdLoading: false,
        paymentIdDetails: null
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentType.pending, (state, action) => {
        return {
          ...state,
          loading: true
        }
      })
      .addCase(getPaymentType.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          paymentProviderType: payload
        }
      })
      .addCase(getPaymentType.rejected, (state, action) => {
        return {
          ...state,
          loading: false
        }
      })
  }
})

export default reducer
export {
  setPaymentIdSuccess,
  setPaymentIdFailure
}
