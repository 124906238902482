import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line
import { useLocation } from 'react-router-dom'
// eslint-disable-next-line
import { ROUTE_PATHS } from 'constants/index'
import MaintenanceMode from '../Maintenance/index'
import { useTheme } from '@emotion/react'
import { MainWrapper } from './style'
import SideNavBar from '../SideNavBar/index'
import Footer from '../Footer/index'
import DepositWithdrawal from '../DepositWithdrawal/index'

const MainLayout = ({ showFooter, children }) => {
  const { siteData } = useSelector((state) => state.general)

  const theme = useTheme()
  const [myAccountDialog, setMyAccountDialog] = useState(false)

  return (
    <>
      <MainWrapper theme={theme}>
        <SideNavBar setDialogOpen={setMyAccountDialog} />
        {(!siteData?.tenantDetail?.maintenance) ? children : <MaintenanceMode />}
        <DepositWithdrawal dialogOpen={myAccountDialog} handleClose={() => setMyAccountDialog(false)} />
      </MainWrapper>
      <Footer />
    </>
  )
}

export default MainLayout
