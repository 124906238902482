import React, { forwardRef } from 'react'
import { CustomDialogWrapper } from './style'
import { useTheme } from '@emotion/react'
import { DialogContent, IconButton, Slide } from '@mui/material'
import { CloseBoldIcon } from 'assets/icons/index'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CustomDialog = (props) => {
  const theme = useTheme()
  const paymentModalBorder = theme.themeImages.paymentModalBorder

  const {
    dialogOpen,
    handleDialogClose,
    title,
    children,
    fullWidth,
    maxWidth,
    fullScreen,
    PaperComponent,
    scroll
  } = props

  return (
    <CustomDialogWrapper
      theme={theme}
      title={title}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      PaperComponent={PaperComponent}
      scroll={scroll}
    >
      {handleDialogClose
        ? (
          <IconButton
            className='dialog-close-btn'
            aria-label='close'
            onClick={handleDialogClose}
          >
            <CloseBoldIcon />
          </IconButton>
          )
        : null}
      <DialogContent>
        {children}
        <img src={paymentModalBorder} className='modal-bg-border' alt='BG Img' loading='lazy' />
      </DialogContent>
    </CustomDialogWrapper>
  )
}

export default React.memo(CustomDialog)
