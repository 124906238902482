import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { RefferalModalWrapper } from './style'
import { useTranslation } from 'react-i18next'
import { getReferralLink } from 'redux-thunk/thunk/user.thunk'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { SpinnerSVG } from 'assets/icons/index'
import CustomDialog from 'components/common-ui/CustomDialog/CustomDialog'
import CopyFieldComponent from 'components/layout/CopyField/CopyFieldComponent'

const ReferralModal = ({ dialogOpen, handleClose }) => {
  // eslint-disable-next-line no-unused-vars
  const { [LOADER_HANDLER_TYPES.submit]: submitLoading } = useSelector((state) => state.loader)
  const [maxWidth] = React.useState('customModal_xm')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { referralLink } = useSelector((state) => state?.user)
  const [loading, setLoading] = useState(false)

  const [isTextCopied, setIsTextCopied] = useState({
    isCopy: false,
    message: ''
  })

  const handleCopyClick = (copyData) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(copyData)
      const message = t('copiedMessage', { title: t('referralCode') })
      setIsTextCopied({ isCopy: true, message })
    }
    setTimeout(() => {
      setIsTextCopied({ isCopy: false, message: '' })
    }, 10000)
  }

  useEffect(() => {
    dispatch(getReferralLink({ loader: LOADER_HANDLER_TYPES.content, setLoading }))
    setLoading(true)
  }, [])

  return (
    <>
      <CustomDialog
        maxWidth={maxWidth}
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        aria-labelledby='customized-dialog-title'
      >
        <RefferalModalWrapper>
          <Box className='dialog-content-wrap'>
            <Box className='dialog-content-box'>
              <Box className='dialog-card-container'>
                <Box>
                  <Typography className='affiliate-description'>
                    {t('refferalDescription')}
                  </Typography>
                  {loading
                    ? <SpinnerSVG style={{ fill: 'white' }} />
                    : (
                      <Box>
                        <CopyFieldComponent
                          value={referralLink}
                          title={t('referralLink')}
                          handleCopyClick={() => handleCopyClick(referralLink)}
                          name='depositAddress'
                          copyTitleMessage={t('copyTitleMessage', { title: t('referralLink') })}
                          setIsTextCopied={setIsTextCopied}
                          isTextCopied={isTextCopied}
                        />
                      </Box>
                      )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='modal-heading-box'>
            <Typography variant='h1'>{t('refferalTitle')} </Typography>
          </Box>

        </RefferalModalWrapper>
      </CustomDialog>
    </>
  )
}

export default ReferralModal
