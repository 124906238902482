import { Box, styled } from '@mui/material'

export const DepositWithdrawalWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    '& .tabs-nav-box': {
      zIndex: '1',
      position: 'absolute',
      top: theme.spacing(3.25),
      left: '50%',
      transform: 'translateX(-50%)',
      '& .MuiTabs-root': {
        '& .MuiTabScrollButton-root': {
          height: theme.spacing(1.5),
          backgroundColor: theme.palette.darkShade.dark_03,
          '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
            fill: theme.palette.typographyText.textMain
          }
        },
        '& .MuiTabs-flexContainer': {
          gap: theme.spacing(1),
          '& .MuiTab-root': {
            zIndex: '1',
            padding: theme.spacing(0),
            transition: '300ms all',
            '&.Mui-selected': {
            }
          }
        },
        '& .MuiTabs-indicator': {
          display: 'none'
        }
      },
      '& .cash-machine-img': {
        width: '100%',
        maxWidth: theme.spacing(10),
        height: 'auto',
        display: 'block',
        margin: `auto auto ${theme.spacing(0)}`
      }
    },
    '& .tabpanel-wrap': {
      width: '100%',
      height: '100%',
      padding: theme.spacing(4, 1.5, 0),
      '& .tabpanel-box': {
        width: '100%',
        height: '100%'
      }
    },
    '& .loader-wraper': {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& .content-wrapper': {
      height: `calc(100% - ${theme.spacing(1.5)})`,
      '& .form-steps': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& .form-box': {
          width: '100%',
          height: `calc(100% - ${theme.spacing(3.5)})`,
          overflow: 'hidden auto',
          padding: theme.spacing(0, 1.5),
          '&::-webkit-scrollbar': {
            width: '0.25rem',
            height: '0.25rem',
            background: theme.palette.darkShade.dark_03
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.warning.light
          }
        }
      }
    },
    '& .title-text': {
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.typographyText.textMain,
      fontSize: theme.spacing(2),
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: theme.spacing(0.875),
      '& .MuiTypography-root': {
        color: theme.palette.warning.main,
        fontSize: theme.spacing(2),
        fontWeight: '600',
        display: 'inline-block'
      }
    },
    '& .subtitle-text': {
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.typographyText.textMain,
      fontSize: theme.spacing(1.5),
      fontWeight: '500',
      textAlign: 'center',
      margin: theme.spacing(0.875, 0)
    },
    '& .checkbox-wrap': {
      '& .MuiFormControlLabel-root': {
        alignItems: 'flex-start'
      }
    },
    '& .form-btn-box': {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      '& .MuiButtonBase-root': {
        minWidth: theme.spacing(20)
      }
    },
    '& .bottom-btn-wrap': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'flex-start',
      gap: theme.spacing(1.5),
      marginTop: 'auto',
      '& .btn-grp': {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1.5),
        marginLeft: 'auto'
      }
    },
    '& .chat-mail-btn, & .back-btn': {
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.typographyText.textMain,
      fill: theme.palette.typographyText.textMain,
      fontSize: theme.spacing(1.5),
      fontWeight: '500',
      lineHeight: 'normal',
      textTransform: 'capitalize',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(0.625),
      cursor: 'pointer',
      transition: '300ms all',
      '&:active': {
        transform: 'scale(0.95)'
      },
      '& .icon-box': {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          width: theme.spacing(2.5),
          height: theme.spacing(2.5)
        }
      }
    },
    '& .form-status-box': {
      width: theme.spacing(23.75),
      height: theme.spacing(4),
      background: theme.palette.success.light,
      maskImage: 'url("/assets/images/stock-images/form-status-bg.png")',
      maskRepeat: 'no-repeat',
      maskSize: '100% 100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      zIndex: '1',
      position: 'absolute',
      top: theme.spacing(3.25),
      left: '50%',
      transform: 'translateX(-50%)',
      '& .MuiTypography-root': {
        fontFamily: 'Poppins, sans-serif',
        color: theme.palette.typographyText.textWhite,
        fill: theme.palette.typographyText.textWhite,
        fontSize: theme.spacing(1.5),
        fontWeight: '600',
        textTransform: 'capitalize'
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .tabs-nav-box': {
        width: '100%',
        background: theme.palette.gradientColor.myAccountHeading,
        padding: theme.spacing(0.563, 1),
        position: 'absolute',
        top: theme.spacing(0),
        left: theme.spacing(0),
        transform: 'none',
        '& .MuiTabs-root': {
          '& .MuiTabs-flexContainer': {
            gap: theme.spacing(0.5)
          }
        }
      },
      '& .tabpanel-wrap': {
        padding: theme.spacing(4.125, 0, 0)
      },
      '& .content-wrapper': {
        height: `calc(100% - ${theme.spacing(0)})`,
        '& .form-box': {
          padding: `${theme.spacing(1)} !important`
        }
      },
      '& .title-text': {
        fontSize: theme.spacing(1.5),
        marginBottom: theme.spacing(0.875),
        '& .MuiTypography-root': {
          fontSize: theme.spacing(1.5)
        }
      },
      '& .subtitle-text': {
        fontSize: theme.spacing(1)
      },
      '& .bottom-btn-wrap': {
        justifyContent: 'center',
        gap: theme.spacing(1),
        marginTop: 'auto',
        '& .btn-grp': {
          justifyContent: 'center',
          gap: theme.spacing(1),
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      },
      '& .chat-mail-btn, & .back-btn': {
        fontSize: theme.spacing(1),
        gap: theme.spacing(0.313),
        padding: theme.spacing(0.5),
        '& .icon-box': {
          width: theme.spacing(1.25),
          height: theme.spacing(1.25),
          '& svg': {
            width: theme.spacing(1.25),
            height: theme.spacing(1.25)
          }
        }
      },
      '& .form-status-box': {
        width: theme.spacing(20.75),
        height: theme.spacing(2.5),
        top: theme.spacing(4.125),
        left: '50%',
        transform: 'translateX(-50%)',
        '& .MuiTypography-root': {
          fontSize: theme.spacing(1)
        }
      }
    }
  }
})
