import { createSlice } from '@reduxjs/toolkit'
import { getAllTournaments, getFinishedTournaments, getLeaderBoardDetails, getSelectedTournament, getTournamentsHistory } from 'redux-thunk/thunk/tournament.thunk'

const defaultTournamentState = {
  loadingTournaments: false,
  allTournaments: null,
  selectedTournament: '',
  leaderBoard: null,
  tournamentHistory: null
}

const tournamentSlice = createSlice({
  name: 'tournamentSlice',
  initialState: defaultTournamentState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTournaments.fulfilled, (state, action) => {
        return {
          ...state,
          allTournaments: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getAllTournaments.pending, (state, action) => {
        return {
          ...state,
          allTournaments: action.payload,
          loadingTournaments: true
        }
      })
      .addCase(getAllTournaments.rejected, (state, action) => {
        return {
          ...state,
          allTournaments: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getSelectedTournament.fulfilled, (state, action) => {
        return {
          ...state,
          selectedTournament: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getSelectedTournament.pending, (state, action) => {
        return {
          ...state,
          selectedTournament: action.payload,
          loadingTournaments: true
        }
      })
      .addCase(getSelectedTournament.rejected, (state, action) => {
        return {
          ...state,
          selectedTournament: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getFinishedTournaments.fulfilled, (state, action) => {
        return {
          ...state,
          finishedTournaments: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getFinishedTournaments.pending, (state, action) => {
        return {
          ...state,
          finishedTournaments: action.payload,
          loadingTournaments: true
        }
      })
      .addCase(getFinishedTournaments.rejected, (state, action) => {
        return {
          ...state,
          finishedTournaments: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getLeaderBoardDetails.fulfilled, (state, action) => {
        return {
          ...state,
          leaderBoard: action.payload
        }
      })
      .addCase(getTournamentsHistory.fulfilled, (state, action) => {
        return {
          ...state,
          tournamentHistory: action.payload,
          loadingTournaments: false
        }
      })
      .addCase(getTournamentsHistory.pending, (state, action) => {
        return {
          ...state,
          tournamentHistory: action.payload,
          loadingTournaments: true
        }
      })
      .addCase(getTournamentsHistory.rejected, (state, action) => {
        return {
          ...state,
          tournamentHistory: action.payload,
          loadingTournaments: false
        }
      })
  }
})

export const { setAllTournaments } = tournamentSlice.actions
export default tournamentSlice.reducer
