import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllTournamentsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/tournament/get-all', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getSelectedTournamentService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/tournament', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getLeaderBoardDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/tournament/leader-board', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const joinTournamentService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/tournament/join', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const tournamentHistoryService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/tournament/get-history', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
