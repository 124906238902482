import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllTournamentsService, getLeaderBoardDetailsService, getSelectedTournamentService, joinTournamentService, tournamentHistoryService } from 'network/services/tournament.service'

export const getAllTournaments = createAsyncThunk(
  'fetch/allTournaments',
  async (data, thunkApi) => {
    try {
      const res = await getAllTournamentsService(data)
      return res?.tournaments
      // return tourRes?.data?.tournaments
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

// creating separate thunk becuase of the difference in res structure
export const getFinishedTournaments = createAsyncThunk(
  'fetch/finishedTournaments',
  async (data, thunkApi) => {
    try {
      const res = await getAllTournamentsService(data)
      return res?.tournaments
      // return tourRes?.data?.tournaments
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getSelectedTournament = createAsyncThunk(
  'fetch/get-selected-tournaments',
  async ({ data, handleSuccess }, thunkApi) => {
    try {
      const res = await getSelectedTournamentService(data)
      handleSuccess()
      return res?.tournamentRound
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getLeaderBoardDetails = createAsyncThunk(
  'fetch/get-leaderBoard',
  async (data, thunkApi) => {
    try {
      const res = await getLeaderBoardDetailsService(data)
      return res?.leaderBord
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const joinTournament = createAsyncThunk(
  'fetch/joinTournament',
  async ({ data, handleSuccess }, thunkApi) => {
    try {
      const res = await joinTournamentService(data)
      handleSuccess()
      return res
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getTournamentsHistory = createAsyncThunk(
  'fetch/tournament-history',
  async (data, thunkApi) => {
    try {
      const res = await tournamentHistoryService(data)
      return res?.history
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
