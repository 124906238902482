import { Box, styled } from '@mui/material'

export const ChangePasswordWrapper = styled(Box)(({ theme }) => {
  return {
    '& .error-message': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      color: '#970505 !important',
      fontSize: '15px !important',
      fontWeight: '500',
      margin: '2px 0px'
    }
  }
})
