import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { FooterWrapper } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSiteinfo } from 'redux-thunk/thunk/gameSetting'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AffiliateModal from 'pages/AffiliateModal/index'
import ReferralModal from 'pages/ReferralModal/index'

export default function Footer () {
  const theme = useTheme()
  const [isAffiliateModalOpen, setAffiliateModalOpen] = useState(false)
  const [isReferralModalOpen, setReferralModalOpen] = useState(false)
  const { cmsData } = useSelector((state) => state?.gameSetting)
  const { selectedLanguage } = useSelector((state) => state.language)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllSiteinfo())
  }, [dispatch])

  return (
    <>
      <FooterWrapper theme={theme}>
        {isAffiliateModalOpen && <AffiliateModal dialogOpen={isAffiliateModalOpen} handleClose={() => setAffiliateModalOpen(false)} />}
        {isReferralModalOpen && <ReferralModal dialogOpen={isReferralModalOpen} handleClose={() => setReferralModalOpen(false)} />}
        <Box className='footer-wrap'>
          <Grid className='footer-grid-wrap'>
            <Grid item className='footer-links-container'>
              <Box className='footer-links-box'>
                <Link onClick={() => setAffiliateModalOpen(true)} className='link-item'>{t('affiliate')}</Link>
                <Link onClick={() => setReferralModalOpen(true)} className='link-item'>{t('referral')}</Link>
                {cmsData?.map((data, i) => (
                  <Link key={i} to={`/cms/${data.slug}`} target='_blank' className='link-item'>{data.title?.[selectedLanguage?.code.toUpperCase()] || data.title?.EN || ''}</Link>
                ))}
              </Box>
              <Box className='img-box'>
                <img src='/assets/images/stock-images/crown.png' loading='lazy' alt='img' />
                <img src='/assets/images/stock-images/18-plus.png' loading='lazy' alt='img' />
              </Box>
            </Grid>

            <Grid item className='footer-logos-container'>
              <Box className='payment-options-box'>
                <Typography variant='subtitle1' className='title-text'>{t('paymentOptions')}</Typography>
                <Box className='logos-box'>
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_visa.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_mastercard.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_postepay.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_footer_interac.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_paysafecard.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_banktransfer.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_skrill.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_klarna.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_mifinity.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_footer_astropay.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_bitcoin.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_litecoin.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/payment-options-logos/paymsystem_neteller.png' loading='lazy' alt='Payment Option Logo' />
                </Box>
              </Box>

              <Box className='providers-box'>
                <Box className='logos-box'>
                  <img src='/assets/images/stock-images/providers-logos/clash-of-slots-black.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/sigma.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/top10casinos.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/casino-en-ligne.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/ask-gamblers-logo.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/bonkku-logo.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/italiancasino-logo.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/new-casino.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/casino-on-line.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/casinolandia.png' loading='lazy' alt='Payment Option Logo' />
                  <img src='/assets/images/stock-images/providers-logos/betrugstest.png' loading='lazy' alt='Payment Option Logo' />
                </Box>
              </Box>
              <Box className='footer-text'>
                2023-2024 &#169; {t('footerRightsReserved')}
                <br />
                {t('footerAboutSite')}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FooterWrapper>
    </>
  )
}
