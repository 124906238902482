import CryptoJS from 'crypto-js'

const SOCKET_ENCRYPTION_KEY = process.env.REACT_APP_SOCKET_ENCRYPTION_KEY

/**
 * To check the email validation
 * @function validateEmail
 *
 * @param {string} email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  const validate = String(email).match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return !!validate
}

export const validateEmailOrUserName = (email) => {
  const validate =
    String(email).match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) || String(email).match(/^[A-Za-z][A-Za-z0-9_]{3,30}$/)
  return !!validate
}

/**
 * To check if two passwords match
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
export const isMatchPassword = (password, confirmPassword) => {
  return password === confirmPassword
}
/**
 * Encode the password using TextEncoder
 *
 * @param {string} password
 * @returns
 */
export function encodePassword (password) {
  const encoder = new TextEncoder()
  const data = encoder.encode(password)
  const base64 = btoa(String.fromCharCode(...data))
  return base64
}

/**
 * To check if password is alphanumeric
 * and between 4 and 8 characters
 *
 * @param {string} password
 * @returns {boolean}
 */
export const validatePassword = (password) => {
  const validate = String(password).match(
    /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z][A-Za-z0-9 !"#$%&'()*+,-.\\/:;<=>?@[\]^_`{|}~]{3,}$/
  )
  return !!validate
}

/**
 * To check Phone Number Validation
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validatePhoneNumber = (string) => {
  const validate = String(string).match(
    /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im
  )
  return !!validate
}

/**
 * To check string contain only numbers
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validateNumber = (string) => {
  const validate = String(string).match(/^[0-9]*$/)
  return !!validate
}

/**
 * To check string contain only letters
 * Vaidates for a-z and A-Z and white space
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validateLetters = (string) => {
  const validate = String(string).match(/^[a-zA-Z ]+$/)
  return !!validate
}
export const validateCharLimit = (string) => {
  return string.length <= 20
}
export const validateLimit = (string) => {
  return string.length <= 30
}

export const formatCategoryName = (name) => {
  if (name) {
    let tempName = name?.replace('-', ' ')
    tempName = tempName.charAt(0).toUpperCase() + tempName.slice(1)
    return tempName
  }
  return ''
}

export const formatDateMDY = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [month, day, year].join('-')
}

export const removeLoginToken = () => {
  window.localStorage.clear()
  window.location.reload()
}

export const encryptCredentials = (data) => {
  return CryptoJS.AES.encrypt(data, SOCKET_ENCRYPTION_KEY).toString()
}

export const decryptCredentials = (data) => {
  return CryptoJS.AES.decrypt(data, SOCKET_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)
}

export const encryptSocketData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SOCKET_ENCRYPTION_KEY).toString()
}

export const decryptSocketData = (data) => {
  return JSON.parse(CryptoJS.AES.decrypt(data, SOCKET_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8))
}

export const getDeviceType = () => {
  const height = window.screen.height
  const width = window.screen.width
  if (height <= 950 && width <= 480) {
    return 'mobile'
  } else if (width <= 820 && height <= 1180) {
    return 'tablet'
  } else {
    return 'desktop'
  }
}

export const getLoginToken = () => {
  if (getItem('authSession')) {
    const token = JSON.parse(window.localStorage.getItem('authSession'))
    if (token) return token
    return ''
  }
  return ''
}

export const setItem = (key, value) => {
  return window.localStorage.setItem(key, encryptCredentials(value))
}

export const getItem = (key) => {
  if (!window?.localStorage?.getItem(key)) {
    return window.localStorage.getItem(key)
  }
  if (key === 'userId') {
    return parseInt(decryptCredentials(window.localStorage.getItem(key)))
  }
  if (key === 'loggedIn') {
    return Boolean(decryptCredentials(window.localStorage.getItem(key)))
  }
  if (key === 'language') {
    return 'en'
  }
  if (key === 'authSession') {
    const token = window.localStorage.getItem(key)
    if (token) return token
    return ''
  }
  return (window.localStorage.getItem(key))
}

export const getUserId = () => {
  const user = localStorage.getItem('authSession')
  if (user) {
    const { userId } = JSON.parse(user)
    return userId
  }
  return ''
}

export const capitalizeMyText = (name, delemiter) => {
  return (name && name?.length > 0 && name.split(delemiter).map(ele =>
    ele.substr(0, 1).toUpperCase().concat(ele.substr(1))
  ).join(' ')) || name
}

export const debounce = (func) => {
  let timer
  return function (...args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      func.apply(this, args)
    }, 1000)
  }
}

export const countDownTimer = (startEndDate, updateTimer, timerEnded) => {
  const countDownDate = new Date(startEndDate).getTime()
  const timerInterval = setInterval(() => {
    const now = new Date().getTime()
    const distance = countDownDate - now
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)
    const timerObj = { days, hours, minutes, seconds }
    updateTimer(timerObj)
    if (distance < 0) {
      clearInterval(timerInterval)
      updateTimer({ days: 0, hours: 0, minutes: 0, seconds: 0 })
      timerEnded(true)
    }
  }, 1000)
}
