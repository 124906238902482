import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/get-all-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const activateWageringService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/activate-wagering', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus activated successfully'
  })
}

export const forfeitBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/bonus/forfeit-bonus', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus forfeit successfully'
  })
}

export const getUserBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/get-all-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}

export const getUserActiveBonus = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/user/get-user-bonus', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
// use bonus service
export const getUseBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/api/v1/user/activate-bonus', data, {
    server: microServices.SERVICE_URL_1,
    // params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const getUserBonusHistoryService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/user/get-bonus-history', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
export const getBonusDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'get-bonus-detail', {}, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}
export const getLoyaltyLevelService = () => {
  return axiosInstance(METHOD_TYPES.get, 'get-loyalty-level', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}
export const getPromotionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/banner/get-promotions', {}, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.page
  })
}
export const availBonusService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/api/v1/user/avail-bonus', data, {
    server: microServices.SERVICE_URL_1,
    params: data,
    loader: LOADER_HANDLER_TYPES.content
  })
}
