import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { VisitedCardStyle } from './style'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
// import DataNotAvailable from 'components/layout/Common/DataNotAvailable/index'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'

const VisitedCard = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const theme = useTheme()
  const receivedData = location.state?.data || {}
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }
  if (receivedData === '') {
    return (
      <>
        <CustomPrimaryButton sx={{ marginBottom: 2 }} onClick={goBack}>
          {t('back')}
        </CustomPrimaryButton>        {/* <DataNotAvailable message='promotionsNotAvailable' /> */}
      </>
    )
  }
  return (
    <VisitedCardStyle theme={theme}>
      <Box className='visted-card-container'>
        <CustomPrimaryButton sx={{ marginBottom: 2 }} onClick={goBack}>
          {t('back')}
        </CustomPrimaryButton>
        <Box className='centered-card'>
          <Paper elevation={3} sx={{ maxWidth: '100%' }} className='card-paper'>
            {receivedData?.image && <img height='250px' width='100%' src={`${process.env.REACT_APP_S3_URL}/${receivedData?.image}`} alt='image' loading='lazy' />}
            <Typography className='card-title'>{receivedData?.title}</Typography>
            <Typography className='card-desctiption'>{receivedData?.description}</Typography>
            {
              receivedData?.url
                ? <iframe src={receivedData?.url} style={{ height: '100vh', width: '100%' }} />
                : <></>
            }
            <Typography dangerouslySetInnerHTML={{ __html: receivedData?.content }} className='dynamic-content' />
          </Paper>
        </Box>
      </Box>
    </VisitedCardStyle>
  )
}

export default VisitedCard
