import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordFormWrapper } from './style'
import {
  CustomFormLabel,
  CustomTextField
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import {
  SocialArrobaIcon,
  SocialFacebookIcon,
  SocialGooglePlusIcon,
  SocialOdnoklassnikiIcon,
  SocialVKIcon,
  SpinnerSVG
} from 'assets/icons/index'
import {
  CustomPrimaryButton
} from 'components/common-ui/CustomButton/CustomButton'
import { forgetPassword } from '../../../redux-thunk/thunk/auth.thunk'
import { verifyEmailSchema } from './schema'
import ErrorMessage from '../ErrorMessage/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'

const ForgotPasswordForm = ({ setShowLoginForm }) => {
  const [emailSent, setEmailSent] = useState(false)
  const theme = useTheme()
  const langCode = useSelector((state) => state.language.selectedLanguage?.code?.toUpperCase())

  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleVerifySubmit = async ({ email }) => {
    dispatch(forgetPassword({ email, setEmailSent }))
  }
  return (
    <>
      <ForgotPasswordFormWrapper theme={theme}>
        <Box className='form-wrap'>
          <Typography variant='h3' className='form-title'>
            {t('signinForgetPassword')}
          </Typography>
          <Formik
            enableReinitialize
            initialValues={{ email: '' }}
            validationSchema={verifyEmailSchema(t)}
            onSubmit={({ email }) =>
              handleVerifySubmit({ email })}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur
            }) => {
              return (
                <Form autoComplete='off'>
                  {!emailSent
                    ? (
                      <Box component='form' noValidate>
                        <Grid container spacing={1.5}>
                          <Grid item xs={12} lg={6}>
                            <Grid container rowSpacing={1.25} columnSpacing={0.625}>
                              <Grid item xs={12} lg={12}>
                                <Typography variant='caption'>
                                  {t('forgotPasswordMsg')}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} lg={12}>
                                <CustomFormLabel>{t('signupEmail')} *</CustomFormLabel>
                                <CustomTextField
                                  fullWidth
                                  name='email'
                                  placeholder={t('signupEmail')}
                                  value={values.email}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.trim()
                                    handleChange(e)
                                  }}
                                  onBlur={handleBlur}
                                  maxLength={50}
                                  haslabel='yes'
                                />
                                <ErrorMessage
                                  component='p'
                                  name='email'
                                  className='error-msg'
                                />
                              </Grid>
                              <Grid item xs={12} lg={12}>
                                <Box className='aut-btn-box'>
                                  <CustomPrimaryButton
                                    fullWidth
                                    type='submit'
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    fontSize={['DE', 'TR', 'RU'].includes(langCode) ? 'smd' : ''}
                                  >
                                    {!loading
                                      ? t('continue')
                                      : <SpinnerSVG style={{ fill: 'white' }} />}
                                  </CustomPrimaryButton>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <Box className='social-auth-wrap'>
                              <Typography variant='caption'>
                                {t('registerViaSocialNetworks')}
                              </Typography>
                              <Box className='social-auth-box'>
                                <SocialArrobaIcon />
                                <SocialOdnoklassnikiIcon />
                                <SocialFacebookIcon />
                                <SocialVKIcon />
                                <SocialGooglePlusIcon />
                              </Box>
                            </Box>
                            <Typography
                              variant='caption'
                              sx={{
                                textAlign: 'center',
                                display: 'block',
                                marginTop: theme.spacing(1.25)
                              }}
                            >
                              Don't have an account?{' '}
                              <Typography
                                variant='button'
                                onClick={() => setShowLoginForm('signup')}
                              >
                                {t('registration')}
                              </Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      )
                    : (
                      <Grid container rowSpacing={1.25} columnSpacing={0.625}>
                        <Grid item xs={12} lg={12}>
                          <Typography variant='caption'>
                            {t('emailLinkMsg')}
                          </Typography>
                        </Grid>
                      </Grid>
                      )}
                </Form>
              )
            }}
          </Formik>
        </Box>
      </ForgotPasswordFormWrapper>
    </>
  )
}

export default React.memo(ForgotPasswordForm)
