import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllCurrencyService = () => {
  // return axiosInstance(METHOD_TYPES.get, '/api/get-currency', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.table
  // })
}

export const getAllSiteinfoService = () => {
  // return axiosInstance(METHOD_TYPES.get, '/api/game-category', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.page
  // })
}

export const getAllCmsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/get-cms-info', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getAllCmsSiteinfoService = (data) => {
  const lang = [data?.selectedLanguage?.code.toUpperCase()]
  return axiosInstance(METHOD_TYPES.get, `/api/v1/get-cms-page?cmsSlug=${data?.cmsId}&language=${lang || ['EN']}`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getGameLanguagesService = () => {
  // return axiosInstance(METHOD_TYPES.get, '/api/get-languages', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.page
  // })
}

export const getGameCountriesService = () => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/get-all-country', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  })
}

export const getSignupRegistrationFieldsService = () => {
  // return axiosInstance(METHOD_TYPES.get, '/api/get-registration-fields', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.page
  // })
}
