export default {
  404: '404',
  vip: '要人',
  upcoming: '今後の予定',
  finished: '終了した',
  prizePool: '賞金総額',
  joinNow: '今すぐ参加',
  joined: '参加しました',
  startsIn: '始まります',
  endsIn: 'で終わる',
  ended: 'トーナメントが終了しました',
  started: 'トーナメントが始まりました',
  seeMore: '続きを見る',
  winPrizes: '刺激的な賞品を獲得しましょう',
  leaderBoard: 'リーダーボード',
  prize: '賞',
  aboutTournament: '大会について',
  congratulationsMessage: 'ブラボー！',
  payments: '支払い',
  tournaments: 'トーナメント',
  shop: '店',
  blog: 'ブログ',
  referral: '照会',
  contacts: '連絡先',
  retry: 'リトライ',
  incorrectCredsMessage: 'ユーザー名またはパスワードが間違っています。',
  bonuses: 'ボーナス',
  depositBonus: '入金ボーナス',
  voucher: 'バウチャー',
  gamlings: 'ギャンブル',
  transactionHistory: '取引履歴',
  offers: 'オファー',
  editProfile: 'プロファイル編集',
  continue: '続く',
  apply: '適用する',
  male: '男',
  female: '女性',
  register: '登録する',
  login: 'ログイン',
  grand: 'グランド',
  major: '選考科目',
  minor: 'マイナー',
  mini: 'ミニ',
  crashGameTitle: 'クラッシュ',
  betAmount: 'ベット額',
  profit: '利益',
  payout: '目標ペイアウト',
  chance: '勝利のチャンス',
  betBtn: 'ベット',
  autoCashout: '自動キャッシュアウト',
  btnHalf: '1/2',
  btnDouble: '2×',
  btnMax: 'マックス',
  wagered: '賭けた',
  nothingFound: '何も見つかりません',
  lost: '失った',
  won: '勝利した',
  streak: 'ストリーク',
  highestWin: '最高勝利数',
  promotions: 'プロモーション',
  latestPromotions: '最新のプロモーション',
  sponsorships: 'スポンサーシップ',
  highestLost: '最高損失額',
  highestBet: '最高の賭け金',
  maxStreak: 'マックスストリーク',
  maxProfit: '最大利益: ',
  bal: 'バル: ',
  messagesNoBetHistory: 'あなたのベット履歴は空です',
  messagesCopyNotSupported: 'お使いのブラウザはクリップボードへの自動コピーをサポートしていません。コードを手動でコピーしてみてください。',
  messagesCopied: 'アドレスがコピーされました。',
  deposit: 'デポジット',
  statReset: '統計をリセットする',
  rollOver: '転がる',
  inputFieldBtnHalf: '1/2',
  inputFieldBtnDouble: '2×',
  back: '戻る',
  inputFieldBtnMax: '最大',
  buttonTextPlaceBet: 'ベットをする',
  buttonTextCancelBet: 'ベットをキャンセルする',
  buttonTextEscapeBet: 'キャッシュアウト',
  buttonTextStartAutoBet: 'オートベットを開始する',
  buttonTextCancelAutoBet: 'オートベットをキャンセルする',
  buttonTextLoading: '読み込み中',
  tabsMyBets: '私の賭け',
  tabsDeposit: 'デポジット',
  tabsCashout: 'キャッシュアウト',
  tabsAllbets: 'すべてのベット',
  tabsTopbets: 'トップベット',
  tabsBonus: 'ボーナス',
  tabsjackpot: 'ジャックポット',
  tabsBetHistory: 'ベット履歴',
  timerTextCurrentPayout: '現在の支払い額',
  timerTextStarting: 'から始まる',
  timerTextSec: 's',
  timerTextFlewAway: 'クラッシュしました',
  timerTextWait: 'お待ちください..',
  timerTextPreparingRound: '準備ラウンド',
  timerTextLoadingRound: 'ラウンドをロード中',
  comingSoon: '近日公開',
  labelsProfitOnWin: '勝利時の利益',
  inputsNoOfBets: 'ベット数',
  inputsAuto: '自動',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: '始める',
  inputsCancel: 'キャンセル',
  buttonsMin: '分',
  buttonsMax: 'マックス',
  prediction: '予測',
  luckyNumber: 'ラッキーナンバー',
  multiplier: '乗数',
  chanceToWin: '勝つチャンス',
  bets: 'ベット',
  depositBtn: 'デポジット',
  rollUnder: 'ロールアンダー',
  symbolsX: 'バツ',
  symbolsPercent: '%',
  navBarTitle: '歴史',
  navBarMyBets: '私の賭け',
  navBarAllBets: 'すべてのベット',
  navBarHighRollers: 'ハイローラー',
  navBarRoundHistory: 'ラウンド履歴',
  navBarTotal: '合計ベット数',
  topHeaderBetId: 'ベットID',
  topHeaderUser: 'ユーザー',
  topHeaderPayout: '支払い',
  topHeaderAmount: '額',
  topHeaderTime: '時間',
  topHeaderProfit: '利益',
  topHeaderRoundCrashed: 'クラッシュしました',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'ハッシュ',
  topHeaderRoundBet: 'ベット',
  topHeaderRoundProfit: '利益',
  topHeaderRoundSignature: 'サイン',
  roundHistoryTitle: 'ゲーム情報',
  roundHistoryDescGame: 'ゲーム',
  roundHistoryDescCrashAt: 'クラッシュした場所:',
  roundHistoryDescDate: '日付 ：',
  roundHistoryDescBetHeader: 'ベット',
  roundHistoryTableTitlePlayer: 'プレーヤー',
  roundHistoryTableTitleBet: 'ベット',
  roundHistoryTableTitleCashedOut: '現金化されました',
  roundHistoryTableTitleProfit: '利益',
  roundHistoryTableTitleBetId: 'ベットID',
  roundHistoryMessagesNoBetHistory: 'あなたのベット履歴は空です',
  placedBetsTabPlaced: '賭け金を賭けた',
  placedBetsTabPrev: '前回のベット',
  placedBetsTitleUser: 'ユーザー',
  placedBetsTitleEscape: 'マルチ.x',
  placedBetsTitleBet: 'ベット',
  placedBetsTitleProfit: '利益',
  placedBetsTitleCashout: 'キャッシュアウト',
  placedBetsNoPlacedBet: '賭けはありません。',
  provablyFairContentCheckBtn: '証明可能なフェアをチェックする',
  provablyFairContentTitle: '証明的に公正',
  provablyFairContentDesc: '4 チームのリードの結果は公平であることが証明できます。',
  provablyFairContentInputFieldsCrashRateTitle: 'クラッシュレート',
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'x を使用しない場合のクラッシュ率',
  provablyFairContentInputFieldsRoundHashTitle: 'ラウンドハッシュ',
  provablyFairContentInputFieldsRoundHashPlaceHolder: '例えば',
  provablyFairContentInputFieldsSubmitBtn: '署名の生成',
  provablyFairContentSignature: 'ラウンドサイン',
  provablyFairContentErrorsCrashRate: 'クラッシュ率フィールドに数値を入力してください',
  resetPasswordTitle: 'パスワードを再設定する',
  resetPasswordNewPasswordTitle: '新しいパスワード',
  resetPasswordNewPasswordErrorsRequired: '*パスワードが必要。',
  resetPasswordNewPasswordErrorsMinLength: '最小 8 文字を使用できます。',
  resetPasswordNewPasswordErrorsMaxLength: '最大 16 文字を使用できます。',
  resetPasswordNewPasswordErrorsPattern: '※パスワードは8～16文字の英数字と特殊文字です。',
  resetPasswordConfirmPasswordTitle: 'パスワードを認証する',
  resetPasswordConfirmPasswordErrorsNotMatched: 'パスワードが一致していません。',
  resetPasswordResetBtn: 'リセット',
  passwordResetSuccess: 'パスワードが正常に変更されました。ゲームをお楽しみいただくためにログインしてください。',
  resetPasswordWentWrong: '何か問題が発生しました!',
  levelGrand: 'グランド',
  levelMajor: '選考科目',
  levelMinor: 'マイナー',
  levelMini: 'ミニ',
  bannerContentWinDaily: '毎日 $1000 を獲得 ',
  bannerContentContest: '現在コンテスト中 ',
  bannerContentjoin: '毎日の賭けコンテストに参加してヒーローになりましょう! ',
  bannerContentPlayNow: '今すぐプレイ',
  bannerContentTitle: 'ジャックポットを獲得する',
  bannerContentContent: 'もっと遊びます',
  bannerContentGameName: 'クラッシュゲーム',
  bannerContentGameDesc: 'もっと遊びます',
  partners: 'パートナー',
  gamesGoldenDragon: '黄金のドラゴン',
  gamesCrashGame: 'クラッシュゲーム',
  gamesDice: 'サイコロ',
  gamesHilo: 'ヒロ',
  allBetsTitle: 'すべてのベット',
  allBetsBetId: 'ベットID',
  allBetsUser: 'ユーザー',
  allBetsPayout: '支払い',
  allBetsAmount: '額',
  allBetsTime: '時間',
  allBetsProfit: '利益',
  LoginwithOtp: 'OTPでログイン',
  EnterLoginOtp: 'ログイン OTP を入力してください',
  submit: '提出する',
  footerAboutUs: '私たちについて',
  footerFaq: 'よくある質問',
  footerSitePolicy: 'サイトポリシー',
  footerResponsibleGambling: '責任あるギャンブル',
  footerTerms: '利用規約',
  signinTitle: 'ログイン',
  signinUsername: 'ユーザー名 / メールアドレス',
  signinPassword: 'パスワード',
  signinLoginBtn: 'ログイン',
  signinSignup: 'サインアップ！',
  signinForgetPassword: 'パスワードをお忘れですか？',
  forgotPasswordMsg: 'アカウントに関連付けられた電子メール アドレスを入力すると、パスワードをリセットするためのリンクが送信されます。',
  emailLinkMsg: 'パスワードをリセットするリンクが登録メールアドレスに送信されました。メールリンクをクリックして新しいパスワードを設定してください。',
  resetPasswardConfirm: 'パスワードが正常にリセットされました',
  signinDontHaveAccount: 'アカウントをお持ちでない場合は、 ',
  signinCreateAccount: 'アカウントを作成する',
  signinErrorsUserNameRequired: '*ユーザー名/メールアドレスを入力してください。',
  signinErrorsUserNameMinLength: '最小 4 文字を使用できます。',
  signinErrorsUserNameMaxLength: '最大 20 文字まで入力できます。',
  signinErrorsUserNamePattern: '有効なユーザー名を入力してください。文字と数字のみを使用できます。',
  signinErrorsPasswordRequired: '*パスワードが必要。',
  signinErrorsPasswordMinLength: '最小 8 文字を使用できます。',
  signinErrorsPasswordMaxLength: '最大 16 文字を使用できます。',
  signinErrorsPasswordPattern: '※パスワードは8～16文字の英数字と特殊文字です。',
  signinSuccessfullyLoggedIn: 'ログインに成功しました。',
  signinResetMailSent: 'パスワードリセットメールを送信しました。',
  signinTryAgain: '何かが間違っていました。もう一度やり直してください。',
  signinResetPasswordUsernameErr: 'パスワードをリセットするには、ユーザー名/メールアドレスを入力してください。',
  signinResetMailSentSuccessContent: 'パスワードをリセットするリンクをメールで送信しました。',
  signupTitle: '登録する',
  signupUsername: 'ユーザー名',
  signupEmail: 'Eメール',
  signupTermsConditions: '条項',
  signupWalletConnect: 'ウォレットでログイン ',
  signupPassword: 'パスワード',
  signupEighteenPlus: '私は 18 歳以上であり、利用規約に同意します',
  signupRegisterBtn: 'サインアップ',
  signupCreateNewAccount: '新しいアカウントを作成する',
  signupFullName: 'フルネーム',
  signupReferralCode: '紹介コード',
  signupConfirmPassword: 'パスワードを認証する',
  signupUseSocialAccount: 'ソーシャルネットワーク経由でログインする',
  signupFacebook: ' フェイスブック',
  signupGoogle: ' グーグル',
  signupHaveAccount: 'アカウントを持っています？ ',
  signupLoginBtn: 'ログイン',
  signupErrorsUserNameRequired: '※ユーザー名を入力してください。',
  signupErrorsUserNameMinLength: '最小 4 文字を使用できます。',
  signupErrorsUserNameMaxLength: '最大 20 文字まで入力できます。',
  signupErrorsUserNamePattern: '有効なユーザー名を入力してください。文字と数字のみを使用できます。',
  signupErrorsFullNameRequired: '※フルネームを入力してください。',
  signupErrorsFullNamePattern: '有効なフルネームを入力してください。文字と数字のみを使用できます。',
  signupErrorsEmailRequired: '※メールアドレスをご入力ください。',
  signupErrorsEmailMinLength: '最小 4 文字を使用できます。',
  signupErrorsEmailMaxLength: '最大 20 文字まで入力できます。',
  signupErrorsEmailPattern: '有効な電子メールを入力してください。',
  signupErrorsPasswordRequired: '*パスワードが必要。',
  signupErrorsPasswordMinLength: '最小 8 文字を使用できます。',
  signupErrorsPasswordMaxLength: '最大デポジットは 16 文字まで許可されます。',
  signupErrorsPasswordPattern: '※パスワードは8～16桁の英数字(大文字・小文字)と特殊文字です。',
  signupErrorsConfirmPasswordMatch: 'パスワードが一致している必要があります。',
  signupPlaceholderUserName: '分。 ',
  signupPlaceholderEmail: '例えば',
  signupPlaceholderPassword: '分。',
  signupSomethingWentWrong: '何か問題が発生しました!',
  signupSignupGoogle: 'Google への登録に成功しました。',
  signupRegistered: '正常に登録されました。',
  signupConfirmAge: 'あなたが18歳であることを確認してください。',
  signupSignupFacebook: 'Facebook へのサインアップに成功しました。',
  signupCookiesConsent: 'ソーシャル ログインを使用するには、ブラウザの設定でサードパーティ Cookie を許可してください。',
  signupRegisterEmailPopupContent: '確認メールを送信しました。',
  accountMenuDeposit: 'デポジット',
  accountMenuCashout: 'キャッシュアウト',
  accountMenujackpotWinners: 'ジャックポット当選者',
  accountMenuTransactions: '取引',
  accountMenuPromotions: 'プロモーション',
  accountMenuProfile: 'プロフィール',
  accountMenuLogout: 'ログアウト',
  accountMenuSound: '音',
  accountMenuMusic: '音楽',
  accountMenuWithdraw: '撤回する',
  pageNotFound: 'お探しのページが見つかりません!',
  error: 'エラー',
  somethingWentWrong: '何か問題が発生しました!',
  verifyEmailThankYou: 'ありがとう！',
  verifyEmailEmailIsVerified: 'あなたのメールアドレスが確認されました。',
  verifyEmailGoToLogin: 'ログインページに移動',
  verifyEmailWentWrong: '何か問題が発生しました!',
  verifyEmailTokenExpired: '電子メールはすでに確認されているか、リンクの有効期限が切れています。以下に登録した電子メールを入力して、電子メール確認リンクを再送信してください。',
  verifyEmailGoToHome: 'ホームページへ',
  verifyEmailSuccessMsg: '電子メールが正常に確認されました。',
  bonusSectionSpanText: '最も寛大でさまざまなものをプレゼントします',
  bonusSectionBonuses: 'ボーナス',
  bonusSectionSubtitleText: '楽しくプレイして、ゲーム中に追加の報酬を獲得してください。',
  JoiningBonusTitle: '入会ボーナス',
  JoiningBonusButton: '今すぐ請求する',
  JoiningBonusDesc: 'このようなボーナスは、オンライン カジノ プレーヤーがビットコインやその他の暗号通貨を使用してギャンブルすることを奨励します。',
  depositBonusTitle: '入金ボーナス',
  depositBonusButton: 'デポジット',
  depositBonusDesc: 'このようなボーナスは、オンライン カジノ プレーヤーがビットコインやその他の暗号通貨を使用してギャンブルすることを奨励します。',
  refferBonusTitle: '紹介ボーナス',
  refferBonusButton: 'デポジット',
  refferBonusDesc: 'このようなボーナスは、オンライン カジノ プレーヤーがビットコインやその他の暗号通貨を使用してギャンブルすることを奨励します。',
  aboutSectionSpanText2: 'オンライン カジノでは、お気に入りのゲームを無料または暗号通貨でプレイできます。',
  aboutSectionSpanText3: ' このビットコイン ギャンブルの仮想世界では、お気に入りのカジノ ゲームをプレイしたり、ボーナスを獲得したり、コンテストやプロモーションに参加したりするなど、多くの機会を楽しむことができます。',
  verifiedSectionTitle: '検証済みのライセンスを取得したソフトウェア',
  verifiedSectionSpanText1: '他の Web サイトからソフトウェアをコピーしたり、採用したりすることはありません。 ',
  faqSectionTitle: 'プレイヤーからのよくある質問',
  faqSectionForgetPasswordTitle: 'パスワードを忘れた。',
  faqSectionForgetPasswordDesc: 'アカウントの回復用電子メール アドレスが指定されていません。',
  faqSectionMyDepositCreditedTitle: 'デポジットが入金されていません。',
  faqSectionMyDepositCreditedDesc: ' このアコーディオンのプレースホルダー コンテンツ。クラスをデモンストレーションすることを目的としています。',
  SupportedCurrencyTitle: 'サポートされている通貨',
  SupportedCurrencySpanText1: '他の Web サイトからソフトウェアをコピーしたり、採用したりすることはありません。 ',
  CarouselSectionTitle: 'ホドルクラッシュ',
  CarouselSectionSubTitle: ' もっと多くのゲームをプレイして、勝利のチャンスを掴みましょう。',
  WalletSettingTitle: 'ウォレットの設定',
  WalletSettingHideZero: 'ゼロ残高を非表示にする',
  WalletSettingHideZeroInfo: 'ゼロ残高はウォレットに表示されません',
  WalletSettingDisplayFiat: '暗号通貨を法定通貨で表示する',
  WalletSettingDisplayFiatInfo: 'すべての賭け',
  WalletSettingNoteForApprox: 'これらは通貨の近似値であることに注意してください。',
  WalletSettingSave: '保存',
  WalletSettingNoWalletFound: 'ウォレットが見つかりません',
  jackpotWinnersNoWinnerAvailable: '勝者はいません',
  jackpotWinnersNew: '新しい',
  jackpotWinnersHeader: 'ジャックポット勝者',
  jackpotDetailsHeader: 'ジャックポットの詳細',
  jackpotDetailsCampaignPeriod: 'キャンペーン期間',
  jackpotDetailsFrom: 'から',
  jackpotDetailsTo: 'に',
  jackpotDetailsBettingRule: 'ベッティングルール',
  jackpotDetailsMinBetAmount: '最低ベット額',
  jackpotDetailsMaxBetAmount: '最大ベット額',
  jackpotDetailsBetPercentage: '賭け率',
  jackpotDetailsWinningRuleHeader: '勝利の法則',
  jackpotDetailsWinningRule: 'より高いベット額で賭けると、勝つ可能性が高くなります。',
  jackpotDetailsProfitRule: 'ベット額が minBetAmount と maxBetAmount の間の場合、ベット額の betPercentage% がジャックポット額に追加されます。',
  profileTitle: '個人情報',
  profileFName: 'ファーストネーム',
  profileLname: '苗字',
  profileUserName: 'ユーザー名',
  profileDob: '生年月日',
  profileEmail: '電子メールアドレス',
  profilePhone: '電話番号',
  profileChange: '変化',
  profileSave: '変更を保存する',
  profilePassword: 'パスワード',
  profileSubmit: '提出する',
  profileUploadProfile: 'プロフィールをアップロードする',
  profileCurrentPassword: '現在のパスワード',
  profileNewPassword: '新しいパスワード',
  profileConfirmPassword: 'パスワードを認証する',
  profileScanQRCode: 'QRコードをスキャン',
  profileEnterSecretCode: 'シークレットコードを入力してください',
  profileErrorsFirstNameRequired: '名は必須です。',
  profileErrorsFirstNamePattern: '有効な名前を入力してください。',
  profileErrorsFirstNameMinLength: '最低 3 文字を使用できます。',
  profileErrorsFirstNameMaxLength: '最大 20 文字まで入力できます。',
  profileErrorsLastNameRequired: '姓は必須です。',
  profileErrorsLastNamePattern: '有効な名前を入力してください。',
  profileErrorsLastNameMinLength: '最低 3 文字を使用できます。',
  profileErrorsLastNameMaxLength: '最大 20 文字まで入力できます。',
  profileErrorsChangePasswordOldRequired: '古いパスワードが必要です。',
  profileErrorsChangePasswordOldPattern: '有効なパスワードを入力してください。',
  profileErrorsChangePasswordOldMinLength: '最小 8 文字を使用できます。',
  profileErrorsChangePasswordOldMaxLength: '最大 16 文字を使用できます。',
  profileErrorsChangePasswordNewRequired: '新しいパスワードが必要です。',
  profileErrorsChangePasswordNewPattern: '有効なパスワードを入力してください。',
  profileErrorsChangePasswordNewMinLength: '最小 8 文字を使用できます。',
  profileErrorsChangePasswordNewMaxLength: '最大 16 文字を使用できます。',
  profileErrorsChangePasswordNewPasswordNotMatched: 'パスワードが一致していません。',
  profileErrorsChangePasswordNewPasswordMatched: '新しいパスワードを現在のパスワードと同じにすることはできません。',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'パスワードの確認が必要です。',
  profileErrorsUserNameRequired: 'ユーザー名は必須です。',
  profileErrorsUserNamePattern: '有効な名前を入力してください。',
  profileErrorsUserNameMinLength: '最低 3 文字を使用できます。',
  profileErrorsUserNameMaxLength: '最大 20 文字まで入力できます。',
  profileErrorsDobRequired: '生年月日は必須です。',
  profileErrorsEmailRequired: '電子メールは必須です。',
  profileErrorsPostcodeRequired: '郵便番号は必須です。',
  profileErrorsCityRequired: '市区町村は必須です。',
  profileErrorsCurrencyRequired: '通貨は必須です。',
  profileErrorsAddressRequired: '住所は必須です。',
  profileErrorsCountryRequired: '国は必須です。',
  profileErrorsEmailPattern: '有効な電子メールを入力してください。',
  profileErrorsEmailMinLength: '最低 3 文字を使用できます。',
  profileErrorsEmailMaxLength: '最大 20 文字まで入力できます。',
  profileMessagesProfileUpdate: 'プロファイルが正常に更新されました。',
  profileMessagesChangePassword: 'パスワードが正常に更新されました。',
  profileProfilePicProfilePicUpload: 'プロフィール画像をアップロードする',
  profileNoNewChanges: 'プロフィールを更新できませんでした!',
  profileLoggedInWithSocial: 'ソーシャルログインでログインしました。',
  profileSideNavigatorSound: '音',
  profileSideNavigatorMusic: '音楽',
  profileSideNavigatorProfile: '私のプロフィール',
  profileSideNavigatorBetHistory: 'ベット履歴',
  profileSideNavigatorTransactions: '取引',
  profileSideNavigatorDeposit: 'デポジット',
  profileSideNavigatorWithdraw: '撤回する',
  profileSideNavigatorGameLimits: 'ゲームの制限',
  profileSideNavigatorReferral: '照会',
  profileSideNavigatorChangePassword: 'パスワードを変更する',
  profileSideNavigatorTermsConditions: '条項',
  profileSideNavigatorLogOut: 'ログアウト',
  profileSideNavigatorProvablyFair: '証明的に公正',
  WalletInfoSectionNotAvailable: '利用不可',
  WalletInfoSectionRealBalance: '実質残高',
  GameLimitsMinimumBet: '最低賭け金',
  GameLimitsMaximumBet: '最大賭け金',
  GameLimitsMaxWinFor1Bet: '1 回のベットで最大の勝ち額',
  ProvablyFairSubTitle: 'このゲームでは、Provively Fair テクノロジーを使用してゲームの結果を決定します。',
  ProvablyFairMaximumBet: '最大ベット:',
  ProvablyFairMaxWinFor1Bet: '1 回のベットで最大の勝ち額',
  promotionsTitle: 'プロモーション',
  promotionsNoLosingData: '申し訳ありませんが、負けなしボーナス プロモーションをご利用いただけます ',
  promotionsNoDepositData: '申し訳ございませんが、入金不要ボーナス プロモーションをご利用いただけます ',
  promotionsCurrency: ' 通貨。',
  promotionsViewBtn: 'ビュー',
  promotionsClaimSuccess: '無事に申請が完了しました',
  promotionsAvailability: '可用性： ',
  promotionsAvailabilityTabLosing: 'ボーナスを失った場合',
  promotionsAvailabilityTabDeposit: '入金ボーナス',
  promotionsDepositBonusTableCode: 'コード',
  promotionsDepositBonusTableMinDeposit: '分。',
  promotionsDepositBonusTablePercentage: 'パーセンテージ',
  promotionsDepositBonusTableMaxBonus: '最大ボーナス',
  promotionsDepositBonusTableRolloverMultipler: 'ロールオーバー乗数',
  promotionsDepositBonusTableMaxRolloverPerBet: 'ベットごとの最大ロールオーバー',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: 'ロールオーバー目標を達成するまでの時間',
  promotionsBonusCode: 'ボーナスコード： ',
  promotionsLossesClaim: '損失を請求できるのは（キャンペーン期間内）： ',
  promotionsLossesClaimLosingBonusTablePercentage: 'パーセンテージ',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: '分。',
  promotionsBonusStatus: 'ボーナスステータス: ',
  promotionsBonusAmount: 'ボーナス額: ',
  promotionsRolloverTarget: 'ロールオーバーターゲット: ',
  promotionsRolloverAchieved: 'ロールオーバーが達成されました: ',
  promotionsActivatebtn: '活性化',
  promotionsClaimBtn: '請求',
  promotionsCancelBtn: 'キャンセル',
  promotionsBackBtn: '戻る',
  promotionsBonus: 'ボーナス',
  promotionsTermAndConditions: ' 条項',
  promotionsClaimDepositBonusTitle: '入金ボーナスを請求する手順',
  promotionsClaimDepositBonusFirst: 'ボーナスコードを有効にする',
  promotionsClaimDepositBonusSecond: 'ウォレットへの入金金額',
  promotionsClaimDepositBonusThird: 'ボーナス獲得のためのロールオーバー目標を達成する',
  promotionsClaimLosingBonusTitle: '負けボーナスを請求する手順',
  promotionsClaimLosingBonusFirst: 'ボーナスコードを有効にする',
  promotionsClaimLosingBonusSecond: 'お気に入りのゲームをプレイする',
  promotionsClaimLosingBonusThird: 'キャンペーン期間内に損失を請求するには、「損失を請求する」をクリックしてください',
  promotionsWentWrong: '何か問題が発生しました!',
  transactionTitle: '取引',
  transactionBetId: 'ベットID',
  transactionUser: 'ユーザー',
  transactionPayout: '支払い',
  transactionAmount: '額',
  transactionProfit: '利益',
  transactionCashout: 'キャッシュアウト',
  transactionBet: 'ベット',
  transactionMultix: 'マルチ.x',
  transactionWin: '勝つ',
  transactionFairness: '公平性',
  transactionReferFriend: '友達を紹介する',
  transactionTotal: '合計ベット数',
  transactionWins: '勝利',
  transactionRefresh: 'リフレッシュ',
  transactionFilterTopMultipliers: '上位の乗数',
  transactionFilterHugeWins: '大勝利',
  transactionFilterBiggestWins: '最大の勝利',
  transactionFilterMultipliers: '乗数',
  transactionRealBalance: '実質残高',
  depositWithdrawTXDate: '日付',
  depositWithdrawTXAmount: '額',
  depositWithdrawTXTxId: '送信ID',
  depositWithdrawTXTxType: '取引タイプ',
  depositWithdrawTXStatus: '状態',
  depositWithdrawTXNoData: 'データなし。',
  depositTitle: 'デポジット',
  depositHistory: '歴史',
  depositSubTitle: 'ユーザーは法定通貨を預け入れたり引き出したりすることはできません。',
  depositCopyNotSupported: 'お使いのブラウザはクリップボードへの自動コピーをサポートしていません。コードを手動でコピーしてみてください。',
  depositCopied: 'アドレスがコピーされました。',
  depositAddressCreated: 'アドレスが正常に作成されました。',
  depositGenerateAddress: 'アドレスを生成する',
  depositWarningText: 'クロスチェーン転送を介して入金しないでください',
  withdrawTitle: '撤回する',
  withdrawSubTitle: 'ユーザーは法定通貨を預け入れたり引き出したりすることはできません。',
  withdrawNoteDesc: 'BEP2、BEP20 (BSC) アドレスを LTC 出金アドレスウォレットとして入力しないように注意してください。',
  withdrawNoteSublist: ' クロスチェーン転送として出金しないでください。',
  withdrawHistory: '歴史',
  withdrawWithdrawBtn: '出金リクエスト',
  withdrawFees: '出金手数料 {{fees}} {{token}}',
  withdrawWithdrawPending: '出金リクエストは保留中です。',
  withdrawEnterFields: '出金金額とウォレットアドレスを入力します。',
  withdrawSelectWallet: '出金するウォレットを選択してください。',
  withdrawAmountFieldTitle: '量 (最小 0.03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'あなたの {{wallet.currency.code}} 住所',
  withdrawAddressFieldPlaceholder: '住所を入力してください',
  withdrawNoBalance: 'バランスが足りません。',
  withdrawMinAmount: '以上の金額を入力してください {{min}} 。',
  fiatCurrencyInfo: 'ユーザーは法定通貨を預け入れたり引き出したりすることはできません。',
  paginationNextBtn: '次',
  paginationPrevBtn: '前の',
  wentWrong: '何か問題が発生しました。',
  referralTitle: '照会',
  referralSubTitle: '友達を紹介する',
  referralEarn: ' それぞれ ₹150 を獲得',
  referralReferAndEarn: '紹介して紹介ごとにボーナスを獲得',
  referralReferPara: ' このコードをユーザーに参照すると、',
  referralReferLink: 'リンクを参照',
  referralCopyReferralCode: '紹介コードをコピーする',
  referralTotalRewards: '報酬総額',
  TwoWayAuthentication: '双方向認証',
  LoginRequired: 'ログインが必要です！',
  BetPlacedSuccessfully: '賭けが成功しました',
  BetAddedInQueueSuccessfully: 'ベットがキューに正常に追加されました',
  BetRemovedFromQueueSuccessfully: 'ベットがキューから正常に削除されました',
  BetCancelledSuccessfully: 'ベットは正常にキャンセルされました',
  PlayerEscapedSuccessfully: 'プレイヤーは脱出に成功しました',
  CopiedSuccessfull: 'コピー成功',
  NotEnoughBalance: '残高が足りない',
  AutoBetStarted: 'オートベット開始 ',
  AutoBetFinished: 'オートベット終了 ',
  IdCopied: 'IDがコピーされました',
  usersConsent: 'このサイトにアクセスすることにより、私は 18 歳であることを確認し、以下の内容を確認し、理解し、同意したことになります。',
  termsOfService: '利用規約',
  email: 'Eメール',
  playNow: '今すぐプレイ',
  orContinueWith: 'または続行してください',
  metamaskExtensionNotAvailable: 'metaMask 拡張機能をインストールし、ページを更新してください。',
  cashier: 'レジ',
  account: 'アカウント',
  hello: 'こんにちは',
  balance: 'バランス',
  referrals: '紹介',
  settings: '設定',
  withdrawals: '出金',
  demoWallet: 'デモウォレット',
  myAccount: '私のアカウント',
  wallet: '財布',
  user: 'ユーザー',
  USD: '米ドル',
  amountIsRequired: '金額は必須です',
  minDepositAmount: '入金額は以上である必要があります {{min}}。',
  maxDepositAmount: '入金額は以下でなければなりません {{max}}。',
  minWithdrawAmount: '出金金額は以下より大きくなければなりません {{min}}',
  maxWithdrawAmount: '引き出し金額はより少なくなければなりません {{max}}',
  withdrawMustLessThanWallet: '出金金額はウォレットの金額よりも少ない必要があります',
  accountTypeIsRequired: 'アカウントの種類は必須です。',
  mobileNumberLength: '携帯電話番号は次のものである必要があります {{length}} 数字',
  phoneIsRequired: '電話番号は必須です',
  cpfError: 'CPF は次のとおりである必要があります {{length}} キャラクター',
  cpfRequiredError: 'CPFは必須です',
  cnpjError: 'CNPJ は {{length}} キャラクター',
  mainMenu: 'メインメニュー',
  casino: 'カジノ',
  sports: 'スポーツ',
  crypto: '暗号',
  NFT: 'NFT',
  poker: 'ポーカー',
  earnRewards: '報酬を獲得する',
  rankUp: 'ランクアップ',
  inviteFriends: '友達を招待',
  partnerProgram: 'パートナープログラム',
  home: '家',
  sportsbook: 'スポーツブック',
  menu: 'メニュー',
  liveBets: 'ライブベット',
  allBets: 'すべての賭け',
  highRollers: 'ハイローラー',
  luckyBets: 'ラッキーベット',
  trade: '貿易',
  game: 'ゲーム',
  player: 'プレーヤー',
  time: '時間',
  wager: '賭け金',
  multiplierBan: '乗数',
  payoutText: '支払い',
  bannerHeadOne: '最高のパフォーマンスで真のイノベーションを体験してください',
  bannerHeadTwo: '業界内の報奨プログラム。',
  registerNow: '今すぐ登録',
  liveWins: 'ライブでの勝利',
  monthly: '月次',
  weekly: '週次',
  daily: '毎日',
  winner1: '優勝者1',
  winner2: '優勝者2',
  winner3: '優勝者3',
  spin: 'スピン',
  spinsWon: '獲得したスピン',
  joinedOn: '参加日時',
  wageringAmount: '賭け金額',
  name: '名前',
  allGames: 'すべてのゲーム',
  allSports: 'すべてのスポーツ',
  cryptoFutures: '仮想通貨先物',
  tradeNow: '今すぐ取引する',
  allTables: 'すべてのテーブル',
  nft: 'NFT',
  viewAllGames: 'すべてのゲームを見る',
  viewAll: 'すべてを見る',
  rewards: '報酬',
  profile: 'プロフィール',
  totalBets: '合計ベット数',
  totalWagered: '賭け金総額',
  joinDate: '参加日',
  displaySocialLinks: 'リンクされたソーシャルアカウントを表示する',
  privateProfile: 'プライベートプロフィール',
  rank: 'ランク',
  winner: '勝者',
  nextRank: '次のランク',
  changeUsername: 'ユーザー名の変更',
  newPassword: '新しいパスワード',
  changeName: '名前を変更する',
  linkSocialAccount: 'ソーシャルアカウントをリンクする',
  privateProfileTooltip: 'プロフィールを非公開にすると、他のユーザーには賭けやレースなどと一緒にあなたのユーザー名が表示されなくなります。',
  socialLinksTooltip: 'コンテンツは利用できません',
  rankTooltip: 'コンテンツは利用できません',
  balances: 'バランス',
  createReferralBalance: '紹介残高の作成',
  total: '合計',
  switchBalance: 'スイッチバランス',
  mainAccount: 'メインアカウント',
  tips: 'チップ',
  receivedAmount: '受取金​​額',
  dateOrTime: '日付時刻',
  view: 'ビュー',
  allChains: 'すべてのチェーン',
  creditAmount: 'クレジット金額',
  setCustomReferralCode: 'カスタム紹介コードを設定する',
  changeEmail: 'メールアドレスを変更',
  newEmail: '新しいメール',
  createPassword: 'パスワードの作成',
  newUsername: '新しいユーザーネーム',
  username: 'ユーザー名',
  changePassword: 'パスワードを変更する',
  updateProfileMsg: 'プロファイルが正常に更新されました',
  twoFactorAuthentication: '二要素認証',
  twoFactorAuthenticationTitle: 'パスワードと電話の両方でアカウントを保護するため、2 要素認証の使用を強くお勧めします。',
  twoFactorAuthenticationSubTitle: '2FA を有効にするには、電子メールとパスワードを設定する必要があります。 ',
  enable2fa: '2FA を有効にする',
  verifyIdentityByKyc: '本人確認（KYC）',
  oldPassword: '以前のパスワード',
  live: 'ライブ',
  soccer: 'サッカー',
  tennis: 'テニス',
  fifa: 'FIFA',
  basketball: 'バスケットボール',
  iceHockey: 'アイスホーキー',
  volleyball: 'バレーボール',
  tableTennis: '卓球',
  baseball: '野球',
  rubgy: 'ラグビー',
  golf: 'ゴルフ',
  boxing: 'ボクシング',
  myBets: '私の賭け',
  favourites: 'お気に入り',
  americanFootball: 'アメリカンフットボール',
  buyCrypto: '仮想通貨を購入する',
  oldEmail: '古いメール',
  loggedInSuccess: '正常にログインしました',
  loggedOutSuccess: '正常にログアウトされました',
  wagerLimit: '賭け金制限が正常に設定されました',
  lossLimit: '損失制限が正常に設定されました',
  accountDisable: 'アカウントが正常に無効になりました',
  sessionTimeout: 'セッションタイムアウトが正常に設定されました',
  signup: 'サインアップに成功しました',
  depositLimit: '入金制限が正常に設定されました',
  tokenVerify: 'トークンがあなたのメールアドレスに送信されました。確認してください',
  emailVerify: 'メールは認証されました',
  emailChangedSuccess: 'メールアドレスは正常に変更されました',
  profileUpdatedSuccess: 'プロフィール更新',
  otpVerified: 'OTP 検証済み',
  twoFADeactivated: '2FA認証が無効化されました',
  internalServerError: '内部サーバーエラー',
  unAuthorized: '無許可',
  dataNotFound: 'データが見つかりません',
  setReferralCode: '紹介コードを設定...',
  ultimate: '究極の',
  notFound: '見つかりません',
  casinoTransactions: 'カジノ取引',
  sportsbookTransactions: 'スポーツブック取引',
  status: '状態',
  success: '成功',
  failed: '失敗した',
  pending: '保留中',
  date: '日付',
  to: 'に',
  gameName: 'ゲーム名',
  transactionId: 'トランザクションID',
  roundId: 'ラウンドID',
  bet: 'ベット',
  win: '勝つ',
  transactionType: '取引タイプ',
  at: 'で',
  downloadAll: 'すべてダウンロード',
  gameText: 'ゲーム',
  betId: 'ベットID',
  gameId: 'ゲームID',
  actionType: 'アクションの種類',
  searchGames: 'ゲームを検索',
  provider: 'プロバイダー',
  search: '検索',
  sortBy: '並べ替え',
  popular: '人気のある',
  challengePool: 'チャレンジプール',
  loadMore: 'もっと読み込む',
  bitcoin: 'ビットコイン',
  chat: 'チャット',
  trades: '取引',
  battles: '戦闘',
  loginToChat: 'チャットにログインする',
  rules: 'ルール',
  chatRule1: '他のユーザーに対する嫌がらせや侮辱をしないでください',
  chatRule2: '物乞いをしたり、ローンや雑学やヒントを求めたりしないでください',
  chatRule5: 'いかなる形態の取引、売買サービスも宣伝しないでください',
  chatRule6: '紹介コードを共有したり宣伝したりしないでください',
  chatRule7: 'スタッフになることを求めないでください',
  chatRule8: '英語だけ',
  chatRule9: 'MOD、管理者、その他のユーザーを尊重する',
  send: '送信',
  expand: '拡大する',
  signIn: 'サインイン',
  cross: 'クロス',
  collapse: '崩壊',
  emailPlaceholder: 'youremail@domain.com',
  removeFromFavourite: 'お気に入りから削除',
  addToFavourite: 'お気に入りに追加',
  footerAboutSite: '科学について少し話しましょうか？ Cash Machine Casinoのウェブサイトは、私たちがみな同じ原子で構成されているため、あなたの一部です。それとの相互作用がこの接続を確認します。',
  footerRightsReserved: 'Сash Machine Casinoのすべての権利を保有しています。',
  signupFirstName: 'ファーストネーム',
  signupAddress: '住所',
  signupCity: '市',
  signupPostcode: '郵便番号 ',
  signupCounty: '国',
  signupCurrency: '通貨',
  signupGender: '性別',
  signupMan: '男',
  signupWomen: '女性',
  signupOther: '他の',
  signupLoginDetails: 'ログイン詳細',
  signupPersonalDetails: '個人情報',
  signupConfirm: '確認する',
  signupPrivacyPolicy: 'プライバシーポリシーを読んで同意しました',
  signupTermAndConditions: '私は私の個人データの処理に同意します。',
  signupNewsLetter: 'ボーナス、プロモーション、ニュース、またはその他の関連情報をニュースレターで定期的に受け取りたいと考えています。',
  signupSms: 'ボーナス、プロモーション、ニュース、またはその他の関連情報を SMS で定期的に受け取りたいと考えています。',
  initialHeaderContent: '驚異の 100% を達成',
  secoundaryHeaderContent: '最大 $100 のウェルカムボーナス',
  playNowButton: '今すぐプレイ',
  registerButtonContent: '登録する',
  results: '結果',
  refresh: 'リフレッシュ',
  cancel: 'キャンセル',
  select: '選択する',
  current: '現在',
  set: 'セット',
  remove: '取り除く',
  save: '保存',
  not: 'ない',
  before: '前に',
  after: '後',
  action: 'アクション',
  withdrawal: '出金',
  requested: 'リクエスト済み',
  withdraw: '撤回する',
  areYouSure: '本気ですか',
  yes: 'はい',
  no: 'いいえ',
  cancelled: 'キャンセル',
  approved: '承認された',
  refunded: '返金されました',
  chargeback: 'チャージバック',
  processedOn: '処理日',
  transaction: '取引',
  dateTime: '日付時刻',
  more: 'もっと',
  fromDate: '開始日',
  toDate: '現在まで',
  percentage: 'パーセンテージ',
  info: '情報',
  min: '分',
  max: 'マックス',
  change: '変化',
  continueToSite: 'サイトに進む',
  choose: '選ぶ',
  enterWithdrawalAmount: '出金額を選択または入力します',
  capDeposit: 'デポジット',
  capWithdrawal: '出金',
  capAmount: '額',
  minimum: '最小',
  required: '必須',
  of: 'の',
  isRequired: 'が必要です',
  isAllowed: '許可されています',
  method: '方法',
  capWithdraw: '撤回する',
  showLess: '表示を減らす',
  clearFilter: 'クリアフィルター',
  quantity: '量',
  reset: 'リセット',
  incorrect: '正しくない',
  load: '負荷',
  applied: '適用済み',
  ok: 'わかりました',
  list: 'リスト',
  all: '全て',
  favorite: 'お気に入り',
  endPointNotFound: 'ネットワーク接続に問題があります',
  category: 'カテゴリー',
  emailUserNameRequired: 'メールアドレス/ユーザー名が必要です',
  emailUserNamePlaceHolder: 'メールアドレスまたはユーザー名を入力してください',
  passwordRequired: 'パスワードが必要',
  accountFrozen: 'あなたのアカウントは次の期限まで凍結されます',
  resendEmail: 'メールを再送',
  resendLink: 'リンクを再送信',
  userLoggedOut: '正常にログアウトしました',
  emailAlreadyRegistered: 'このEメールはすでに登録されています',
  userNameAlreadyTaken: 'このユーザー名は既に使われています',
  fillAllFields: '次のステップに進む前に、すべてのフィールドに入力してください',
  pleaseAccept: '承認してください',
  acceptPrivacyPolicy: 'プライバシーポリシー',
  acceptTerms: '利用規約',
  modeOfComm: ' そしてコミュニケーションの方法',
  beforeNextStep: '次のステップに進む前に',
  userNamePlaceholder: 'ユーザーネームを入力してください',
  min8Characters: '8文字以上',
  min1Number: '最小 1 の数値',
  min1LowerCase: '最小 1 文字の小文字',
  min1UpperCase: '最小 1 文字の大文字',
  min1SpecialChar: '特殊文字は 1 文字以上',
  passwordTip: 'パスワードは、少なくとも 1 つの大文字、1 つの小文字、1 つの数字、および 1 つの特殊文字を含む 8 文字以上である必要があります',
  passwordPlaceholder: 'パスワードを入力する',
  confirmPasswordPlaceholder: '確認用パスワードを入力してください',
  acceptAll: 'すべてを受け入れる',
  firstNamePlaceholder: '名を入力してください',
  lastNamePlaceholder: '姓を入力してください',
  addressPlaceholder: '住所を入力してください',
  cityPlaceholder: '都市を入力してください',
  postCodePlaceholder: '郵便番号を入力してください',
  phonePlaceholder: '電話番号を入力してください',
  invalidEmail: '無効な電子メール',
  emailRequired: 'メールアドレスが必要です',
  confirmPasswordRequired: 'パスワードの確認が必要です',
  firstNameRequired: '名は必須です',
  lastNameRequired: '姓は必須です',
  phoneRequired: '電話番号が必要です',
  dateOfBirthRequired: '生年月日は必須です',
  genderRequired: '性別必須',
  userNameRequired: 'ユーザー名は必須です',
  addressRequired: '住所が必要です',
  cityRequired: '市区町村は必須です',
  postCodeRequired: '郵便番号は必須です',
  currencyCodeRequired: '通貨コードが必要です',
  countryRequired: '国は必須です',
  matchPassword: 'パスワードが一致している必要があります',
  fName3Chars: '名は少なくとも 3 文字である必要があります',
  onlyAlphabet: 'アルファベットのみ使用可能',
  lName3Chars: '姓は少なくとも 3 文字である必要があります',
  mustBe18: '登録するには18歳以上である必要があります',
  mustBeUnder200: '登録するには200歳未満である必要があります',
  validDOB: '正しい誕生日を入力してください',
  max20Characters: '最大 20 文字を使用できます',
  min3Characters: '最低 3 文字が必要です',
  max100Characters: '最大 100 文字を使用できます',
  max50Characters: '最大 50 文字を使用できます',
  invalidNumber: '無効な番号',
  preferredLanguageRequired: '希望言語は必須です',
  invalidPass: '無効なパスワード',
  prevBtn: '前の',
  dayRequired: '曜日は必須です',
  monthRequired: '月は必須です',
  yearRequired: '年は必須です',
  uploadImage: '画像をアップロードする',
  removeImage: '画像の削除',
  preferredLanguage: '優先言語',
  wantToChangePass: 'パスワードを変更しますか?',
  selectCountryCode: '国コードを選択してください',
  selectPreferredLanguage: '優先言語を選択してください',
  fName50Chars: '名は最大 50 文字である必要があります',
  lName50Chars: '姓は最大50文字である必要があります',
  invalidDate: '無効な日付',
  DOBEarlyThanToday: '生年月日は今日より前の日付である必要があります',
  enterValidPhone: '有効な電話番号を入力してください',
  accountStatus: 'アカウントのステータス',
  kycVerified: 'あなたの KYC は正常に検証されました。',
  denied: '拒否されました',
  chooseFile: 'ファイルを選ぶ',
  upload: 'アップロード',
  countryCantChange: 'KYC 検証を開始しようとしています。',
  proceed: '進む',
  update: ' ',
  history: '歴史',
  type: 'タイプ',
  amount: '額',
  forfeited: '没収された',
  expired: '期限切れ',
  completed: '完了しました',
  zeroedOut: 'ゼロアウト',
  active: 'アクティブ',
  lapsed: '失効しました',
  yourActiveBonus: 'アクティブなボーナス',
  currentActiveBonus: '現在有効なボーナス',
  bonusStatus: '状態',
  bonusWager: '賭け金',
  bonusActivate: '活性化',
  bonusForfeit: 'ボーナスの没収',
  notUsableBonus: '「はい」の場合、このボーナスを再度使用することはできません。',
  bonusLapsed: 'キャッシュバックを受け取るのに十分な損失がありませんでした。',
  inProcess: '処理中',
  claiming: '主張する',
  loyaltyPopover: 'これらのロイヤルティ ポイントはキャッシュバックの獲得に使用できます。',
  loyaltyPage: 'ロイヤルティページ',
  loyaltyPerCurrency: '通貨ごとのロイヤルティ',
  maxLevelReached: '最大レベルに到達しました',
  pointsToReach: '到達すべきポイント ',
  loyaltyBannerHeading: 'あなたは私たちの真新しい忠誠心に値します',
  loyaltyBannerDesc: '100% ウェルカムボーナス最大 $200',
  loyaltyHeadingOne: 'CashMachine では常に報酬が得られます',
  loyaltyHeadingOneDesc: 'CashMachine では、全く新しいレベルの興奮に忠誠を尽くし、あらゆる紆余曲折で報酬を提供します。',
  loyaltySubHeadOne: 'ロイヤルティポイントの獲得',
  loyaltySubHeadOneDesc: 'カジノでの現金ベット 10 ユーロごとに、1 ロイヤルティ ポイントを獲得できます。',
  loyaltySubHeadTwo: '忠誠のはしごを登る',
  loyaltySubHeadTwoDesc: 'より多くのポイントを蓄積するほど、忠誠度レベルが上がり、途中でさらに素晴らしい報酬のロックが解除されます。',
  loyaltySubHeadThree: 'レベルアップして報酬を獲得 ',
  loyaltySubHeadThreeDesc: '魅力的な報酬レベルを段階的に進めてゲーム体験を向上させましょう。各レベルが上がると、勝利のチャンスを最大限に高めるための爽快なフリースピンのロックが解除されます。',
  loyaltyTestimonialHeadOne: 'CashMachine CASINO では次のことを保証します。',
  loyaltyTestimonialHeadTwo: 'すべてのスピンが重要',
  loyaltyTestimonialDesc: 'スロットでスピンするたびに、ただ楽しむだけでなく、非常に素晴らしい報酬に近づくこともできます。',
  loyaltyPoints: 'ポイント',
  headerLevel: 'レベル',
  loyaltyCashback: 'キャッシュバック',
  loyaltyBannerBtn: '進行状況を確認する',
  loyaltyHeadingTwo: '究極のゲーム体験をアンロック: CashMachine VIP プログラム',
  loyaltyHeadingTwoDesc: '当社のロイヤルティ プログラムでレベル 6 に到達すると、権威ある CashMachine VIP プログラムのメンバーとして独占的な特権の世界がアンロックされます。',
  loyaltySubHeadFour: 'より高い入出金限度額',
  loyaltySubHeadFourDesc: 'VIP メンバーとして、入出金制限の増加による柔軟性をお楽しみください。',
  loyaltySubHeadFive: '特別なボーナスとプロモーション',
  loyaltySubHeadFiveDesc: 'VIP プレーヤー向けに特別に用意された特別なボーナスやプロモーションにアクセスできます',
  loyaltySubHeadSix: 'パーソナルアカウントマネージャー',
  loyaltySubHeadSixDesc: 'アカウント マネージャーから専用のサポートと個別の支援を受けてください。',
  loyaltySubHeadSeven: '出金時間の短縮',
  loyaltySubHeadSevenDesc: 'VIP の場合は、出金時間が短縮され、賞金にさらに早くアクセスできるようになります。',
  loyaltySubHeadEight: '毎月のキャッシュバック特典',
  loyaltySubHeadEightDesc: 'VIP メンバー限定の毎月の豊富なキャッシュバック特典でバンクロールを増やしましょう。',
  loyaltySubHeadNine: '限定イベントやトーナメントへの招待',
  loyaltySubHeadNineDesc: '特別なイベントやトーナメントへの切望された招待状を手に入れて、仲間の VIP と交流し、素晴らしい賞品を目指して競い合いましょう。',
  loyaltySubHeadTen: '誕生日ボーナス',
  loyaltySubHeadTenDesc: 'あなただけの楽しい誕生日ボーナスで特別な日をスタイリッシュに祝いましょう!',
  loyaltySubHeadEleven: '豪華なギフト',
  loyaltySubHeadElevenDesc: '最も大切なプレイヤーへのご褒美として厳選された豪華なギフトの絶妙なセレクションをお楽しみください。',
  loyaltyTableHeading: 'レベルと報酬の概要',
  loyaltyTableHeaderOne: '忠誠度レベル',
  loyaltyTableHeaderTwo: 'ロイヤルティポイント',
  loyaltyTableHeaderThree: '毎日のキャッシュバック',
  promReadMore: '続きを読む',
  currentPortalBlock: 'このポータルに対してのみアカウントをブロックしたい場合。',
  allPortalBlock: 'すべてのポータルからアカウントをブロックします。',
  limit24Reset: '賭け金、損失、入金の制限を設定すると、24 時間後に編集および削除できるようになりますが、制限をすぐに下げることもできます。',
  enterAmount: '金額を入力します',
  limit: '限界',
  loss: '損失',
  takeABreak: '休憩する',
  session: 'セッション',
  selfExclusion: '自己排除',
  used: '使用済み',
  edit: '編集',
  updatedAt: '更新日',
  cannotBeRemoved: '以前は削除できませんでした',
  timePeriod: '期間',
  custom: 'カスタム',
  hours: '時間',
  days: '日々',
  timePeriodPlaceholder: '日数',
  months: '月',
  permanent: '永続',
  onlyNumbers: '数字のみを使用できます',
  notNumbers: '数字は使用できません',
  userNameAllowed: 'アルファベットで始める必要があります。',
  timePeriodRequired: '必要な期間',
  cannotBeIncreased: '以前は増やすことはできません',
  amountGTZero: '金額は0より大きくなければなりません',
  amountEqualOrLess: '金額は毎週および毎月以下である必要があります',
  amountInBetween: '金額は日次と月次の間（両端を含む）である必要があります',
  amountEqualOIrGreater: '金額は日次および月次以上である必要があります',
  limitRemoved: 'フォロー制限が解除されます。',
  limitSet24Hrs: 'アカウントに次の制限を設定しようとしています。',
  takeABreakInfo: 'あらかじめ設定された期間、アカウントへのアクセスをブロックしようとしています。',
  sessionLimitInfo: 'アカウントに次の制限を設定しようとしています。',
  limitCantSetBefore: 'セッション制限を事前に設定することはできません',
  selfExclusionInfo: 'あらかじめ設定された期間、アカウントへのアクセスをブロックしようとしています。',
  quickSearch: 'クイック検索',
  cash: '現金',
  nonCash: '非現金',
  userDetail: 'ユーザーの詳細',
  gameIdentifier: 'ゲーム識別子',
  rollback: 'ロールバック',
  rollbackBeforeBetWin: 'ベット勝利前のロールバック',
  freeSpins: 'フリースピン',
  betInternal: '内部ベット',
  winInternal: '内部勝利',
  addMoney: 'お金を追加する',
  removeMoney: 'お金を削除する',
  addMoneyInternal: '社内でお金を追加する',
  removeMoneyInternal: '内部マネーの削除',
  depositInternal: '内部預金',
  withdrawInternal: '内部引き出し',
  promotionTitle: 'プロモーションタイトル',
  cancelWithdrawRequest: 'このリクエストをキャンセルしたい',
  rowsPerPage: 'ページあたりの行数',
  availedBonus: 'すでに有効なボーナスを獲得しています。',
  capForfeit: '没収',
  itFirst: 'それを最初に。',
  selectYourBonus: 'ボーナスを選択してください',
  skip: 'スキップ',
  with: 'と',
  without: 'それなし',
  selectPaymentMethod: 'お支払い方法を選択してください',
  volatility: 'ボラティリティ',
  paylines: 'ペイライン',
  theme: 'テーマ',
  tryForFree: '無料でお試しください',
  resultsFound: '見つかった結果',
  games: 'ゲーム',
  in: 'で',
  low: '低い',
  medium: '中くらい',
  high: '高い',
  'medium-high': '中高',
  'very-high': 'すごく高い',
  'low-medium': '低中度',
  fantasy: 'ファンタジー',
  ancient_civilizations: '古代文明',
  fruits: '果物',
  africa: 'アフリカ',
  military: '軍隊',
  joker: 'ジョーカー',
  asia: 'アジア',
  luxurylife: '贅沢な生活',
  underwater_world: '水中の世界',
  book_off: 'ブックオフ',
  western: '西洋',
  retro: 'レトロ',
  egypt: 'エジプト',
  party: 'パーティー',
  st_patrick_day: '聖パトリックの日',
  space: '空間',
  easter: 'イースター',
  girls: '女の子',
  branded: 'ブランド',
  x_mas_and_new_year: 'クリスマスと新年',
  horrors: 'ホラー',
  other: '他の',
  loginFirst: 'ゲームをお気に入りに追加するには、まずログインしてください',
  loginFirstPlay: 'このゲームをプレイするにはまずログインしてください',
  sport: 'スポーツ',
  pirates: '海賊',
  sweets: 'お菓子',
  luxury_life: '贅沢な生活',
  st_valentines_day: '聖バレンタインデー',
  halloween: 'ハロウィン',
  food: '食べ物',
  lobby: 'ロビー',
  favourite: 'お気に入り',
  playNGO: 'プレイ＆ゴー',
  loyaltySystem: 'ロイヤルティシステム',
  progressionSystem: '進行システム',
  startPoint: '出発地点',
  endPoint: '終点',
  loyaltyBonus: 'ロイヤルティボーナス',
  pageRemoved: 'このページは存在しないか、削除されました',
  suggestToBackHome: '家に戻ることをお勧めします',
  backToHome: '家に帰る',
  goHome: '家に帰れ',
  oopps: 'おっと',
  somethingNotWorking: '申し訳ありませんが、ここで問題が発生しています。',
  tournament: 'トーナメント',
  none: 'なし',
  validOnDays: '有効日',
  validTill: 'まで有効',
  yourRequest: 'あなたの要求',
  withdrawalRequestPending: '保留状態の出金リクエストが 1 件あります。',
  realBalance: '実質残高',
  bonusInCasino: 'カジノのボーナス',
  bonusSelected: 'ボーナスが選択されました',
  payWith: '支払い方法',
  reached: '到達しました',
  receiptOfYour: 'あなたの領収書',
  capEmail: 'Eメール',
  capCreditCard: 'クレジットカード番号',
  capExpiry: '有効期限',
  capCvv: 'CVV',
  expiryIncorrectFormat: 'EXPIRY の形式が正しくありません',
  min13Numbers: '最低 13 個の数字が必要です',
  max19Numbers: '最大 19 個の番号を使用可能',
  min3Numbers: '少なくとも 3 つの数字が必要です',
  max4Numbers: '最大 4 つの数字が許可されます',
  Other: '他の',
  forfeit: '没収',
  withdrawRequestCreated: '出金リクエストが正常に作成されました',
  noBetHistory: 'ベット履歴が見つかりません',
  noPromotions: 'プロモーションが見つかりませんでした',
  noBonuses: 'ボーナスが見つかりませんでした',
  noHistory: '履歴が見つかりません',
  noWithdrawalHistory: '出金履歴が見つかりません',
  noCMS: 'CMS が見つかりません',
  noProvidersFound: 'プロバイダーが見つかりませんでした',
  noDataFound: '何もデータが見つかりませんでした',
  countryBlocked: 'ブロックされている国',
  forbidden: '禁断',
  notAcceptingVisitors: '誠に申し訳ございませんが、shinywilds.com はあなたの国ではご利用いただけません。',
  contactUsAt: 'これが誤って表示されたと思われる場合は、電子メールでサポートにご連絡ください。',
  siteUnderMaintenance: 'メンテナンス中のサイト',
  weWillBeRightBack: 'すぐに戻ります！',
  serviceUnavailable: '当社のサービスは一時的にご利用いただけません。',
  tryAgainLater: '後でもう一度試してください。',
  checkYourEmail: 'あなたのメールをチェック',
  sentAVerification: '確認リンクを送信しました',
  toYourEmail: 'あなたのメールに。',
  backToLogin: 'ログインに戻る',
  goToLogin: 'ログインに移動',
  accountVerified: 'アカウントが認証されました',
  yourAccountVerified: 'あなたのアカウントは認証されました',
  successfully: '成功しました',
  verificationFailed: '検証に失敗しました',
  emailFailedContact: '検証に失敗しました。これが頻繁に発生する場合は、お問い合わせください。 ',
  setNewPass: '新しいパスワードを設定する',
  passResetLink: 'パスワードリセットリンクを送信しました',
  toYourAccount: 'あなたのアカウントへ',
  sendPasswordResetLink: 'パスワードのリセットのリクエスト',
  gamePlayWagerComplete: 'おめでとうございます。賭けが正常に完了し、獲得したボーナスが許可された最大勝利額を超えています。',
  real: '本物',
  demo: 'デモ',
  money: 'お金',
  formatRquired: '.png 、 .jpg 、 .jpeg 形式が必要です',
  chooseImage: '画像を選択してください',
  imageSize3MB: 'サイズが 3 MB 未満の画像を入力してください',
  noProfileImage: '削除できるプロフィール画像がありません',
  limitSetSuccess: '制限が正常に設定されました',
  accountDisabled: 'アカウントが正常に無効になりました',
  loginSuccess: 'ログイン成功',
  signupSuccess: 'サインアップが成功しました',
  linkExpired: 'リンクの有効期限が切れています',
  linkSent: 'リンクを送信しました',
  accAlreadyVerified: 'アカウントはすでに認証されています。',
  profileImage: 'プロフィール画像',
  profileImageRemoved: 'プロフィール画像が正常に削除されました',
  docs: '書類',
  updatedSuccess: '更新成功',
  MATCH: 'マッチ',
  BALANCE: 'バランス',
  FREESPINS: 'フリースピン',
  MATCH_1: 'マッチ',
  DEPOSIT: 'デポジット',
  WAGERING: '賭け',
  PROMOTION: 'プロモーション',
  CREDITCARD: 'クレジットカード',
  WALLET: '財布',
  CRYPTO: '暗号',
  OTHER: '他の',
  INSTANTBANKING: 'インスタントバンキング',
  cookieTitle: 'クッキー！',
  declineButton: '衰退',
  acceptButton: '受け入れる',
  cookieDeclinedMessage: '最高のエクスペリエンスを実現するには、この Web サイトの使用を続けるために Cookie を受け入れる必要があります。',
  cookieDescription: 'Shinywilds.com へようこそ!',
  forfeitMessageFirst: '現在、次の有効なボーナスがあります:',
  forfeitMessageSecond: '新しいボーナスを請求する前に、このボーナスを放棄する必要があります。',
  forfeitButton: '没収',
  pleaseVerifyFirstText: '出金をリクエストできるようにするには、まずメールアドレスを確認する必要があります。',
  clickHere: 'ここをクリック',
  pleaseVerifyLastText: '確認メールを再送信して続行できます。',
  pleseWait: 'お待ちください',
  toResend: 'リンクを再送信するには',
  notVerified: 'お金を引き出すにはメールアドレスを認証する必要があります',
  verification: '検証',
  UPLOAD_IMG_MSG: 'ここにファイルをドラッグ アンド ドロップするか、クリックしてファイルを選択します',
  affiliate: 'アフィリエイト',
  affiliateTitle: 'アフィリエイトプログラム',
  affiliateDescription: ' 当社のブランドを紹介し、Cash Machine アフィリエイト プログラムに参加してお金を稼ぎましょう!',
  refferalTitle: '紹介プログラム',
  refferalDescription: 'この紹介リンクを他の人に共有し、ユーザーが指定したリンクを使用して登録するとボーナスを獲得できます',
  affiliateButtonText: 'アフィリエイトになる',
  affiliateLink: 'アフィリエイトリンク',
  affiliateStatus: 'アフィリエイトリクエストは正常に送信されました',
  currentStatus: '現在のアフィリエイトステータスは {{status}}',
  referralLink: '紹介リンク',
  copyTitleMessage: 'このリンクをコピーしてください',
  copiedMessage: 'リンクがコピーされました',
  searchedGame: '検索されたゲーム',
  favoriteGame: '好きなゲーム',
  randomGames: 'ランダムゲーム',
  poolPrize: '賞金総額',
  chooseYourWelcomeBonus: 'ウェルカムボーナスを選択してください',
  useNow: '今すぐ使用',
  readTheTerms: '規約を読む',
  proceedWithoutWelcomeBonus: 'ウェルカムボーナスなしで続行',
  doYouAlreadyHaveAccount: 'すでにアカウントをお持ちですか？ ',
  registerViaSocialNetworks: 'ソーシャルネットワーク経由で登録する',
  iconfirmIm18YearsOlder: '私は18歳以上であることを確認します',
  iAgreeWithTermsAndConditions: '利用規約に同意します',
  thankYouSuccessfullyRegisteringCashMachine: 'Cash Machine に登録していただきありがとうございます。',
  verifyEmailMessage: 'サイトに正常にログインするには、電子メールに送信された確認リンクをクリックして、電子メールを認証してください。',
  gotIt: 'わかった',
  phonecodeRequired: '電話番号は必須です',
  cpNotAllow: 'コピー/ペーストは許可されていません',
  playFun: '楽しく遊ぶ',
  playReal: 'リアルで遊ぶ',
  yourRealBalance: 'あなたの実際の残高は次のとおりです。',
  chooseYourPaymentMethod: 'お支払方法を選択してください',
  balanceHistory: '残高履歴',
  liveChat: 'ライブチャット',
  mail: '郵便',
  chooseEnterYourDepositAmounts: '入金額を選択または入力します',
  noBonusDataAvailable: 'ボーナスデータはありません',
  nowTryPlayForRealBets: 'さあ、実際に賭けてみてください',
  registerAndPlayForRealBets: '登録して実際の賭けに参加する',
  tournamentDataNotAvailable: 'トーナメントデータは利用できません',
  startDate: '開始日',
  endDate: '終了日',
  merchant: '商人',
  seachHere: '検索',
  show: '見せる',
  betResults: 'ベット結果:',
  noTransactionsFoundForselectedDate: '選択した日付の取引は見つかりませんでした',
  loginError: 'ログインエラー',
  errorMessageIncorrectlyAuthorization: '電子メール / ログインまたはパスワードが正しく入力されませんでした。認証エラーです。',
  profileSaved: 'プロファイルが保存されました',
  addInfo: '情報を追加',
  iHaveReadAndAgreePaymentSystemRestrictions: '支払いシステムの制限を読み、同意します*',
  paymentOptions: '支払いオプション',
  language: '言語',
  betTime: 'ベットタイム',
  noActiveBonusFound: 'アクティブなボーナスが見つかりません',
  bonusType: 'ボーナスの種類',
  requiredWageringAmount: '必要な賭け金額',
  currentWageringAmount: '現在の賭け金額',
  validUpto: 'まで有効',
  bonusCode: 'ボーナスコード',
  wageringProgressBar: '賭けの進行状況バー',
  noGamesFound: 'ゲームが見つかりません',
  noteYouMayOptfreeSpin: '注: これらのゲームのいずれかで FREESPIN を選択できます。',
  viewOfferDetails: 'オファーの詳細を表示する',
  viewFreespinGames: 'フリースピン ゲームを見る',
  depositBonusPercentage: '入金ボーナスの割合: ',
  validity: '有効',
  activated: 'アクティブ化された',
  bonusName: 'ボーナス名',
  freeRounds: '無料ラウンド',
  wagering: '賭け事',
  ended2: '終了しました',
  searchBonusTitle: '特典タイトルから探す',
  claimed: '主張した',
  noBonusHistoryFoundselectedDates: '選択した日付のボーナス履歴が見つかりませんでした',
  noBonusDetailsFound: 'ボーナスの詳細が見つかりませんでした',
  enterCode: 'コードを入力する',
  transactionTime: 'トランザクション時間',
  credit: 'クレジット',
  debit: 'デビット',
  bonusInfoOfHeading: 'ボーナスはあなたのパフォーマンスに報いる追加の支払いです',
  onlineCasino: 'オンラインカジノ',
  isTheTop: ' トップです ',
  bonusInfoPara0: '最高のオンラインカジノゲームをお探しですか?',
  tournamentsHistory: '大会履歴',

  loginWelcomeBonusInfo: `Cash Machine は、100% のウェルカム ボーナスで新規プレイヤーを歓迎します。
  ゲーム「Pat’s Heroes」（Platipus）のデポジット + 100 フリースピン。
  最低入金額は EUR 20 / ARS 900 / AUD 30 / CZK 500 です
  / DKK 150 / NOK 200 / NZD 30 / PLN 80 / USD 20 / ZAR 300 / GEL 70
  / KZT 9,000 / TJS 200 / UZS 200,000 / TRY 130`,
  loginDepositBonusInfo: `ボーナスの最大額: 入金額の 100% (ただしそれ以上)
  100ユーロ/ARS 4000/AUD 150/CZK 2500/DKK 750/NOK 1000より
  / NZD 150 / PLN 400 / USD 100 / ZAR 1500 / GEL 350 / KZT 45,000 /
  TJS 1000 / UZS 1 000 000 / TRY 600`,
  loginFreespinBonusInfo: `フリースピンはボーナス有効化から5日以内に発行されます。
  毎日20回のフリースピン。 フリースピンで獲得した資金はすべて、
  ボーナスアカウントがあり、プレイ/賭けに利用できます。
  ボーナス。 X40を賭けた後に出金可能。 ボーナス賭け時間
  7日です`,
  loginMaximumBetLimitInfo: `賭け時の最大ベット制限: EUR 1 / ARS 45 / AUD 1.5 / CZK
  25 / DKK 7 / NOK 10 / NZD 1.5 / PLN 5 / USD 1 / ZAR 15 / GEL 3.5 /
  KZT 450 / TJS 10 / UZS 10,000 / トライ6`,

  bonusInfoPara1: '、幅広い素晴らしいゲームと素晴らしい特別オファーをご用意しています。 当社のスロット ゲームにはさまざまなテーマがあり、プログレッシブ ジャックポット スロット ゲームはプレイヤーに大きな勝利を収める有利なチャンスを提供します。 また、カードがシャッフルされ∂ リアルタイムで配られるのを確認できる、エキサイティングなライブ ディーラー ゲームも提供しています。 どのゲームが自分に適しているかわからないですか? 心配しないでください。実際にプレイする前に、まずデモ モードで練習して各ゲームの雰囲気をつかむことができます。',
  bonusInfoPara2: '当社のスロット ゲームにはさまざまなテーマがあり、プログレッシブ ジャックポット スロット ゲームでは、大勝利を収める有利なチャンスが提供されます。 また、カードがシャッフルされ、配られるのをリアルタイムで確認できる、エキサイティングなライブ ディーラー ゲームも提供しています。 どのゲームが自分に適しているかわからないですか? 心配しないでください。実際にプレイする前に、まずデモ モードで練習して各ゲームの雰囲気をつかむことができます。 当社のスロット ゲームにはさまざまなテーマがあり、プログレッシブ ジャックポット スロット ゲームはプレイヤーに大きな勝利を収める有利なチャンスを提供します。 また、カードがシャッフルされ、配られるのをリアルタイムで確認できる、エキサイティングなライブ ディーラー ゲームも提供しています。 どのゲームが自分に適しているかわからないですか? 心配しないでください。実際にプレイする前に、まずデモ モードで練習して各ゲームの雰囲気をつかむことができます。',
  bonusInfoPara3: '当社のスロット ゲームにはさまざまなテーマがあり、プログレッシブ ジャックポット スロット ゲームはプレイヤーに大きな勝利を収める有利なチャンスを提供します。 また、カードがシャッフルされ、配られるのをリアルタイムで確認できる、エキサイティングなライブ ディーラー ゲームも提供しています。 どのゲームが自分に適しているかわからないですか? 心配しないでください。実際にプレイする前に、まずデモ モードで練習して各ゲームの雰囲気をつかむことができます。',
  bonusInfoPara4: '当社のスロット ゲームにはさまざまなテーマがあり、プログレッシブ ジャックポット スロット ゲームはプレイヤーに大きな勝利を収める有利なチャンスを提供します。 また、カードがシャッフルされ、配られるのをリアルタイムで確認できる、エキサイティングなライブ ディーラー ゲームも提供しています。 どのゲームが自分に適しているかわからないですか? 心配しないでください。実際にプレイする前に、まずデモ モードで練習して各ゲームの雰囲気をつかむことができます。 当社のスロット ゲームにはさまざまなテーマがあり、プログレッシブ ジャックポット スロット ゲームはプレイヤーに大きな勝利を収める有利なチャンスを提供します。 また、カードがシャッフルされ、配られるのをリアルタイムで確認できる、エキサイティングなライブ ディーラー ゲームも提供しています。 どのゲームが自分に適しているかわからないですか? 心配しないでください。実際にプレイする前に、まずデモ モードで練習して各ゲームの雰囲気をつかむことができます。',
  cashMachineFooterPara1: 'オンラインカジノの最高のゲームをお探しですか？ それなら、正しい場所に来ました。',
  cashMachineFooterPara2: `素晴らしい範囲の素晴らしい
ゲームと魅力的な特別オファーがあります。 当社のスロットゲームは幅広い
テーマで提供されており、プログレッシブジャックポットスロットゲームが
選手に大きな勝利のチャンスを提供しています。 また、
カードがリアルタイムでシャッフルされるエキサイティングなライブディーラーゲームも提供しています！
どのゲームがあなたに適しているかわからないですか？心配しないでください

実際にプレイする前に各ゲームの感触を掴むために最初にデモモードで練習できます。, tournamentDescTitle: 'ライブカジノ、スロット、ドロップス＆ウィンズカジノトーナメント', tournamentPara1: 'オンラインカジノの最高のゲームをお探しですか？ それなら、正しい場所に来ました。', tournamentPara2: 素晴らしい範囲の素晴らしい
ゲームと魅力的な特別オファーがあります。 当社のスロットゲームは幅広い
テーマで提供されており、プログレッシブジャックポットスロットゲームが
選手に大きな勝利のチャンスを提供しています。 また、
カードがリアルタイムでシャッフルされるエキサイティングなライブディーラーゲームも提供しています！
どのゲームがあなたに適しているかわからないですか？心配しないでください
実際にプレイする前に各ゲームの感触を掴むために最初にデモモードで練習できます。, tournamentPara3: 当社のスロットゲームは幅広いテーマで提供されており、プログレッシブジャックポットスロットゲームが
選手に大きな勝利のチャンスを提供しています。 また、
カードがリアルタイムでシャッフルされるエキサイティングなライブディーラーゲームも提供しています！
どのゲームがあなたに適しているかわからないですか？心配しないでください
実際にプレイする前に各ゲームの感触を掴むために最初にデモモードで練習できます。当社のスロットゲームは幅広い
テーマで提供されており、プログレッシブジャックポットスロットゲームが
選手に大きな勝利のチャンスを提供しています。 また、
カードがリアルタイムでシャッフルされるエキサイティングなライブディーラーゲームも提供しています！
どのゲームがあなたに適しているかわからないですか？心配しないでください
実際にプレイする前に各ゲームの感触を掴むために最初にデモモードで練習できます。`,
  'New Games': '新しいゲーム',
  'Popular Games': '人気のゲーム',
  'Games Of The Month': '今月のゲーム',
  Exclusive: 'エクスクルーシブ',
  'Bonus Buy': 'ボーナス購入',
  'Jackpot Games': 'ジャックポット ゲーム',
  lightMode: 'ライトモード',
  darkMode: 'ダークモード',
  searchProvider: '検索プロバイダー',
  tournamentHistoryDataNotAvailable: 'トーナメント履歴データは利用できません',
  viewMore: 'もっと見る',
  description: '説明',
  codeCopied: 'コードがコピーされました',
  tournamentGames: 'トーナメントゲーム',
  freeSpinGames: 'フリースピンゲーム',
  promoCode: 'プロモーションコード',
  paymentNote: '注意: トランザクションが処理されている間は、ウィンドウを閉じたり更新しないでください',
  liveGames: 'ライブゲーム',
  play: 'プレイする',
  demo1: 'デモ',
  registration: '登録'
}
