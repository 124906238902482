import { Box, styled } from '@mui/material'

export const AuthLoginErrorWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(0, 1.5),
    '&::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.25rem',
      background: theme.palette.darkShade.dark_03
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.warning.light
    },
    '& .title-text': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.typographyText.textWhite,
      fontSize: theme.spacing(1.625),
      textAlign: 'center'
    },
    '& .pera-text': {
      color: theme.palette.typographyText.textWhite,
      fontSize: theme.spacing(1.5),
      fontWeight: '500',
      textAlign: 'center'
    },
    '& .btn-box': {
      marginTop: theme.spacing(1.5)
    }
  }
})
