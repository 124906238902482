import { createSlice } from '@reduxjs/toolkit'
import { languagesMenuItem } from 'utils/constant'

const initialState = {
  loading: false,
  languageKeys: null,
  languageTabs: null,
  selectedLanguage: localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : languagesMenuItem[0]
}

const {
  actions: {
    getLanguageSupportKeysStart,
    getLanguageSupportKeysSuccess,
    getLanguageSupportKeysFailure,
    setSelectedLanguage
  },
  reducer
} = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    getLanguageSupportKeysStart: (state) => ({
      ...state,
      loading: true,
      languageKeys: null
    }),
    getLanguageSupportKeysSuccess: (state, { payload }) => {
      return ({
        ...state,
        loading: false,
        languageKeys: payload.languageData,
        languageTabs: payload.languageKeys
      })
    },
    getLanguageSupportKeysFailure: (state) => ({
      ...state,
      loading: false
    }),
    setSelectedLanguage: (state, action) => ({
      ...state,
      selectedLanguage: action.payload
    })
  }
})

export default reducer
export {
  getLanguageSupportKeysStart,
  getLanguageSupportKeysSuccess,
  getLanguageSupportKeysFailure,
  setSelectedLanguage
}
