import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllCurrencyService,
  getAllCmsService,
  getGameCountriesService,
  getGameLanguagesService,
  getSignupRegistrationFieldsService,
  getAllCmsSiteinfoService
} from 'network/services/gameSetting.service'
import { setAllCountries, setLanguages, setRegistrationFields, setAllCurrency, setSystemData, setCmsSiteData } from '../redux/slices/gameSetting.slice'

export const getAllCurrencyStart = createAsyncThunk(
  'user/getAllCurrency',
  async (_, thunkApi) => {
    try {
      const res = await getAllCurrencyService()
      thunkApi.dispatch(setAllCurrency(res?.currency))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getAllSiteinfo = createAsyncThunk(
  'system/get-all-cms',
  async (_, thunkApi) => {
    try {
      const res = await getAllCmsService()
      thunkApi.dispatch(setSystemData(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getAllCmsSiteData = createAsyncThunk(
  'system/get-all-cms-site',
  async ({ data, handleSuccess }, thunkApi) => {
    try {
      const res = await getAllCmsSiteinfoService(data)
      thunkApi.dispatch(setCmsSiteData(res?.cmsDetails))
      if (handleSuccess) handleSuccess(res?.cmsDetails)
      return res
    } catch (error) {
      data.history.push('/cms-page-not-found')
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getLanguagesStart = createAsyncThunk(
  'game/get-languages',
  async (data, thunkApi) => {
    try {
      const res = await getGameLanguagesService(data)
      thunkApi.dispatch(setLanguages(res?.languages))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getAllCountriesStart = createAsyncThunk(
  'game/get-all-countries',
  async (data, thunkApi) => {
    try {
      const res = await getGameCountriesService(data)
      thunkApi.dispatch(setAllCountries(res?.countries))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getRegistrationFieldsStart = createAsyncThunk(
  'game/get-signup-registration-fields',
  async (data, thunkApi) => {
    try {
      const res = await getSignupRegistrationFieldsService(data)
      thunkApi.dispatch(setRegistrationFields(res?.registrationFields))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
