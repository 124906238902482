import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { DepositWithdrawalWrapper } from './style'
import DepositWithdrawalDialog from 'components/common-ui/DepositWithdrawalDialog/DepositWithdrawalDialog'
import {
  CustomMainButton
} from 'components/common-ui/CustomButton/CustomButton'
import Payment from './Payment/index'
import { useDispatch } from 'react-redux'
import { getPaymentId, getPaymentType } from 'redux-thunk/thunk/payment.thunk'
import { useTranslation } from 'react-i18next'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Box
      className='tabpanel-wrap'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box className='tabpanel-box'>{children}</Box>}
    </Box>
  )
}

const DepositWithdrawal = ({ dialogOpen, handleClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [step, setStep] = useState(1)
  const handleNext = (type) => {
    if (type === 'bonus') setStep('bonus')
    else setStep(step + 1)
  }
  const handlePrevious = (type) => {
    if (type === 'bonus') setStep(1)
    else setStep(step - 1)
  }

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
    fetchPaymentdetails(newValue)
  }

  const fetchPaymentdetails = (val) => {
    if (['deposit', 'withdraw'].includes(dialogOpen)) {
      dispatch(getPaymentType({
        paymentType: val === 0 ? 'deposit' : 'withdraw'
      }))
      dispatch(getPaymentId({
        type: val === 0 ? 'deposit' : 'withdraw'
      }))
    }
  }

  useEffect(() => {
    setValue(dialogOpen === 'deposit' ? 0 : 1)
    fetchPaymentdetails(dialogOpen === 'deposit' ? 0 : 1)
  }, [dialogOpen])

  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [maxWidth] = React.useState('customModal_xm')

  const handlePaymentClose = () => {
    handleClose()
    setStep(1)
  }

  return (
    <>
      <DepositWithdrawalDialog
        maxWidth={maxWidth}
        // fullScreen={fullScreen}
        dialogOpen={['deposit', 'withdraw'].includes(dialogOpen)}
        handleDialogClose={handlePaymentClose}
        aria-labelledby='customized-dialog-title'
      >
        <DepositWithdrawalWrapper theme={theme}>
          {step === 1 && (
            <Box className='tabs-nav-box'>
              <Tabs
                variant='scrollable'
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
              >
                <Tab label={<CustomMainButton className={value === 0 ? 'active' : ''}>{t('deposit')}</CustomMainButton>} />{' '}
                <Tab
                  label={<CustomMainButton className={value === 1 ? 'active' : ''}>{t('withdraw')}</CustomMainButton>}
                />{' '}
              </Tabs>
            </Box>
          )}

          <Box className='tab-content-wrap'>
            {/* Deposit TabPanel Start */}
            <TabPanel value={value} index={0}>
              <Payment step={step} handleNext={handleNext} handlePrevious={handlePrevious} paymentType='deposit' />
            </TabPanel>

            {/* Withdrawal TabPanel Start */}
            <TabPanel value={value} index={1}>
              <Payment step={step} handleNext={handleNext} handlePrevious={handlePrevious} paymentType='withdrawal' />
            </TabPanel>
          </Box>

          {step === 4 && (
            <Box className='form-status-box'>
              <Typography variant='h1'>{t('profileSaved')}</Typography>
            </Box>
          )}
        </DepositWithdrawalWrapper>
      </DepositWithdrawalDialog>
    </>
  )
}

export default React.memo(DepositWithdrawal)
