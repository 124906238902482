import React from 'react'
import { useTheme } from '@emotion/react'
import { Box, Tab, Tabs } from '@mui/material'
import TransactionHistoryCash from '../Cash/TransactionHistoryCash/index'
import { useDispatch } from 'react-redux'
import { setUserModal } from 'redux-thunk/redux/slices/auth.slice'

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='tab-content'>{children}</Box>
      )}
    </div>
  )
}

const TabCash = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  dispatch(setUserModal(''))

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const OpenWithdrawOrDeposit = (value) => {
    dispatch(setUserModal(value))
    setValue(0)
  }

  return (
    <Box className='tabs-container' theme={theme}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
        aria-label='scrollable force tabs example'
      >
        <Tab label='Deposit' onClick={() => OpenWithdrawOrDeposit('deposit')} />
        <Tab label='Withdrawal' onClick={() => OpenWithdrawOrDeposit('withdraw')} />
        <Tab label='Transactions History' />
      </Tabs>
      {/* <CustomTabPanel value={value} index={0}>
        Deposit
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Withdrawal
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={2}>
        <TransactionHistoryCash />
      </CustomTabPanel>
    </Box>
  )
}

export default React.memo(TabCash)
