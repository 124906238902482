import { Box, styled } from '@mui/material'

export const RefferalModalWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    display: 'flex',
    justifyContet: 'center',
    '& .affiliate-description': {
      marginBottom: '50px',
      fontSize: '1.5rem'
    },
    '& .affiliate-status': {
      '& .success': {
        color: theme.palette.warning.main,
        fontWeight: '600',
        fontSize: '1.5rem'
      },
      '& .pending': {
        color: theme.palette.warning.main,
        fontWeight: '600',
        fontSize: '1.5rem'
      },
      '& .rejected': {
        color: theme.palette.danger.main,
        fontWeight: '600',
        fontSize: '1.5rem'
      },
      '& .approved': {
        color: theme.palette.success.main,
        fontWeight: '600',
        fontSize: '1.5rem'
      },
      '& .re_request': {
        color: theme.palette.success.main,
        fontWeight: '600',
        fontSize: '1.5rem'
      }
    }
  }
})
