import * as Yup from 'yup'

export const DEFAULT_MIN_PAYMENT_AMOUNT = 1
export const DEFAULT_MAX_PAYMENT_AMOUNT = 10000

export const withdrawSchema = (t, userWallet) => Yup.object().shape({
  amount: Yup.number().positive(t('amountGTZero'))
    .min(DEFAULT_MIN_PAYMENT_AMOUNT, t('minWithdrawAmount', { min: DEFAULT_MIN_PAYMENT_AMOUNT }))
    .max(userWallet?.amount < DEFAULT_MAX_PAYMENT_AMOUNT ? userWallet?.amount : DEFAULT_MAX_PAYMENT_AMOUNT,
      t('maxWithdrawAmount', { max: userWallet?.amount < DEFAULT_MAX_PAYMENT_AMOUNT ? userWallet?.amount : DEFAULT_MAX_PAYMENT_AMOUNT }))
    .required(t('amountIsRequired')),
  termscheck: Yup.boolean().oneOf([true], t('required'))
})

export const depositSchema = (t, userWallet) => Yup.object().shape({
  amount: Yup.number().positive(t('amountGTZero'))
    .min(DEFAULT_MIN_PAYMENT_AMOUNT, t('minDepositAmount', { min: DEFAULT_MIN_PAYMENT_AMOUNT }))
    .max(DEFAULT_MAX_PAYMENT_AMOUNT, t('maxDepositAmount', { max: DEFAULT_MAX_PAYMENT_AMOUNT }))
    .required(t('amountIsRequired')),
  termscheck: Yup.boolean().oneOf([true], t('required'))
})
