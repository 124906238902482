import { Box, styled } from '@mui/material'

export const LanguageSwitchWrapper = styled(Box)(({ theme }) => {
  return {
    '& .language-switch-box': {

    },
    '& .MuiButtonBase-root.language-btn': {
      width: '100%',
      paddingLeft: theme.spacing(4.5),
      '& .flag-box': {
        width: theme.spacing(4.25),
        height: theme.spacing(4.25),
        background: theme.palette.gradientColor.buttonImgBg,
        borderRadius: theme.spacing(3.875),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        position: 'absolute',
        top: '50%',
        left: theme.spacing(0),
        transform: 'translateY(-50%)',
        '& .img-box': {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2.875),
        '& .flag-box': {
          width: theme.spacing(2.625),
          height: theme.spacing(2.625),
          padding: theme.spacing(0.375)
        }
      }
    }
  }
})

export const LanguageModalWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    '& .language-content-box': {
      height: '85%',
      padding: theme.spacing(4, 1.5, 0),
      overflowY: 'hidden auto'
    },
    '& .modal-heading-box': {
      width: theme.spacing(23.75),
      height: theme.spacing(4),
      background: theme.palette.success.light,
      maskImage: 'url("/assets/images/stock-images/form-status-bg.png")',
      maskRepeat: 'no-repeat',
      maskSize: '100% 100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      zIndex: '1',
      position: 'absolute',
      top: theme.spacing(3.25),
      left: '50%',
      transform: 'translateX(-50%)',
      '& .MuiTypography-root': {
        fontFamily: 'Poppins, sans-serif',
        color: theme.palette.typographyText.textWhite,
        fill: theme.palette.typographyText.textWhite,
        fontSize: theme.spacing(1.5),
        fontWeight: '600',
        textTransform: 'capitalize'
      }
    },
    '& .MuiButtonBase-root.language-btn': {
      filter: `drop-shadow(0 0 5px ${theme.palette.darkShade.dark_05})`,
      paddingLeft: theme.spacing(4.5),
      '& .flag-box': {
        width: theme.spacing(4.25),
        height: theme.spacing(4.25),
        background: theme.palette.gradientColor.buttonImgBg,
        borderRadius: theme.spacing(3.875),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        position: 'absolute',
        top: '50%',
        left: theme.spacing(0),
        transform: 'translateY(-50%)',
        '& .img-box': {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center'
          }
        }
      },
      '&.active': {
        backgroundColor: theme.palette.button.transparent,
        // background: theme.palette.button.hoverGradient,
        '& .inner-box': {
          // background: theme.palette.button.hoverGradientInner,
          // borderColor: theme.palette.button.hoverBorderInner
        }
      },
      '&.selected-language': {
        pointerEvents: 'none'
      }
    },
    '& .selected-language-box': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1.75)
    },
    '& .language-options-container': {
      height: `calc(100% - ${theme.spacing(7.25)})`,
      background: theme.palette.gradientColor.languageBgBox,
      borderRadius: theme.spacing(2.125),
      padding: theme.spacing(1.5),
      '& .language-options-box': {
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(1.5),
        '&::-webkit-scrollbar': {
          width: '0.25rem',
          height: '0.25rem',
          background: theme.palette.darkShade.dark_03
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.warning.light
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .language-content-box': {
        padding: theme.spacing(8, 1, 0)
      },
      '& .MuiButtonBase-root.language-btn': {
        paddingLeft: theme.spacing(2.875),
        '& .flag-box': {
          width: theme.spacing(2.625),
          height: theme.spacing(2.625),
          padding: theme.spacing(0.375)
        }
      },
      '& .language-options-container': {
        height: `calc(100% - ${theme.spacing(4.75)})`,
        borderRadius: theme.spacing(1.125),
        padding: theme.spacing(1.5, 1),
        '& .language-options-box': {
          padding: theme.spacing(1)
        }
      },
      '& .modal-heading-box': {
        width: `${theme.spacing(20.75)} !important`,
        height: `${theme.spacing(2.5)} !important`,
        top: `${theme.spacing(4.125)} !important`,
        left: '50% !important',
        transform: 'translateX(-50%) !important',
        '& .MuiTypography-root': {
          fontSize: `${theme.spacing(1)} !important`
        }
      }
    }
  }
})
