import React from 'react'
import { useTheme } from '@emotion/react'
import { CustomTooltipWrapper } from './style'

const CustomTooltip = ({
  title,
  arrow,
  placement,
  enterDelay,
  leaveDelay,
  open,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  TransitionComponent,
  onClose,
  PopperProps,
  followCursor,
  className,
  ...otherProps
}) => {
  const theme = useTheme()

  return (
    <>
      <CustomTooltipWrapper
        theme={theme}
        title={title}
        arrow={arrow}
        placement={placement}
        enterDelay={enterDelay}
        leaveDelay={leaveDelay}
        open={open}
        disableFocusListener={disableFocusListener}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableTouchListener}
        TransitionComponent={TransitionComponent}
        onClose={onClose}
        PopperProps={PopperProps}
        followCursor={followCursor}
        className={className}
        {...otherProps}
      />
    </>
  )
}

export default React.memo(CustomTooltip)
