import styled from '@emotion/styled'
import { Button, IconButton } from '@mui/material'

// COMMON BUTTON START
export const StyledButton = styled(Button)(({ theme }) => {
  return {
    minWidth: theme.spacing(5),
    height: theme.spacing(3.875),
    backgroundColor: theme.palette.button.transparent,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.875, 1.75),
    transition: '300ms all',
    '& .MuiTouchRipple-root': {
      display: 'none',
      zIndex: '2'
    },
    '& .xs': {
      fontSize: theme.spacing(0.92)
    },
    '& .sm': {
      fontSize: theme.spacing(1.05)
    },
    '& .smd': {
      fontSize: theme.spacing(1.25)
    },
    '& .md': {
      fontSize: theme.spacing(1.625)
    },
    '& .lg': {
      fontSize: theme.spacing(2)
    },
    '& .text': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.button.contrastTextMain,
      // fontSize: theme.spacing(1.625),
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      textShadow: theme.shadows[2],
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      zIndex: '2'
    },
    '& .left-img-box, & .center-img-box, & .right-img-box': {
      height: '100%',
      position: 'absolute',
      top: theme.spacing(0),
      '& img': {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        pointerEvents: 'none',
        position: 'absolute',
        inset: theme.spacing(0),
        '&:nth-child(2)': {
          opacity: '0',
          transition: '300ms all'
        }
      }
    },
    '& .left-img-box': {
      width: theme.spacing(1.75),
      zIndex: '1',
      left: '1px'
    },
    '& .right-img-box': {
      width: theme.spacing(1.75),
      zIndex: '1',
      right: '1px'
    },
    '& .center-img-box': {
      width: `calc(100% - ${theme.spacing(3.25)})`,
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '&:hover': {
      backgroundColor: theme.palette.button.transparent,
      '& .left-img-box, & .center-img-box, & .right-img-box': {
        '& img': {
          '&:nth-child(2)': {
            opacity: '1'
          }
        }
      }
    },
    '&:active': {
      transform: 'scale(0.95)'
    },
    '&.active': {
      backgroundColor: theme.palette.button.transparent,
      '& .left-img-box, & .center-img-box, & .right-img-box': {
        '& img': {
          '&:nth-child(2)': {
            opacity: '1'
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      minWidth: theme.spacing(5),
      height: theme.spacing(2.625),
      padding: theme.spacing(0.875, 1.188),
      '& .text': {
        fontSize: theme.spacing(0.875)
      },
      '& .left-img-box': {
        width: theme.spacing(1.188)
      },
      '& .right-img-box': {
        width: theme.spacing(1.188)
      },
      '& .center-img-box': {
        width: `calc(100% - ${theme.spacing(2.376)})`
      }
    }
  }
})

// MAIN BUTTON START
export const StyledMainButton = styled(StyledButton)(({ theme }) => {
  return {
  }
})

// PRIMARY BUTTON START
export const StyledPrimaryButton = styled(StyledButton)(({ theme }) => {
  return {
  }
})

// SECONDARY BUTTON START
export const StyledSecondaryButton = styled(StyledButton)(({ theme }) => {
  return {
  }
})

// TERTIARY BUTTON START
export const StyledTertiaryButton = styled(StyledButton)(({ theme }) => {
  return {
  }
})

// QUATERNARY BUTTON START
export const StyledQuaternaryButton = styled(StyledButton)(({ theme }) => {
  return {
  }
})

// ICON BUTTON START
export const StyledIconButton = styled(IconButton)(({ theme }) => {
  return {
    width: theme.spacing(4.063),
    height: theme.spacing(3.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0),
    transition: '300ms all',
    '& .bg-img': {
      width: '100%',
      maxWidth: '100%',
      height: 'auto'
    },
    '& svg': {
      width: theme.spacing(1.875),
      height: theme.spacing(1.875),
      fill: theme.palette.button.contrastTextMain,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      filter: `drop-shadow(1px 2px 2px ${theme.palette.darkShade.dark_04})`
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.transparent
    },
    '&:active': {
      transform: 'scale(0.95)'
    }
  }
})

// ANIMATED BUTTON START
export const StyledAnimatedButton = styled(StyledButton)(({ theme }) => {
  return {
    '& .left-img-box, & .center-img-box, & .right-img-box': {
      height: '100%',
      position: 'absolute',
      top: theme.spacing(0),
      '& img': {
        opacity: '1',
        position: 'absolute',
        top: theme.spacing(0),
        left: theme.spacing(0),
        '&.visible': {
          opacity: '1',
          transition: '300ms all'
        },
        '&.hidden': {
          opacity: '0',
          transition: '1500ms all'
        }
      }
    }
  }
})
