import React from 'react'
import { useTheme } from '@emotion/react'
import { CustomMenuDropdownWrapper } from './style'

const CustomMenuDropdown = ({
  id,
  open,
  onClose,
  children,
  transformOrigin,
  anchorOrigin,
  anchorEl,
  className
}) => {
  const theme = useTheme()

  return (
    <CustomMenuDropdownWrapper
      theme={theme}
      id={id}
      anchorEl={anchorEl}
      open={open}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      className={className}
    >
      {children}
    </CustomMenuDropdownWrapper>
  )
}

export default React.memo(CustomMenuDropdown)
