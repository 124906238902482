import { TableContainer, styled } from '@mui/material'

export const CustomTableWrapper = styled(TableContainer)(({ theme }) => {
  return {
    backgroundColor: theme.palette.solidColor.transparent,
    backgroundImage: 'none',
    boxShadow: theme.shadows[0],
    '& .MuiTable-root': {
      minWidth: theme.spacing(40.625),
      borderCollapse: 'separate',
      borderSpacing: theme.spacing(0, 0.5),
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            border: '0',
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1),
            fontWeight: '700',
            lineHeight: '1.44',
            whiteSpace: 'nowrap',
            padding: theme.spacing(0.75, 1.25)
          }
        }
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            backgroundColor: theme.palette.solidColor.solidColor_01,
            border: '0',
            color: theme.palette.typographyText.textWhite,
            fontSize: theme.spacing(1),
            fontWeight: '400',
            lineHeight: '1.44',
            whiteSpace: 'nowrap',
            padding: theme.spacing(0.938, 1.25),
            '&:first-child': {
              borderRadius: theme.spacing(0.313, 0, 0, 0.313)
            },
            '&:last-child': {
              borderRadius: theme.spacing(0, 0.313, 0.313, 0)
            }
          }
        }
      }
    }
  }
})
