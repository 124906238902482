import React, { forwardRef } from 'react'
import { MyAccountDialogWrapper } from './style'
import { useTheme } from '@emotion/react'
import { DialogContent, IconButton, Slide, useMediaQuery } from '@mui/material'
import { ArrowLeftIcon, CloseBoldIcon } from 'assets/icons/index'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const MyAccountDialog = (props) => {
  const theme = useTheme()
  const modalBorder = theme.themeImages.modalBorder
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    dialogOpen,
    handleDialogClose,
    title,
    children,
    fullWidth,
    maxWidth,
    fullScreen,
    PaperComponent,
    scroll
  } = props

  return (
    <MyAccountDialogWrapper
      theme={theme}
      title={title}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      PaperComponent={PaperComponent}
      scroll={scroll}
    >
      {handleDialogClose
        ? (
          <IconButton
            className='dialog-close-btn'
            aria-label='close'
            onClick={handleDialogClose}
          >
            {isDesktop
              ? (
                <CloseBoldIcon />
                )
              : <ArrowLeftIcon />}
          </IconButton>
          )
        : null}
      <DialogContent>
        {children}
        <img src={modalBorder} className='modal-bg-border' alt='BG Img' loading='lazy' />
      </DialogContent>
    </MyAccountDialogWrapper>
  )
}

export default React.memo(MyAccountDialog)
