import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

/**
 * Service to get payment type
 */
export const getPaymentTypeService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/payment-providers', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: params
  })
}

/**
 * Service to get payment id
 */
export const getPaymentIdService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/user/init-pay', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: params
  })
}
