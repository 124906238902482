import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Card,
  CardActions,
  CardContent
} from '@mui/material'
import { GameCardLargeHoverNonWrapper } from './style'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index'
import { defaultCurrency } from 'utils/constant'
import { playGame } from 'redux-thunk/thunk/game.thunk'
import { setAuthModal } from 'redux-thunk/redux/slices/auth.slice'
import { useTranslation } from 'react-i18next'

const GameCardLargeHoverNon = ({ gameImg, gameCode }) => {
  const theme = useTheme()
  const [error, isError] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isAuthenticated } = useSelector((state) => state.gameSetting)
  const userData = useSelector((state) => state.user?.user)
  const { t } = useTranslation()

  const languageCode = useSelector((state) => state?.language?.selectedLanguage?.code.toUpperCase())

  const openGame = (gameCode, url) => {
    navigate(`/casino/play-game/${gameCode}`, { state: { gameURL: url } })
  }

  const handlePlayGame = (mode) => {
    if (isAuthenticated && !mode) {
      const data = {
        currency: userData?.currencyCode || defaultCurrency,
        gameCode: gameCode,
        funMode: mode
      }
      dispatch(playGame({ data, openGame }))
      navigate(`/casino/play-game/${gameCode}`)
    } else if (!isAuthenticated && !mode) {
      dispatch(setAuthModal('login'))
    } else {
      const data = {
        currency: userData?.currencyCode || defaultCurrency,
        gameCode: gameCode,
        funMode: mode
      }
      dispatch(playGame({ data, openGame }))
      navigate(`/casino/play-game/${gameCode}`)
    }
  }

  return (
    <GameCardLargeHoverNonWrapper theme={theme}>
      <Card>
        <CardContent>
          <Box className='game-img-box'>
            <img
              onError={() => {
                isError(true)
              }} src={error ? '/assets/images/stock-images/g10.png' : gameImg} className='game-img' alt='Game Img'
            />
          </Box>
          <img src='/assets/images/stock-images/game-img-border-large.png' className='border-img' alt='Game Img' loading='lazy' />
        </CardContent>
        <CardActions>
          <CustomMainButton fontSize={['FR', 'ES'].includes(languageCode) ? 'xs' : ''} className='play-btn' onClick={() => handlePlayGame(false)}>{t('playNow')}</CustomMainButton>
        </CardActions>
      </Card>
    </GameCardLargeHoverNonWrapper>
  )
}
export default GameCardLargeHoverNon
