import { createSlice } from '@reduxjs/toolkit'
import { getUserCasinoTransactions, getUserCustomGameTransactions, getUserDepositTransactions, getUserSportsbookTransactions, getUserWithdrawDepositTransactions, getUsersTotalBets } from 'redux-thunk/thunk/user.thunk'

const initialState = {
  withdrawTransactions: null,
  depositTransactions: null,
  usersCasinoTransactions: null,
  usersCustomGameTransactions: null,
  usersSportsbookTransactions: null,
  usersTotalBetAmounts: null
}

const {
  reducer
} = createSlice({
  name: 'transactions',
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDepositTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          depositTransactions: action.payload
        }
      })
      .addCase(getUserWithdrawDepositTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          withdrawTransactions: action.payload
        }
      })
      .addCase(getUserCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCasinoTransactions: action.payload
        }
      })
      .addCase(getUserCustomGameTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersCustomGameTransactions: action.payload
        }
      })
      .addCase(getUserSportsbookTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          usersSportsbookTransactions: action.payload
        }
      })
      .addCase(getUsersTotalBets.fulfilled, (state, action) => {
        return {
          ...state,
          usersTotalBetAmounts: action.payload
        }
      })
  }
})

export default reducer
