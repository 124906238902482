import React from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid } from '@mui/material'
import { GameGridWrapper } from './style'
import GameCard from 'pages/GameCard/GameCard'
import HeadingGameCards from 'pages/GameCard/HeadingGameCards'
import { useDispatch, useSelector } from 'react-redux'
import { getFilteredGameData, getSearchedGameData } from 'redux-thunk/thunk/game.thunk'
import { clearFilteredGames, clearSearchedGames, setGameCategoryId, setSearch, clearRandomGames } from 'redux-thunk/redux/slices/games.slice'
import InfiniteScroll from '../../../node_modules/react-infinite-scroll-component/dist/index'
import useCallbackState from 'hooks/useCallbackState'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { setSelectedProviderData, setShowFavoriteGames, setShowProviderGames, setShowRandomGames } from 'redux-thunk/redux/slices/gameSetting.slice'
import { useTranslation } from 'react-i18next'

const GameGrid = ({ gameArr, headingImg, headingName, categoryId, isFiltered, gamesCount, isSearched, favoriteGameHandler, hideViewBtn, isTagGame, handleSeeAllForTagsGames }) => {
  const { showRandomGames, selectedProvierData, showProviderGames } = useSelector((state) => state.gameSetting)
  const { [LOADER_HANDLER_TYPES.content]: contentLoading } = useSelector(state => state.loader)
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [page, setPage] = useCallbackState(1)
  const navigate = useNavigate()
  const { subCategoryId, search } = useSelector((state) => state.games)

  const handleSeeAllGames = () => {
    if (isTagGame) {
      handleSeeAllForTagsGames()
      window.scrollTo({ top: 500, behavior: 'smooth' })
      return
    }
    dispatch(setGameCategoryId(categoryId))
    const data = {
      limit: 15,
      pageNo: page,
      category: categoryId
    }
    dispatch(getFilteredGameData({ data, navigate }))
  }

  const handleBack = () => {
    dispatch(clearSearchedGames())
    dispatch(clearFilteredGames())
    dispatch(clearRandomGames())
    dispatch(setShowRandomGames(false))
    dispatch(setGameCategoryId(null))
    dispatch(setSearch(''))
    dispatch(setShowFavoriteGames(false))
    dispatch(setShowProviderGames(false))
    dispatch(setSelectedProviderData(null))
    handleSeeAllForTagsGames(null)
  }

  const fetchMore = () => {
    if (showRandomGames) {
      setPage(page + 1, () => {
        const data = {
          limit: 10,
          pageNo: page,
          isRandom: true
        }
        dispatch(getFilteredGameData({ data, navigate, hasMore: true }))
      })
    } else if (showProviderGames) {
      setPage(page + 1, () => {
        const data = {
          provider: selectedProvierData?.providerId || null
        }
        dispatch(getFilteredGameData({ data, navigate, hasMore: true }))
      })
    } else if (isSearched) {
      setPage(page + 1, () => {
        const data = {
          limit: 20,
          pageNo: page,
          search: search
        }
        dispatch(getSearchedGameData(data))
      })
    } else {
      setPage(page + 1, () => {
        const data = {
          limit: 15,
          pageNo: page,
          category: subCategoryId
        }
        dispatch(getFilteredGameData({ data, navigate, hasMore: true }))
      })
    }
  }

  return (
    <GameGridWrapper theme={theme}>
      <Box className='game-grid-wrap'>
        <HeadingGameCards headingImg={headingImg} headingName={headingName} handleSeeAllGames={handleSeeAllGames} isFiltered={isFiltered} handleBack={handleBack} isSearched={isSearched} showRandomGames={showRandomGames} hideViewBtn={showRandomGames || hideViewBtn} />
        {isFiltered || isSearched
          ? contentLoading
              ? <Box className='game-loader abc'><img src='/Loader.gif' height='40px' width='40px' alt='loaderImg' loading='lazy' /></Box>
              : (
                <Box className='game-grid-wrap-section' id='game-grid-wrap-section'>
                  <InfiniteScroll
                    dataLength={gameArr?.length || 0}
                    next={fetchMore}
                    hasMore={gameArr?.length < gamesCount}
                    scrollableTarget='game-grid-wrap-section'
                    loader={<div className='game-loader'><img src='/Loader.gif' height='40px' width='40px' alt='loaderImg' loading='lazy' /></div>}
                  >
                    <Grid className='game-grid-container'>
                      {gameArr?.map((item) => {
                        const { thumbnailUrl, demo, casinoGameId, gameImg, CasinoGames } = item
                        return (<GameCard key={uuidv4()} gameImg={thumbnailUrl || gameImg || CasinoGames?.thumbnailUrl} isDemo={demo} gameCode={casinoGameId} gameData={item} favoriteGameHandler={favoriteGameHandler} />)
                      })}
                    </Grid>
                    {gameArr?.length === 0 && <Box className='no-game-text'>{t('noGamesFound')}</Box>}
                  </InfiniteScroll>
                </Box>
                )
          : contentLoading
            ? <Box className='game-loader'><img src='/Loader.gif' height='40px' width='40px' alt='loaderImg' loading='lazy' /></Box>
            : (
              <Grid className='game-grid-container'>
                {gameArr?.map((item) => {
                  const { thumbnailUrl, demo, casinoGameId, gameImg } = item
                  return (<GameCard key={uuidv4()} gameImg={thumbnailUrl || gameImg} isDemo={demo} gameCode={casinoGameId} gameData={item} favoriteGameHandler={favoriteGameHandler} />)
                })}

                {gameArr?.length === 0 && <Box className='no-game-text'>{t('noGamesFound')}</Box>}
              </Grid>
              )}
      </Box>
    </GameGridWrapper>
  )
}

export default GameGrid
