import React, { useEffect, useState } from 'react'
import { Buffer } from 'buffer'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography, InputAdornment, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ResetPasswordFormWrapper } from './style'
import {
  CustomFormLabel,
  CustomTextField
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import {
  CustomPrimaryButton
} from 'components/common-ui/CustomButton/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { VerifyPasswordToken } from 'redux-thunk/thunk/auth.thunk'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { verifyResetPasswordSchema } from './schema'
import ErrorMessage from '../ErrorMessage/index'
import { SpinnerSVG } from 'assets/icons/index'
import { LOADER_HANDLER_TYPES } from 'constants/index'

const LoginForm = ({ setShowLoginForm, handleClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const langCode = useSelector((state) => state.language.selectedLanguage?.code?.toUpperCase())
  const { t } = useTranslation()

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showFormWrap, setShowFormWrap] = useState(true)
  const [showLoginError, setShowLoginError] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const [forgotToken, setForgotToken] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const [copyPaste, setCopyPaste] = useState({
    password: false,
    confirmPassword: false
  })
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleRetry = () => {
    setShowLoginError(false)
    setShowFormWrap(true)
  }

  const handleVerificationSuccess = () => {
    setSearchParams('')
    navigate('/')
  }

  const checkCopyPaste = (event) => {
    const { name } = event.target
    event.preventDefault()
    setCopyPaste(prev => {
      return {
        ...prev,
        [name]: true
      }
    })
  }

  const handleResetPasswordSubmit = async ({ password, confirmPassword }) => {
    const data = {
      newPasswordKey: forgotToken,
      password: Buffer.from(password).toString('base64')
    }
    dispatch(VerifyPasswordToken({ params: data, setEmailSent, handleVerificationSuccess, t }))
  }

  useEffect(() => {
    setForgotToken(searchParams.get('newPasswordKey'))
  }, [])

  return (
    <>
      <ResetPasswordFormWrapper theme={theme}>
        {showFormWrap && (
          <Box className='form-wrap'>
            <Typography variant='h3' className='form-title'>
              {t('resetPasswordTitle')}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={verifyResetPasswordSchema(t)}
              onSubmit={({ password, confirmPassword }) =>
                handleResetPasswordSubmit({ password, confirmPassword })}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                errors
              }) => {
                const isBothPasswordMatch = values?.password === values.confirmPassword && values?.password.length >= 8 && (values.password !== '' && values.confirmPassword !== '')
                return (
                  <Form autoComplete='off'>
                    {!emailSent
                      ? (
                        <Box component='form' noValidate>
                          <Grid container spacing={1.5}>
                            <Grid item xs={12} lg={6}>
                              <Grid container rowSpacing={1.25} columnSpacing={0.625}>
                                <Grid item xs={12} lg={12}>
                                  <CustomFormLabel>{t('profileNewPassword')} *</CustomFormLabel>
                                  <CustomTextField
                                    fullWidth
                                    name='password'
                                    type={showPassword?.password ? 'text' : 'password'}
                                    showPassword={showPassword}
                                    handleClickShowPassword={handleClickShowPassword}
                                    handleMouseDownPassword={handleMouseDownPassword}
                                    placeholder='**********'
                                    value={values.password}
                                    onChange={(e) => {
                                      e.target.value = e.target.value.trim()
                                      setCopyPaste(prev => {
                                        return {
                                          ...prev,
                                          password: false
                                        }
                                      })
                                      handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                    maxLength={50}
                                    minLength={8}
                                    haslabel='yes'
                                    sx={{ border: isBothPasswordMatch && !errors?.password && '1px solid green' }}
                                    onPaste={checkCopyPaste}
                                    onCopy={checkCopyPaste}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <IconButton onClick={() => setShowPassword({ ...showPassword, password: !showPassword?.password })} edge='end'>
                                            {showPassword?.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name='password'
                                    key='password'
                                    className='error-message'
                                  />
                                  <Typography sx={{ color: 'red', fontSize: 13.5 }}>
                                    {copyPaste?.password && t('cpNotAllow')}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                  <CustomFormLabel>{t('profileConfirmPassword')} *</CustomFormLabel>
                                  <CustomTextField
                                    fullWidth
                                    name='confirmPassword'
                                    type={showPassword?.confirmPassword ? 'text' : 'password'}
                                    showPassword={showConfirmPassword}
                                    handleClickShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
                                    handleMouseDownPassword={handleMouseDownPassword}
                                    placeholder='**********'
                                    value={values.confirmPassword}
                                    onChange={(e) => {
                                      e.target.value = e.target.value.trim()
                                      setCopyPaste(prev => {
                                        return {
                                          ...prev,
                                          confirmPassword: false
                                        }
                                      })
                                      handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                    maxLength={50}
                                    minLength={8}
                                    haslabel='yes'
                                    onPaste={checkCopyPaste}
                                    onCopy={checkCopyPaste}
                                    sx={{ border: isBothPasswordMatch && !errors?.password && '1px solid green' }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          <IconButton onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword?.confirmPassword })} edge='end'>
                                            {showPassword?.confirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    component='div'
                                    name='confirmPassword'
                                    key='confirmPassword'
                                    className='error-message'
                                  />
                                  <Typography sx={{ color: 'red', fontSize: 13.5 }}>
                                    {copyPaste?.confirmPassword && t('cpNotAllow')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Box className='aut-btn-box'>
                                <CustomPrimaryButton
                                  fullWidth
                                  type='submit'
                                  onClick={handleSubmit}
                                  disabled={loading}
                                  fontSize={['DE', 'TR', 'RU'].includes(langCode) ? 'smd' : ''}
                                >
                                  {!loading
                                    ? <>{t('continue')}</>
                                    : <SpinnerSVG />}
                                </CustomPrimaryButton>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        )
                      : (
                        <Grid container rowSpacing={1.25} columnSpacing={0.625}>
                          <Grid item xs={12} lg={12}>
                            <Typography variant='caption'>
                              {t('resetPasswardConfirm')}
                            </Typography>
                          </Grid>
                        </Grid>
                        )}
                  </Form>
                )
              }}
            </Formik>

          </Box>
        )}
        {showLoginError && (
          <Box className='welcome-text-box'>
            <Typography variant='body1' className='pera-text'>
              {t('incorrectCredsMessage')}
              <CustomPrimaryButton fullWidth onClick={handleRetry}>{t('retry')}</CustomPrimaryButton>
            </Typography>
          </Box>
        )}
      </ResetPasswordFormWrapper>
    </>
  )
}

export default React.memo(LoginForm)
