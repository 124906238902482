import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography
  , IconButton
} from '@mui/material'
import { GameCardWrapper } from './style'
import { CaretArrowRightIcon } from 'assets/icons/index'
import { useDispatch, useSelector } from 'react-redux'
import { playGame } from 'redux-thunk/thunk/game.thunk'
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index'
import { defaultCurrency } from 'utils/constant'
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded'
import { useTranslation } from 'react-i18next'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { setAuthModal } from 'redux-thunk/redux/slices/auth.slice'

const GameCard = ({ gameImg, isDemo, gameCode, favoriteGameHandler, gameData }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userData = useSelector((state) => state.user?.user)
  const [error, isError] = useState(false)
  const { isAuthenticated } = useSelector((state) => state.gameSetting)
  const { t } = useTranslation()
  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)

  const openGame = (gameCode, url) => {
    navigate(`/casino/play-game/${gameCode}`, { state: { gameURL: url } })
  }

  const handlePlayGame = (mode) => {
    if (isAuthenticated && !mode) {
      const data = {
        currency: userData?.currencyCode || defaultCurrency,
        gameCode: gameCode,
        funMode: mode
      }
      dispatch(playGame({ data, openGame }))
      navigate(`/casino/play-game/${gameCode}`)
    } else if (!isAuthenticated && !mode) {
      dispatch(setAuthModal('login'))
    } else {
      const data = {
        currency: userData?.currencyCode || defaultCurrency,
        gameCode: gameCode,
        funMode: mode
      }
      dispatch(playGame({ data, openGame }))
      navigate(`/casino/play-game/${gameCode}`)
    }
  }

  const isFav = (gameData?.isFavourite || gameData?.isFavorite || gameData?.CasinoGames?.isFavourite || gameData?.CasinoGames?.isFavorite)

  const favGameHandler = () => {
    console.log({ ...gameData, isFavourite: isFav })
    if (!loading) {
      favoriteGameHandler({ ...gameData, isFavourite: isFav })
    }
  }

  return (
    <GameCardWrapper theme={theme}>
      <Card>
        <CardContent>
          <Box className='game-img-box'>
            <img
              onError={() => {
                isError(true)
              }} src={error ? '/assets/images/stock-images/g10.png' : gameImg} alt='Game Thumbnail' className='game-img' loading='lazy'
            />
          </Box>
          <Button className='button-overlay'>
            {isAuthenticated &&
              <Box title={t('favorite')} className='favorite-btn-box'>
                {
              isFav
                ? <IconButton className='favorite-btn' onClick={() => favGameHandler()}><StarRateRoundedIcon /></IconButton>
                : <IconButton className='favorite-btn' onClick={() => favGameHandler()}><StarOutlineRoundedIcon /></IconButton>
              }
              </Box>}
            <img src='/assets/images/stock-images/play-img.png' alt='Play Img' onClick={() => handlePlayGame(false)} loading='lazy' />
            <Typography variant='subtitle1' className='text-1' onClick={() => handlePlayGame(false)}>{t('play')}</Typography>
            {isDemo && <Typography variant='subtitle1' className='text-2' onClick={() => handlePlayGame(true)}>{t('demo')}<CaretArrowRightIcon /></Typography>}
          </Button>
          <img src='/assets/images/stock-images/game-img-border.png' className='border-img' alt='Game Img' loading='lazy' />
        </CardContent>
      </Card>
    </GameCardWrapper>
  )
}
export default GameCard
