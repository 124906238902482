
export const message = {
  LOGIN_SUCCESS: 'MESSAGE.LOGIN_SUCCESS',
  FORGOT_SUCCESS: 'MESSAGE.FORGOT_SUCCESS',
  WrongEAndPwd: 'MESSAGE.WrongEAndPwd',
  EMPTY_EMAIL: 'MESSAGE.EMPTY_EMAIL',
  EMPTY_PASSWORD: 'MESSAGE.EMPTY_PASSWORD',
  EMPTY_TRANSACTION_PASSWORD: 'MESSAGE.EMPTY_TRANSACTION_PASSWORD',
  INVALID_EMAIL: 'MESSAGE.INVALID_EMAIL',
  NAME_REQUIRED: 'MESSAGE.NAME_REQUIRED',
  USER_NAME_REQUIRED: 'MESSAGE.USER_NAME_REQUIRED',
  FULL_NAME_REQUIRED: 'MESSAGE.FULL_NAME_REQUIRED',
  FIRST_NAME_REQUIRED: 'MESSAGE.FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED: 'MESSAGE.LAST_NAME_REQUIRED',
  PHONE_REQUIRED: 'MESSAGE.PHONE_REQUIRED',
  PHONE_LENGTH_REQUIRED: 'MESSAGE.PHONE_LENGTH_REQUIRED',
  ACCOUNT_NO_REQUIRED: 'MESSAGE.ACCOUNT_NO_REQUIRED',
  ACCOUNT_HOLDER_REQUIRED: 'MESSAGE.ACCOUNT_HOLDER_REQUIRED',
  ONLY_CHARECTERS: 'MESSAGE.ONLY_CHARECTERS',
  VALID_PHONE: 'MESSAGE.VALID_PHONE',
  EMPTY_CONFIRM_PASSWORD: 'MESSAGE.EMPTY_CONFIRM_PASSWORD',
  EMPTY_CONFIRM_TRANSACTION_PASSWORD: 'MESSAGE.EMPTY_CONFIRM_TRANSACTION_PASSWORD',
  PASSWORD_LENGTH: 'MESSAGE.PASSWORD_LENGTH',
  PASSWORD_NOT_MATCH: 'MESSAGE.PASSWORD_NOT_MATCH',
  EMPTY_ACCESS_TOKEN: 'MESSAGE.EMPTY_ACCESS_TOKEN',
  EMPTY_OTP: 'MESSAGE.EMPTY_OTP',
  EMPTY_BANKNAME: 'MESSAGE.EMPTY_BANKNAME',
  SIMPLE_SIGNUP_SUCCESS: 'MESSAGE.SIMPLE_SIGNUP_SUCCESS',
  NUMBER_REQUIRED: 'MESSAGE.NUMBER_REQUIRED',
  DOB_REQUIRED: 'MESSAGE.DOB_REQUIRED',
  ADDRESS_REQUIRED: 'MESSAGE.ADDRESS_REQUIRED',
  EMPTY_COUNTRY: 'MESSAGE.EMPTY_COUNTRY',
  EMPTY_CITY: 'MESSAGE.EMPTY_CITY',
  SOME_THING_WRONG: 'MESSAGE.SOME_THING_WRONG',
  PROJECT_NAME_REQUIRED: 'MESSAGE.PROJECT_NAME_REQUIRED',
  PROJECT_DOMAIN_REQUIRED: 'MESSAGE.PROJECT_DOMAIN_REQUIRED',
  PROJECT_DOMAIN_INVALID: 'MESSAGE.PROJECT_DOMAIN_INVALID',
  FRONTEND_DOMAIN_INVALID: 'MESSAGE.FRONTEND_DOMAIN_INVALID',
  CLIENT_PUBLIC_KEY_REQUIRED: 'MESSAGE.CLIENT_PUBLIC_KEY_REQUIRED',
  BANNER_TITLE_REQUIRED: 'MESSAGE.BANNER_TITLE_REQUIRED',
  BANNER_TYPE_REQUIRED: 'MESSAGE.BANNER_TYPE_REQUIRED',
  BANNER_CATEGORY_REQUIRED: 'MESSAGE.BANNER_CATEGORY_REQUIRED',
  ZIP_CODE_REQUIRED: 'MESSAGE.ZIP_CODE_REQUIRED',
  AGENT_CODE_REQUIRED: 'MESSAGE.AGENT_CODE_REQUIRED',
  CAPTCHA_REQUIRED: 'MESSAGE.CAPTCHA_REQUIRED',
  MUST_BE_18: 'MESSAGE.MUST_BE_18',
  VALID_DATE: 'MESSAGE.VALID_DATE',
  CURRENCY_REQUIRED: 'MESSAGE.CURRENCY_REQUIRED',
  GENDER_REQUIRED: 'MESSAGE.GENDER_REQUIRED',
  NEWS_CHECK_REQUIRED: 'MESSAGE.NEWS_CHECK_REQUIRED',
  SMS_REQUIRED: 'MESSAGE.SMS_REQUIRED'
}

export const errorMessages = {
  betHistory: 'No bet history found',
  promotionPage: 'No promotions found',
  bonusPage: 'No bonuses found',
  bonusPageHistory: 'No history found',
  bonusPageYourBonuses: 'No bonuses found',
  withdrawalHistory: 'No withdawal history found',
  favoriteGames: 'No favorite games found',
  cms: 'No Cms Found'
}

export const defaultCurrency = 'EUR'

export const languagesMenuItem = [
  {
    title: 'English',
    img: '/assets/images/stock-images/languages/english.png',
    code: 'en'
  },
  {
    title: 'Polski',
    img: '/assets/images/stock-images/languages/polski.png',
    code: 'pl'
  },
  {
    title: 'Deutsch',
    img: '/assets/images/stock-images/languages/deutsch.png',
    code: 'de'
  },
  {
    title: 'English',
    img: '/assets/images/stock-images/languages/english-aus.png',
    code: 'enAU'
  },
  {
    title: 'Danish',
    img: '/assets/images/stock-images/languages/dansk.png',
    code: 'da'
  },
  {
    title: 'Español',
    img: '/assets/images/stock-images/languages/espanol.png',
    code: 'es'
  },
  {
    title: 'Português',
    img: '/assets/images/stock-images/languages/portuguese-brazil.png',
    code: 'pt'
  },
  {
    title: 'Türkçe',
    img: '/assets/images/stock-images/languages/turkish.png',
    code: 'tr'
  },
  {
    title: '日本語',
    img: '/assets/images/stock-images/languages/japanese.png',
    code: 'ja'
  },
  {
    title: 'Українська',
    img: '/assets/images/stock-images/languages/ukrainian.png',
    code: 'uk'
  },
  {
    title: 'العربية',
    img: '/assets/images/stock-images/languages/arabic.png',
    code: 'ar'
  },
  {
    title: 'English',
    img: '/assets/images/stock-images/languages/english-usa.png',
    code: 'enUS'
  },
  {
    title: 'Suomi',
    img: '/assets/images/stock-images/languages/suomi.png',
    code: 'fi' // finland
  },
  {
    title: 'Français',
    img: '/assets/images/stock-images/languages/french.png',
    code: 'fr'
  },
  {
    title: 'Русский',
    img: '/assets/images/stock-images/languages/russian.png',
    code: 'ru'
  },
  {
    title: 'Nederlands',
    img: '/assets/images/stock-images/languages/netherlands.png',
    code: 'nl'
  },
  {
    title: 'English',
    img: '/assets/images/stock-images/languages/Canada.png',
    code: 'enCA'
  }
]

export const tourRes = {
  data: {
    tournaments: {
      MONTHLY: [
        {
          tournamentId: 3,
          tournamentRoundId: 3,
          isActive: true,
          startDate: '2024-03-20T00:00:00.000Z',
          endDate: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-14T13:39:13.277Z',
          CasinoTournament: {
            name: {
              EN: 'tour2'
            },
            repeat: 'MONTHLY',
            tournamentId: 3
          }
        }
      ],
      WEEKLY: [
        {
          tournamentId: 4,
          tournamentRoundId: 4,
          isActive: true,
          startDate: '2024-03-20T00:00:00.000Z',
          endDate: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-14T13:41:01.788Z',
          CasinoTournament: {
            name: {
              EN: 'tour2'
            },
            repeat: 'WEEKLY',
            tournamentId: 4
          }
        },
        {
          tournamentId: 6,
          tournamentRoundId: 6,
          isActive: true,
          startDate: '2024-03-20T00:00:00.000Z',
          endDate: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-15T06:25:19.509Z',
          CasinoTournament: {
            name: {
              EN: 'toux'
            },
            repeat: 'WEEKLY',
            tournamentId: 6
          }
        }
      ],
      NO_REPEAT: [
        {
          tournamentId: 5,
          tournamentRoundId: 5,
          isActive: true,
          startDate: '2024-03-20T00:00:00.000Z',
          endDate: '2024-03-27T00:00:00.000Z',
          createdAt: '2024-03-14T13:45:06.048Z',
          CasinoTournament: {
            name: {
              EN: 'tour2'
            },
            repeat: 'NO_REPEAT',
            tournamentId: 5
          }
        }
      ]
    },
    message: 'Record get successfully'
  },
  errors: []
}

export const tournamentType = {
  all: 'not_started',
  active: 'active',
  finished: 'closed'
}

export const jackpotGames = [
  {
    id: 872,
    name: '24 Hour Grand Prix',
    casinoGameId: 'rtg_24_hour_grand_prix',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_24_hour_grand_prix/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.789Z',
    updatedAt: '2024-02-09T11:48:39.789Z'
  },
  {
    id: 873,
    name: '4Squad',
    casinoGameId: 'rtg_4squad',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_4squad/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.789Z',
    updatedAt: '2024-02-09T11:48:39.789Z'
  },
  {
    id: 874,
    name: '5 Families',
    casinoGameId: 'rtg_5_families',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_5_families/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.790Z',
    updatedAt: '2024-02-09T11:48:39.790Z'
  },
  {
    id: 875,
    name: '777 Strike',
    casinoGameId: 'rtg_777_strike',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_777_strike/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.790Z',
    updatedAt: '2024-02-09T11:48:39.790Z'
  },
  {
    id: 878,
    name: 'Agent Royale',
    casinoGameId: 'rtg_agent_royale',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_agent_royale/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.791Z',
    updatedAt: '2024-02-09T11:48:39.791Z'
  },
  {
    id: 876,
    name: 'Age of Akkadia',
    casinoGameId: 'rtg_age_of_akkadia',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_age_of_akkadia/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.790Z',
    updatedAt: '2024-02-09T11:48:39.790Z'
  },
  {
    id: 1054,
    name: 'Aloha! Christmas',
    casinoGameId: 'ntn_aloha_christmas',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 1,
    thumbnailUrl: 'https://luckmedia.link/ntn_aloha_christmas/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:40.227Z',
    updatedAt: '2024-02-09T11:48:40.227Z'
  },
  {
    id: 1055,
    name: 'Aloha! Cluster Pays',
    casinoGameId: 'ntn_aloha_cluster_pays',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 1,
    thumbnailUrl: 'https://luckmedia.link/ntn_aloha_cluster_pays/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:40.227Z',
    updatedAt: '2024-02-09T11:48:40.227Z'
  },
  {
    id: 1056,
    name: 'American Roulette',
    casinoGameId: 'ntn_american_roulette',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 1,
    thumbnailUrl: 'https://luckmedia.link/ntn_american_roulette/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:40.227Z',
    updatedAt: '2024-02-09T11:48:40.227Z'
  },
  {
    id: 879,
    name: 'Ancients Blessing',
    casinoGameId: 'rtg_ancients_blessing',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_ancients_blessing/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.791Z',
    updatedAt: '2024-02-09T11:48:39.791Z'
  },
  {
    id: 877,
    name: 'Ancient Script',
    casinoGameId: 'rtg_ancient_script',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_ancient_script/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.791Z',
    updatedAt: '2024-02-09T11:48:39.791Z'
  },
  {
    id: 880,
    name: 'Arcade Bomb',
    casinoGameId: 'rtg_arcade_bomb',
    casinoSubCategoryId: null,
    casinoCategoryId: 2,
    casinoProviderId: 3,
    thumbnailUrl: 'https://luckmedia.link/rtg_arcade_bomb/thumb_3_4.webp',
    isActive: true,
    isFeatured: true,
    description: null,
    hasFreespins: null,
    restrictions: null,
    devices: null,
    returnToPlayer: null,
    wageringContribution: 100,
    moreDetails: null,
    orderId: null,
    demo: false,
    createdAt: '2024-02-09T11:48:39.792Z',
    updatedAt: '2024-02-09T11:48:39.792Z'
  }
]
