import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Parser from 'html-react-parser'
import { getAllCmsSiteData } from 'redux-thunk/thunk/gameSetting'

export const Cms = () => {
  const dispatch = useDispatch()
  // const history = useHistory()
  const { cmsSiteData } = useSelector((state) => state?.gameSetting)
  const { selectedLanguage } = useSelector((state) => state.language)

  const { cmsId } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = {
      cmsId,
      selectedLanguage
    }
    dispatch(getAllCmsSiteData({ data }))
  }, [cmsId])

  return (
    <div className='container'>
      {Parser(cmsSiteData?.content || '')}
    </div>
  )
}
