import PrivateRoute from './HOC/PrivateRoutes/index'
import PublicRoute from './HOC/PublicRoutes/index'
import { useSelector } from 'react-redux'

const RouteValidator = ({ route }) => {
  const { component: Component, child: Child, showFooter = false, isFullLayout } = route
  const { isAuthenticated } = useSelector((state) => state.gameSetting)
  return (
    <>
      {
        route?.isPrivate
          ? (
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              Component={Component}
              child={Child}
              showFooter={showFooter}
              isFullLayout={isFullLayout}
            />
            )
          : (
            <PublicRoute
              isAuthenticated={isAuthenticated}
              Component={Component}
              child={Child}
              showFooter={showFooter}
            />
            )
        }
    </>
  )
}

export default RouteValidator
