import { Card, styled } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

export const BonusCardWrapper = styled(Card)(({ theme }) => {
  return {
    minHeight: theme.spacing(15),
    backgroundColor: theme.palette.solidColor.transparent,
    backgroundImage: 'none',
    borderRadius: theme.spacing(0),
    boxShadow: theme.shadows[0],
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: '1fr',
    gap: theme.spacing(0.625),
    '& .MuiCardMedia-root': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      borderRadius: theme.spacing(0.313),
      gridColumnStart: '1',
      gridColumnEnd: '5'
    },
    '& .MuiCardContent-root': {
      backgroundColor: theme.palette.solidColor.solidColor_01,
      borderRadius: theme.spacing(0.313),
      display: 'flex',
      flexDirection: 'column',
      gridColumnStart: '5',
      gridColumnEnd: '13',
      padding: theme.spacing(1.25),
      position: 'relative',
      '& .text-box': {
        width: '100%',
        '& .MuiTypography-subtitle1': {
          color: theme.palette.typographyText.textWhite,
          fontSize: theme.spacing(1.125),
          fontWeight: '800',
          lineHeight: '1.4',
          textTransform: 'uppercase',
          paddingRight: theme.spacing(3.5),
          marginBottom: theme.spacing(0.625)
        },
        '& .MuiTypography-body1': {
          color: theme.palette.lightShade.light_07,
          fontSize: theme.spacing(1.25),
          fontWeight: '400',
          lineHeight: '1.5',
          marginBottom: theme.spacing(0.625)
        }
      },
      '& .MuiButtonBase-root': {
        width: '100%',
        background: theme.palette.solidColor.transparent,
        border: `1px solid ${theme.palette.warning.light}`,
        borderRadius: theme.spacing(1.25),
        color: theme.palette.warning.light,
        fontSize: theme.spacing(1),
        fontWeight: '700',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        padding: theme.spacing(0.5),
        marginTop: 'auto'
      },
      '& .info-tooltip': {
        fill: theme.palette.typographyText.textWhite,
        cursor: 'pointer',
        position: 'absolute',
        top: theme.spacing(1.25),
        right: theme.spacing(1.25)
      }
    },
    '&.active-bonus': {
      '& .MuiCardContent-root': {
        '& .MuiButtonBase-root': {
          background: theme.palette.gradientColor.tabsActive,
          border: `1px solid ${theme.palette.border.transparent}`,
          color: theme.palette.typographyText.textActive
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
      '& .MuiCardMedia-root': {
        height: 'auto',
        borderRadius: theme.spacing(0.313, 0.313, 0, 0),
        aspectRatio: '16/11'
      },
      '& .MuiCardContent-root': {
        height: '100%',
        borderRadius: theme.spacing(0, 0, 0.313, 0.313),
        '& .text-box': {
          width: '100%',
          '& .MuiTypography-subtitle1': {
            fontSize: theme.spacing(0.875),
            paddingRight: theme.spacing(1.75)
          },
          '& .MuiTypography-body1': {
            fontSize: theme.spacing(0.75)
          }
        },
        '& .MuiButtonBase-root': {
          fontSize: theme.spacing(0.875)
        }
      }
    }
  }
})

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(0.8),
  width: '100%',
  marginTop: theme.spacing(-0.5),
  borderRadius: theme.spacing(2),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
    border: '1px solid #fbf195'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#ddad1c'
  }
}))
