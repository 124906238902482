import { Box, styled } from '@mui/material'

export const TransactionHistoryCashWrapper = styled(Box)(({ theme }) => {
  return {
    '& .transactions-results-text': {
      borderBottom: `1px solid ${theme.palette.border.active}`,
      color: theme.palette.typographyText?.textMain,
      fontSize: theme.spacing(1),
      fontWeight: '400',
      lineHeight: 'normal',
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(1.875),
      marginBottom: theme.spacing(1)
    },
    '& .success': {
      color: theme.palette.success.main
    },
    '& .failed': {
      color: theme.palette.danger.main
    },
    '& .pending': {
      color: theme.palette.warning.main
    },
    '& .inline-box': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly'
    },
    '& .form-btn-box': {
      marginTop: theme.spacing(0.5),
      '& .MuiButtonBase-root': {
        width: '100%'
      }
    }
  }
})
