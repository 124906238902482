import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { SpinnerSVG } from 'assets/icons/index'
import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { OffersBonusesWrapper } from './style'
// import BonusCard from '../BonusCard/index'
import { getUserBonus } from 'redux-thunk/thunk/bonus.thunk'
import { SpinnerSVG } from 'assets/icons/index'
import BonusCard from 'pages/Bonus/BonusCard'
import { useTranslation } from 'react-i18next'

// import { LOADER_HANDLER_TYPES } from 'constants/index'

const OffersBonuses = ({ from }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const bonus = useSelector(state => state?.bonus?.userBonus?.bonus)
  const { bonusLoading } = useSelector(state => state?.bonus)
  const { t } = useTranslation()

  // const { userBonus } = useSelector(state => state.bonus)
  // const { [LOADER_HANDLER_TYPES.content]: isLoading } = useSelector(state => state.loader)

  // const useBonus = () => {
  //   const data = {
  //     bonusId: 2
  //   }
  //   dispatch(useBonus(data))
  // }

  useEffect(() => {
    dispatch(getUserBonus())
  }, [])

  const OffersBonusElement = (
    <OffersBonusesWrapper theme={theme} className='content-wrapper'>
      <Box className='offer-wrap'>
        {bonus?.rows?.map((bonusData) => {
          const desc = JSON.parse(bonusData?.description)
          const termCondition = JSON.parse(bonusData?.termCondition)
          return (
            <BonusCard
              data={bonusData}
              key={bonusData?.bonusId}
              bonusId={bonusData?.bonusId}
              bonusTitle={bonusData?.bonusTitle}
              bonusCode={bonusData?.bonusCode}
              bonusType={bonusData?.bonusSlug}
              description={desc}
              depositBonusPercent={bonusData?.depositBonusPercent}
              validFrom={bonusData?.validFrom}
              validTo={bonusData?.validTo}
              termCondition={termCondition}
              bonusImg={bonusData?.bonusImg || '/assets/images/stock-images/offers/offer-thumbnail.png'}
              from={from}
              isClaimed={bonusData?.isClaimed}
            />
          )
        }
        )}
      </Box>
    </OffersBonusesWrapper>
  )
  return (
    <>
      {bonusLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}><SpinnerSVG style={{ fill: 'white' }} /></div> : bonus?.count ? OffersBonusElement : <Typography vatiant='h3' className='form-title'>{t('noBonusDetailsFound')}</Typography>}
    </>
  )
}

export default React.memo(OffersBonuses)
