import React from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line
import { useLocation } from 'react-router-dom'
// eslint-disable-next-line
import { ROUTE_PATHS } from 'constants/index'
import MaintenanceMode from '../Maintenance/index'
import { useTheme } from '@emotion/react'
import { MainWrapper } from './style'
import Footer from '../Footer/index'

const FullLayout = ({ children, showFooter }) => {
  const { siteData } = useSelector((state) => state.general)

  const theme = useTheme()

  return (
    <>
      <MainWrapper theme={theme}>
        {(!siteData?.tenantDetail?.maintenance) ? children : <MaintenanceMode />}
      </MainWrapper>
      {showFooter && <Footer />}
    </>
  )
}

export default FullLayout
