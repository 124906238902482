import React, { forwardRef } from 'react'
import { useTheme } from '@emotion/react'
import { Box, DialogContent, IconButton, Slide } from '@mui/material'
import { AuthDialogWrapper } from './style'

import { CloseBoldIcon } from 'assets/icons/index'
import Lottie from 'lottie-react'
import roboflyAnimation from '../../../assets/lottie-animation-json/robofly.json'
import roboflyLightAnimation from '../../../assets/lottie-animation-json/robofly-light-new.json'
import logoAnimation from '../../../assets/lottie-animation-json/logo.json'
import { useSelector } from 'react-redux'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const AuthDialog = (props) => {
  const theme = useTheme()
  const { isDarkMode } = useSelector((state) => state.gameSetting)

  const modalBgAtmImage = theme.themeImages.modalBgAtmImage

  const {
    dialogOpen,
    handleDialogClose,
    title,
    children,
    fullWidth,
    maxWidth,
    fullScreen,
    PaperComponent,
    scroll
  } = props

  return (
    <AuthDialogWrapper
      theme={theme}
      title={title}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      PaperComponent={PaperComponent}
      scroll={scroll}
    >
      <img
        src={modalBgAtmImage}
        className='bg-img'
        alt='BG Img'
        loading='lazy'
      />
      <Lottie
        animationData={logoAnimation}
        loop
        autoplay
        className='brand-logo'
      />

      {/* <Box className='currency-note-animation-container'>
        <Box className='currency-note-animation-wrap'>
          <Box className='inner-box'>
            <Box className='animation-img-box'>
              <img
                src='/assets/images/stock-images/dollar-note.webp'
                className='animated-img'
                alt='Currency Note'
              />
            </Box>
          </Box>
        </Box>
        {handleDialogClose
          ? (
            <IconButton
              className='dialog-close-btn'
              aria-label='close'
              onClick={handleDialogClose}
            >
              <CloseBoldIcon />
            </IconButton>
            )
          : null}
      </Box> */}

      {handleDialogClose
        ? (
          <IconButton
            className='dialog-close-btn'
            aria-label='close'
            onClick={handleDialogClose}
          >
            <CloseBoldIcon />
          </IconButton>
          )
        : null}

      {/* <Box className='atm-card-animation-container'>
        <Box className='atm-card-animation-wrap'>
          <Box className='inner-box'>
            <Box className='animation-img-box'>
              <img
                src='/assets/images/stock-images/atm-card.png'
                className='animated-img'
                alt='Currency Note'
              />
            </Box>
          </Box>
        </Box>
      </Box> */}

      <Box className='atm-machine-animation-img-box'>
        <Lottie animationData={isDarkMode ? roboflyAnimation : roboflyLightAnimation} loop autoplay />
      </Box>

      <DialogContent>
        <Box className='content-wrap'>{children}</Box>
      </DialogContent>
    </AuthDialogWrapper>
  )
}

export default React.memo(AuthDialog)
