const config = {
  apiGateways: {
    BASE_URL_1: `${process.env.REACT_APP_API_URL}`
  },
  SOCKET_URL: `${process.env.REACT_APP_USER_BACKEND_SOCKET_URL}`,
  META_MASK_SIGN_MESSAGE: `${process.env.REACT_APP_META_MASK_SIGN_MESSAGE}`,
  IMAGE_URL: 'https://gammastack-casino.s3.amazonaws.com/'
}

export default config
