import React from 'react'
import { useTheme } from '@emotion/react'
import { Box, Paper, Typography, Tab, Tabs, Table, TableBody, TableCell, TableHead, TableRow, Zoom } from '@mui/material'
import { BonusDialogWrapper } from './style'
import { formatDateDMY } from 'utils/dateFormatter'
import Parser from 'html-react-parser'
import GameGrid from 'pages/GameGrid/index'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CustomTableWrapper } from 'components/common-ui/CustomTable/style'
import MyAccountDialog from 'components/common-ui/MyAccountDialog/MyAccountDialog'
import { BorderLinearProgress } from 'components/layout/MyAccount/Bonuses/BonusCard/style'
import CustomTooltip from 'components/common-ui/CustomTooltip/index'
import { InfoCircleIcon } from 'assets/icons/index'

export const BonusDialog = ({
  data,
  from,
  bonusTitle,
  bonusCode,
  description,
  depositBonusPercent,
  requiredWager,
  currentWagered,
  validFrom,
  validTo,
  isClaimed,
  gamesArr,
  bonusType,
  freeSpinsQty,
  viewMore,
  handleViewMore,
  termCondition
}) => {
  const theme = useTheme()
  const [maxWidth] = React.useState('customModal_lg')
  const langCode = useSelector((state) =>
    state.language.selectedLanguage?.code?.toUpperCase()
  )
  const [value, setValue] = React.useState(0)
  const { t } = useTranslation()
  const [copySuccess, setCopySuccess] = React.useState('')

  const MIN = 0 // min amount
  const MAX = requiredWager // required wagering amount
  // Function to normalise the values (MIN / MAX could be integrated)
  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const bonusGamesData = {
    headingName: `${t('freeSpins')} ${t('games')}`,
    headingImg: '/assets/images/stock-images/game-icon-4.png'
  }

  const copyCode = () => {
    navigator.clipboard.writeText(bonusCode)
    setCopySuccess(t('codeCopied'))
    setTimeout(() => {
      setCopySuccess('')
    }, [1000])
  }

  function CustomTabPanel (props) {
    const { children, value, index, ...other } = props

    return (
      <Box
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    )
  }

  const notfoundElement = (
    <Box style={{ display: 'flex', justifyContent: 'center', padding: '5rem' }}>
      <Typography variant='h5' className='text-7'>
        {t('notFound')}
      </Typography>
    </Box>
  )
  return (
    <MyAccountDialog
      maxWidth={maxWidth}
      // fullScreen={fullScreen}
      dialogOpen={viewMore}
      handleDialogClose={() => handleViewMore(false)}
      aria-labelledby='customized-dialog-title'
    >
      <BonusDialogWrapper>
        <Box className='dialog-content-wrap'>
          <Box className='bonus-details-wrap'>
            {from === 'offer'
              ? (
                <Box className='text-box'>
                  <Typography variant='body1' className='heading-text'>
                    {bonusTitle[langCode] || bonusTitle.EN}
                  </Typography>

                  <CustomTableWrapper theme={theme} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('type')}</TableCell>
                          <TableCell>{t('depositBonusTitle')} %</TableCell>
                          <TableCell>{t('promotionsBonusCode')}</TableCell>
                          <TableCell>{t('status')}</TableCell>
                          <TableCell>{t('validity')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{bonusType?.toUpperCase()}</TableCell>
                          <TableCell>{depositBonusPercent} %</TableCell>
                          <TableCell>{bonusCode}</TableCell>
                          <TableCell>{isClaimed ? <>{t('activated')}</> : `Not ${t('activated')}`}</TableCell>
                          <TableCell>{formatDateDMY(validFrom)} to {formatDateDMY(validTo)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CustomTableWrapper>
                </Box>
                )
              : (
                <Box className='text-box'>
                  <Typography variant='body1' className='heading-text'>
                    {bonusTitle[langCode] || bonusTitle.EN}
                  </Typography>

                  <CustomTableWrapper theme={theme} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('type')}</TableCell>
                          <TableCell>{t('requiredWageringAmount')}</TableCell>
                          <TableCell>{t('currentWageringAmount')}</TableCell>
                          <TableCell>{t('promotionsBonusCode')}</TableCell>
                          <TableCell>{t('status')}</TableCell>
                          <TableCell>{t('validUpto')}</TableCell>
                          <TableCell>{t('freeRounds')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{bonusType?.toUpperCase()}</TableCell>
                          <TableCell>{requiredWager?.toFixed(2)}</TableCell>
                          <TableCell>{currentWagered?.toFixed(2)}</TableCell>
                          <TableCell>
                            <Box className='bonus-code-box'>
                              {bonusCode}{' '}{' '}
                              <ContentCopyIcon className='copy-icon' onClick={copyCode} />{' '}
                              <span className='copy-text'> {' ' + copySuccess} </span>
                            </Box>
                          </TableCell>
                          <TableCell>{data?.status}</TableCell>
                          <TableCell>{formatDateDMY(validTo)}</TableCell>
                          <TableCell>{freeSpinsQty}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CustomTableWrapper>
                </Box>
                )}
          </Box>

          {from === 'active' && (
            <Box className='active-bonus-details-wrap'>
              <Box>
                <Typography>
                  {t('requiredWageringAmount')}: {requiredWager?.toFixed(2)}
                </Typography>
                <Typography>
                  {t('currentWageringAmount')}: {currentWagered?.toFixed(2)}
                </Typography>
              </Box>
              <Box>
                <Box>
                  <CustomTooltip
                    title={` ${t('validUpto')}: ${formatDateDMY(validTo)}`}
                    placement='right-start'
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <InfoCircleIcon className='info-tooltip' />
                  </CustomTooltip>
                </Box>
                <Box className='marquee-div'>
                  <marquee direction='right' behavior='alternate' className='marquee-text'>
                    {t('wageringProgressBar')}
                  </marquee>
                  <BorderLinearProgress
                    variant='determinate'
                    value={
                      currentWagered >= requiredWager ? 100 : normalise(currentWagered)
                    }
                  />
                </Box>
              </Box>
            </Box>)}

          <Box className='more-bonus-card-wrap'>
            <Box className='more-bonus-card-container'>
              <Box className='more-bonus-card-box'>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant='fullWidth'
                  aria-label='scrollable force tabs example'
                >
                  <Tab
                    label={<Box className='inner-box'> {t('description')} </Box>}
                  />
                  <Tab
                    label={<Box className='inner-box'> {t('footerTerms')} </Box>}
                  />
                  {gamesArr?.length && (
                    <Tab label={<Box className='inner-box'> {t('games')} </Box>} />
                  )}
                </Tabs>

                <Box>

                  <CustomTabPanel value={value} index={0}>
                    {description
                      ? (
                        <Typography variant='h5' className='text-7'>
                          {description[langCode]
                            ? Parser(description[langCode])
                            : Parser(description?.EN)}
                        </Typography>
                        )
                      : (
                          notfoundElement
                        )}
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={1}>
                    {termCondition
                      ? (
                        <Typography variant='h5' className='text-7'>
                          {termCondition[langCode]
                            ? Parser(termCondition[langCode])
                            : Parser(termCondition?.EN)}
                        </Typography>
                        )
                      : (
                          notfoundElement
                        )}
                  </CustomTabPanel>

                  <CustomTabPanel value={value} index={2}>
                    {gamesArr?.length
                      ? (
                        <>
                          <GameGrid
                            gameArr={gamesArr}
                            headingImg={bonusGamesData.headingImg}
                            headingName={bonusGamesData.headingName}
                            hideViewBtn
                          />
                          <Typography variant='h5' className='text-7'>
                            {t('noteYouMayOptfreeSpin')}
                          </Typography>
                        </>
                        )
                      : (
                        <Typography variant='h5' className='text-7'>
                          {t('games')} {t('notFound')}
                        </Typography>
                        )}
                  </CustomTabPanel>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BonusDialogWrapper>
    </MyAccountDialog>
  )
}
