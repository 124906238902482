import { Box, styled } from '@mui/material'

export const DepositWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    '& .payment-section': {
      width: '100%',
      maxWidth: theme.spacing(58.25),
      marginTop: theme.spacing(2.25),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    '& .payment-logo-btn, & .payment-logo-box': {
      width: '100%',
      aspectRatio: '109/65',
      backgroundColor: theme.palette.lightShade.main,
      border: '0',
      borderRadius: theme.spacing(0.25),
      boxShadow: theme.shadows[4],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      padding: theme.spacing(1.5),
      transition: '300ms all',
      '&:active': {
        transform: 'scale(0.95)'
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center'
      }
    },
    '& .payment-logo-box': {
      cursor: 'default',
      '&:active': {
        transform: 'scale(1)'
      }
    },
    '& form': {
      width: '100%'
    },
    '& .amount-input-label': {
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.warning.main,
      fontSize: theme.spacing(1.5),
      fontWeight: '500',
      textAlign: 'center',
      marginBottom: theme.spacing(0.625)
    },
    '& .amount-input': {
      '& .MuiInputBase-input': {
        fontSize: theme.spacing(1.5),
        fontWeight: '800',
        textAlign: 'center',
        '&::placeholder': {
          fontSize: theme.spacing(1.5),
          fontWeight: '800'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .mobile-back-btn': {
        height: theme.spacing(2.5),
        fontFamily: 'Poppins, sans-serif',
        color: theme.palette.typographyText.textMain,
        fill: theme.palette.typographyText.textMain,
        fontSize: theme.spacing(1),
        fontWeight: '500',
        lineHeight: 'normal',
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(0.313),
        cursor: 'pointer',
        padding: theme.spacing(0.5),
        position: 'absolute',
        top: theme.spacing(0.813),
        left: theme.spacing(0.875),
        // transform: `translate(${theme.spacing(-7.188)}, ${theme.spacing(-3.313)})`,
        transition: '300ms all',
        '& .icon-box': {
          width: theme.spacing(1.25),
          height: theme.spacing(1.25),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        '&:active': {
          transform: 'scale(0.95)'
        }
      },
      '& .amount-input-label': {
        fontSize: theme.spacing(1)
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(0.25)
      }
    }
  }
})
