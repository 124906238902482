import React, { useEffect, useState } from 'react'
// import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import CustomDialog from 'components/common-ui/CustomDialog/CustomDialog'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'
// import { languagesMenuItem } from 'utils/constant'
import { useDispatch, useSelector } from 'react-redux'
// import { setSelectedLanguage } from 'redux-thunk/redux/slices/language.slice'
import { AffiliateModalWrapper } from './style'
import { becomeAffiliate, getAffiliateData } from 'redux-thunk/thunk/user.thunk'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { SpinnerSVG } from 'assets/icons/index'

const AffiliateModal = ({ dialogOpen, handleClose }) => {
  // const theme = useTheme()
  const { t } = useTranslation()
  const {
    // [LOADER_HANDLER_TYPES.content]: initialLoading,
    [LOADER_HANDLER_TYPES.submit]: submitLoading
  } = useSelector(
    (state) => state.loader
  )
  const [loading, setLoading] = useState(false)
  const { isAuthenticated } = useSelector((state) => state?.gameSetting)

  const { affiliateData } = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const becomeAffiliateHandler = () => {
    dispatch(becomeAffiliate({ loader: LOADER_HANDLER_TYPES.submit }))
  }

  const [maxWidth] = React.useState('customModal_xm')

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAffiliateData({ loader: LOADER_HANDLER_TYPES.content, setLoading }))
      setLoading(true)
    }
  }, [])

  return (
    <>
      <CustomDialog
        maxWidth={maxWidth}
        // fullScreen={fullScreen}
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        aria-labelledby='customized-dialog-title'
      >

        <AffiliateModalWrapper>

          <Box className='dialog-content-wrap'>
            <Box className='dialog-content-box'>
              <Box className='dialog-card-container'>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography className='affiliate-description'>
                    {t('affiliateDescription')}
                  </Typography>

                  {isAuthenticated && (
                    loading
                      ? <SpinnerSVG style={{ fill: 'white' }} />
                      : (
                        <>
                          {!['USER_IS_NOT_AFFILIATE'].includes(affiliateData?.status) && (
                            <Typography className='affiliate-status'>
                              <span className='success'>{t('affiliateStatus')}</span> &nbsp;
                              {t('currentStatus')} &nbsp;
                              <span className={affiliateData?.status?.toLowerCase()}>{affiliateData?.status === 'RE_REQUEST' ? 'RE-Requested' : affiliateData?.status}</span>
                              <br />
                              {['REJECTED', 'RE_REQUEST'].includes(affiliateData?.status) && <span>Reason: {affiliateData?.history[affiliateData?.history.length - 1]?.reason}</span>}
                              <br />
                            </Typography>
                          )}

                          {['USER_IS_NOT_AFFILIATE', 'RE_REQUEST'].includes(affiliateData?.status) && <CustomPrimaryButton disabled={submitLoading} onClick={becomeAffiliateHandler}>{t('affiliateButtonText')} {submitLoading && <SpinnerSVG style={{ fill: 'white' }} />}</CustomPrimaryButton>}
                        </>
                        )
                  )}
                </Box>
              </Box>

            </Box>
          </Box>

          <Box className='modal-heading-box'>
            <Typography variant='h1'>{t('affiliateTitle')} </Typography>
          </Box>

        </AffiliateModalWrapper>
      </CustomDialog>
    </>
  )
}

export default React.memo(AffiliateModal)
