import { Box, styled } from '@mui/material'

export const JackpotWrapper = styled(Box)(({ theme }) => {
  return {
    // background: 'url("/assets/images/stock-images/jackpot-bg.jpeg")',
    width: '80%',
    height: '20%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    '& .content-wrap': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .pool-header': {
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(3),
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.typographyText.text_02
    }
  }
})
