import { Box, styled } from '@mui/material'

export const VoucherBonusesWrapper = styled(Box)(({ theme }) => {
  return {
    '& .form-btn-box': {
      marginTop: theme.spacing(0.5),
      '& .MuiButtonBase-root': {
        width: '100%'
      }
    }
  }
})
