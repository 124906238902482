import React, { useEffect, useState } from 'react'
import { JackpotWrapper } from './style'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getJackpotPrice } from 'redux-thunk/thunk/game.thunk'
import SectionHeading from 'components/common-ui/SectionHeading/SectionHeading'
import { useTranslation } from 'react-i18next'

const Jackpot = () => {
  const dispatch = useDispatch()
  const { jackpotPrice } = useSelector(state => state.games)
  const { t } = useTranslation()
  // Function to format ERU like 111,111,11.00
  const formatNumberWithCommas = (number) => {
    if (isNaN(number) || typeof number === 'undefined') {
      return ''
    }
    return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const [formattedJackpotPrice, setFormattedJackpotPrice] = useState()
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getJackpotPrice())
    }, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!formattedJackpotPrice) {
      dispatch(getJackpotPrice())
    }
    const incrementInterval = setInterval(() => {
      setFormattedJackpotPrice(prevPrice => {
        if (typeof prevPrice === 'number' && prevPrice <= jackpotPrice?.finalValue) {
          return prevPrice + 0.01
        }
        return prevPrice
      })
    }, 1000)
    return () => {
      clearInterval(incrementInterval)
    }
  }, [dispatch, formattedJackpotPrice])

  useEffect(() => {
    if (jackpotPrice?.currentValue !== undefined) {
      setFormattedJackpotPrice(jackpotPrice.currentValue)
    }
  }, [jackpotPrice])

  return (
    <JackpotWrapper>
      <Box className='content-wrap'>
        <SectionHeading headingNameNeon={t('poolPrize')} id='gameslider1' />
        <SectionHeading headingNameNeon={'€' + formatNumberWithCommas(formattedJackpotPrice)} id='gameslider2' />
      </Box>
    </JackpotWrapper>
  )
}

export default Jackpot
