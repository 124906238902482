import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { VoucherBonusesWrapper } from './style'
import { CustomTextField } from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { availBonus } from 'redux-thunk/thunk/bonus.thunk'
import { SpinnerSVG } from 'assets/icons/index'

const VoucherBonuses = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [bonusCode, setBonusCode] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState()
  const dispatch = useDispatch()

  const handleApply = () => {
    setLoading(true)
    const handleSuccess = (msg) => {
      setLoading(false)
      setSuccess(msg)
      setTimeout(() => {
        setSuccess()
        setBonusCode('')
      }, [3000])
    }
    dispatch(availBonus({ bonusCode, setLoading, handleSuccess }))
  }

  return (
    <>
      <VoucherBonusesWrapper theme={theme} className='content-wrapper'>
        <Box component='form' noValidate>
          <Grid container spacing={{ md: 1.25, xs: 0.75 }}>
            <Grid item xs={12} lg={12}>
              <CustomTextField fullWidth type='text' placeholder={t('enterCode')} value={bonusCode} onChange={(e) => setBonusCode(e.target.value)} />
            </Grid>

            <Grid item xs={12} lg={12}>
              <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                <Grid item xs={12} lg={4}>
                  {success && <Typography variant='h5'>{success}</Typography>}
                  <Box className='form-btn-box'>
                    <CustomPrimaryButton fullWidth onClick={handleApply} disabled={!bonusCode.length || success?.length}>
                      {isLoading ? <SpinnerSVG style={{ fill: 'white' }} /> : t('apply')}
                    </CustomPrimaryButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VoucherBonusesWrapper>
    </>
  )
}

export default React.memo(VoucherBonuses)
