import { Box, styled } from '@mui/material'

export const MainLoaderWrapper = styled(Box)(({ theme }) => {
  return {
    height: theme.spacing(30),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .loader-box': {
      width: '50%',
      minWidth: theme.spacing(10),
      maxWidth: theme.spacing(15),
      height: '50%',
      minHeight: theme.spacing(10),
      maxHeight: theme.spacing(15)
    }
  }
})
