import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Typography, Pagination, PaginationItem } from '@mui/material'
import CustomTable from 'components/common-ui/CustomTable/index'
import { ArrowLeftIcon, ArrowRightIcon, SpinnerSVG } from 'assets/icons/index'
import { useDispatch, useSelector } from 'react-redux'
import { getTournamentsHistory } from 'redux-thunk/thunk/tournament.thunk'
import { formatDateDMY } from 'utils/dateFormatter'
import { TournamentsHistoryWrapper } from './style'
import { useTranslation } from 'react-i18next'

const TournamentsHistory = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { tournamentHistory, loadingTournaments } = useSelector((state) => state.tournaments)
  const langCode = useSelector(state => state.language?.selectedLanguage?.code?.toUpperCase())
  const [historyData, setHistoryData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const itemsPerPage = 5
  const { t } = useTranslation()
  useEffect(() => {
    const params = {
      pageNo: 1,
      limit: itemsPerPage
    }
    dispatch(getTournamentsHistory(params))
  }, [])

  useEffect(() => {
    const params = {
      pageNo,
      limit: itemsPerPage
    }
    dispatch(getTournamentsHistory(params))
  }, [pageNo])

  useEffect(() => {
    const myData = tournamentHistory?.tournaments?.map((entry, index) => {
      return ({
        '#': <Typography textAlign='left'>{index + 1}</Typography>,
        roundId: <Typography textAlign='left'>{entry?.touramentRoundId}</Typography>,
        name: <Typography textAlign='left'>{entry?.tournamentName[langCode] || entry.tournamentName.EN}</Typography>,
        wagerAmount: <Typography textAlign='left'>{entry?.amount?.toFixed(2)}</Typography>,
        joinedAt: <Typography textAlign='left'>{formatDateDMY(entry?.joinedAt)}</Typography>,
        winnings: <Typography textAlign='left'>{entry?.winSpins || '-'}</Typography>
      })
    })
    setHistoryData(myData)
  }, [tournamentHistory])

  const columns = [
    {
      id: '#',
      label: <Typography textAlign='left'>#</Typography>
    },
    {
      id: 'roundId',
      label: <Typography textAlign='left'>{t('roundId')}</Typography>
    },
    {
      id: 'name',
      label: <Typography textAlign='left'>{t('name')}</Typography>
    },
    {
      id: 'wagerAmount',
      label: <Typography textAlign='left'>{t('wageringAmount')}</Typography>
    },
    {
      id: 'joinedAt',
      label: <Typography textAlign='left'>{t('joinedOn')}</Typography>
    },
    {
      id: 'winnings',
      label: <Typography textAlign='left'>{t('spinsWon')}</Typography>
    }
  ]

  return (
    <>
      <TournamentsHistoryWrapper theme={theme} className='content-wrapper'>
        {loadingTournaments
          ? <div className='spinner-wrap'><SpinnerSVG style={{ fill: 'white' }} /></div>
          : historyData?.length
            ? (
              <>
                <CustomTable columns={columns} data={historyData} />
                <Pagination
                  count={(tournamentHistory?.count / itemsPerPage) < 1 ? 1 : Math.ceil(tournamentHistory?.count / itemsPerPage)}
                  onChange={(e, value) => setPageNo(value)}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                      {...item}
                    />
                  )}
                />
              </>)
            : <Typography variant='body1'>{t('tournamentHistoryDataNotAvailable')}</Typography>}

      </TournamentsHistoryWrapper>
    </>
  )
}

export default React.memo(TournamentsHistory)
