import React, { Suspense } from 'react'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import CustomRoutes from './routes'
import MainLoader from 'components/common-ui/MainLoader/index'

const Routes = ({ toggleTheme }) => (
  <Router>
    <Suspense
      fallback={<MainLoader />}
    >
      <CustomRoutes toggleTheme={toggleTheme} />
    </Suspense>
  </Router>
)

export default Routes
