import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSideNav: false,
  showChat: false,
  isChatCollapse: true,
  showSearchPopup: false
}

const {
  actions: {
    setShowSideNav,
    setChatCollapse,
    setShowChat,
    setShowSearchPopup
  },
  reducer
} = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setShowSideNav: (state, { payload }) => ({
      ...state,
      showSideNav: payload
    }),
    setChatCollapse: (state, { payload }) => ({
      ...state,
      isChatCollapse: payload
    }),
    setShowChat: (state, { payload }) => ({
      ...state,
      showChat: payload
    }),
    setShowSearchPopup: (state, { payload }) => ({
      ...state,
      showSearchPopup: payload
    })
  }
})

export default reducer
export {
  setShowSideNav,
  setChatCollapse,
  setShowChat,
  setShowSearchPopup
}
