import { openErrorToaster } from 'helpers/toaster.helpers'
import { useState, useEffect } from 'react'
import { io } from 'socket.io-client'
// eslint-disable-next-line import/no-extraneous-dependencies
// import msgPackParser from 'socket.io-msgpack-parser'

// const MAX_RETRY = 1000

const useWebSocket = (url, eventName, handleData, token) => {
  // const [retry, setRetry] = useState(0)
  const [socket, setSocket] = useState(null)
  const [isConnected, setIsConnected] = useState(false)
  const [error, setError] = useState('')

  // Event handler for successful connection
  const handleConnected = () => {
    console.log(`${eventName} connected`)
    setIsConnected(true)
  }

  // Event handler for socket errors
  const handleError = (err) => {
    setError(err || `Failed to connect socket on ${url}`)
  }

  const disconnectSocket = () => {
    if (socket) {
      socket.close()
    }
  }

  const connectSocket = () => {
    disconnectSocket()
    if (socket) {
      socket.connect()
    }
  }

  // Event handler for socket closure
  const handleDisconnect = (reason) => {
    console.log(`${eventName} disconnected`)
    setIsConnected(false)
    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      connectSocket()
    }
    // else the socket will automatically try to reconnect
  }

  const startConnection = () => {
    try {
      const newSocket = io(url, {
        path: '/api/socket',
        extraHeaders: token ? { auth: token } : {}
      })

      newSocket.on('connect', handleConnected)
      newSocket.on(eventName, handleData)
      newSocket.on('connect_error', handleError)
      newSocket.on('disconnect', handleDisconnect)

      setSocket((prev) => {
        if (prev) {
          prev.close()
        }
        return newSocket
      })

      window.privateSocket = newSocket // If need to use at other places of project
    } catch (er) {
      openErrorToaster({
        message: `Unable to connect ${url}, ${er?.message}`
      })
    }
  }

  useEffect(() => {
    // Create a new WebSocket connection
    // disconnectSocket()
    // startConnection()

    // Cleanup function to close the socket when the component unmounts
    return () => {
      disconnectSocket()
    }
    //   }, [url, retry])
  }, [url, token]) // Reconnect when the URL changes

  // Function to send a message through the socket
  const sendMessage = (message) => {
    if (isConnected) {
      socket.send(JSON.stringify(message))
    } else {
      setError('Socket not connected. Message not sent:', message)
    }
  }

  return { socket, isConnected, sendMessage, error, connectSocket, disconnectSocket, startConnection }
}

export default useWebSocket
