import React from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Typography, useMediaQuery
} from '@mui/material'
import { HeadingGameCardsWrapper } from './style'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'

const HeadingGameCards = ({ headingName, headingImg, handleSeeAllGames, isFiltered, handleBack, isSearched, showRandomGames, hideViewBtn }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width:720px)')

  return (
    <HeadingGameCardsWrapper theme={theme}>
      <Box className='heading-box'>
        <img src={headingImg} alt='Coin Img' height='3.75rem' width='3.75rem' loading='lazy' />
        <Typography variant='h2'>{headingName}</Typography>
      </Box>
      <Box className='btn-box'>
        {!hideViewBtn &&
          <CustomPrimaryButton onClick={isFiltered ? handleBack : isSearched ? handleBack : showRandomGames ? handleBack : handleSeeAllGames}>
            {isFiltered ? (t('back')) : isSearched ? (t('back')) : showRandomGames ? (t('back')) : isMobile ? (t('viewAll')) : (t('viewAllGames'))}
          </CustomPrimaryButton>}
      </Box>
    </HeadingGameCardsWrapper>
  )
}
export default HeadingGameCards
