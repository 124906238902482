import React, { Suspense, useEffect } from 'react'

import { CssBaseline, ThemeProvider } from '@mui/material'
import MainLoader from 'components/common-ui/MainLoader/index'
import { useDispatch, useSelector } from 'react-redux'
import store from 'redux-thunk/store'
import Routes from 'routes/index'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { MainLayoutWrapper, MatrixLayoutWrapper } from 'style'
import { darkTheme, lightTheme } from 'theme/index'
import { setIsDarkMode } from 'redux-thunk/redux/slices/gameSetting.slice'

const App = () => {
  const { isDarkMode } = useSelector((state) => state.gameSetting)
  const languageCode = useSelector((state) => state?.language?.selectedLanguage?.code)
  const dispatch = useDispatch()
  useEffect(() => {
    Promise.all(
      Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve
            })
        )
    ).then(() => {
      // document.querySelector('.loading-page').style.display = 'none'
    })
  }, [])

  // DARK/LIGHT THEME SWITCHING
  // Set initially to dark mode
  const toggleTheme = () => {
    dispatch(setIsDarkMode((!isDarkMode)))
  }
  const theme = isDarkMode ? darkTheme : lightTheme
  if (['uk', 'ru'].includes(languageCode)) { theme.typography.fontFamily = 'ChunkfiveEx' } else if (['pl'].includes(languageCode)) { theme.typography.fontFamily = 'Biko' } else theme.typography.fontFamily = 'Heavitas'

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense
        fallback={<MainLoader />}
      >
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
        <MainLayoutWrapper component='main'>
          <MatrixLayoutWrapper>
            <Routes toggleTheme={toggleTheme} />
          </MatrixLayoutWrapper>
        </MainLayoutWrapper>
      </Suspense>
    </ThemeProvider>
  )
}

export { store }

export default App
