import { Box, styled } from '@mui/material'

export const FooterWrapper = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    backgroundColor: theme.palette.footer.main,
    padding: theme.spacing(2.5, 1.5, 2.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .footer-wrap': {
      width: '68.5vw',
      maxWidth: theme.spacing(85.25),
      padding: theme.spacing(0, 0.75, 0),
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .footer-grid-wrap': {
        width: '100%',
        maxWidth: theme.spacing(81),
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: '1fr',
        gap: theme.spacing(5.75),
        '& .footer-links-container': {
          gridColumnStart: '1',
          gridColumnEnd: '2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          '& .footer-links-box': {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.625),
            '& .link-item': {
              width: 'fit-content',
              fontFamily: 'Poppins, sans-serif',
              color: theme.palette.typographyText.textDark,
              fontSize: theme.spacing(1.125),
              fontWeight: '600',
              lineHeight: 'normal',
              textDecoration: 'none',
              cursor: 'pointer',
              transition: '300ms all',
              '&:hover': {
                color: theme.palette.typographyText.textActive
              }
            }
          },
          '& .img-box': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: theme.spacing(1.5),
            gap: theme.spacing(1),
            '& img': {
              width: 'auto',
              maxWidth: theme.spacing(3.5),
              height: '100%',
              maxHeight: theme.spacing(3.5)
            }
          }
        },
        '& .footer-logos-container': {
          gridColumnStart: '2',
          gridColumnEnd: '6',
          '& .payment-options-box': {
            padding: theme.spacing(0, 2),
            marginBottom: theme.spacing(5.75),
            '& .title-text': {
              fontFamily: 'Poppins, sans-serif',
              color: theme.palette.typographyText.textDark,
              fontSize: theme.spacing(1.125),
              fontWeight: '600',
              lineHeight: 'normal',
              marginBottom: theme.spacing(1.438)
            },
            '& .logos-box': {
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: theme.spacing(2.25),
              '& img': {
                width: 'auto',
                maxWidth: theme.spacing(7.5),
                height: 'auto',
                maxHeight: theme.spacing(2)
              }
            }
          },
          '& .providers-box': {
            backgroundColor: theme.palette.footer.light,
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(2),
            '& .logos-box': {
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: theme.spacing(2.25),
              '& img': {
                width: 'auto',
                maxWidth: theme.spacing(7.5),
                height: 'auto',
                maxHeight: theme.spacing(2)
              }
            }
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .footer-wrap': {
        width: '100%',
        padding: theme.spacing(0),
        '& .footer-grid-wrap': {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.875),
          '& .footer-links-container': {
            '& .footer-links-box': {
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: theme.spacing(0.5)
            }
          },
          '& .footer-logos-container': {
            '& .payment-options-box': {
              padding: theme.spacing(0),
              marginBottom: theme.spacing(1.875),
              '& .logos-box': {
                gap: theme.spacing(1),
                '& img': {
                  width: 'auto',
                  maxWidth: theme.spacing(5.625),
                  height: 'auto',
                  maxHeight: theme.spacing(1.5)
                }
              }
            },
            '& .providers-box': {
              padding: theme.spacing(1),
              '& .logos-box': {
                gap: theme.spacing(1),
                '& img': {
                  width: 'auto',
                  maxWidth: theme.spacing(5.625),
                  height: 'auto',
                  maxHeight: theme.spacing(1.5)
                }
              }
            }
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .footer-wrap': {
        '& .footer-grid-wrap': {
          '& .footer-links-container': {
            '& .footer-links-box': {
              '& .link-item': {
                fontSize: theme.spacing(0.75)
              }
            }
          },
          '& .footer-logos-container': {
            '& .payment-options-box': {
              '& .title-text': {
                fontSize: theme.spacing(0.75)
              },
              '& .logos-box': {
                '& img': {
                  width: 'auto',
                  maxWidth: theme.spacing(3.75),
                  height: 'auto',
                  maxHeight: theme.spacing(1)
                }
              }
            },
            '& .providers-box': {
              '& .logos-box': {
                '& img': {
                  width: 'auto',
                  maxWidth: theme.spacing(3.75),
                  height: 'auto',
                  maxHeight: theme.spacing(1)
                }
              }
            }
          }
        }
      }
    },
    '& .footer-text': {
      textAlign: 'center',
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.typographyText.textDark,
      fontSize: theme.spacing(1.125),
      fontWeight: '600',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(0.75)
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(0.5)
      }
    }
  }
})
