import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { LanguageSwitchWrapper } from './style'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import LanguageModal from './LanguageModal'
import { useSelector } from 'react-redux'

const LanguageSwitch = () => {
  const theme = useTheme()

  const [changeLanguage, setChangeLanguage] = useState(false)
  const { selectedLanguage } = useSelector((state) => state.language)

  return (
    <>
      <LanguageSwitchWrapper theme={theme}>
        <Box className='language-switch-box'>
          <CustomMainButton className='language-btn' onClick={() => { setChangeLanguage(true) }}>
            <Box className='flag-box'>
              <Box className='img-box'>
                <img
                  src={selectedLanguage?.img}
                  alt='flag'
                  loading='lazy'
                />
              </Box>
            </Box>
            {selectedLanguage?.title}
          </CustomMainButton>
        </Box>
      </LanguageSwitchWrapper>

      <LanguageModal dialogOpen={changeLanguage} handleClose={() => setChangeLanguage(false)} />
    </>
  )
}

export default React.memo(LanguageSwitch)
