import { Box, styled } from '@mui/material'

export const MainWrapper = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    maxWidth: theme.layout.maxWidth,
    padding: theme.spacing(8, 1.5, 2),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5.5, 0.75, 2)
    }
  }
})
