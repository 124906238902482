import React, { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Divider,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material'
import { MyAccountWrapper } from './style'
import MyAccountDialog from 'components/common-ui/MyAccountDialog/MyAccountDialog'
import EditProfile from './EditProfile/index'
import ChangePassword from './ChangePassword/index'
import TransactionHistoryCash from './Cash/TransactionHistoryCash/index'
import BetsHistory from './BetsHistory/index'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MobileMyAccount from './MobileMyAccount/MobileMyAccount'
import { setActiveTabIndex, setLoginData, setUserModal } from 'redux-thunk/redux/slices/auth.slice'
import TournamentsHistory from './TournamentsHistory/index'
import { setShowSideNav } from 'redux-thunk/redux/slices/settings.slice'
import { userLogout } from 'redux-thunk/thunk/auth.thunk'
import { setAuthStatus } from 'redux-thunk/redux/slices/gameSetting.slice'
import { setUserData } from 'redux-thunk/redux/slices/user.slice'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Box
      className='tabpanel-wrap'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box className='tabpanel-box'>{children}</Box>}
    </Box>
  )
}

const MyAccount = ({ dialogOpen, handleClose }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { activeTabIndex } = useSelector(state => state.auth)
  const [value, setValue] = React.useState(activeTabIndex)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setValue(activeTabIndex)
  }, [activeTabIndex])
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [maxWidth] = React.useState('customModal_lg')

  const handleLogout = () => {
    dispatch(setAuthStatus(false))
    dispatch(setLoginData(null))
    dispatch(setUserData(null))
    dispatch(userLogout())
    dispatch(setUserModal(''))
    setValue(0)
    dispatch(setActiveTabIndex(0))
    dispatch(setShowSideNav(false))
    handleClose()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate('/')
  }

  const handleCloseModal = () => {
    handleClose()
    setValue(0)
    dispatch(setActiveTabIndex(0))
  }

  const OpenWithdrawOrDeposit = (value) => {
    dispatch(setUserModal(value))
    setValue(0)
  }

  return (
    <>
      <MyAccountDialog
        maxWidth={maxWidth}
        // fullScreen={fullScreen}
        dialogOpen={dialogOpen === 'account'}
        handleDialogClose={handleCloseModal}
        aria-labelledby='customized-dialog-title'
      >
        <MyAccountWrapper theme={theme}>
          {isDesktop
            ? (
              <>
                <Box className='tabs-nav-box'>
                  <Typography variant='h4' className='title-text'>
                    {t('myAccount')}
                  </Typography>
                  <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={value}
                    onChange={handleChange}
                    aria-label='Vertical tabs example'
                  >
                    <Tab label={t('editProfile')} />{' '}
                    {/* Use index={0} in the TabPanel to show this Tab */}
                    <Tab label={t('changePassword')} />{' '}
                    {/* Use index={1} in the TabPanel to show this Tab */}
                    {/* <Divider /> */}
                    {/* <Typography variant='subtitle1' className='heading-text'>
                      {t('bonuses')}
                    </Typography>
                    <Tab label='Offers' />{' '} */}
                    {/* Use index={4} in the TabPanel to show this Tab */}
                    {/* <Tab label={t('active')} />{' '} */}
                    {/* Use index={5} in the TabPanel to show this Tab */}
                    {/* <Tab label={t('history')} />{' '} */}
                    {/* Use index={6} in the TabPanel to show this Tab */}
                    {/* <Tab label={t('voucher')} />{' '} */}
                    {/* Use index={7} in the TabPanel to show this Tab */}

                    <Divider />
                    <Typography variant='subtitle1' className='heading-text'>
                      {t('cash')}
                    </Typography>
                    <Tab
                      label={t('deposit')} onClick={() => OpenWithdrawOrDeposit('deposit')}
                    />{' '}
                    {/* Use index={10} in the TabPanel to show this Tab */}
                    <Tab label={t('withdrawal')} onClick={() => OpenWithdrawOrDeposit('withdraw')} />{' '}
                    {/* Use index={11} in the TabPanel to show this Tab */}
                    <Tab label={t('transactionHistory')} />{' '}
                    {/* Use index={12} in the TabPanel to show this Tab */}

                    <Divider />
                    <Typography variant='subtitle1' className='heading-text'>
                      {t('gamlings')}
                    </Typography>
                    <Tab label={t('tabsBetHistory')} />{' '}
                    {/* Use index={15} in the TabPanel to show this Tab */}
                    <Divider />
                    <Typography variant='subtitle1' className='heading-text'>
                      {t('tournaments')}
                    </Typography>
                    <Tab label={t('tournamentsHistory')} />{' '}
                    <Divider />
                    <Tab label={t('accountMenuLogout')} onClick={handleLogout} />{' '}
                  </Tabs>
                  <img
                    src='/assets/images/stock-images/my-account-cash-machine.png'
                    className='cash-machine-img'
                    alt='Cash Machine' loading='lazy'
                  />
                </Box>

                {/* Edit Profile TabPanel Start */}
                <TabPanel value={value} index={0}>
                  <Typography variant='h4' className='content-title-text'>{t('editProfile')}</Typography>
                  <EditProfile />
                </TabPanel>

                {/* Change Password TabPanel Start */}
                <TabPanel value={value} index={1}>
                  <Typography variant='h4' className='content-title-text'>{t('changePassword')}</Typography>
                  <ChangePassword />
                </TabPanel>

                {/* Offers TabPanel Start */}
                {/* <TabPanel value={value} index={4}>
                  <Typography variant='h4' className='content-title-text'>{t('offers')}</Typography>
                  <OffersBonuses />
                </TabPanel> */}

                {/* Active TabPanel Start */}
                {/* <TabPanel value={value} index={5}>
                  <Typography variant='h4' className='content-title-text'>{t('active')}</Typography>
                  <ActiveBonuses />
                </TabPanel> */}

                {/* History TabPanel Start */}
                {/* <TabPanel value={value} index={6}>
                  <Typography variant='h4' className='content-title-text'>{t('history')}</Typography>
                  <HistoryBonuses />
                </TabPanel> */}

                {/* Voucher TabPanel Start */}
                {/* <TabPanel value={value} index={7}>
                  <Typography variant='h4' className='content-title-text'>{t('voucher')}</Typography>
                  <VoucherBonuses />
                </TabPanel> */}

                {/* Deposit TabPanel Start */}
                {/* <TabPanel value={value} index={10}>
            <Typography variant='h4' className='content-title-text'>Deposit</Typography>
            <DepositCash />
          </TabPanel> */}

                {/* Withdrawal TabPanel Start */}
                {/* <TabPanel value={value} index={11}>
            <Typography variant='h4' className='content-title-text'>Withdrawal</Typography>
            <WithdrawalCash />
          </TabPanel> */}

                {/* Transaction History TabPanel Start */}
                <TabPanel value={value} index={6}>
                  <Typography variant='h4' className='content-title-text'>{t('transactionHistory')}</Typography>
                  <TransactionHistoryCash />
                </TabPanel>

                {/* Bets History TabPanel Start */}
                <TabPanel value={value} index={9}>
                  <Typography variant='h4' className='content-title-text'>{t('tabsBetHistory')} </Typography>
                  <BetsHistory />
                </TabPanel>

                {/* Tournament History TabPanel Start */}
                <TabPanel value={value} index={12}>
                  <Typography variant='h4' className='content-title-text'>{t('tournamentsHistory')} </Typography>
                  <TournamentsHistory />
                </TabPanel>
              </>)
            : <MobileMyAccount handleLogout={handleLogout} />}
        </MyAccountWrapper>
      </MyAccountDialog>
    </>
  )
}

export default React.memo(MyAccount)
