import { Box, styled } from '@mui/material'

export const ProviderModalWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    '& .provider-content-box': {
      height: '100%',
      padding: theme.spacing(4, 1.5, 0)
    },
    '& .modal-heading-box': {
      width: theme.spacing(23.75),
      height: theme.spacing(4),
      background: theme.palette.success.light,
      maskImage: 'url("/assets/images/stock-images/form-status-bg.png")',
      maskRepeat: 'no-repeat',
      maskSize: '100% 100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      zIndex: '1',
      position: 'absolute',
      top: theme.spacing(3.25),
      left: '50%',
      transform: 'translateX(-50%)',
      '& .MuiTypography-root': {
        fontFamily: 'Poppins, sans-serif',
        color: theme.palette.typographyText.textWhite,
        fill: theme.palette.typographyText.textWhite,
        fontSize: theme.spacing(1.5),
        fontWeight: '600',
        textTransform: 'capitalize'
      }
    },
    '& .MuiButtonBase-root.provider-btn': {
      filter: `drop-shadow(0 0 5px ${theme.palette.darkShade.dark_05})`,
      paddingLeft: theme.spacing(4.5),
      '& .flag-box': {
        width: theme.spacing(4.25),
        height: theme.spacing(4.25),
        background: theme.palette.gradientColor.buttonImgBg,
        borderRadius: theme.spacing(3.875),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        position: 'absolute',
        top: '50%',
        left: theme.spacing(0),
        transform: 'translateY(-50%)',
        '& .img-box': {
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center'
          }
        }
      },
      '&.selected-provider': {
        pointerEvents: 'none'
      }
    },
    '& .provider-options-container': {
      height: `calc(100% - ${theme.spacing(7.25)})`,
      background: theme.palette.gradientColor.languageBgBox,
      borderRadius: theme.spacing(2.125),
      padding: theme.spacing(1.5),
      '& .provider-options-box': {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(1.5),
        '&::-webkit-scrollbar': {
          width: '0.25rem',
          height: '0.25rem',
          background: theme.palette.darkShade.dark_03
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.warning.light
        }
      }
    },
    '& .search-wrap, & .MuiFormControl-root': {
      width: '100%',
      maxWidth: theme.spacing(45),
      marginRight: 'auto',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.formInput.dark,
        borderRadius: theme.spacing(0.5),
        '& .MuiInputBase-input': {
          height: theme.spacing(1.188),
          color: theme.palette.formInput.contrastTextMain,
          fontSize: theme.spacing(1),
          fontWeight: '500',
          padding: theme.spacing(1.032, 0.875),
          '&::placeholder': {
            color: theme.palette.formInput.contrastTextWhite,
            fontSize: theme.spacing(1),
            fontWeight: '500',
            opacity: '1'
          }
        },
        '& .MuiInputAdornment-root': {
          '& .MuiIconButton-root': {
            fill: theme.palette.formInput.contrastTextWhite,
            padding: theme.spacing(0.5),
            '& svg': {
              width: theme.spacing(1.5),
              height: theme.spacing(1.5)
            }
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.palette.formInput.borderDark}`
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.formInput.borderMain
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .provider-content-box': {
        padding: theme.spacing(8, 1, 0)
      },
      '& .MuiButtonBase-root.provider-btn': {
        paddingLeft: theme.spacing(2.875),
        '& .flag-box': {
          width: theme.spacing(2.625),
          height: theme.spacing(2.625),
          padding: theme.spacing(0.375)
        }
      },
      '& .provider-options-container': {
        height: `calc(100% - ${theme.spacing(0.063)})`,
        borderRadius: theme.spacing(1.125),
        padding: theme.spacing(1.5, 1),
        '& .provider-options-box': {
          padding: theme.spacing(1)
        }
      },
      '& .modal-heading-box': {
        width: `${theme.spacing(20.75)} !important`,
        height: `${theme.spacing(2.5)} !important`,
        top: `${theme.spacing(4.125)} !important`,
        left: '50% !important',
        transform: 'translateX(-50%) !important',
        '& .MuiTypography-root': {
          fontSize: `${theme.spacing(1)} !important`
        }
      }
    }
  }
})
