import { Box, styled } from '@mui/material'

export const GameGridWrapper = styled(Box)(({ theme }) => {
  return {
    zIndex: '1',
    position: 'relative',
    '& .game-grid-wrap': {
      marginBottom: theme.spacing(4),
      '& .game-grid-container': {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridTemplateRows: '1fr',
        gap: theme.spacing(0.125)
      }
    },
    '& .game-grid-wrap-section': {
      height: theme.spacing(43.875),
      overflow: 'auto'
    },
    '& .game-loader': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.down('md')]: {
      '& .game-grid-wrap': {
        '& .game-grid-container': {
          gridTemplateColumns: 'repeat(4, 1fr)'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .game-grid-wrap': {
        '& .game-grid-container': {
          gridTemplateColumns: 'repeat(3, 1fr)'
        }
      }
    },
    '& .no-game-text': {
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.typographyText.textWhite,
      fontSize: theme.spacing(1.5),
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'uppercase'
    }
  }
})
