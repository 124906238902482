import React from 'react'
// eslint-disable-next-line no-unused-vars
import { Route, Routes, Router } from 'react-router-dom'
import routes from './routesList'
import RouteValidator from './RouteValidator'
import NotFoundComponent from 'components/layout/404/index'
import Header from 'components/layout/Header/index'
import { useSelector } from 'react-redux'
// eslint-disable-next-line no-unused-vars

const CustomRoutes = ({ toggleTheme }) => {
  const { siteData } = useSelector((state) => state.general)

  return (
    <>
      {siteData?.tenantDetail?.maintenance
        ? (
          <></>
          )
        : (
          <>
            <Header toggleTheme={toggleTheme} />
          </>
          )}

      <Routes>
        {routes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            exact
            element={<RouteValidator route={route} />}
          />
        ))}
        <Route element={<NotFoundComponent />} />
      </Routes>
    </>
  )
}

export default CustomRoutes
