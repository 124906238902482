import styled from '@emotion/styled'
import { FormControl, FormLabel, TextField, Select, Autocomplete } from '@mui/material'

// TEXTFIELD START
export const StyledCustomTextField = styled(TextField)(({ theme }) => {
  return {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.formInput.main,
      borderRadius: theme.spacing(0.313),
      '& .MuiInputBase-input': {
        color: theme.palette.formInput.contrastTextWhite,
        fontSize: theme.spacing(1),
        fontWeight: '500',
        padding: theme.spacing(0.938, 0.875),
        '&::placeholder': {
          color: theme.palette.formInput.contrastPlaceholder,
          fontSize: theme.spacing(1),
          fontWeight: '400',
          lineHeight: 'normal',
          opacity: '1'
        }
      },
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          padding: theme.spacing(0.375),
          '& svg': {
            width: theme.spacing(1.75),
            height: theme.spacing(1.75),
            fill: theme.palette.formInput.contrastPlaceholder
          }
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.formInput.transparent
      },
      '&:hover, &.Mui-focused': {
        '& .MuiInputBase-input': {
          '&::placeholder': {
            color: theme.palette.formInput.contrastTextWhite,
            opacity: '1'
          }
        },
        '& .MuiInputAdornment-root': {
          '& .MuiButtonBase-root': {
            '& svg': {
              fill: theme.palette.formInput.contrastTextWhite
            }
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.formInput.borderLight
        }
      }
    }
  }
})

// SELECT START
export const StyledCustomSelect = styled(Select)(({ theme }) => {
  return {
    '&.MuiInputBase-root': {
      backgroundColor: theme.palette.formInput.main,
      borderRadius: theme.spacing(0.313),
      '& .MuiInputBase-input': {
        color: theme.palette.formInput.contrastTextWhite,
        fontSize: theme.spacing(1),
        fontWeight: '500',
        padding: theme.spacing(0.938, 0.875),
        '&::placeholder': {
          color: theme.palette.formInput.contrastPlaceholder,
          fontSize: theme.spacing(1),
          fontWeight: '400',
          lineHeight: 'normal',
          opacity: '1'
        }
      },
      '& svg': {
        width: theme.spacing(1.25),
        height: theme.spacing(1.25),
        fill: theme.palette.formInput.contrastPlaceholder,
        top: `calc(50% - ${theme.spacing(0.625)})`,
        right: theme.spacing(0.625)
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.formInput.transparent
      },
      '&:hover, &.Mui-focused': {
        '& svg': {
          fill: theme.palette.formInput.contrastTextWhite
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.formInput.borderLight
        }
      }
    }
  }
})

// SELECT SEARCH
export const StyledCustomSearchSelect = styled(Autocomplete)(({ theme }) => {
  return {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.formInput.main,
      borderRadius: theme.spacing(0.313),
      padding: theme.spacing(0),
      '& .MuiInputBase-input': {
        color: theme.palette.formInput.contrastTextWhite,
        fontSize: theme.spacing(1),
        fontWeight: '500',
        padding: theme.spacing(0.938, 0.875),
        '&::placeholder': {
          color: theme.palette.formInput.contrastPlaceholder,
          fontSize: theme.spacing(1),
          fontWeight: '400',
          lineHeight: 'normal',
          opacity: '1'
        }
      },
      '& svg': {
        width: theme.spacing(1.25),
        height: theme.spacing(1.25),
        fill: theme.palette.formInput.contrastPlaceholder,
        top: `calc(50% - ${theme.spacing(0.625)})`,
        right: theme.spacing(0.625)
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.formInput.transparent
      },
      '&:hover, &.Mui-focused': {
        '& svg': {
          fill: theme.palette.formInput.contrastTextWhite
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.formInput.borderLight
        }
      }
    }
  }
})

// PASSWORD START
export const StyledCustomPasswordInput = styled(FormControl)(({ theme }) => {
  return {
    '&.MuiFormControl-root': {
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.formInput.main,
        borderRadius: theme.spacing(0.313),
        '& .MuiInputBase-input': {
          color: theme.palette.formInput.contrastTextWhite,
          fontSize: theme.spacing(1),
          fontWeight: '500',
          padding: theme.spacing(0.938, 0.875),
          '&::placeholder': {
            color: theme.palette.formInput.contrastPlaceholder,
            fontSize: theme.spacing(1),
            fontWeight: '400',
            lineHeight: 'normal',
            opacity: '1'
          }
        },
        '& .MuiInputAdornment-root': {
          '& .MuiButtonBase-root': {
            padding: theme.spacing(0.375),
            '& svg': {
              width: theme.spacing(1.75),
              height: theme.spacing(1.75),
              fill: theme.palette.formInput.contrastPlaceholder
            }
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.formInput.transparent
        },
        '&:hover, &.Mui-focused': {
          '& .MuiInputBase-input': {
            '&::placeholder': {
              color: theme.palette.formInput.contrastTextWhite,
              opacity: '1'
            }
          },
          '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
              '& svg': {
                fill: theme.palette.formInput.contrastTextWhite
              }
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.formInput.borderLight
          }
        }
      }
    }
  }
})

// FORMLABEL START
export const StyledCustomFormLabel = styled(FormLabel)(({ theme }) => {
  return {
    color: theme.palette.formInput.contrastTextMain,
    fontSize: theme.spacing(1),
    fontWeight: '500',
    lineHeight: 'normal',
    display: 'block',
    marginBottom: theme.spacing(0.625)
  }
})
