import React, { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { ActiveBonusesWrapper } from './style'
// import BonusCard from '../BonusCard/index'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveBonuses } from 'redux-thunk/thunk/bonus.thunk'
import { useTranslation } from 'react-i18next'
import { SpinnerSVG } from 'assets/icons/index'
import BonusCard from 'pages/Bonus/BonusCard'

// const offersData = {
//   offersArr: [
//     {
//       offerImg: '/assets/images/stock-images/offers/offer-1.png',
//       offerTitle: 'Welcome bonus and 100 free spins + secret daily bonuses',
//       offerText: 'Make your Tuesday mornings more fun by placing a deposit between 9:00 and 10:00 UTC and receiving 15 free spins on Net Ent slot machines of your liking.',
//       offerInfo: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
//     },
//     {
//       offerImg: '/assets/images/stock-images/offers/offer-2.png',
//       offerTitle: 'Welcome bonus and 100 free spins + secret daily bonuses',
//       offerText: 'Make your Tuesday mornings more fun by placing a deposit between 9:00 and 10:00 UTC and receiving 15 free spins on Net Ent slot machines of your liking.',
//       offerInfo: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
//     }
//   ]
// }

const ActiveBonuses = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const activeBonus = useSelector(state => state?.bonus?.activeBonus?.userBonus)
  const { bonusLoading } = useSelector(state => state?.bonus)

  useEffect(() => {
    dispatch(getActiveBonuses())
  }, [])

  const activeBonusElement = (
    <ActiveBonusesWrapper theme={theme} className='content-wrapper'>
      <Box className='offer-wrap'>
        {activeBonus?.rows?.length
          ? activeBonus?.rows?.map((data, i) => {
              const termCondition = data?.termCondition && JSON.parse(data?.termCondition)
              const description = data?.bonus?.description && JSON.parse(data?.bonus?.description)
              return (
                <BonusCard
                  key={i}
                  data={data}
                  bonusId={data?.bonusId}
                  bonusTitle={data?.bonus?.bonusTitle}
                  bonusCode={data?.bonus?.bonusCode}
                  description={description}
                  bonusType={data?.bonusType}
                  freeSpinsQty={data?.freeSpinsQty}
                  requiredWager={data?.requiredWagerAmount}
                  currentWagered={data?.currentWageredAmount}
                  validFrom={data?.updatedAt}
                  validTo={data?.expireAt}
                  termCondition={termCondition}
                  bonusImg={data?.bonusImg || '/assets/images/stock-images/offers/active-thumbnail.png'}
                  from='active'
                  gamesArr={data?.games}
                  className='active-bonus'
                />
              )
            })
          : <Typography variant='h3' className='form-title'>{t('noActiveBonusFound')}</Typography>}
      </Box>
    </ActiveBonusesWrapper>
  )
  return (
    <>
      {bonusLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}><SpinnerSVG style={{ fill: 'white' }} /></div> : activeBonusElement}
    </>
  )
}

export default React.memo(ActiveBonuses)
