import { Box, styled } from '@mui/material'

export const DateTimePickerWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiStack-root': {
      padding: theme.spacing(0),
      '& .MuiFormControl-root': {
        width: '100%',
        minWidth: 'unset',
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.formInput.main,
          borderRadius: theme.spacing(0.313),
          '& .MuiInputBase-input': {
            color: theme.palette.formInput.contrastTextWhite,
            fontSize: theme.spacing(1),
            fontWeight: '500',
            padding: theme.spacing(0.938, 0.875),
            '&::placeholder': {
              color: theme.palette.formInput.contrastPlaceholder,
              fontSize: theme.spacing(1),
              fontWeight: '400',
              lineHeight: 'normal',
              opacity: '1'
            }
          },
          '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
              padding: theme.spacing(0.5),
              '& svg': {
                fill: theme.palette.formInput.contrastPlaceholder,
                transition: '0ms all'
              }
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.formInput.transparent
          },
          '&:hover, &.Mui-focused': {
            '& .MuiInputBase-input': {
              '&::placeholder': {
                color: theme.palette.formInput.contrastTextWhite,
                opacity: '1'
              }
            },
            '& .MuiInputAdornment-root': {
              '& .MuiButtonBase-root': {
                '& svg': {
                  fill: theme.palette.formInput.contrastTextWhite
                }
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.formInput.borderLight
            }
          }
        }
      }
    }
  }
})

export const DatePickerWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiStack-root': {
      padding: theme.spacing(0),
      '& .MuiFormControl-root': {
        width: '100%',
        minWidth: 'unset',
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.formInput.main,
          borderRadius: theme.spacing(0.313),
          '& .MuiInputBase-input': {
            color: theme.palette.formInput.contrastTextWhite,
            fontSize: theme.spacing(1),
            fontWeight: '500',
            padding: theme.spacing(0.938, 0.875),
            '&::placeholder': {
              color: theme.palette.formInput.contrastPlaceholder,
              fontSize: theme.spacing(1),
              fontWeight: '400',
              lineHeight: 'normal',
              opacity: '1'
            }
          },
          '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
              padding: theme.spacing(0.5),
              '& svg': {
                fill: theme.palette.formInput.contrastPlaceholder,
                transition: '0ms all'
              }
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.formInput.transparent
          },
          '&:hover, &.Mui-focused': {
            '& .MuiInputBase-input': {
              '&::placeholder': {
                color: theme.palette.formInput.contrastTextWhite,
                opacity: '1'
              }
            },
            '& .MuiInputAdornment-root': {
              '& .MuiButtonBase-root': {
                '& svg': {
                  fill: theme.palette.formInput.contrastTextWhite
                }
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.formInput.borderLight
            }
          }
        }
      }
    }
  }
})

export const TimePickerWrapper = styled(Box)(({ theme }) => {
  return {
    '& .MuiStack-root': {
      padding: theme.spacing(0),
      '& .MuiFormControl-root': {
        width: '100%',
        minWidth: 'unset',
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.formInput.main,
          borderRadius: theme.spacing(0.313),
          '& .MuiInputBase-input': {
            color: theme.palette.formInput.contrastTextWhite,
            fontSize: theme.spacing(1),
            fontWeight: '500',
            padding: theme.spacing(0.938, 0.875),
            '&::placeholder': {
              color: theme.palette.formInput.contrastPlaceholder,
              fontSize: theme.spacing(1),
              fontWeight: '400',
              lineHeight: 'normal',
              opacity: '1'
            }
          },
          '& .MuiInputAdornment-root': {
            '& .MuiButtonBase-root': {
              padding: theme.spacing(0.5),
              '& svg': {
                fill: theme.palette.formInput.contrastPlaceholder,
                transition: '0ms all'
              }
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.formInput.transparent
          },
          '&:hover, &.Mui-focused': {
            '& .MuiInputBase-input': {
              '&::placeholder': {
                color: theme.palette.formInput.contrastTextWhite,
                opacity: '1'
              }
            },
            '& .MuiInputAdornment-root': {
              '& .MuiButtonBase-root': {
                '& svg': {
                  fill: theme.palette.formInput.contrastTextWhite
                }
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.formInput.borderLight
            }
          }
        }
      }
    }
  }
})
