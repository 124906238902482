import { Dialog, styled } from '@mui/material'

export const AlertDialogWrapper = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiBackdrop-root': {
      // backdropFilter: 'blur(4px)'
    },
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: theme.spacing(40),
      height: theme.spacing(23),
      maxHeight: '100%',
      background: 'none',
      overflow: 'hidden',
      '& .dialog-close-btn': {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        zIndex: '2',
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        '& svg': {
          fill: theme.palette.typographyText.textMain,
          filter: `drop-shadow(1px 2px 2px ${theme.palette.darkShade.dark_04})`
        }
      },
      '& .MuiDialogContent-root': {
        width: '100%',
        background: theme.palette.solidColor.solidColor_05,
        maskImage: 'url("/assets/images/stock-images/alert-modal-bg.png")',
        maskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        overflow: 'hidden',
        zIndex: '1',
        padding: theme.spacing(4, 1.5, 2)
      }
    }
  }
})
