export default {
  404: '404',
  vip: 'VIP',
  upcoming: 'A venir',
  finished: 'Fini',
  prizePool: 'Cagnotte',
  joinNow: 'Adhérer maintenant',
  joined: 'Rejoint',
  startsIn: 'Commence dans',
  endsIn: 'Fini dans',
  ended: 'Tournoi terminé',
  started: 'Tournoi commencé',
  seeMore: 'Voir plus',
  winPrizes: 'Gagnez des prix passionnants',
  leaderBoard: 'Classement',
  prize: 'Prix',
  aboutTournament: 'À propos du tournoi',
  congratulationsMessage: 'Bravo! ',
  payments: 'Paiement',
  tournaments: 'Tournois',
  shop: 'Boutique',
  blog: 'Blog',
  referral: 'Référence',
  contacts: 'Contacts',
  retry: 'Recommencez',
  incorrectCredsMessage: "Votre nom d'utilisateur ou votre mot de passe est incorrect. ",
  bonuses: 'Prime',
  depositBonus: 'Bonus de dépôt',
  voucher: 'Bon',
  gamlings: 'Jeux de hasard',
  transactionHistory: 'Historique des transactions',
  offers: 'Des offres',
  editProfile: 'Editer le profil',
  continue: 'Continuer',
  apply: 'Appliquer',
  male: 'Mâle',
  female: 'Femelle',
  register: 'Registre',
  login: 'Se connecter',
  grand: 'Grandiose',
  major: 'Majeur',
  minor: 'Mineure',
  mini: 'Mini',
  crashGameTitle: 'Accident',
  betAmount: 'Montant du pari',
  profit: 'Profit',
  payout: 'Paiement cible',
  chance: 'Chance de gagner',
  betBtn: 'Pari',
  autoCashout: 'Retrait automatique',
  btnHalf: '½',
  btnDouble: '2×',
  btnMax: 'Max.',
  wagered: 'Parié',
  nothingFound: "rien n'a été trouvé",
  lost: 'Perdu',
  won: 'Gagné',
  streak: 'Traînée',
  highestWin: 'Gain le plus élevé',
  promotions: 'Promotions',
  latestPromotions: 'Dernières promotions',
  sponsorships: 'Parrainages',
  highestLost: 'Le plus haut perdu',
  highestBet: 'Pari le plus élevé',
  maxStreak: 'Série maximale',
  maxProfit: 'Bénéfice maximum : ',
  bal: 'Bal : ',
  messagesNoBetHistory: "L'historique de vos paris est vide",
  messagesCopyNotSupported: 'Votre navigateur ne prend pas en charge la copie automatique dans le presse-papiers. Veuillez essayer de copier le code manuellement.',
  messagesCopied: 'Adresse copiée.',
  deposit: 'Dépôt',
  statReset: 'Réinitialiser les statistiques',
  rollOver: 'Rouler sur',
  inputFieldBtnHalf: '½',
  inputFieldBtnDouble: '2×',
  back: 'Dos',
  inputFieldBtnMax: 'maximum',
  buttonTextPlaceBet: 'Parier',
  buttonTextCancelBet: 'Annuler le pari',
  buttonTextEscapeBet: 'Retrait',
  buttonTextStartAutoBet: 'Démarrer le pari automatique',
  buttonTextCancelAutoBet: 'Annuler le pari automatique',
  buttonTextLoading: 'Chargement',
  tabsMyBets: 'Mes paris',
  tabsDeposit: 'Dépôt',
  tabsCashout: 'Retraits',
  tabsAllbets: 'Tous les paris',
  tabsTopbets: 'Meilleurs paris',
  tabsBonus: 'Bonus',
  tabsjackpot: 'Cagnotte',
  tabsBetHistory: 'Historique des paris',
  timerTextCurrentPayout: 'Paiement actuel',
  timerTextStarting: 'À partir de',
  timerTextSec: 's',
  timerTextFlewAway: 'Écrasé',
  timerTextWait: "S'il vous plaît, attendez..",
  timerTextPreparingRound: 'Tour de préparation',
  timerTextLoadingRound: 'Chargement du tour',
  comingSoon: 'À venir',
  labelsProfitOnWin: 'Bénéficiez sur la victoire',
  inputsNoOfBets: 'Nombre de paris',
  inputsAuto: 'Auto',
  inputsHalf: '1/2',
  inputsDouble: '2×',
  inputsStart: 'Commencer',
  inputsCancel: 'Annuler',
  buttonsMin: 'Min.',
  buttonsMax: 'Max.',
  prediction: 'Prédiction',
  luckyNumber: 'Nombre chanceux',
  multiplier: 'Multiplicateur',
  chanceToWin: 'Chance de gagner',
  bets: 'Paris',
  depositBtn: 'Dépôt',
  rollUnder: 'Rouler sous',
  symbolsX: 'X',
  symbolsPercent: '%',
  navBarTitle: 'Histoire',
  navBarMyBets: 'Mes paris',
  navBarAllBets: 'Tous les paris',
  navBarHighRollers: 'Gros joueurs',
  navBarRoundHistory: 'Histoire du tour',
  navBarTotal: 'Paris totaux',
  topHeaderBetId: 'Identifiant du pari',
  topHeaderUser: 'Utilisateur',
  topHeaderPayout: 'Paiement',
  topHeaderAmount: 'Montant',
  topHeaderTime: 'Temps',
  topHeaderProfit: 'Profit',
  topHeaderRoundCrashed: 'Écrasé',
  topHeaderRoundEscape: '@',
  topHeaderRoundRoundHash: 'Hacher',
  topHeaderRoundBet: 'Pari',
  topHeaderRoundProfit: 'Profit',
  topHeaderRoundSignature: 'Signature',
  roundHistoryTitle: 'INFORMATIONS SUR LE JEU',
  roundHistoryDescGame: 'Jeu',
  roundHistoryDescCrashAt: 'Crashé à :',
  roundHistoryDescDate: 'Date :',
  roundHistoryDescBetHeader: 'Pari',
  roundHistoryTableTitlePlayer: 'JOUEUR',
  roundHistoryTableTitleBet: 'PARI',
  roundHistoryTableTitleCashedOut: 'ENCAISSÉ',
  roundHistoryTableTitleProfit: 'PROFIT',
  roundHistoryTableTitleBetId: 'ID DE PARI',
  roundHistoryMessagesNoBetHistory: "L'historique de vos paris est vide",
  placedBetsTabPlaced: 'Pari placé',
  placedBetsTabPrev: 'Pari précédent',
  placedBetsTitleUser: 'Utilisateur',
  placedBetsTitleEscape: 'Multi.x',
  placedBetsTitleBet: 'Pari',
  placedBetsTitleProfit: 'Profit',
  placedBetsTitleCashout: 'Retrait',
  placedBetsNoPlacedBet: 'Aucun pari placé.',
  provablyFairContentCheckBtn: 'Chèque Prouvable Juste',
  provablyFairContentTitle: 'Prouvablement juste',
  provablyFairContentDesc: 'Le résultat de l’avancée à quatre côtés peut s’avérer équitable. ',
  provablyFairContentInputFieldsCrashRateTitle: "Taux d'accidents",
  provablyFairContentInputFieldsCrashRatePlaceHolder: 'Taux de crash sans x',
  provablyFairContentInputFieldsRoundHashTitle: 'Hachage rond',
  provablyFairContentInputFieldsRoundHashPlaceHolder: 'par exemple. ',
  provablyFairContentInputFieldsSubmitBtn: 'Générer une signature',
  provablyFairContentSignature: 'Signature ronde',
  provablyFairContentErrorsCrashRate: 'veuillez saisir le numéro dans le champ Taux de crash',
  resetPasswordTitle: 'réinitialiser le mot de passe',
  resetPasswordNewPasswordTitle: 'nouveau mot de passe',
  resetPasswordNewPasswordErrorsRequired: '*Mot de passe requis.',
  resetPasswordNewPasswordErrorsMinLength: 'Minimum de 8 caractères autorisés.',
  resetPasswordNewPasswordErrorsMaxLength: 'Maximum 16 caractères autorisés.',
  resetPasswordNewPasswordErrorsPattern: '*Le mot de passe doit comporter entre 8 et 16 caractères alphanumériques et spéciaux. ',
  resetPasswordConfirmPasswordTitle: 'Confirmez le mot de passe',
  resetPasswordConfirmPasswordErrorsNotMatched: 'Les mots de passe ne correspondent pas.',
  resetPasswordResetBtn: 'Réinitialiser',
  passwordResetSuccess: 'Le mot de passe a été modifié avec succès, veuillez vous connecter pour profiter du jeu !',
  resetPasswordWentWrong: "Quelque chose s'est mal passé !",
  levelGrand: 'GRANDIOSE',
  levelMajor: 'MAJEUR',
  levelMinor: 'MINEURE',
  levelMini: 'MINI',
  bannerContentWinDaily: 'Gagnez quotidiennement 1 000 $ ',
  bannerContentContest: 'Concours maintenant ',
  bannerContentjoin: 'Rejoignez notre concours de paris quotidien et devenez le héros !  ',
  bannerContentPlayNow: 'joue maintenant',
  bannerContentTitle: 'Gagnez le gros lot',
  bannerContentContent: 'Joue plus',
  bannerContentGameName: 'JEU CRASH',
  bannerContentGameDesc: 'Joue plus',
  partners: 'Les partenaires',
  gamesGoldenDragon: "Dragon d'or",
  gamesCrashGame: 'Jeu de crash',
  gamesDice: 'Dé',
  gamesHilo: 'Hilo',
  allBetsTitle: 'Tous les paris',
  allBetsBetId: 'Identifiant du pari',
  allBetsUser: 'Utilisateur',
  allBetsPayout: 'Paiement',
  allBetsAmount: 'Montant',
  allBetsTime: 'Temps',
  allBetsProfit: 'Profit',
  LoginwithOtp: 'Connectez-vous avec OTP',
  EnterLoginOtp: 'Entrez la connexion OTP',
  submit: 'Soumettre',
  footerAboutUs: 'À propos de nous',
  footerFaq: 'FAQ',
  footerSitePolicy: 'Politique du site',
  footerResponsibleGambling: 'Jeu responsable',
  footerTerms: 'Termes et conditions',
  signinTitle: 'Se connecter',
  signinUsername: "Nom d'utilisateur / E-mail",
  signinPassword: 'Mot de passe',
  signinLoginBtn: 'Se connecter',
  signinSignup: "S'inscrire!",
  signinForgetPassword: 'Mot de passe oublié?',
  forgotPasswordMsg: "Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
  emailLinkMsg: "Le lien de réinitialisation du mot de passe a été envoyé à votre e-mail d'inscription. Veuillez cliquer sur le lien de l'e-mail et définir votre nouveau mot de passe.",
  resetPasswardConfirm: 'Le mot de passe a été réinitialisé avec succès',
  signinDontHaveAccount: "Vous n'avez pas de compte ? ",
  signinCreateAccount: 'Créer un compte',
  signinErrorsUserNameRequired: "*Veuillez saisir votre nom d'utilisateur/e-mail.",
  signinErrorsUserNameMinLength: 'Minimum de 4 caractères autorisés.',
  signinErrorsUserNameMaxLength: 'Maximum 20 caractères autorisés.',
  signinErrorsUserNamePattern: "Veuillez saisir un nom d'utilisateur valide, seuls les lettres et les chiffres sont autorisés.",
  signinErrorsPasswordRequired: '*Mot de passe requis.',
  signinErrorsPasswordMinLength: 'Minimum de 8 caractères autorisés.',
  signinErrorsPasswordMaxLength: 'Maximum 16 caractères autorisés.',
  signinErrorsPasswordPattern: '*Le mot de passe doit comporter entre 8 et 16 caractères alphanumériques et spéciaux. ',
  signinSuccessfullyLoggedIn: 'Connecté avec succès.',
  signinResetMailSent: 'E-mail de réinitialisation du mot de passe envoyé.',
  signinTryAgain: "Une erreur s'est produite. Veuillez réessayer.",
  signinResetPasswordUsernameErr: "Veuillez saisir votre nom d'utilisateur/e-mail pour réinitialiser le mot de passe.",
  signinResetMailSentSuccessContent: 'Nous vous avons envoyé un lien de réinitialisation du mot de passe sur votre e-mail. ',
  signupTitle: 'Registre',
  signupUsername: "Nom d'utilisateur",
  signupEmail: 'E-mail',
  signupTermsConditions: 'Termes',
  signupWalletConnect: 'Connectez-vous avec Wallet ',
  signupPassword: 'Mot de passe',
  signupEighteenPlus: "J'ai plus de 18 ans et j'accepte les conditions d'utilisation",
  signupRegisterBtn: "S'inscrire",
  signupCreateNewAccount: 'Créer un nouveau compte',
  signupFullName: 'Nom et prénom',
  signupReferralCode: 'Code de Parrainage',
  signupConfirmPassword: 'Confirmez le mot de passe',
  signupUseSocialAccount: 'Connectez-vous via les réseaux sociaux',
  signupFacebook: ' Facebook',
  signupGoogle: ' Google',
  signupHaveAccount: 'Avoir un compte? ',
  signupLoginBtn: 'Se connecter',
  signupErrorsUserNameRequired: "*Veuillez saisir votre nom d'utilisateur.",
  signupErrorsUserNameMinLength: 'Minimum de 4 caractères autorisés.',
  signupErrorsUserNameMaxLength: 'Maximum 20 caractères autorisés.',
  signupErrorsUserNamePattern: "Veuillez saisir un nom d'utilisateur valide, seuls les lettres et les chiffres sont autorisés.",
  signupErrorsFullNameRequired: '*Veuillez entrer votre nom complet.',
  signupErrorsFullNamePattern: 'Veuillez saisir un nom complet valide, seuls les lettres et les chiffres sont autorisés.',
  signupErrorsEmailRequired: '*Veuillez saisir votre adresse e-mail.',
  signupErrorsEmailMinLength: 'Minimum de 4 caractères autorisés.',
  signupErrorsEmailMaxLength: 'Maximum 20 caractères autorisés.',
  signupErrorsEmailPattern: 'Veuillez saisir une adresse e-mail valide.',
  signupErrorsPasswordRequired: '*Mot de passe requis.',
  signupErrorsPasswordMinLength: 'Minimum de 8 caractères autorisés.',
  signupErrorsPasswordMaxLength: 'Dépôt maximum16 caractères autorisés.',
  signupErrorsPasswordPattern: '*Le mot de passe doit contenir de 8 à 16 caractères alphanumériques (majuscules et minuscules) et des caractères spéciaux. ',
  signupErrorsConfirmPasswordMatch: 'Le mot de passe doit correspondre.',
  signupPlaceholderUserName: 'Min. ',
  signupPlaceholderEmail: 'par exemple. ',
  signupPlaceholderPassword: 'Min. ',
  signupSomethingWentWrong: "Quelque chose s'est mal passé !",
  signupSignupGoogle: 'Inscrivez-vous avec succès auprès de Google.',
  signupRegistered: 'Enregistré avec succès.',
  signupConfirmAge: 'Veuillez confirmer que vous avez 18 ans.',
  signupSignupFacebook: 'Inscrivez-vous avec succès sur Facebook.',
  signupCookiesConsent: 'Veuillez autoriser les cookies tiers dans les paramètres de votre navigateur à utiliser la connexion sociale.',
  signupRegisterEmailPopupContent: 'Nous vous avons envoyé un e-mail de vérification. ',
  accountMenuDeposit: 'Dépôt',
  accountMenuCashout: 'Retrait',
  accountMenujackpotWinners: 'Gagnants du gros lot',
  accountMenuTransactions: 'Transactions',
  accountMenuPromotions: 'Promotions',
  accountMenuProfile: 'Profil',
  accountMenuLogout: 'Se déconnecter',
  accountMenuSound: 'Son',
  accountMenuMusic: 'Musique',
  accountMenuWithdraw: 'Retirer',
  pageNotFound: 'Nous ne trouvons pas la page que vous recherchez !',
  error: 'Erreur',
  somethingWentWrong: "Quelque chose s'est mal passé !",
  verifyEmailThankYou: 'Merci!',
  verifyEmailEmailIsVerified: 'Votre email est vérifié. ',
  verifyEmailGoToLogin: 'Aller à la page de connexion',
  verifyEmailWentWrong: "Quelque chose s'est mal passé !",
  verifyEmailTokenExpired: 'E-mail déjà vérifié ou lien expiré, veuillez renvoyer le lien de vérification par e-mail en fournissant votre e-mail enregistré ci-dessous. ',
  verifyEmailGoToHome: "Aller à la page d'accueil",
  verifyEmailSuccessMsg: 'E-mail vérifié avec succès.',
  bonusSectionSpanText: 'présente les plus généreux et variés',
  bonusSectionBonuses: 'Bonus',
  bonusSectionSubtitleText: 'Jouez avec plaisir et obtenez des récompenses supplémentaires pendant le jeu. ',
  JoiningBonusTitle: "Bonus d'adhésion",
  JoiningBonusButton: 'Réclamez maintenant',
  JoiningBonusDesc: 'De tels bonus encouragent les joueurs de casino en ligne à jouer en utilisant Bitcoin ou d’autres cryptos. ',
  depositBonusTitle: 'Bonus de dépôt',
  depositBonusButton: 'Dépôt',
  depositBonusDesc: 'De tels bonus encouragent les joueurs de casino en ligne à jouer en utilisant Bitcoin ou d’autres cryptos. ',
  refferBonusTitle: 'Bonus de parrainage',
  refferBonusButton: 'Dépôt',
  refferBonusDesc: 'De tels bonus encouragent les joueurs de casino en ligne à jouer en utilisant Bitcoin ou d’autres cryptos. ',
  aboutSectionSpanText2: 'Le casino en ligne vous permet de jouer à vos jeux préférés gratuitement ou en crypto-monnaie. ',
  aboutSectionSpanText3: ' Dans ce monde virtuel du jeu Bitcoin, vous pouvez profiter de nombreuses opportunités : jouer à vos jeux de casino préférés, obtenir des bonus, participer à des concours et des promotions.',
  verifiedSectionTitle: 'Logiciel vérifié et sous licence',
  verifiedSectionSpanText1: 'Nous ne copions ni n’adoptons de logiciels provenant d’autres sites Web. ',
  faqSectionTitle: 'FAQ des joueurs',
  faqSectionForgetPasswordTitle: "J'ai oublié mon mot de passe.",
  faqSectionForgetPasswordDesc: "Vous n'avez pas fourni d'adresse e-mail de récupération pour votre compte. ",
  faqSectionMyDepositCreditedTitle: "Mon dépôt n'a pas été crédité.",
  faqSectionMyDepositCreditedDesc: " Contenu d'espace réservé pour cet accordéon, destiné à démontrer la classe. ",
  SupportedCurrencyTitle: 'Devise prise en charge',
  SupportedCurrencySpanText1: 'Nous ne copions ni n’adoptons de logiciels provenant d’autres sites Web. ',
  CarouselSectionTitle: 'CRASH HODL',
  CarouselSectionSubTitle: ' Jouez à plus de jeux et courez la chance de gagner. ',
  WalletSettingTitle: 'Paramètres du portefeuille',
  WalletSettingHideZero: 'Masquer les soldes nuls',
  WalletSettingHideZeroInfo: 'Votre solde nul n’apparaîtra pas dans votre portefeuille',
  WalletSettingDisplayFiat: 'Afficher la crypto en fiat',
  WalletSettingDisplayFiatInfo: 'Tous les paris',
  WalletSettingNoteForApprox: "Veuillez noter qu'il s'agit d'estimations de devises.",
  WalletSettingSave: 'Sauvegarder',
  WalletSettingNoWalletFound: 'Aucun portefeuille trouvé',
  jackpotWinnersNoWinnerAvailable: 'Aucun gagnant disponible',
  jackpotWinnersNew: 'Nouveau',
  jackpotWinnersHeader: 'Gagnant du gros lot',
  jackpotDetailsHeader: 'Détails du jackpot',
  jackpotDetailsCampaignPeriod: 'Période de campagne',
  jackpotDetailsFrom: 'Depuis',
  jackpotDetailsTo: 'À',
  jackpotDetailsBettingRule: 'Règle de pari',
  jackpotDetailsMinBetAmount: 'Montant minimum de la mise',
  jackpotDetailsMaxBetAmount: 'Montant maximum de la mise',
  jackpotDetailsBetPercentage: 'Pourcentage de pari',
  jackpotDetailsWinningRuleHeader: 'Règle gagnante',
  jackpotDetailsWinningRule: 'Placer des paris avec un montant plus élevé augmentera les chances de gagner.',
  jackpotDetailsProfitRule: 'Si le montant de la mise est compris entre minBetAmount et maxBetAmount, alors betPercentage% du montant de la mise sera ajouté au montant du jackpot.',
  profileTitle: 'Détails personnels',
  profileFName: 'Prénom',
  profileLname: 'Nom de famille',
  profileUserName: "Nom d'utilisateur",
  profileDob: 'Date de naissance',
  profileEmail: 'Adresse e-mail',
  profilePhone: 'Numéro de téléphone',
  profileChange: 'Changement',
  profileSave: 'Sauvegarder les modifications',
  profilePassword: 'Mot de passe',
  profileSubmit: 'Soumettre',
  profileUploadProfile: 'Télécharger le profil',
  profileCurrentPassword: 'Mot de passe actuel',
  profileNewPassword: 'nouveau mot de passe',
  profileConfirmPassword: 'Confirmez le mot de passe',
  profileScanQRCode: 'Scanner le code QR',
  profileEnterSecretCode: 'Entrez le code secret',
  profileErrorsFirstNameRequired: 'Le prénom est requis.',
  profileErrorsFirstNamePattern: 'Veuillez entrer un nom valide.',
  profileErrorsFirstNameMinLength: 'Minimum 3 caractères autorisés.',
  profileErrorsFirstNameMaxLength: 'Maximum 20 caractères autorisés.',
  profileErrorsLastNameRequired: 'Le nom de famille est obligatoire.',
  profileErrorsLastNamePattern: 'Veuillez entrer un nom valide.',
  profileErrorsLastNameMinLength: 'Minimum 3 caractères autorisés.',
  profileErrorsLastNameMaxLength: 'Maximum 20 caractères autorisés.',
  profileErrorsChangePasswordOldRequired: "L'ancien mot de passe est requis.",
  profileErrorsChangePasswordOldPattern: 'Veuillez saisir un mot de passe valide.',
  profileErrorsChangePasswordOldMinLength: 'Minimum de 8 caractères autorisés.',
  profileErrorsChangePasswordOldMaxLength: 'Maximum 16 caractères autorisés.',
  profileErrorsChangePasswordNewRequired: 'Un nouveau mot de passe est requis.',
  profileErrorsChangePasswordNewPattern: 'Veuillez saisir un mot de passe valide.',
  profileErrorsChangePasswordNewMinLength: 'Minimum de 8 caractères autorisés.',
  profileErrorsChangePasswordNewMaxLength: 'Maximum 16 caractères autorisés.',
  profileErrorsChangePasswordNewPasswordNotMatched: 'Les mots de passe ne correspondent pas.',
  profileErrorsChangePasswordNewPasswordMatched: 'Le nouveau mot de passe ne peut pas être identique au mot de passe actuel.',
  profileErrorsChangePasswordNewRequiredConfirmPass: 'Confirmer que le mot de passe est requis.',
  profileErrorsUserNameRequired: "Nom d'utilisateur est nécessaire.",
  profileErrorsUserNamePattern: 'Veuillez saisir un nom valide.',
  profileErrorsUserNameMinLength: 'Minimum 3 caractères autorisés.',
  profileErrorsUserNameMaxLength: 'Maximum 20 caractères autorisés.',
  profileErrorsDobRequired: 'La date de naissance est requise.',
  profileErrorsEmailRequired: 'Un e-mail est requis.',
  profileErrorsPostcodeRequired: 'Le code postal est requis.',
  profileErrorsCityRequired: 'La ville est obligatoire.',
  profileErrorsCurrencyRequired: 'La monnaie est requise.',
  profileErrorsAddressRequired: "L'adresse est requise.",
  profileErrorsCountryRequired: 'Le pays est requis.',
  profileErrorsEmailPattern: 'Veuillez saisir une adresse e-mail valide.',
  profileErrorsEmailMinLength: 'Minimum 3 caractères autorisés.',
  profileErrorsEmailMaxLength: 'Maximum 20 caractères autorisés.',
  profileMessagesProfileUpdate: 'Mise à jour du profil réussie.',
  profileMessagesChangePassword: 'Mot de passe mis à jour avec succès.',
  profileProfilePicProfilePicUpload: 'Télécharger une image de profil',
  profileNoNewChanges: 'Impossible de mettre à jour le profil ! ',
  profileLoggedInWithSocial: 'Vous êtes connecté avec la connexion sociale.',
  profileSideNavigatorSound: 'Son',
  profileSideNavigatorMusic: 'Musique',
  profileSideNavigatorProfile: 'Mon profil',
  profileSideNavigatorBetHistory: 'Historique des paris',
  profileSideNavigatorTransactions: 'Transactions',
  profileSideNavigatorDeposit: 'Dépôt',
  profileSideNavigatorWithdraw: 'Retirer',
  profileSideNavigatorGameLimits: 'Limites du jeu',
  profileSideNavigatorReferral: 'Référence',
  profileSideNavigatorChangePassword: 'Changer le mot de passe',
  profileSideNavigatorTermsConditions: 'Termes',
  profileSideNavigatorLogOut: 'Se déconnecter',
  profileSideNavigatorProvablyFair: 'Prouvablement juste',
  WalletInfoSectionNotAvailable: 'Pas disponible',
  WalletInfoSectionRealBalance: 'Solde réel',
  GameLimitsMinimumBet: 'Pari minimum',
  GameLimitsMaximumBet: 'Mise maximale',
  GameLimitsMaxWinFor1Bet: 'Gain maximum pour un pari',
  ProvablyFairSubTitle: 'Ce jeu utilise la technologie Provably Fair pour déterminer le résultat du jeu. ',
  ProvablyFairMaximumBet: 'Mise maximale :',
  ProvablyFairMaxWinFor1Bet: 'Gain maximum pour un pari',
  promotionsTitle: 'Promotions',
  promotionsNoLosingData: 'Désolé, promotions de bonus sans perte disponibles pour ',
  promotionsNoDepositData: 'Désolé, promotions de bonus sans dépôt disponibles pour ',
  promotionsCurrency: ' devise.',
  promotionsViewBtn: 'Voir',
  promotionsClaimSuccess: 'Vous avez réclamé avec succès',
  promotionsAvailability: 'Disponibilité: ',
  promotionsAvailabilityTabLosing: 'Perdre le bonus',
  promotionsAvailabilityTabDeposit: 'Bonus de dépôt',
  promotionsDepositBonusTableCode: 'Code',
  promotionsDepositBonusTableMinDeposit: 'Min. ',
  promotionsDepositBonusTablePercentage: 'Pourcentage',
  promotionsDepositBonusTableMaxBonus: 'Bonus maximum',
  promotionsDepositBonusTableRolloverMultipler: 'Multiplicateur de roulement',
  promotionsDepositBonusTableMaxRolloverPerBet: 'Rollover maximum par pari',
  promotionsDepositBonusTableTimeToAchieveRolloverTarget: "Il est temps d'atteindre l'objectif de refinancement",
  promotionsBonusCode: 'CODE BONUS: ',
  promotionsLossesClaim: 'Les pertes peuvent être réclamées (pendant la période de campagne) : ',
  promotionsLossesClaimLosingBonusTablePercentage: 'Pourcentage',
  promotionsLossesClaimLosingBonusTableMinLosingAmount: 'Min. ',
  promotionsBonusStatus: 'Statut bonus : ',
  promotionsBonusAmount: 'Montant du bonus : ',
  promotionsRolloverTarget: 'Objectif de rollover : ',
  promotionsRolloverAchieved: 'Rollover réalisé : ',
  promotionsActivatebtn: 'ACTIVER',
  promotionsClaimBtn: 'RÉCLAMER',
  promotionsCancelBtn: 'ANNULER',
  promotionsBackBtn: 'DOS',
  promotionsBonus: 'Prime',
  promotionsTermAndConditions: ' Termes',
  promotionsClaimDepositBonusTitle: 'Étapes pour réclamer le bonus de dépôt',
  promotionsClaimDepositBonusFirst: 'Activer le code bonus',
  promotionsClaimDepositBonusSecond: 'Montant du dépôt sur le portefeuille',
  promotionsClaimDepositBonusThird: "Atteindre l'objectif de roulement pour réclamer le bonus",
  promotionsClaimLosingBonusTitle: 'Étapes pour réclamer le bonus de perte',
  promotionsClaimLosingBonusFirst: 'Activer le code bonus',
  promotionsClaimLosingBonusSecond: 'Jouez à vos jeux préférés',
  promotionsClaimLosingBonusThird: 'Cliquez sur « Réclamation des pertes » pour réclamer les pertes pendant la période de campagne.',
  promotionsWentWrong: "Quelque chose s'est mal passé !",
  transactionTitle: 'Transactions',
  transactionBetId: 'Identifiant du pari',
  transactionUser: 'Utilisateur',
  transactionPayout: 'Paiement',
  transactionAmount: 'Montant',
  transactionProfit: 'Profit',
  transactionCashout: 'Retrait',
  transactionBet: 'Pari',
  transactionMultix: 'Multi.x',
  transactionWin: 'Gagner',
  transactionFairness: 'Justice',
  transactionReferFriend: 'Référez un ami',
  transactionTotal: 'Paris totaux',
  transactionWins: 'Victoires',
  transactionRefresh: 'Rafraîchir',
  transactionFilterTopMultipliers: 'Meilleurs multiplicateurs',
  transactionFilterHugeWins: 'Des gains énormes',
  transactionFilterBiggestWins: 'Les plus gros gains',
  transactionFilterMultipliers: 'Multiplicateurs',
  transactionRealBalance: 'Solde réel',
  depositWithdrawTXDate: 'Date',
  depositWithdrawTXAmount: 'Montant',
  depositWithdrawTXTxId: 'ID de transmission',
  depositWithdrawTXTxType: 'Type de transaction',
  depositWithdrawTXStatus: 'Statut',
  depositWithdrawTXNoData: 'Pas de données disponibles.',
  depositTitle: 'Dépôt',
  depositHistory: 'Histoire',
  depositSubTitle: "L'utilisateur ne peut pas déposer ou retirer de monnaie fiduciaire. ",
  depositCopyNotSupported: 'Votre navigateur ne prend pas en charge la copie automatique dans le presse-papiers. Veuillez essayer de copier le code manuellement.',
  depositCopied: 'Adresse copiée.',
  depositAddressCreated: 'Adresse créée avec succès.',
  depositGenerateAddress: 'Générer une adresse',
  depositWarningText: 'Veuillez NE PAS déposer via un transfert inter-chaînes',
  withdrawTitle: 'Retirer',
  withdrawSubTitle: "L'utilisateur ne peut pas déposer ou retirer de monnaie fiduciaire. ",
  withdrawNoteDesc: "Veuillez vous assurer de NE PAS saisir l'adresse BEP2, BEP20 (BSC) comme portefeuille d'adresses de retrait LTC.",
  withdrawNoteSublist: ' Veuillez NE PAS retirer en tant que transfert inter-chaînes',
  withdrawHistory: 'Histoire',
  withdrawWithdrawBtn: 'Demande de retrait',
  withdrawFees: 'Frais de retrait {{fees}} {{token}}',
  withdrawWithdrawPending: 'Votre demande de retrait est en attente. ',
  withdrawEnterFields: "Entrez le montant du retrait et l'adresse de votre portefeuille.",
  withdrawSelectWallet: 'Veuillez sélectionner le portefeuille à retirer.',
  withdrawAmountFieldTitle: 'Montant (Min. 0,03 {{wallet.currency.code}})',
  withdrawAmountFieldPlaceholder: '0',
  withdrawAddressFieldTitle: 'Ton {{wallet.currency.code}} adresse',
  withdrawAddressFieldPlaceholder: 'Tapez votre adresse',
  withdrawNoBalance: "Pas assez d'équilibre.",
  withdrawMinAmount: 'Veuillez saisir un montant supérieur ou égal à {{min}} .',
  fiatCurrencyInfo: "L'utilisateur ne peut pas déposer ou retirer de monnaie fiduciaire. ",
  paginationNextBtn: 'Suivant',
  paginationPrevBtn: 'Précédent',
  wentWrong: "Quelque chose s'est mal passé.",
  referralTitle: 'Référence',
  referralSubTitle: 'Parrainez vos amis',
  referralEarn: ' Gagnez 150 ₹ chacun',
  referralReferAndEarn: 'Parrainez et gagnez un bonus pour chaque parrainage',
  referralReferPara: ' Si vous référez ce code à un utilisateur',
  referralReferLink: 'Lien de référence',
  referralCopyReferralCode: 'Copier le code de référence',
  referralTotalRewards: 'Récompense totale',
  TwoWayAuthentication: 'Authentification bidirectionnelle',
  LoginRequired: 'Connexion requise!',
  BetPlacedSuccessfully: 'Pari placé avec succès',
  BetAddedInQueueSuccessfully: "Pari ajouté dans la file d'attente avec succès",
  BetRemovedFromQueueSuccessfully: "Pari supprimé de la file d'attente avec succès",
  BetCancelledSuccessfully: 'Pari annulé avec succès',
  PlayerEscapedSuccessfully: "Le joueur s'est échappé avec succès",
  CopiedSuccessfull: 'Copié avec succès',
  NotEnoughBalance: "Pas assez d'équilibre",
  AutoBetStarted: 'Pari automatique commencé ',
  AutoBetFinished: 'Pari automatique terminé ',
  IdCopied: 'Identifiant copié',
  usersConsent: "En accédant à ce site, je confirme que j'ai 18 ans et que j'ai lu, compris et consenti aux",
  termsOfService: "Conditions d'utilisation",
  email: 'E-mail',
  playNow: 'joue maintenant',
  orContinueWith: 'Ou continuez avec',
  metamaskExtensionNotAvailable: "Veuillez installer l'extension metaMask et actualiser la page.",
  cashier: 'LA CAISSIÈRE',
  account: 'COMPTE',
  hello: 'Bonjour',
  balance: 'Équilibre',
  referrals: 'Références',
  settings: 'Paramètres',
  withdrawals: 'Retraits',
  demoWallet: 'Portefeuille démo',
  myAccount: 'Mon compte',
  wallet: 'Portefeuille',
  user: 'Utilisateur',
  USD: 'USD',
  amountIsRequired: 'Le montant est requis',
  minDepositAmount: 'Le montant du dépôt doit être supérieur à {{min}}.',
  maxDepositAmount: 'Le montant du dépôt doit être inférieur à {{max}}.',
  minWithdrawAmount: 'Le montant du retrait doit être supérieur à {{min}}',
  maxWithdrawAmount: 'Le montant du retrait doit être inférieur à {{max}}',
  withdrawMustLessThanWallet: 'Le montant du retrait doit être inférieur au montant de votre portefeuille',
  accountTypeIsRequired: 'Le type de compte est requis.',
  mobileNumberLength: 'Le numéro de portable doit être du {{length}} chiffres',
  phoneIsRequired: 'Le téléphone est requis',
  cpfError: 'Le CPF doit être de {{length}} personnages',
  cpfRequiredError: 'Le CPF est obligatoire',
  cnpjError: 'Le CNPJ doit être de {{length}} personnages',
  mainMenu: 'MENU PRINCIPAL',
  casino: 'Casino',
  sports: 'Des sports',
  crypto: 'Cryptomonnaie',
  NFT: 'NFT',
  poker: 'Poker',
  earnRewards: 'GAGNER DES RÉCOMPENSES',
  rankUp: 'Monter en rang',
  inviteFriends: 'Inviter des amis',
  partnerProgram: 'Programme de partenariat',
  home: 'Maison',
  sportsbook: 'Paris sportifs',
  menu: 'Menu',
  liveBets: 'PARIS EN DIRECT',
  allBets: 'Tous les paris',
  highRollers: 'Gros joueurs',
  luckyBets: 'Paris chanceux',
  trade: 'Commerce',
  game: 'JEU',
  player: 'JOUEUR',
  time: 'TEMPS',
  wager: 'PARI',
  multiplierBan: 'MULTIPLICATEUR',
  payoutText: 'PAIEMENT',
  bannerHeadOne: "Faites l'expérience d'une véritable innovation avec le plus haut niveau",
  bannerHeadTwo: 'programme de récompenses au sein de l’industrie.',
  registerNow: "S'inscrire maintenant",
  liveWins: 'GAINS EN DIRECT',
  monthly: 'Mensuel',
  weekly: 'Hebdomadaire',
  daily: 'Quotidien',
  winner1: 'Gagnant 1',
  winner2: 'Gagnant 2',
  winner3: 'Gagnant 3',
  spin: 'Tourner',
  spinsWon: 'Tours Gagnés',
  joinedOn: 'Inscrit le',
  wageringAmount: 'Montant misé',
  name: 'Nom',
  allGames: 'Tous les jeux',
  allSports: 'Tous les sports',
  cryptoFutures: 'contrats à terme sur les cryptomonnaies',
  tradeNow: 'Échangeons maintenant',
  allTables: 'Tous les tableaux',
  nft: 'NFT',
  viewAllGames: 'Voir tous les jeux',
  viewAll: 'Voir tout',
  rewards: 'RÉCOMPENSES',
  profile: 'Profil',
  totalBets: 'Paris totaux',
  totalWagered: 'Total misé',
  joinDate: "Date d'adhésion",
  displaySocialLinks: 'Afficher les comptes sociaux liés',
  privateProfile: 'Profil privé',
  rank: 'Rang',
  winner: 'Gagnants',
  nextRank: 'Rang suivant',
  changeUsername: "Changer le nom d'utilisateur",
  newPassword: 'Nouveau mot de passe',
  changeName: 'Changer de nom',
  linkSocialAccount: 'Associer un compte social',
  privateProfileTooltip: "Après avoir rendu votre profil privé, les autres utilisateurs ne verront pas votre nom d'utilisateur à côté des paris, des courses, etc.",
  socialLinksTooltip: 'Contenu non disponible',
  rankTooltip: 'Contenu non disponible',
  balances: 'soldes',
  createReferralBalance: 'Créer un solde de référence',
  total: 'Total',
  switchBalance: 'Changer de solde',
  mainAccount: 'Compte principal',
  tips: 'Conseils',
  receivedAmount: 'Montant reçu',
  dateOrTime: 'Date/Heure',
  view: 'Voir',
  allChains: 'Toutes les chaînes',
  creditAmount: 'Montant du crédit',
  setCustomReferralCode: 'Définir un code de référence personnalisé',
  changeEmail: "Changer l'e-mail",
  newEmail: 'Nouveau courriel',
  createPassword: 'Créer un mot de passe',
  newUsername: "Nouveau nom d'utilisateur",
  username: "Nom d'utilisateur",
  changePassword: 'Changer le mot de passe',
  updateProfileMsg: 'Mise à jour du profil réussie',
  twoFactorAuthentication: 'authentification à deux facteurs',
  twoFactorAuthenticationTitle: "L'utilisation de l'authentification à deux facteurs est fortement recommandée car elle protège votre compte à la fois avec votre mot de passe et votre téléphone.",
  twoFactorAuthenticationSubTitle: 'Un e-mail et un mot de passe doivent être définis pour activer 2FA. ',
  enable2fa: 'ACTIVER 2FA',
  verifyIdentityByKyc: 'Vérifiez votre identité (kyc)',
  oldPassword: 'Ancien mot de passe',
  live: 'En direct',
  soccer: 'Football',
  tennis: 'Tennis',
  fifa: 'FIFA',
  basketball: 'Basket-ball',
  iceHockey: 'Hokey sur glace',
  volleyball: 'Volley-ball',
  tableTennis: 'Tennis de table',
  baseball: 'Base-ball',
  rubgy: 'Le rugby',
  golf: 'Le golf',
  boxing: 'Boxe',
  myBets: 'Mes paris',
  favourites: 'Favoris',
  americanFootball: 'football américain',
  buyCrypto: 'Acheter des cryptomonnaies',
  oldEmail: 'Ancien e-mail',
  loggedInSuccess: 'Connecté avec succès',
  loggedOutSuccess: 'Déconnecté avec succès',
  wagerLimit: 'Limite de mise définie avec succès',
  lossLimit: 'Limite de perte définie avec succès',
  accountDisable: 'Compte désactivé avec succès',
  sessionTimeout: "Le délai d'expiration de la session a été défini avec succès",
  signup: 'Inscription réussie',
  depositLimit: 'Limite de dépôt définie avec succès',
  tokenVerify: 'Le jeton a été envoyé à votre e-mail, veuillez vérifier',
  emailVerify: "L'e-mail a été vérifié",
  emailChangedSuccess: "L'e-mail a été modifié avec succès",
  profileUpdatedSuccess: 'Profil mis à jour',
  otpVerified: 'OTP vérifié',
  twoFADeactivated: 'Authentification 2FA désactivée',
  internalServerError: 'Erreur interne du serveur',
  unAuthorized: 'Non autorisé',
  dataNotFound: 'Données introuvables',
  setReferralCode: 'Définir le code de parrainage...',
  ultimate: 'ULTIME',
  notFound: 'Pas trouvé',
  casinoTransactions: 'Transactions de casino',
  sportsbookTransactions: 'Transactions de paris sportifs',
  status: 'Statut',
  success: 'Succès',
  failed: 'Échoué',
  pending: 'En attente',
  date: 'Date',
  to: 'À',
  gameName: 'Nom du jeu',
  transactionId: 'Identifiant de transaction',
  roundId: 'ID de ronde',
  bet: 'Pari',
  win: 'Gagner',
  transactionType: 'Type de transaction',
  at: 'à',
  downloadAll: 'Tout télécharger',
  gameText: 'Jeu',
  betId: 'Identifiant du pari',
  gameId: 'Identifiant de jeu',
  actionType: "type d'action",
  searchGames: 'Rechercher des jeux',
  provider: 'Fournisseurs',
  search: 'Recherche',
  sortBy: 'Trier par',
  popular: 'Populaire',
  challengePool: 'Pool de défis',
  loadMore: 'Charger plus',
  bitcoin: 'Bitcoin',
  chat: 'Chat',
  trades: 'Métiers',
  battles: 'Batailles',
  loginToChat: 'Connectez-vous pour discuter',
  rules: 'Règles',
  chatRule1: 'Ne pas harceler ou insulter les autres utilisateurs',
  chatRule2: 'Ne mendiez pas, demandez des prêts, des anecdotes ou des pourboires',
  chatRule5: 'Ne faites la publicité d’aucune forme de commerce, d’achat ou de vente de services.',
  chatRule6: 'Ne partagez pas et ne faites pas de publicité pour votre code de parrainage',
  chatRule7: 'Ne demandez pas à devenir membre du personnel',
  chatRule8: "que l'anglais",
  chatRule9: 'Respectez les mods, les administrateurs et les autres utilisateurs',
  send: 'Envoyer',
  expand: 'Développer',
  signIn: 'Se connecter',
  cross: 'Croix',
  collapse: 'Effondrement',
  emailPlaceholder: 'votre email@domain.com',
  removeFromFavourite: 'Supprimer des favoris',
  addToFavourite: 'Ajouter aux Favoris',
  footerAboutSite: 'Parlons un peu de science ? Le site du Casino Cash Machine fait partie de vous, car nous sommes tous composés des mêmes atomes. Votre interaction avec lui confirme cette connexion.',
  footerRightsReserved: 'Tous droits réservés pour Сash Machine Casino.',
  signupFirstName: 'Prénom',
  signupAddress: 'Adresse',
  signupCity: 'Ville',
  signupPostcode: 'Code postal ',
  signupCounty: 'Pays',
  signupCurrency: 'Devise',
  signupGender: 'Genre',
  signupMan: 'Homme',
  signupWomen: 'Femmes',
  signupOther: 'Autre',
  signupLoginDetails: 'Connexion Détails',
  signupPersonalDetails: 'Détails personnels',
  signupConfirm: 'Confirmer',
  signupPrivacyPolicy: "J'ai lu et accepté la politique de confidentialité",
  signupTermAndConditions: "J'accepte le traitement de mes données personnelles. ",
  signupNewsLetter: "Je souhaite recevoir périodiquement des bonus, des promotions, des actualités ou d'autres informations pertinentes par News Letter.",
  signupSms: "Je souhaite recevoir périodiquement des bonus, des promotions, des actualités ou d'autres informations pertinentes par SMS.",
  initialHeaderContent: 'Obtenez un incroyable 100 %',
  secoundaryHeaderContent: "Bonus de bienvenue jusqu'à 100 $",
  playNowButton: 'joue maintenant',
  registerButtonContent: 'Registre',
  results: 'Résultats',
  refresh: 'Rafraîchir',
  cancel: 'Annuler',
  select: 'Sélectionner',
  current: 'Actuel',
  set: 'Ensemble',
  remove: 'Retirer',
  save: 'Sauvegarder',
  not: 'pas',
  before: 'Avant',
  after: 'Après',
  action: 'Action',
  withdrawal: 'Retrait',
  requested: 'Demandé',
  withdraw: 'Retirer',
  areYouSure: 'Es-tu sûr',
  yes: 'Oui',
  no: 'Non',
  cancelled: 'Annulé',
  approved: 'Approuvé',
  refunded: 'Remboursé',
  chargeback: 'Rétrofacturation',
  processedOn: 'Traité le',
  transaction: 'Transaction',
  dateTime: 'Date/Heure',
  more: 'Plus',
  fromDate: 'Partir de la date',
  toDate: 'À ce jour',
  percentage: 'Pourcentage',
  info: 'Info',
  min: 'Min.',
  max: 'Max.',
  change: 'Changement',
  continueToSite: 'Continuer vers le site',
  choose: 'Choisir',
  enterWithdrawalAmount: 'Choisissez ou entrez votre montant de retrait',
  capDeposit: 'DÉPÔT',
  capWithdrawal: 'RETRAIT',
  capAmount: 'MONTANT',
  minimum: 'Le minimum',
  required: 'Requis',
  of: 'de',
  isRequired: 'est requis',
  isAllowed: 'est autorisée',
  method: 'Méthode',
  capWithdraw: 'RETIRER',
  showLess: 'Montrer moins',
  clearFilter: 'Effacer le filtre',
  quantity: 'Quantité',
  reset: 'Réinitialiser',
  incorrect: 'Incorrect',
  load: 'Charger',
  applied: 'Appliqué',
  ok: "D'ACCORD",
  list: 'Liste',
  all: 'Tous',
  favorite: 'Préféré',
  endPointNotFound: 'Quelque chose ne va pas avec la connexion réseau',
  category: 'Catégorie',
  emailUserNameRequired: "E-mail/nom d'utilisateur requis",
  emailUserNamePlaceHolder: "Entrez votre e-mail ou votre nom d'utilisateur",
  passwordRequired: 'Mot de passe requis',
  accountFrozen: "Votre compte est gelé jusqu'à",
  resendEmail: "Ré-envoyer l'email",
  resendLink: 'RENVOYER LE LIEN',
  userLoggedOut: 'Déconnexion réussie',
  emailAlreadyRegistered: 'Cet e-mail est déjà enregistré',
  userNameAlreadyTaken: "Ce nom d'utilisateur est déjà pris",
  fillAllFields: "Remplissez tous les champs avant de passer à l'étape suivante",
  pleaseAccept: "S'il-vous-plaît acceptez",
  acceptPrivacyPolicy: 'politique de confidentialité',
  acceptTerms: 'Termes et conditions',
  modeOfComm: ' et mode de communication',
  beforeNextStep: "avant de passer à l'étape suivante",
  userNamePlaceholder: "Saisissez votre nom d'utilisateur",
  min8Characters: 'Minimum 8 caractères',
  min1Number: 'Nombre minimum de 1',
  min1LowerCase: 'Minimum 1 caractère minuscule',
  min1UpperCase: 'Minimum 1 caractère majuscule',
  min1SpecialChar: 'Minimum 1 caractère spécial',
  passwordTip: 'Le mot de passe doit comporter au moins huit caractères dont au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
  passwordPlaceholder: 'Entrer le mot de passe',
  confirmPasswordPlaceholder: 'Entrez Confirmer le mot de passe',
  acceptAll: 'Accepter tout',
  firstNamePlaceholder: 'Entrez votre prénom',
  lastNamePlaceholder: 'Entrer le nom de famille',
  addressPlaceholder: "Entrer l'adresse",
  cityPlaceholder: 'Entrez la ville',
  postCodePlaceholder: 'Entrez le code postal',
  phonePlaceholder: 'Entrez le numéro de téléphone',
  invalidEmail: 'Email invalide',
  emailRequired: 'Email (requis',
  confirmPasswordRequired: 'Confirmer le mot de passe requis',
  firstNameRequired: 'Prénom (obligatoire',
  lastNameRequired: 'Nom de famille requis',
  phoneRequired: 'Téléphone requis',
  dateOfBirthRequired: 'Date de naissance requise',
  genderRequired: 'Sexe requis',
  userNameRequired: "Nom d'utilisateur (requis",
  addressRequired: 'Adresse requise',
  cityRequired: 'Ville requise',
  postCodeRequired: 'Code postal requis',
  currencyCodeRequired: 'Code de devise requis',
  countryRequired: 'Pays requis',
  matchPassword: 'Le mot de passe doit correspondre',
  fName3Chars: 'Le prénom doit contenir au moins 3 caractères',
  onlyAlphabet: 'Seuls les alphabets sont autorisés',
  lName3Chars: 'Le nom de famille doit contenir au moins 3 caractères',
  mustBe18: 'Vous devez avoir 18 ans pour vous inscrire',
  mustBeUnder200: 'Vous devez avoir moins de 200 ans pour vous inscrire',
  validDOB: 'Entrez une date de naissance valide',
  max20Characters: 'Maximum 20 caractères autorisés',
  min3Characters: 'Minimum 3 caractères requis',
  max100Characters: 'Maximum 100 caractères autorisés',
  max50Characters: 'Maximum 50 caractères autorisés',
  invalidNumber: 'Numéro invalide',
  preferredLanguageRequired: 'Langue préférée requise',
  invalidPass: 'Mot de passe incorrect',
  prevBtn: 'Précédent',
  dayRequired: 'La journée est obligatoire',
  monthRequired: 'Le mois est requis',
  yearRequired: "L'année est requise",
  uploadImage: 'Télécharger une image',
  removeImage: "Supprimer l'image",
  preferredLanguage: 'langue préférée',
  wantToChangePass: 'Voulez-vous changer le mot de passe ?',
  selectCountryCode: 'Sélectionnez le code du pays',
  selectPreferredLanguage: 'Sélectionnez la langue préférée',
  fName50Chars: 'Le prénom doit contenir au maximum 50 caractères',
  lName50Chars: 'Le nom de famille doit contenir au maximum 50 caractères',
  invalidDate: 'Date invalide',
  DOBEarlyThanToday: "La date de naissance doit être antérieure à aujourd'hui",
  enterValidPhone: "S'il vous plait entrez un numéro de téléphone valide",
  accountStatus: 'Statut du compte',
  kycVerified: 'Votre KYC a été vérifié avec succès.',
  denied: 'Refusé',
  chooseFile: 'Choisir le fichier',
  upload: 'Télécharger',
  countryCantChange: 'Vous êtes sur le point de lancer la vérification KYC. ',
  proceed: 'Procéder',
  update: ' ',
  history: 'Histoire',
  type: 'Taper',
  amount: 'Montant',
  forfeited: 'Permis',
  expired: 'Expiré',
  completed: 'Complété',
  zeroedOut: 'Mis à zéro',
  active: 'Actif',
  lapsed: 'Péchu',
  yourActiveBonus: 'Vos bonus actifs',
  currentActiveBonus: 'Bonus actifs actuels',
  bonusStatus: 'Statut',
  bonusWager: 'Pari',
  bonusActivate: 'Activer',
  bonusForfeit: 'Bonus perdu',
  notUsableBonus: 'Si oui, vous ne pourrez plus utiliser ce bonus.',
  bonusLapsed: "Vous n'avez pas subi suffisamment de pertes pour obtenir un cashback. ",
  inProcess: 'En cours',
  claiming: 'Affirmant',
  loyaltyPopover: 'Ces points de fidélité peuvent être utilisés pour obtenir du cashback. ',
  loyaltyPage: 'Page de fidélité',
  loyaltyPerCurrency: 'Fidélité par devise',
  maxLevelReached: 'Niveau maximum atteint',
  pointsToReach: 'points à atteindre ',
  loyaltyBannerHeading: 'Vous méritez notre toute nouvelle fidélité',
  loyaltyBannerDesc: "Bonus de bienvenue de 100 % jusqu'à 200 $",
  loyaltyHeadingOne: 'Vous êtes toujours récompensé chez CashMachine',
  loyaltyHeadingOneDesc: "Chez CashMachine, nous portons la fidélité à un tout nouveau niveau d'excitation, en vous comblant de récompenses à chaque détour ! ",
  loyaltySubHeadOne: 'Gagner des points de fidélité',
  loyaltySubHeadOneDesc: 'Pour chaque tranche de 10 € de mises en cash placées sur notre casino, vous gagnerez 1 point de fidélité. ',
  loyaltySubHeadTwo: 'Gravir les échelons de la fidélité',
  loyaltySubHeadTwoDesc: 'Plus vous accumulez de points, plus vous gravirez les niveaux de fidélité et débloquerez des récompenses encore plus fantastiques en cours de route. ',
  loyaltySubHeadThree: 'Passez au niveau supérieur, soyez récompensé ',
  loyaltySubHeadThreeDesc: 'Améliorez votre expérience de jeu en progressant dans nos niveaux de récompenses alléchants, où chaque niveau supérieur débloque une gamme exaltante de tours gratuits pour maximiser vos chances de gagner. ',
  loyaltyTestimonialHeadOne: 'CHEZ CashMachine CASINO, NOUS ASSURONS QUE',
  loyaltyTestimonialHeadTwo: 'Chaque tour compte',
  loyaltyTestimonialDesc: "Chaque tour que vous effectuez sur les machines à sous n'est pas seulement pour vous amuser, mais vous rapproche également de récompenses très intéressantes ! ",
  loyaltyPoints: 'Points',
  headerLevel: 'niveau',
  loyaltyCashback: 'Remise en argent',
  loyaltyBannerBtn: 'Vérifier la progression',
  loyaltyHeadingTwo: "Débloquez l'expérience de jeu ultime : programme VIP CashMachine",
  loyaltyHeadingTwoDesc: 'Une fois que vous aurez atteint le niveau 6 de notre programme de fidélité, vous débloquerez un monde de privilèges exclusifs en tant que membre du prestigieux programme VIP CashMachine. ',
  loyaltySubHeadFour: 'Limites de dépôt et de retrait plus élevées',
  loyaltySubHeadFourDesc: 'Profitez de la flexibilité des limites de dépôt et de retrait accrues en tant que membre VIP.',
  loyaltySubHeadFive: 'Bonus et promotions exclusifs',
  loyaltySubHeadFiveDesc: 'Accédez à des bonus et promotions spéciaux sur mesure pour nos joueurs VIP',
  loyaltySubHeadSix: 'Gestionnaire de compte personnel',
  loyaltySubHeadSixDesc: 'Bénéficiez d’un accompagnement dédié et d’une assistance personnalisée de votre account manager.',
  loyaltySubHeadSeven: 'Délais de retrait plus rapides',
  loyaltySubHeadSevenDesc: "En tant que VIP, vous bénéficierez de délais de retrait accélérés, vous garantissant ainsi d'accéder à vos gains encore plus rapidement.",
  loyaltySubHeadEight: 'Récompenses mensuelles en argent',
  loyaltySubHeadEightDesc: 'Augmentez votre bankroll avec de généreuses récompenses mensuelles en cashback exclusivement réservées à nos membres VIP.',
  loyaltySubHeadNine: 'Invitations à des événements et tournois exclusifs',
  loyaltySubHeadNineDesc: "Mettez la main sur des invitations convoitées à des événements et des tournois exclusifs, où vous pourrez vous mêler à d'autres VIP et concourir pour des prix incroyables.",
  loyaltySubHeadTen: "Bonus d'anniversaire",
  loyaltySubHeadTenDesc: "Célébrez votre journée spéciale avec style avec un délicieux bonus d'anniversaire rien que pour vous !",
  loyaltySubHeadEleven: 'Cadeaux de luxe',
  loyaltySubHeadElevenDesc: 'Offrez-vous une sélection exquise de cadeaux de luxe triés sur le volet pour récompenser nos joueurs les plus précieux.',
  loyaltyTableHeading: 'Aperçu des niveaux et des récompenses',
  loyaltyTableHeaderOne: 'Niveau de fidélité',
  loyaltyTableHeaderTwo: 'Points de fidélité',
  loyaltyTableHeaderThree: 'Remise en argent quotidienne',
  promReadMore: 'En savoir plus',
  currentPortalBlock: 'si vous souhaitez bloquer votre compte uniquement pour ce portail.',
  allPortalBlock: 'pour bloquer votre compte sur tous les portails.',
  limit24Reset: 'Une fois que vous avez défini les limites de mise, de perte et de dépôt, elles seront modifiables et amovibles après 24 heures, mais les limites peuvent être diminuées immédiatement.',
  enterAmount: 'Entrer le montant',
  limit: 'Limite',
  loss: 'Perte',
  takeABreak: 'Prendre une pause',
  session: 'Session',
  selfExclusion: 'Auto-exclusion',
  used: 'Utilisé',
  edit: 'Modifier',
  updatedAt: 'Mis à jour à',
  cannotBeRemoved: 'Ne peut pas être retiré avant',
  timePeriod: 'Période de temps',
  custom: 'Coutume',
  hours: 'Heures',
  days: 'Jours',
  timePeriodPlaceholder: 'Nombre de jours',
  months: 'Mois',
  permanent: 'Permanent',
  onlyNumbers: 'Seuls les numéros sont autorisés',
  notNumbers: 'Numéros non autorisés',
  userNameAllowed: 'Doit commencer par Alphabet. ',
  timePeriodRequired: 'Période requise',
  cannotBeIncreased: 'Ne peut pas être augmenté avant',
  amountGTZero: 'Le montant doit être supérieur à 0',
  amountEqualOrLess: 'Le montant doit être égal ou inférieur à celui hebdomadaire et mensuel',
  amountInBetween: 'Le montant doit être compris entre quotidien et mensuel (inclus)',
  amountEqualOIrGreater: 'Le montant doit être égal ou supérieur à celui quotidien et mensuel',
  limitRemoved: 'Votre limite suivante sera supprimée.',
  limitSet24Hrs: 'Vous êtes sur le point de définir la limite suivante pour votre compte. ',
  takeABreakInfo: "Vous êtes sur le point de bloquer l'accès à votre compte pour une période de temps prédéfinie. ",
  sessionLimitInfo: 'Vous êtes sur le point de définir la limite suivante pour votre compte. ',
  limitCantSetBefore: 'Votre limite de session ne peut pas être définie avant',
  selfExclusionInfo: "Vous êtes sur le point de bloquer l'accès à votre compte pour une période de temps prédéfinie. ",
  quickSearch: 'Recherche rapide',
  cash: 'Espèces',
  nonCash: 'Non monétaire',
  userDetail: "Détails de l'utilisateur",
  gameIdentifier: 'Identifiant du jeu',
  rollback: 'Retour en arriere',
  rollbackBeforeBetWin: 'Revenir en arrière avant de gagner le pari',
  freeSpins: 'Tours gratuits',
  betInternal: 'Parier interne',
  winInternal: 'Gagner en interne',
  addMoney: "Ajouter de l'argent",
  removeMoney: "Supprimer de l'argent",
  addMoneyInternal: "Ajouter de l'argent interne",
  removeMoneyInternal: "Supprimer l'argent interne",
  depositInternal: 'Dépôt interne',
  withdrawInternal: 'Retirer interne',
  promotionTitle: 'Titre de la promotion',
  cancelWithdrawRequest: 'Vous souhaitez annuler cette demande',
  rowsPerPage: 'Lignes par page',
  availedBonus: "Vous disposez déjà d'un bonus actif. ",
  capForfeit: 'DÉCLARER FORFAIT',
  itFirst: 'cela en premier.',
  selectYourBonus: 'Sélectionnez votre bonus',
  skip: 'SAUTER',
  with: 'avec',
  without: 'sans',
  selectPaymentMethod: 'Veuillez choisir votre mode de paiement',
  volatility: 'Volatilité',
  paylines: 'Lignes de paiement',
  theme: 'Thème',
  tryForFree: 'Essayer gratuitement',
  resultsFound: 'Résultats trouvés',
  games: 'Jeux',
  in: 'dans',
  low: 'Faible',
  medium: 'Moyen',
  high: 'Haut',
  'medium-high': 'Moyen-élevé',
  'very-high': 'Très haut',
  'low-medium': 'Faible Moyen',
  fantasy: 'Fantaisie',
  ancient_civilizations: 'Civilisations anciennes',
  fruits: 'Des fruits',
  africa: 'Afrique',
  military: 'Militaire',
  joker: 'Joker',
  asia: 'Asie',
  luxurylife: 'Vie luxueuse',
  underwater_world: 'Monde sous marin',
  book_off: 'Bookoff',
  western: 'Occidental',
  retro: 'Rétro',
  egypt: 'Egypte',
  party: 'Faire la fête',
  st_patrick_day: 'Fête de la Saint-Patrick',
  space: 'Espace',
  easter: 'Pâques',
  girls: 'Filles',
  branded: 'De marque',
  x_mas_and_new_year: 'Noël et nouvel an',
  horrors: 'Horreurs',
  other: 'Autre',
  loginFirst: "Veuillez d'abord vous connecter pour ajouter un jeu aux favoris",
  loginFirstPlay: "Veuillez d'abord vous connecter pour jouer à ce jeu",
  sport: 'sport',
  pirates: 'Pirates',
  sweets: 'Bonbons',
  luxury_life: 'Vie luxueuse',
  st_valentines_day: 'La saint Valentin',
  halloween: 'Halloween',
  food: 'Nourriture',
  lobby: "hall d'entrée",
  favourite: 'Préféré',
  playNGO: 'Jouer et aller',
  loyaltySystem: 'Système de fidélité',
  progressionSystem: 'Système de progression',
  startPoint: 'Point de départ',
  endPoint: 'Point final',
  loyaltyBonus: 'Bonus de fidélité',
  pageRemoved: "Cette page n'existe pas ou a été supprimée",
  suggestToBackHome: 'Nous vous proposons de rentrer chez vous',
  backToHome: 'De retour à la maison',
  goHome: 'Rentrer chez soi',
  oopps: 'Oups',
  somethingNotWorking: 'Nous sommes désolés, quelque chose ne fonctionne pas ici !',
  tournament: 'Tournoi',
  none: 'Aucun',
  validOnDays: 'Valable les jours',
  validTill: "Valable jusqu'au",
  yourRequest: 'Votre demande',
  withdrawalRequestPending: 'Vous avez une demande de retrait en attente. ',
  realBalance: 'Solde réel',
  bonusInCasino: 'Bonus au casino',
  bonusSelected: 'Bonus sélectionné',
  payWith: 'Payer avec',
  reached: 'Atteint',
  receiptOfYour: 'Réception de votre',
  capEmail: 'E-MAIL',
  capCreditCard: 'NUMÉRO DE CARTE DE CRÉDIT',
  capExpiry: 'EXPIRATION',
  capCvv: 'CVV',
  expiryIncorrectFormat: "EXPIRY n'est pas au format correct",
  min13Numbers: 'Minimum 13 numéros requis',
  max19Numbers: 'Maximum 19 numéros autorisés',
  min3Numbers: 'Minimum 3 numéros requis',
  max4Numbers: 'Maximum 4 numéros autorisés',
  Other: 'Autre',
  forfeit: 'Déclarer forfait',
  withdrawRequestCreated: 'Demande de retrait créée avec succès',
  noBetHistory: 'Aucun historique de pari trouvé',
  noPromotions: 'Aucune promotion trouvée',
  noBonuses: 'Aucun bonus trouvé',
  noHistory: 'Aucun historique trouvé',
  noWithdrawalHistory: 'Aucun historique de retrait trouvé',
  noCMS: 'Aucun CMS trouvé',
  noProvidersFound: 'Aucun fournisseur trouvé',
  noDataFound: 'Aucune donnée disponible',
  countryBlocked: 'PAYS BLOQUÉ',
  forbidden: 'INTERDIT',
  notAcceptingVisitors: "Nous en sommes profondément désolés, mais Shinywilds.com n'est pas disponible dans votre pays.",
  contactUsAt: 'Si vous pensez que cela vous a été affiché par erreur, veuillez contacter notre support par e-mail au',
  siteUnderMaintenance: 'Site en maintenance',
  weWillBeRightBack: 'Nous serons très bientôt de retour!',
  serviceUnavailable: 'Notre service est temporairement indisponible. ',
  tryAgainLater: 'Veuillez réessayer plus tard.',
  checkYourEmail: 'Vérifiez votre courrier électronique',
  sentAVerification: 'Nous avons envoyé un lien de vérification',
  toYourEmail: 'à votre email.',
  backToLogin: 'Retour connexion',
  goToLogin: 'Aller à la connexion',
  accountVerified: 'Compte vérifié',
  yourAccountVerified: 'Votre compte a été vérifié',
  successfully: 'Avec succès',
  verificationFailed: 'Échec de la vérification',
  emailFailedContact: 'Échec de la vérification. Si cela se produit régulièrement, veuillez contacter ',
  setNewPass: 'Définir un nouveau mot de passe',
  passResetLink: 'Nous avons envoyé un lien de réinitialisation du mot de passe à',
  toYourAccount: 'À votre compte',
  sendPasswordResetLink: 'Demande de réinitialisation du mot de passe',
  gamePlayWagerComplete: "Félicitations, vous avez terminé avec succès votre mise et l'argent du bonus gagné est supérieur au montant maximum des gains autorisés. ",
  real: 'Réel',
  demo: 'Démo',
  money: 'Argent',
  formatRquired: 'Formats .png, .jpg, .jpeg requis',
  chooseImage: 'Veuillez choisir une image',
  imageSize3MB: 'Entrez une image dont la taille est inférieure à 3 Mo',
  noProfileImage: 'Aucune image de profil disponible pour supprimer',
  limitSetSuccess: 'limite définie avec succès',
  accountDisabled: 'Compte désactivé avec succès',
  loginSuccess: 'Connexion réussie',
  signupSuccess: 'Inscription réussie',
  linkExpired: 'Le lien a expiré',
  linkSent: 'Lien envoyé',
  accAlreadyVerified: 'Compte déjà vérifié. ',
  profileImage: 'Image de profil',
  profileImageRemoved: 'Image de profil supprimée avec succès',
  docs: 'Documents',
  updatedSuccess: 'Mis à jour avec succés',
  MATCH: 'CORRESPONDRE',
  BALANCE: 'ÉQUILIBRE',
  FREESPINS: 'TOURS GRATUITS',
  MATCH_1: 'CORRESPONDRE',
  DEPOSIT: 'DÉPÔT',
  WAGERING: 'PARI',
  PROMOTION: 'PROMOTION',
  CREDITCARD: 'CARTE DE CRÉDIT',
  WALLET: 'PORTEFEUILLE',
  CRYPTO: 'CRYPTO',
  OTHER: 'AUTRE',
  INSTANTBANKING: 'BANQUE INSTANTANÉE',
  cookieTitle: 'Biscuits!',
  declineButton: 'Déclin',
  acceptButton: 'Accepter',
  cookieDeclinedMessage: 'Pour la meilleure expérience possible, les cookies doivent être acceptés pour continuer à utiliser ce site Web. ',
  cookieDescription: 'Bienvenue sur Shinywilds.com ! ',
  forfeitMessageFirst: 'Vous disposez actuellement du bonus actif suivant :',
  forfeitMessageSecond: 'Vous devez renoncer à ce bonus avant d’en réclamer un nouveau.',
  forfeitButton: 'Déclarer forfait',
  pleaseVerifyFirstText: "Afin de pouvoir demander un retrait, nous devons d'abord vérifier votre adresse e-mail. ",
  clickHere: 'Cliquez ici',
  pleaseVerifyLastText: "afin que nous puissions vous renvoyer l'e-mail de vérification et continuer.",
  pleseWait: "Attendez s'il vous plaît",
  toResend: 'pour renvoyer le lien',
  notVerified: "Vous devez vérifier votre adresse e-mail pour retirer de l'argent",
  verification: 'Vérification',
  UPLOAD_IMG_MSG: 'Faites glisser et déposez les fichiers ici, ou cliquez pour sélectionner des fichiers',
  affiliate: 'Affilier',
  affiliateTitle: "Programme d'affiliation",
  affiliateDescription: " Parrainez notre marque et gagnez de l'argent en participant au programme d'affiliation Cash Machine ! ",
  refferalTitle: 'Programme de référencement',
  refferalDescription: "Vous pouvez partager ce lien de parrainage avec d'autres et gagner un bonus si l'utilisateur s'inscrit en utilisant votre lien donné.",
  affiliateButtonText: 'Devenez affilié',
  affiliateLink: "Lien d'affiliation",
  affiliateStatus: "Votre demande d'affiliation a été envoyée avec succès",
  currentStatus: "et le statut d'affilié actuel est {{status}}",
  referralLink: 'Lien de référence',
  copyTitleMessage: 'Veuillez copier ce lien',
  copiedMessage: 'Lien copié',
  searchedGame: 'Jeu recherché',
  favoriteGame: 'Jeux préférés',
  randomGames: 'Jeux aléatoires',
  poolPrize: 'Cagnotte',
  chooseYourWelcomeBonus: 'Choisissez votre bonus de bienvenue',
  useNow: 'Utiliser maintenant',
  readTheTerms: 'Lire les termes',
  proceedWithoutWelcomeBonus: 'Continuer sans bonus de bienvenue',
  doYouAlreadyHaveAccount: 'Avez-vous déjà un compte? ',
  registerViaSocialNetworks: 'Inscrivez-vous via les réseaux sociaux',
  iconfirmIm18YearsOlder: "Je confirme que j'ai 18 ans ou plus",
  iAgreeWithTermsAndConditions: "Je suis d'accord avec les termes et les conditions",
  thankYouSuccessfullyRegisteringCashMachine: 'Merci de votre inscription réussie sur Cash Machine.',
  verifyEmailMessage: 'Nous vous demandons de vérifier votre e-mail en cliquant sur le lien de vérification envoyé à votre e-mail pour une connexion réussie sur le site.',
  gotIt: "J'ai compris",
  phonecodeRequired: 'Le code de téléphone est requis',
  cpNotAllow: "le copier/coller n'est pas autorisé",
  playFun: "Jouer en s'amusant",
  playReal: 'Jouez en vrai',
  yourRealBalance: 'Votre solde réel est :',
  chooseYourPaymentMethod: 'Choisissez votre méthode de paiement',
  balanceHistory: 'Historique du solde',
  liveChat: 'Chat en direct',
  mail: 'Mail',
  chooseEnterYourDepositAmounts: 'Choisissez ou saisissez vos montants de dépôt',
  noBonusDataAvailable: 'Aucune donnée bonus disponible',
  nowTryPlayForRealBets: 'Maintenant, essayez de jouer pour de vrais paris',
  registerAndPlayForRealBets: 'Inscrivez-vous et jouez pour de vrais paris',
  tournamentDataNotAvailable: 'Données du tournoi non disponibles',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  merchant: 'Marchand',
  seachHere: 'Cherche ici',
  show: 'Montrer',
  betResults: 'Résultats des paris :',
  noTransactionsFoundForselectedDate: 'Aucune transaction trouvée pour la date sélectionnée',
  loginError: "Erreur d'identification",
  errorMessageIncorrectlyAuthorization: "Email / login ou mot de passe mal saisi Erreur d'autorisation.",
  profileSaved: 'Profil enregistré',
  addInfo: 'Ajouter des informations',
  iHaveReadAndAgreePaymentSystemRestrictions: "J'ai lu et j'accepte les restrictions des systèmes de paiement*",
  paymentOptions: 'Options de paiement',
  language: 'Langue',
  betTime: 'Temps de pari',
  noActiveBonusFound: 'Aucun bonus actif trouvé',
  bonusType: 'Type de bonus',
  requiredWageringAmount: 'Montant de mise requis',
  currentWageringAmount: 'Montant de la mise actuelle',
  validUpto: "Valable jusqu'au",
  bonusCode: 'Code bonus',
  wageringProgressBar: 'Barre de progression des mises',
  noGamesFound: 'Aucun jeu trouvé',
  noteYouMayOptfreeSpin: "Remarque : vous pouvez opter pour FREESPIN sur n'importe lequel de ces jeux",
  viewOfferDetails: "Voir les détails de l'offre",
  viewFreespinGames: 'Voir les jeux Freespin',
  depositBonusPercentage: 'Pourcentage de bonus de dépôt : ',
  validity: 'Validité',
  activated: 'Activé',
  bonusName: 'Nom du bonus',
  freeRounds: 'Tours gratuits',
  wagering: 'Paris',
  ended2: 'Terminé',
  searchBonusTitle: 'Recherche par titre bonus',
  claimed: 'Revendiqué',
  noBonusHistoryFoundselectedDates: 'Aucun historique de bonus trouvé pour les dates sélectionnées',
  noBonusDetailsFound: 'Aucun détail de bonus trouvé',
  enterCode: 'Entrez le code',
  transactionTime: 'Temps de transaction',
  credit: 'Crédit',
  debit: 'Débit',
  bonusInfoOfHeading: 'Les bonus sont des paiements supplémentaires qui récompensent votre performance',
  onlineCasino: 'Casino en ligne',
  isTheTop: " c'est le top ",
  bonusInfoPara0: 'Vous recherchez les meilleurs jeux de casino en ligne ? ',
  tournamentsHistory: 'Historique des tournois',

  loginWelcomeBonusInfo: `Cash Machine accueille les nouveaux joueurs avec un bonus de bienvenue - 100% de
  le dépôt + 100 tours gratuits dans le jeu "Pat's Heroes" (Platipus).
  Le montant minimum du dépôt est de 20 EUR / 900 ARS / 30 AUD / 500 CZK
  / 150 DKK / 200 NOK / 30 NZD / 80 PLN / 20 USD / 300 ZAR / 70 GEL
  / KZT 9 000 / TJS 200 / UZS 200 000 / TRY 130`,
  loginDepositBonusInfo: `Montant maximum du bonus: 100% du montant du dépôt, mais pas plus
  supérieur à 100 EUR / 4 000 ARS / 150 AUD / 2 500 CZK / 750 DKK / 1 000 NOK
  / 150 NZD / 400 PLN / 100 USD / 1 500 ZAR / 350 GEL / 45 000 KZT /
  1 000 TJS / 1 000 000 UZS / 600 TRY`,
  loginFreespinBonusInfo: `Les tours gratuits sont émis dans les 5 jours suivant l'activation du bonus,
  20 tours gratuits par jour. Tous les fonds gagnés sur les tours gratuits sont crédités à
  le compte bonus et sont disponibles pour jouer/parier le
  prime. Retrait disponible après avoir misé X40. Temps de mise bonus
  c'est 7 jours`,
  loginMaximumBetLimitInfo: `Limite de mise maximale pendant les paris : 1 EUR / 45 ARS / 1,5 AUD / CZK
  25 / 7 DKK / 10 NOK / 1,5 NZD / 5 PLN / 1 USD / 15 ZAR / 3,5 GEL /
  450 KZT / 10 TJS / 10 000 UZS / 6 TRY`,

  bonusInfoPara1: ' , avec une large gamme de jeux fantastiques et des offres spéciales éblouissantes. Nos jeux de machines à sous se déclinent dans une grande variété de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux passionnants avec croupiers en direct où vous pouvez voir vos cartes mélangées∂ et distribuées en temps réel ! Vous ne savez pas quel jeu vous convient le mieux ? Ne vous inquiétez pas, vous pouvez d\'abord vous entraîner en mode démo pour avoir une idée de chaque jeu avant de choisir de jouer pour de vrai.',
  bonusInfoPara2: 'Nos jeux de machines à sous se déclinent dans une grande variété de thèmes, avec des jeux de machines à sous à jackpot progressif offrant une chance lucrative de remporter un gros gain. Nous proposons également des jeux passionnants avec croupiers en direct où vous pouvez voir vos cartes mélangées et distribuées en temps réel ! Vous ne savez pas quel jeu vous convient le mieux ? Ne vous inquiétez pas, vous pouvez d\'abord vous entraîner en mode démo pour avoir une idée de chaque jeu avant de choisir de jouer pour de vrai. Nos jeux de machines à sous se déclinent dans une grande variété de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux passionnants avec croupiers en direct où vous pouvez voir vos cartes mélangées et distribuées en temps réel ! Vous ne savez pas quel jeu vous convient le mieux ? Ne vous inquiétez pas, vous pouvez d\'abord vous entraîner en mode démo pour avoir une idée de chaque jeu avant de choisir de jouer pour de vrai.',
  bonusInfoPara3: 'Nos jeux de machines à sous se déclinent dans une grande variété de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux passionnants avec croupiers en direct où vous pouvez voir vos cartes mélangées et distribuées en temps réel ! Vous ne savez pas quel jeu vous convient le mieux ? Ne vous inquiétez pas, vous pouvez d\'abord vous entraîner en mode démo pour avoir une idée de chaque jeu avant de choisir de jouer pour de vrai.',
  bonusInfoPara4: 'Nos jeux de machines à sous se déclinent dans une grande variété de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux passionnants avec croupiers en direct où vous pouvez voir vos cartes mélangées et distribuées en temps réel ! Vous ne savez pas quel jeu vous convient le mieux ? Ne vous inquiétez pas, vous pouvez d\'abord vous entraîner en mode démo pour avoir une idée de chaque jeu avant de choisir de jouer pour de vrai. Nos jeux de machines à sous se déclinent dans une grande variété de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux passionnants avec croupier en direct où vous pouvez voir vos cartes mélangées et distribuées en temps réel ! Vous ne savez pas quel jeu vous convient le mieux ? Ne vous inquiétez pas, vous pouvez d\'abord vous entraîner en mode démo pour avoir une idée de chaque jeu avant de choisir de jouer pour de vrai.',
  cashMachineFooterPara1: 'Vous recherchez les meilleurs jeux de casino en ligne ? Alors vous êtes au bon endroit.',
  cashMachineFooterPara2: 'avec une grande variété de jeux fantastiques et d\'offres spéciales éblouissantes. Nos jeux de machines à sous sont disponibles dans une large gamme de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux de croupier en direct excitants où vous pouvez voir vos cartes être mélangées et distribuées en temps réel ! Vous n\'êtes pas sûr du jeu qui vous convient ? Pas de soucis - vous pouvez d\'abord vous entraîner en mode démo pour vous familiariser avec chaque jeu avant de choisir de jouer pour de vrai.',
  tournamentDescTitle: 'Live Casino, Machines à sous et Tournois de casino Gouttes et gains',
  tournamentPara1: 'Vous recherchez les meilleurs jeux de casino en ligne ? Alors vous êtes au bon endroit.',
  tournamentPara2: 'avec une grande variété de jeux fantastiques et d\'offres spéciales éblouissantes. Nos jeux de machines à sous sont disponibles dans une large gamme de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux de croupier en direct excitants où vous pouvez voir vos cartes être mélangées et distribuées en temps réel ! Vous n\'êtes pas sûr du jeu qui vous convient ? Pas de soucis - vous pouvez d\'abord vous entraîner en mode démo pour vous familiariser avec chaque jeu avant de choisir de jouer pour de vrai.',
  tournamentPara3: 'Nos jeux de machines à sous sont disponibles dans une large gamme de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux de croupier en direct excitants où vous pouvez voir vos cartes être mélangées et distribuées en temps réel ! Vous n\'êtes pas sûr du jeu qui vous convient ? Pas de soucis - vous pouvez d\'abord vous entraîner en mode démo pour vous familiariser avec chaque jeu avant de choisir de jouer pour de vrai. Nos jeux de machines à sous sont disponibles dans une large gamme de thèmes, avec des jeux de machines à sous à jackpot progressif offrant aux joueurs une chance lucrative de remporter un gros gain. Nous proposons également des jeux de croupier en direct excitants où vous pouvez voir vos cartes être mélangées et distribuées en temps réel ! Vous n\'êtes pas sûr du jeu qui vous convient ? Pas de soucis - vous pouvez d\'abord vous entraîner en mode démo pour vous familiariser avec chaque jeu avant de choisir de jouer pour de vrai.',
  'New Games': 'Nouveaux Jeux',
  'Popular Games': 'Jeux Populaires',
  'Games Of The Month': 'Jeux Du Mois',
  Exclusive: 'Exclusif',
  'Bonus Buy': 'Achat bonus',
  'Jackpot Games': 'Jeux à jackpot',
  lightMode: 'Mode lumière',
  darkMode: 'Mode sombre',
  searchProvider: 'Fournisseur de recherche',
  tournamentHistoryDataNotAvailable: "Les données de l'historique du tournoi ne sont pas disponibles",
  viewMore: 'Voir plus',
  description: 'Description',
  codeCopied: 'Code copié',
  tournamentGames: 'Jeux de tournoi',
  freeSpinGames: 'Jeux de tours gratuits',
  promoCode: 'Code promotionnel',
  paymentNote: 'Remarque : Veuillez ne pas fermer ou rafraîchir la fenêtre pendant que la transaction est en cours de traitement',
  liveGames: 'Jeux en Direct',
  play: 'JOUER',
  demo1: 'DÉMO',
  registration: 'INSCRIPTION'
}
