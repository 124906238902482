import styled from 'styled-components'

export const MaintenanceModeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-image: var(--theme-dark);
  .mainen-mode-wrap {
    margin: 3.75rem auto 0;
    text-align: center;
    width: 100%;
    padding: 40px;
    border-radius: 8px;
    border: transparent;
    color: #fff;
  }
  .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .logo {
    margin-bottom: 20px;
  }
  h6 {
    font-size: 28px;
    margin-bottom: 10px;
    // text-shadow: 0 0 2.125rem var(--authTabBtnText);
    font-size: 2.75rem;
    font-weight: 700;
    white-space: nowrap;
    animation: TextAnimation 2s ease-in-out infinite;
    text-shadow: var(--text-shadow);
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .contact {
    font-size: 14px;
    margin-top: 5px;
  }
  .footer {
    //   margin-top: 50px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
  }
`
