import { Dialog, styled } from '@mui/material'

export const MyAccountDialogWrapper = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiBackdrop-root': {
      // backdropFilter: 'blur(4px)'
    },
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: theme.spacing(85.625),
      height: `calc(100% - ${theme.spacing(2)})`,
      maxHeight: '100%',
      background: 'none',
      borderRadius: theme.spacing(0),
      overflow: 'hidden',
      '& .bg-img': {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        position: 'absolute',
        inset: '0'
      },
      '& .dialog-close-btn': {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        zIndex: '2',
        position: 'absolute',
        top: theme.spacing(1.25),
        right: theme.spacing(1.25),
        '& svg': {
          fill: theme.palette.typographyText.textMain,
          filter: `drop-shadow(1px 2px 2px ${theme.palette.darkShade.dark_04})`
        }
      },
      '& .MuiDialogContent-root': {
        width: '100%',
        background: theme.palette.gradientColor.DialogBg,
        maskImage: 'url("/assets/images/stock-images/modal-bg.png")',
        maskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        overflow: 'hidden',
        zIndex: '1',
        padding: theme.spacing(0),
        '& .modal-bg-border': {
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper': {
        maxWidth: '100%',
        height: '100%',
        maxHeight: `calc(100% - ${theme.spacing(0)})`,
        margin: theme.spacing(0),
        '& .modal-bg-border': {
          display: 'none'
        },
        '& .dialog-close-btn': {
          width: theme.spacing(2.25),
          height: theme.spacing(2.25),
          top: theme.spacing(0.938),
          left: theme.spacing(0.875)
        },
        '& .MuiDialogContent-root': {
          width: '100%',
          maxWidth: '100%',
          background: theme.palette.gradientColor.DialogBg,
          maskImage: 'none',
          padding: theme.spacing(0)
        }
      }
    }
  }
})
