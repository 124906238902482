import { createSlice } from '@reduxjs/toolkit'
import { fetchUserInformation } from 'redux-thunk/thunk/user.thunk'
import { getLocalLanguage, setLocalLanguage } from 'helpers/localstorage.helpers'
import { LANGUAGES } from 'constants/index'
import { updatePassword, userLogin, userLogout } from 'redux-thunk/thunk/auth.thunk'

const defaultAuthState = {
  loginData: null,
  operatorInformationData: null,
  language: getLocalLanguage() || LANGUAGES[0].languageCode,
  authModal: '',
  activeTabIndex: 0
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    setLoginData: (state, action) => ({
      ...state,
      loginData: action?.payload
    }),
    handleLanguageChange: (state, action) => {
      setLocalLanguage(action.payload)
      return {
        ...state,
        language: action.payload
      }
    },
    setAuthModal: (state, action) => ({
      ...state,
      authModal: action?.payload
    }),
    setUserModal: (state, action) => ({
      ...state,
      userModal: action?.payload
    }),
    setActiveTabIndex: (state, action) => ({
      ...state,
      activeTabIndex: action.payload
    })

  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        return {
          ...state,
          loginData: action.payload,
          language: action.payload?.additionalInfo?.languageCode || LANGUAGES[0].languageCode
        }
      })
      .addCase(userLogin.pending, (state, action) => {
        const { password, userNameOrEmail } = action.meta.arg
        return {
          ...state,
          loginData: { password, userNameOrEmail }
        }
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          operatorInformationData: action.payload?.userDetails
        }
      })
  }
})

export const { handleLanguageChange, setLoginData, setAuthModal, setUserModal, setActiveTabIndex } = authSlice.actions

export default authSlice.reducer
