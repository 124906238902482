import React from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography } from '@mui/material'
import { AuthLoginErrorWrapper } from './style'
import AlertDialog from 'components/common-ui/AlertDialog/AlertDialog'
import { CustomSecondaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'

const AuthLoginError = ({ dialogOpen, handleClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [maxWidth] = React.useState('customModal_sm')

  return (
    <AlertDialog
      maxWidth={maxWidth}
        // fullScreen={fullScreen}
      dialogOpen={dialogOpen}
      handleDialogClose={handleClose}
      aria-labelledby='customized-dialog-title'
    >
      <AuthLoginErrorWrapper theme={theme}>
        <Grid container spacing={1.5} justifyContent='center'>
          <Grid item xs={12} md={9}>
            <Typography variant='subtitle1' className='title-text'>{t('loginError')}</Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography variant='body1' className='pera-text'>{t('errorMessageIncorrectlyAuthorization')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className='btn-box'>
              <CustomSecondaryButton fullWidth>{t('resetPasswordResetBtn')}</CustomSecondaryButton>
            </Box>
          </Grid>
        </Grid>
      </AuthLoginErrorWrapper>
    </AlertDialog>
  )
}

export default React.memo(AuthLoginError)
