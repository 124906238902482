import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'

export const playCasinoGame = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/api/v1/casino/game-launch', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getFilteredGames = ({ data, hasMore }) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/casino/all-games', {}, {
    server: microServices.SERVICE_URL_1,
    loader: hasMore ? LOADER_HANDLER_TYPES.submit : LOADER_HANDLER_TYPES.content,
    params: data
  })
}

export const getCasinoSubCategories = (data) => {
  // return axiosInstance(METHOD_TYPES.get, '/api/game-sub-category', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.table,
  //   params: data
  // })
}

export const getCasinoProviderListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, 'api/v1/casino/game-provider', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getCasinoBannerListService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/banners', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
    // params: data
  })
}

export const getGameDataServices = (data) => {
  // return axiosInstance(METHOD_TYPES.post, '/api/user/init-game', data, {
  //   server: microServices.SERVICE_URL_1,
  //   loader: LOADER_HANDLER_TYPES.table
  // })
}

export const getJackpotPrices = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/jackpot', data, {
    server: microServices.SERVICE_URL_1
    // loader: LOADER_HANDLER_TYPES.table
  })
}

export const getCasinoGameByTags = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/casino/tags', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
