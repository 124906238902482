import React from 'react'
import { useTheme } from '@emotion/react'
import { } from '@mui/material'
import { DateTimePickerWrapper } from './style'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

const CustomDateTimePicker = ({ format, value, handleChange, ...rest }) => {
  const theme = useTheme()

  return (
    <DateTimePickerWrapper theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker']}>
          <DateTimePicker format='MM-DD-YYYY' views={['year', 'month', 'day']} value={value} onChange={(newValue) => handleChange(newValue)} inputFormat='MM-DD-YYYY' {...rest} />
        </DemoContainer>
      </LocalizationProvider>
    </DateTimePickerWrapper>
  )
}

export default React.memo(CustomDateTimePicker)
