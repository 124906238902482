import React from 'react'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import {
  StyledCustomFormLabel,
  StyledCustomPasswordInput,
  StyledCustomSelect,
  StyledCustomTextField,
  StyledCustomSearchSelect
} from './style'
import { EyeCloseIcon, EyeOpenIcon } from 'assets/icons/index'

// CUSTOM TEXTFIELD START
export const CustomTextField = ({
  id,
  label,
  value,
  onChange,
  variant,
  color,
  fullWidth,
  size,
  type,
  multiline,
  rows,
  rowsMax,
  inputProps,
  error,
  helperText,
  required,
  startAdornment,
  endAdornment,
  disabled,
  placeholder,
  className,
  ...otherProps
}) => {
  return (
    <StyledCustomTextField
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      size={size}
      type={type}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      inputProps={inputProps}
      error={error}
      helperText={helperText}
      required={required}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      disabled={disabled}
      placeholder={placeholder}
      className={className}
      {...otherProps}
    />
  )
}

// CUSTOM SELECT START
export const CustomSelect = ({
  id,
  label,
  value,
  onChange,
  variant,
  color,
  fullWidth,
  size,
  type,
  multiline,
  rows,
  rowsMax,
  inputProps,
  error,
  helperText,
  required,
  startAdornment,
  endAdornment,
  disabled,
  placeholder,
  children,
  ...otherProps
}) => {
  return (
    <StyledCustomSelect
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      size={size}
      type={type}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      inputProps={inputProps}
      error={error}
      helperText={helperText}
      required={required}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      disabled={disabled}
      placeholder={placeholder}
      {...otherProps}
    >
      {children}
    </StyledCustomSelect>
  )
}

// CUSTOM SEARCH SELECT
export const CustomSearchSelect = ({
  id,
  label,
  value,
  onChange,
  variant,
  color,
  fullWidth,
  size,
  type,
  multiline,
  rows,
  rowsMax,
  inputProps,
  error,
  helperText,
  required,
  startAdornment,
  endAdornment,
  disabled,
  placeholder,
  children,
  getOptionLabel,
  rennderOptions,
  renderInput,
  ...otherProps
}) => {
  return (
    <StyledCustomSearchSelect
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      size={size}
      type={type}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      inputProps={inputProps}
      error={error}
      helperText={helperText}
      required={required}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      disabled={disabled}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      rennderOptions={rennderOptions}
      renderInput={renderInput}
      {...otherProps}
    >
      {children}
    </StyledCustomSearchSelect>
  )
}

// CUSTOM PASSWORD START
export const CustomPasswordInput = ({
  id,
  value,
  name,
  onChange,
  variant,
  fullWidth,
  size,
  error,
  helperText,
  disabled,
  placeholder,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  ...otherProps
}) => {
  return (
    <StyledCustomPasswordInput fullWidth={fullWidth} variant='outlined'>
      <OutlinedInput
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        variant={variant}
        fullWidth={fullWidth}
        size={size}
        error={error}
        helperText={helperText}
        inputProps={{ autoComplete: 'off' }}
        disabled={disabled}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        {...otherProps}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
    </StyledCustomPasswordInput>
  )
}

// CUSTOM TEXTFIELD START
export const CustomFormLabel = ({ children }) => {
  return <StyledCustomFormLabel>{children}</StyledCustomFormLabel>
}
