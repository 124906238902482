import React, { useState, useEffect } from 'react'
import { Buffer } from 'buffer'
import { useTheme } from '@emotion/react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import { SignupFormWrapper } from './style'
import {
  CheckboxCheckIcon,
  CheckboxUncheckBorderIcon,
  ChevronRightIcon,
  SocialArrobaIcon,
  SocialFacebookIcon,
  SocialGooglePlusIcon,
  SocialOdnoklassnikiIcon,
  SocialVKIcon,
  SpinnerSVG
} from 'assets/icons/index'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import {
  CustomFormLabel,
  CustomPasswordInput,
  CustomTextField
} from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import { useDispatch, useSelector } from 'react-redux'
import { userSignUp } from 'redux-thunk/thunk/auth.thunk'
import { getAllBonus } from 'redux-thunk/thunk/bonus.thunk'
import CustomDatePicker from 'components/common-ui/CustomDateTimePicker/CustomDatePicker'
import { Form, Formik } from 'formik'
import { userSignupSchema } from './schema'
import ErrorMessage from '../ErrorMessage/index'
import { useTranslation } from 'react-i18next'
import { formatDateMDY } from 'utils/dateFormatter'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { getAllCmsSiteData } from 'redux-thunk/thunk/gameSetting'
import Parser from 'html-react-parser'

const SignupForm = ({ setShowLoginForm, handleClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { [LOADER_HANDLER_TYPES.submit]: loading } = useSelector(state => state.loader)
  const { [LOADER_HANDLER_TYPES.content]: isLoading } = useSelector(state => state.loader)
  const { selectedLanguage } = useSelector((state) => state.language)
  const bonus = useSelector((state) => state.bonus.allBonus?.bonus)
  const langCode = selectedLanguage?.code
  const { cmsData } = useSelector((state) => state?.gameSetting)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault()
  }
  const slug = cmsData?.find(item => item?.slug?.includes('terms'))?.slug
  const [showFormWrap1, setShowFormWrap1] = useState(true)
  const [showFormWrap2, setShowFormWrap2] = useState(false)
  const [showFormWrap3, setShowFormWrap3] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [ageChecked, setAgeChecked] = useState(false)
  const [bonusCode, setBonusCode] = useState('')
  const [termsConditionData, setTermsConditionData] = useState('')
  const [policyData, setPolicyData] = useState('')
  const [finalValues, setFinalValues] = useState({
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    dateOfBirth: '',
    email: '',
    bonusCode: bonusCode || ''
  })
  const dispatch = useDispatch()

  const handleTermsCondition = () => {
    console.log('handleTermsCondition called')
    const handleSuccess = (res) => setTermsConditionData(res)
    const data = {
      cmsId: slug,
      selectedLanguage
    }
    dispatch(getAllCmsSiteData({ data, handleSuccess }))
  }

  const handlePrivacyPolicy = () => {
    console.log('policy called')

    const handleSuccess = (res) => setPolicyData(res)
    const data = {
      cmsId: 'privacy-policy',
      selectedLanguage
    }
    dispatch(getAllCmsSiteData({ data, handleSuccess }))
  }
  useEffect(() => {
    handleTermsCondition()
    handlePrivacyPolicy()
  }, [])

  console.log(termsConditionData, policyData)
  const handleFormWrap1ButtonClick = () => {
    setShowFormWrap1(false)
    setShowFormWrap2(true)
    setBonusCode('')
  }

  const loginWithBonus = (bonusCode) => {
    setShowFormWrap1(false)
    setShowFormWrap2(true)
    setBonusCode(bonusCode)
  }

  const backToFirstPage = () => {
    setShowFormWrap1(true)
    setShowFormWrap2(false)
  }

  const handleBack = () => {
    setShowFormWrap3(false)
    setShowFormWrap2(true)
  }

  const handleFormWrap2ButtonClick = (formValues) => {
    setFinalValues({ ...formValues })
    setShowFormWrap2(false)
    setShowFormWrap3(true)
  }

  const signUpSuccess = () => {
    setShowFormWrap3(false)
    setShowSuccessMessage(true)
  }

  const handleRegister = () => {
    const uniqueKey = (Math.random() + 1).toString(36)
    const userDetails = {
      ...finalValues,
      confirmPassword: Buffer.from(finalValues.confirmPassword).toString('base64'),
      password: Buffer.from(finalValues.password).toString('base64'),
      dateOfBirth: formatDateMDY(finalValues.dateOfBirth),
      uniqueKey,
      bonusCode
    }
    if (localStorage.getItem('stag') !== null) {
      userDetails.stag = localStorage.getItem('stag')
    } else if (localStorage.getItem('referCode') !== null) {
      userDetails.referCode = localStorage.getItem('referCode')
    }
    dispatch(userSignUp({ userDetails, signUpSuccess }))
    setFinalValues({})
  }

  // const { allBonus } = useSelector(state => state.bonus)

  useEffect(() => {
    dispatch(getAllBonus())
  }, [])

  return (
    <>
      <SignupFormWrapper theme={theme}>
        {showFormWrap1 && (
          <Box className='form-wrap-1'>
            <Box component='form' noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Typography variant='h3' className='form-title'>
                    {t('registration')}
                  </Typography>

                  <Box className='social-auth-wrap'>
                    <Typography variant='caption'>
                      {t('registerViaSocialNetworks')}
                    </Typography>
                    <Box className='social-auth-box'>
                      <SocialArrobaIcon />
                      <SocialOdnoklassnikiIcon />
                      <SocialFacebookIcon />
                      <SocialVKIcon />
                      <SocialGooglePlusIcon />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={9}>
                  <Grid container rowSpacing={0.875} columnSpacing={0.625}>
                    <Grid item xs={12} md={12}>
                      <Typography variant='caption'>
                        {t('chooseYourWelcomeBonus')}
                      </Typography>
                    </Grid>
                    {isLoading
                      ? <SpinnerSVG style={{ fill: 'white' }} />
                      : (bonus && bonus?.rows?.map((item) => {
                          const title = item?.bonusTitle
                          if (item?.bonusSlug === 'joining') {
                            // show bonus cards only for joining bonuses
                            return (
                              <Grid item xs={12} lg={4} key={item?.bonusId}>
                                <Card className='welcome-bonus-card'>
                                  <CardContent>
                                    <Typography variant='h4'>
                                      {title[langCode?.toUpperCase()] || title.EN}
                                    </Typography>
                                    <Button onClick={() => loginWithBonus(item?.bonusCode)}>{t('useNow')}</Button>
                                    <Typography variant='button' onClick={() => { window.open('/cms/general-terms', '_blank') }}>
                                      {t('readTheTerms')}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )
                          } else {
                            return null
                          }
                        })
                        )}
                  </Grid>
                </Grid>
              </Grid>

              <Box className='aut-btn-box'>
                <CustomPrimaryButton onClick={handleFormWrap1ButtonClick}>
                  {t('proceedWithoutWelcomeBonus')}
                </CustomPrimaryButton>
                <Typography variant='caption'>
                  {t('doYouAlreadyHaveAccount')}
                  <Typography
                    variant='button'
                    onClick={() => setShowLoginForm('login')}
                  >
                    {t('login')}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {showFormWrap2 && (
          <Box className='form-wrap-2'>
            <Typography variant='h3' className='form-title'>
              {t('registration')}
            </Typography>
            <Box component='form' noValidate>
              <Formik
                enableReinitialize
                initialValues={{ ...finalValues }}
                validationSchema={userSignupSchema(t)}
                validateOnMount
                validateOnBlur
                validateOnChange
                onSubmit={(formValues) => handleFormWrap2ButtonClick(formValues)}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                }) => {
                  return (
                    <Form>
                      <Grid container spacing={1.5}>
                        <Grid item xs={12} md={6}>
                          <Grid container rowSpacing={1.25} columnSpacing={0.625}>
                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('profileFName')}*</CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                required
                                type='text'
                                placeholder={t('profileFName')}
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='firstName'
                              />
                              <ErrorMessage
                                component='p'
                                name='firstName'
                                key='firstName'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('profileLname')}*</CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                type='text'
                                placeholder={t('profileLname')}
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='lastName'
                              />
                              <ErrorMessage
                                component='p'
                                name='lastName'
                                key='lastName'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('profileUserName')} *</CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                type='text'
                                placeholder={t('profileUserName')}
                                value={values.username}
                                onChange={(e) => setFieldValue('username', e.target.value?.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''))}
                                onBlur={handleBlur}
                                name='username'
                              />
                              <ErrorMessage
                                component='p'
                                name='username'
                                key='username'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('profileEmail')} *</CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                type='email'
                                placeholder={t('profileEmail')}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='email'
                              />
                              <ErrorMessage
                                component='p'
                                name='email'
                                key='email'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('profilePassword')} *</CustomFormLabel>
                              <CustomPasswordInput
                                fullWidth
                                showPassword={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                                name='password'
                                handleMouseDownPassword={handleMouseDownPassword}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={t('profilePassword')}
                              />
                              <ErrorMessage
                                component='p'
                                name='password'
                                key='password'
                                className='error-msg'
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('signupConfirmPassword')} *</CustomFormLabel>
                              <CustomPasswordInput
                                fullWidth
                                showPassword={showConfirmPassword}
                                handleClickShowPassword={handleClickShowConfirmPassword}
                                handleMouseDownPassword={handleMouseDownConfirmPassword}
                                name='confirmPassword'
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={t('signupConfirmPassword')}
                              />
                              <ErrorMessage
                                component='p'
                                name='confirmPassword'
                                key='confirmPassword'
                                className='error-msg'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('profileDob')} *</CustomFormLabel>
                              <CustomDatePicker
                                fullWidth
                                value={values.dateOfBirth}
                                onChange={(date) => setFieldValue('dateOfBirth', date)}
                                maxDate={new Date()}
                                onBlur={handleBlur}
                                name='dateOfBirth'
                              />
                              <ErrorMessage
                                component='p'
                                name='dateOfBirth'
                                key='dateOfBirth'
                                className='error-msg'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CustomFormLabel>{t('promoCode')}</CustomFormLabel>
                              <CustomTextField
                                fullWidth
                                type='text'
                                placeholder={t('promoCode')}
                                value={bonusCode || values.promoCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name='promoCode'
                                disabled={bonusCode}
                              />
                              <ErrorMessage
                                component='p'
                                name='promoCode'
                                key='promoCode'
                                className='error-msg'
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Box className='social-auth-wrap'>
                            <Typography variant='caption'>
                              {t('registerViaSocialNetworks')}
                            </Typography>
                            <Box className='social-auth-box'>
                              <SocialArrobaIcon />
                              <SocialOdnoklassnikiIcon />
                              <SocialFacebookIcon />
                              <SocialVKIcon />
                              <SocialGooglePlusIcon />
                            </Box>
                          </Box>
                          <Box className='aut-btn-box'>
                            <CustomPrimaryButton
                              fullWidth
                              onClick={handleSubmit}
                            >
                              {t('paginationNextBtn')}
                            </CustomPrimaryButton>
                            {/* </Box> */}
                            {/* <Box className='aut-btn-box'> */}
                            <CustomPrimaryButton
                              fullWidth
                              onClick={backToFirstPage}
                              disabled={loading}
                            >
                              {t('back')}
                            </CustomPrimaryButton>
                          </Box>
                          <Typography
                            variant='caption'
                            sx={{
                              textAlign: 'center',
                              display: 'block',
                              marginTop: theme.spacing(1.25)
                            }}
                          >
                            {t('doYouAlreadyHaveAccount')}
                            <Typography
                              variant='button'
                              onClick={() => setShowLoginForm('login')}
                            >
                              {t('login')}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </Box>
          </Box>
        )}

        {showFormWrap3 && (
          <Box className='form-wrap-3'>
            <Box component='form' noValidate>
              <Grid container spacing={1.5}>
                <Grid item xs={12} md={6}>
                  <Paper className='terms-privacy-paper'>
                    <Box className='terms-privacy-box'>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ChevronRightIcon />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          {t('footerTerms')}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant='body1'>
                            {Parser(termsConditionData?.content)}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ChevronRightIcon />}
                          aria-controls='panel2-content'
                          id='panel2-header'
                        >
                          {t('acceptPrivacyPolicy')}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant='body1'>
                            {Parser(policyData?.content)}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <FormGroup className='checkbox-wrap'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckboxUncheckBorderIcon />}
                              checkedIcon={<CheckboxCheckIcon />}
                              inputProps={{ 'aria-label': 'Star checkbox' }}
                              checked={ageChecked}
                              onChange={() => setAgeChecked(!ageChecked)}
                            />
                          }
                          label={t('iconfirmIm18YearsOlder')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckboxUncheckBorderIcon />}
                              checkedIcon={<CheckboxCheckIcon />}
                              inputProps={{ 'aria-label': 'Star checkbox' }}
                              checked={termsChecked}
                              onChange={() => setTermsChecked(!termsChecked)}
                            />
                          }
                          label={t('iAgreeWithTermsAndConditions')}
                        />
                      </FormGroup>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className='social-auth-wrap'>
                    <Typography variant='caption'>
                      {t('registerViaSocialNetworks')}
                    </Typography>
                    <Box className='social-auth-box'>
                      <SocialArrobaIcon />
                      <SocialOdnoklassnikiIcon />
                      <SocialFacebookIcon />
                      <SocialVKIcon />
                      <SocialGooglePlusIcon />
                    </Box>
                  </Box>
                  <Box className='aut-btn-box'>
                    <CustomPrimaryButton fullWidth onClick={() => handleRegister()} disabled={loading || !!(!termsChecked || !ageChecked)}>
                      {!loading
                        ? t('registration')
                        : <SpinnerSVG style={{ fill: 'white' }} />}
                    </CustomPrimaryButton>
                    <CustomPrimaryButton
                      fullWidth
                      onClick={handleBack}
                      disabled={loading}
                    >
                      {t('back')}
                    </CustomPrimaryButton>
                  </Box>
                  <Typography
                    variant='caption'
                    sx={{
                      textAlign: 'center',
                      display: 'block',
                      marginTop: theme.spacing(1.25)
                    }}
                  >
                    {t('doYouAlreadyHaveAccount')}
                    <Typography
                      variant='button'
                      onClick={() => setShowLoginForm('login')}
                    >
                      {t('login')}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}

        {showSuccessMessage && (
          <Box className='welcome-text-box'>
            <Typography variant='body1' className='pera-text'>
              {t('thankYouSuccessfullyRegisteringCashMachine')}
            </Typography>
            <Typography variant='body1' className='pera-text'>
              {t('verifyEmailMessage')}
            </Typography>
            <CustomPrimaryButton fullWidth onClick={handleClose}>{t('gotIt')}</CustomPrimaryButton>
          </Box>
        )}
      </SignupFormWrapper>
    </>
  )
}

export default React.memo(SignupForm)
