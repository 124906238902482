import { Box, styled } from '@mui/material'

export const MobileMyAccountWrapper = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    '& .mobile-my-account-wrap': {
      '& .header-box': {
        background: theme.palette.gradientColor.myAccountHeading,
        padding: theme.spacing(1.25, 1),
        '& .title-text': {
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.typographyText.textMain,
          fontSize: theme.spacing(1.25),
          fontWeight: '400',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          textAlign: 'center'
        }
      },
      '& .body-box': {
        height: `calc(100dvh - ${theme.spacing(4.125)})`,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        padding: theme.spacing(1),
        '& .logout-btn-box': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: 'auto',
          '& .MuiButtonBase-root': {
            marginTop: theme.spacing(1.25)
          }
        }
      },
      '& .MuiAccordion-root': {
        backgroundColor: theme.palette.darkShade.dark_01,
        backgroundImage: 'none',
        borderRadius: theme.spacing(0.313),
        boxShadow: theme.shadows[0],
        margin: theme.spacing(0, 0, 0.375),
        '&::before': {
          display: 'none'
        },
        '&.Mui-expanded': {
          margin: theme.spacing(0, 0, 0.375)
        },
        '& .MuiAccordionSummary-root': {
          minHeight: 'unset',
          padding: theme.spacing(1),
          '&.Mui-expanded': {
            minHeight: 'unset'
          },
          '& .MuiAccordionSummary-content': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1),
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: theme.spacing(0.875),
            margin: theme.spacing(0),
            '&.Mui-expanded': {
              margin: theme.spacing(0)
            }
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            '& svg': {
              width: theme.spacing(1),
              height: theme.spacing(1),
              fill: theme.palette.typographyText.textMain,
              textShadow: theme.shadows[4]
            }
          }
        },
        '& .MuiAccordionDetails-root': {
          backgroundColor: theme.palette.lightShade.light_02,
          borderRadius: theme.spacing(0, 0, 0.313, 0.313),
          padding: theme.spacing(1)
        }
      }
    },
    '& .tabs-container': {
      '& .MuiTabs-root': {
        minHeight: 'unset',
        '& .MuiTabScrollButton-root': {
          width: theme.spacing(1.5),
          backgroundColor: theme.palette.darkShade.dark_03,
          '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
            fill: theme.palette.typographyText.textMain
          },
          '&.Mui-disabled': {
            display: 'none'
          }
        },
        '& .MuiTabs-hideScrollbar': {
          borderBottom: `1px solid ${theme.palette.lightShade.light_02}`,
          marginBottom: `${theme.spacing(0)} !important`,
          '& .MuiTabs-flexContainer': {
            '& .MuiTab-root': {
              minWidth: 'unset',
              minHeight: 'unset',
              color: theme.palette.typographyText.textMain,
              fontSize: theme.spacing(1),
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'capitalize',
              padding: theme.spacing(0.5, 0.875),
              '&.Mui-selected': {
                fontWeight: '600'
              }
            }
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.border.active
          }
        }
      },
      '& .tab-content': {
        paddingTop: theme.spacing(1)
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .content-wrapper': {
        height: '100%',
        paddingRight: theme.spacing(0)
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(0.25)
      }
    }
  }
})
