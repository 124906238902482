import React, { forwardRef } from 'react'
import { DepositWithdrawalDialogWrapper } from './style'
import { useTheme } from '@emotion/react'
import { Box, DialogContent, IconButton, Slide } from '@mui/material'
import { CloseBoldIcon } from 'assets/icons/index'
import Lottie from 'lottie-react'
import cashMachineAnimation from '../../../assets/lottie-animation-json/cashbot.json'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const DepositWithdrawalDialog = (props) => {
  const theme = useTheme()
  const paymentModalBorder = theme.themeImages.paymentModalBorder

  const {
    dialogOpen,
    handleDialogClose,
    title,
    children,
    fullWidth,
    maxWidth,
    fullScreen,
    PaperComponent,
    scroll
  } = props

  return (
    <DepositWithdrawalDialogWrapper
      theme={theme}
      title={title}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      PaperComponent={PaperComponent}
      scroll={scroll}
    >
      {handleDialogClose
        ? (
          <IconButton
            className='dialog-close-btn'
            aria-label='close'
            onClick={handleDialogClose}
          >
            <CloseBoldIcon />
          </IconButton>
          )
        : null}
      <DialogContent>
        {children}
        <img src={paymentModalBorder} className='modal-bg-border' alt='BG Img' loading='lazy' />
        <Box className='atm-animation-img-box'>
          <Lottie
            animationData={cashMachineAnimation}
            loop
            autoplay
          />
        </Box>
      </DialogContent>
    </DepositWithdrawalDialogWrapper>
  )
}

export default React.memo(DepositWithdrawalDialog)
