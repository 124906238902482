import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstance from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getGamesListService = (params, hasMore = false) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/casino/category-wise-games', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: hasMore ? LOADER_HANDLER_TYPES.submit : LOADER_HANDLER_TYPES.content
  })
}

export const getGamesSearchService = (params, hasMore = false) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/casino/all-games', {}, {
    server: microServices.SERVICE_URL_1,
    params,
    loader: hasMore ? LOADER_HANDLER_TYPES.submit : LOADER_HANDLER_TYPES.content
  })
}

export const addFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/api/v1/casino/favourite', data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const removeFavouriteGameService = (data) => {
  return axiosInstance(METHOD_TYPES.delete, '/api/v1/casino/favourite', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data
  })
}

export const getGameStartService = (params) => {
  // return axiosInstance(METHOD_TYPES.get, '/api/game-category', {}, {
  //   server: microServices.SERVICE_URL_1,
  //   params
  // })
}

export const getFavoriteGameStartService = (params) => {
  return axiosInstance(METHOD_TYPES.get, '/api/v1/casino/favourite', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.content,
    params
  })
}
