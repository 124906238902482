import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Grid, Typography, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { ProviderModalWrapper } from './style'
import CustomDialog from 'components/common-ui/CustomDialog/CustomDialog'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from 'assets/icons/index'

const ProvidersModal = ({ dialogOpen, handleClose, casinoProviderList, providerSelectHandler, selectedProvierData }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [maxWidth] = React.useState('customModal_xm')
  const [inputValue, setInputValue] = useState('')
  const [searchResult, setSearchResult] = useState([])

  const providerSearchHandler = (value) => {
    const res = casinoProviderList?.filter((item) => item?.name?.toLowerCase().includes(value))
    setSearchResult(res)
  }

  useEffect(() => {
    providerSearchHandler(inputValue)
  }, [inputValue])

  const searchBar = (
    <FormControl variant='outlined' className='search-wrap'>
      <OutlinedInput
        type='text'
        value={inputValue}
        onChange={(e) => {
          providerSearchHandler(e.target.value)
          setInputValue(e.target.value?.toLowerCase())
        }}
        placeholder={t('searchProvider')}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              edge='end'
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
      }
      />
    </FormControl>
  )

  return (
    <>
      <CustomDialog
        maxWidth={maxWidth}
        // fullScreen={fullScreen}
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        aria-labelledby='customized-dialog-title'
      >
        <ProviderModalWrapper theme={theme}>
          <Box className='dialog-content-wrap'>
            <Box className='provider-content-box'>
              <Box className='provider-options-container'>
                <Box className='provider-options-box'>
                  <Grid container spacing={{ md: 2, xs: 1 }} justifyContent='center'>
                    <Grid item xs={12} md={4}>
                      {searchBar}
                    </Grid>
                  </Grid>

                  <Grid container spacing={{ md: 2, xs: 1 }}>
                    {inputValue.length === 0 &&
                      <Grid item xs={12} md={4} onClick={() => providerSelectHandler('')}>
                        <CustomMainButton
                          fullWidth
                          // onClick={() => handleSearch('')}
                          className='selected-provider provider-btn'
                        >
                          <Box className='flag-box'>
                            <Box className='img-box'>
                              <img
                                src='/assets/images/stock-images/coinImage.png'
                                alt='flag'
                                loading='lazy'
                              />
                            </Box>
                          </Box>
                          {t('all')}
                        </CustomMainButton>
                      </Grid>}
                    {(inputValue?.length ? searchResult : casinoProviderList)?.map((providerItem, i) => providerItem?.name && (
                      <Grid item xs={12} md={4} key={i} onClick={() => providerSelectHandler(providerItem?.name, providerItem?.id)}>
                        <CustomMainButton
                          fullWidth
                          // onClick={() => console.log(providerItem?.name)}
                          className='provider-btn'
                        >
                          <Box className='flag-box'>
                            <Box className='img-box'>
                              <img
                                src={providerItem?.thumbnail_url || '/assets/images/stock-images/coinImage.png'}
                                alt='flag'
                                loading='lazy'
                              />
                            </Box>
                          </Box>
                          {providerItem?.name}
                        </CustomMainButton>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='modal-heading-box'>
            <Typography variant='h1'>{t('provider')}</Typography>
          </Box>
        </ProviderModalWrapper>
      </CustomDialog>
    </>
  )
}

export default React.memo(ProvidersModal)
