import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isPasswordCorrect: null,
  limitDataLoading: false,
  userDetails: {}
}
const {
  actions: {
    setDailyLimitSuccess,
    setDepositLimitSuccess,
    setLossLimitSuccess,
    setDisableUntilSuccess,
    setSessionTimeSuccess,
    updatePasswordStatus,
    getLimitTableSuccess
  },
  reducer
} = createSlice({
  name: 'limits',
  initialState,
  reducers: {
    setDailyLimitSuccess: (state) => ({
      ...state,
      limitDataLoading: true,
      isPasswordCorrect: null
    }),
    setDepositLimitSuccess: (state) => ({
      ...state,
      limitDataLoading: true,
      isPasswordCorrect: null
    }),

    setLossLimitSuccess: (state) => ({
      ...state,
      limitDataLoading: true,
      isPasswordCorrect: null
    }),

    setDisableUntilSuccess: (state) => ({
      ...state,
      isPasswordCorrect: null
    }),

    setSessionTimeSuccess: (state) => ({
      ...state,
      isPasswordCorrect: null
    }),
    updatePasswordStatus: (state, { payload }) => ({
      ...state,
      // limitDataLoading:false,
      isPasswordCorrect: payload
    }),
    getLimitTableSuccess: (state, { payload }) => {
      return ({
        ...state,
        limitDataLoading: false,
        userDetails: {
          ...state?.userDetails,
          userLimit: {
            ...state?.userDetails?.userLimit,
            ...(payload?.dailyBetLimit?.usedLimit !== null && { dailyBetUsedLimit: payload?.dailyBetLimit?.usedLimit }),
            ...(payload?.weeklyBetLimit?.usedLimit !== null && { weeklyBetUsedLimit: payload?.weeklyBetLimit?.usedLimit }),
            ...(payload?.monthlyBetLimit?.usedLimit !== null && { monthlyBetUsedLimit: payload?.monthlyBetLimit?.usedLimit }),
            ...(payload?.dailyLossLimit?.usedLimit !== null && { dailyLossUsedLimit: payload?.dailyLossLimit?.usedLimit }),
            ...(payload?.weeklyLossLimit?.usedLimit !== null && { weeklyLossUsedLimit: payload?.weeklyLossLimit?.usedLimit }),
            ...(payload?.monthlyLossLimit?.usedLimit !== null && { monthlyLossUsedLimit: payload?.monthlyLossLimit?.usedLimit }),
            ...(payload?.dailyDepositLimit?.usedLimit !== null && { dailyDepositUsedLimit: payload?.dailyDepositLimit?.usedLimit }),
            ...(payload?.weeklyDepositLimit?.usedLimit !== null && { weeklyDepositUsedLimit: payload?.weeklyDepositLimit?.usedLimit }),
            ...(payload?.monthlyDepositLimit?.usedLimit !== null && { monthlyDepositUsedLimit: payload?.monthlyDepositLimit?.usedLimit })
          }
        }
      })
    }

  }
})

export default reducer
export {
  setDailyLimitSuccess,
  setDepositLimitSuccess,
  setLossLimitSuccess,
  setDisableUntilSuccess,
  setSessionTimeSuccess,
  updatePasswordStatus,
  getLimitTableSuccess

}
