import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Box, Pagination, PaginationItem, Typography, Grid, MenuItem } from '@mui/material'
import { BetsHistoryWrapper } from './style'
import CustomTable from 'components/common-ui/CustomTable/index'
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, SpinnerSVG } from 'assets/icons/index'
import { CustomFormLabel, CustomSelect, CustomTextField } from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import CustomDateTimePicker from 'components/common-ui/CustomDateTimePicker/CustomDateTimePicker'
import { useDispatch, useSelector } from 'react-redux'
import { getUserCasinoTransactions } from 'redux-thunk/thunk/user.thunk'
import dayjs from 'dayjs'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import { LOADER_HANDLER_TYPES } from 'constants/index'
import { useTranslation } from 'react-i18next'

const columns = (t) => ([
  {
    id: '#',
    label: <Typography textAlign='left'>#</Typography>
  },
  {
    id: 'betTime',
    label: <Typography textAlign='left'>{t('betTime')}</Typography>
  },
  {
    id: 'amount',
    label: <Typography textAlign='left'>{t('amount')}</Typography>
  },
  {
    id: 'type',
    label: <Typography textAlign='left'>{t('actionType')}</Typography>
  },
  {
    id: 'game',
    label: <Typography textAlign='left'>{t('game')}</Typography>
  }
])

const BetsHistory = (props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { usersCasinoTransactions } = useSelector((state) => state.transactions)
  const [transactionsData, setTransData] = useState([])
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(7, 'day'))
  const [endDate, setEndDate] = useState(dayjs(new Date()))
  const [actionType, setActionType] = useState('all')
  const [search, setSearch] = useState('')
  const { [LOADER_HANDLER_TYPES.table]: loading } = useSelector(state => state.loader)
  const { t } = useTranslation()

  useEffect(() => {
    const params = {
      limit: 5,
      startDate: startDate.$d.toISOString().split('T')[0],
      endDate: endDate.$d.toISOString().split('T')[0],
      actionType: actionType,
      search: search,
      pageNo: page
    }
    dispatch(getUserCasinoTransactions(params))
  }, [])

  useEffect(() => {
    const myData = usersCasinoTransactions?.rows?.map((bet) => {
      return ({
        '#': <Typography textAlign='left'>{bet.id}</Typography>,
        betTime: <Typography textAlign='left'>{new Date(bet.createdAt).toLocaleDateString()}</Typography>,
        amount: <Typography textAlign='left'>{bet.currencyCode} {bet.amount}</Typography>,
        type: <Typography textAlign='left' className='success'>{bet.actionType}</Typography>,
        game: <Typography textAlign='left'>{bet.CasinoGame?.name}</Typography>
      })
    })
    setTransData(myData)
  }, [usersCasinoTransactions])

  useEffect(() => {
    const params = {
      limit: 5,
      startDate: startDate.$d.toISOString().split('T')[0],
      endDate: endDate.$d.toISOString().split('T')[0],
      actionType: actionType,
      search: search,
      pageNo: page
    }
    dispatch(getUserCasinoTransactions(params))
  }, [page])

  const handleShow = () => {
    const params = {
      limit: 5,
      startDate: startDate.$d.toISOString().split('T')[0],
      endDate: endDate.$d.toISOString().split('T')[0],
      actionType: actionType,
      pageNo: 1,
      search: search
    }
    dispatch(getUserCasinoTransactions(params))
  }

  const transactions = (
    <>
      <CustomTable columns={columns(t)} data={transactionsData} />
      <Pagination
        count={(usersCasinoTransactions?.count / 5) < 1 ? 1 : Math.ceil(usersCasinoTransactions?.count / 5)}
        onChange={(e, value) => setPage(value)}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            {...item}
          />
        )}
      />
    </>
  )

  return (
    <>
      <BetsHistoryWrapper theme={theme} className='content-wrapper'>
        <Box component='form' noValidate>
          <Grid container spacing={{ md: 1.25, xs: 0.75 }}>
            <Grid item xs={6} lg={3}>
              <CustomFormLabel>{t('startDate')}</CustomFormLabel>
              <CustomDateTimePicker value={startDate} handleChange={setStartDate} />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CustomFormLabel>{t('endDate')}</CustomFormLabel>
              <CustomDateTimePicker value={endDate} handleChange={setEndDate} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('merchant')}</CustomFormLabel>
              <CustomSelect
                fullWidth
                value={actionType}
                onChange={(e) => setActionType(e.target.value)}
                displayEmpty
                IconComponent={ChevronDownIcon}
              >
                <MenuItem value='all'>{t('all')}</MenuItem>
                <MenuItem value='win'>{t('win')}</MenuItem>
                <MenuItem value='bet'>{t('bet')}</MenuItem>
              </CustomSelect>
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>{t('search')}</CustomFormLabel>
              <CustomTextField
                fullWidth
                type='text'
                placeholder={t('seachHere')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                <Grid item xs={12} lg={4}>
                  <Box className='form-btn-box'>
                    <CustomPrimaryButton fullWidth onClick={handleShow}>
                      {!loading
                        ? t('show')
                        : <SpinnerSVG style={{ fill: 'white' }} />}
                    </CustomPrimaryButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Typography className='bet-results-text'>{t('betResults')} {startDate?.$d?.toISOString()} - {endDate?.$d?.toISOString()}</Typography>
        {usersCasinoTransactions?.count
          ? transactions
          : <Typography className='bet-results-text'>{t('noTransactionsFoundForselectedDate')}</Typography>}
      </BetsHistoryWrapper>
    </>
  )
}

export default React.memo(BetsHistory)
