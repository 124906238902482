import React from 'react'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { GamesSliderWrapper } from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import GameCardHoverNon from 'pages/GameCard/GameCardHoverNon'
import SectionHeading from 'components/common-ui/SectionHeading/SectionHeading'
import { CaretLeftIcon, CaretRightIcon } from 'assets/icons/index'
import Jackpot from 'pages/Jackpot/index'
import { CustomIconButton } from 'components/common-ui/CustomButton/CustomButton'

const GamesSlider = ({ gameSliderArr, headingNameNeon, showSlider, showJackpot, t }) => {
  const theme = useTheme()

  const params = {
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next'
    }
  }

  return (
    <GamesSliderWrapper theme={theme}>
      <Box className='games-slider-wrap'>

        <SectionHeading headingNameNeon={headingNameNeon} id='gameslider' />
        {showJackpot && <Jackpot />}
        {showSlider &&
          <Box className='game-slider-box'>
            <Swiper
              modules={[Navigation]}
              // loop
              navigation
              slidesPerView={1}
              spaceBetween={4}
              breakpoints={{
                992: {
                  slidesPerView: 5
                },
                768: {
                  slidesPerView: 4
                },
                600: {
                  slidesPerView: 3
                },
                425: {
                  slidesPerView: 2
                },
                0: {
                  slidesPerView: 1
                }
              }}
              className='mySwiper'
              {...params}
            >
              {gameSliderArr.map(({ gameImg, thumbnailUrl, id, casinoGameId }) => (
                <SwiperSlide key={id}>
                  <GameCardHoverNon t={t} gameImg={gameImg || thumbnailUrl} gameCode={casinoGameId} />
                </SwiperSlide>
              ))}

              <CustomIconButton className='swiper-button swiper-button-prev'>
                <CaretLeftIcon />
              </CustomIconButton>
              <CustomIconButton className='swiper-button swiper-button-next'>
                <CaretRightIcon />
              </CustomIconButton>
            </Swiper>
          </Box>}
      </Box>
    </GamesSliderWrapper>
  )
}
export default GamesSlider
