import { createSlice } from '@reduxjs/toolkit'
import { depositAmountRequest } from 'redux-thunk/thunk/user.thunk'

const initialState = {
  depositData: null,
  showWalletPopup: false
}
const {
  actions: {
    setDepositData,
    setShowWalletPopup
  },
  reducer
} = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setShowWalletPopup: (state, action) => ({
      ...state,
      showWalletPopup: action.payload
    }),
    setDepositData: (state, action) => ({
      ...state,
      depositData: null
    })

  },
  extraReducers: (builder) => {
    builder
      .addCase(depositAmountRequest.fulfilled, (state, action) => {
        return {
          ...state,
          depositData: action.payload
        }
      })
  }
})

export default reducer
export {
  setDepositData,
  setShowWalletPopup
}
