import MediaCard from 'components/layout/Card/Card'
import * as React from 'react'
import { useTheme } from '@emotion/react'
import { Box, Typography, Grid } from '@mui/material'
import { memo, useEffect, useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPromotions } from 'redux-thunk/thunk/bonus.thunk'
import { useTranslation } from 'react-i18next'
import { PromotionsCasinoStyle } from './style'
import InfiniteScroll from '../../../../node_modules/react-infinite-scroll-component/dist/index'

const PromotionsCasino = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()
  const id = location.state?.id || 1
  const dispatch = useDispatch()
  const { promotionsData, promotionsLoader } = useSelector(state => state.bonus)
  const { language } = useSelector((state) => state.auth)
  const [page, setPage] = useState(1)
  const visitCardHandler = (card) => {
    navigate(`/promotion/${card?.id}`, { state: { data: card } })
  }

  const fetchPromotions = () => {
    const reqBody = {
      category: id,
      limit: 10,
      pageNo: page
    }
    dispatch(getPromotions(reqBody))
  }

  useEffect(() => {
    fetchPromotions()
  }, [])
  useEffect(() => { fetchPromotions() }, [page])

  const promotionsList = useCallback(() => {
    if (!promotionsData || !promotionsData.promotions || !promotionsData.promotions.rows) {
      return []
    }
    return promotionsData.promotions.rows.map(promotionData => ({
      ...promotionData,
      title: promotionData?.title?.[language?.toUpperCase()] ? promotionData?.title?.[language?.toUpperCase()] : promotionData?.title?.['EN'?.toUpperCase()],
      content: promotionData?.content?.[language?.toUpperCase()] ? promotionData?.content?.[language?.toUpperCase()] : promotionData?.content?.['EN'?.toUpperCase()],
      description: promotionData?.description?.[language?.toUpperCase()] ? promotionData?.description?.[language?.toUpperCase()] : promotionData?.description?.['EN'?.toUpperCase()]

    }))
  }, [promotionsData, language])

  return (
    <PromotionsCasinoStyle>
      <Box className='promotions-casino-container'>
        <Typography className='main-heading'>{id === 1 ? t('casino') : t('sponsorships')}</Typography>

        {
          promotionsLoader
            ? (
              <div className='game-loader'><img src='/Loader.gif' height='40px' width='40px' alt='loaderImg' /></div>
              )
            : (
              <>
                {
                  promotionsList().length === 0
                    ? (
                      <div className='game-loader'>
                        <Typography className='nothing-found'>{id === 1 ? t('casino') : t('nothingFound')}</Typography>
                      </div>
                      )
                    : (
                      <InfiniteScroll
                        dataLength={promotionsList()?.length}
                        next={() => setPage(page + 1)}
                        hasMore={promotionsList()?.length < promotionsData?.promotions?.count}
                        scrollThreshold={0.6}
                        loader={<div className='game-loader'><img src='/Loader.gif' height='40px' width='40px' alt='loaderImg' /></div>}
                      >
                        <Grid container spacing={2}>
                          {promotionsList()?.map((card) => (
                            <Grid key={uuidv4()} onClick={() => visitCardHandler(card)} item xs={12} sm={6} md={4} lg={3}>
                              <MediaCard
                                image={`${process.env.REACT_APP_S3_URL}/${card?.image}`}
                                title={card?.title}
                                description={card?.description}
                                sx={{ width: '100%' }}
                                cardBackground={theme.palette.gradientColor.gameCardOverlay}
                              />
                            </Grid>
                          ))}
                        </Grid>

                      </InfiniteScroll>
                      )
                }
              </>
              )
        }
      </Box>
    </PromotionsCasinoStyle>

  )
}

export default memo(PromotionsCasino)
