import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCasinoProviderListService, getCasinoBannerListService } from 'network/services/casino.service'
import { setCasinoProviderListData, setCasinoBannerListData } from 'redux-thunk/redux/slices/casino.slice'

export const getCasinoProviderList = createAsyncThunk(
  '/getCasinoProviderList',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoProviderListService(data)
      thunkApi.dispatch(setCasinoProviderListData(res?.providers))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)

export const getCasinoBannerList = createAsyncThunk(
  '/getCasinoBannerList',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoBannerListService()
      const data = res?.banners?.rows
      thunkApi.dispatch(setCasinoBannerListData(data))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error && error[0] && error[0]?.description)
    }
  }
)
