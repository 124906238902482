import { Box, Typography, styled } from '@mui/material'

export const SectionHeadingWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }
})

export const HeadingTypographyNeon = styled(Typography)(({ theme }) => {
  return {
    fontFamily: 'HelloDenverDisplay-Regular',
    color: theme.palette.typographyText.sectionHeading,
    fontSize: theme.spacing(8),
    fontWeight: 'normal',
    textTransform: 'uppercase',
    letterSpacing: theme.spacing(0.75),
    textAlign: 'center',
    textShadow: theme.textShadows[1],
    '& span': {
      minWidth: theme.spacing(2.5),
      display: 'inline-block',
      opacity: 1,
      transition: 'opacity 0.2s'
    },
    '&.polish-animation': {
      fontFamily: theme.typography.fontFamily,
      textShadow: theme.textShadows[5]
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(4)
    },
    [theme.breakpoints.down('sd')]: {
      fontSize: theme.spacing(3),
      '& span': {
        minWidth: theme.spacing(2)
      }
    }
  }
})
