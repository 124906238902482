import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  CircularProgress
} from '@mui/material'
import { DepositWrapper } from './style'
import { SWIPER_SLIDER_PARAMS } from 'constants/index'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import { CaretArrowLeftIcon } from 'assets/icons/index'
import { getUserBonus } from 'redux-thunk/thunk/bonus.thunk'
import { useDispatch, useSelector } from 'react-redux'
import BonusCard from 'pages/Bonus/BonusCard'

const Bonus = ({ paymentType, handlePrevious }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const bonus = useSelector(state => state?.bonus?.userBonus?.bonus)
  const { bonusLoading } = useSelector(state => state?.bonus)

  useEffect(() => {
    dispatch(getUserBonus())
  }, [])

  return (
    <DepositWrapper>
      <Box className='form-steps step-1'>
        <Box className='form-box'>
          <Typography variant='h1' className='title-text'>
            {t('depositBonus')}
          </Typography>

          <Box className='payment-section'>
            {bonusLoading
              ? <Box className='loader-wraper'><CircularProgress /></Box>
              : (
                <Swiper
                  modules={[Navigation]}
                  // loop
                  navigation
                  slidesPerView={1}
                  spaceBetween={4}
                  breakpoints={{
                    992: {
                      slidesPerView: 1
                    },
                    768: {
                      slidesPerView: 1
                    },
                    600: {
                      slidesPerView: 1
                    },
                    425: {
                      slidesPerView: 1
                    },
                    0: {
                      slidesPerView: 1
                    }
                  }}
                  className='mySwiper'
                  {...SWIPER_SLIDER_PARAMS}
                >
                  {bonus?.rows?.map((bonusData, index) => {
                    if (bonusData?.bonusSlug !== 'deposit') return null
                    const desc = JSON.parse(bonusData?.description)
                    const termCondition = JSON.parse(bonusData?.termCondition)
                    return (
                      <SwiperSlide key={`${index}`}>
                        <BonusCard
                          data={bonusData}
                          key={bonusData?.bonusId}
                          bonusId={bonusData?.bonusId}
                          bonusTitle={bonusData?.bonusTitle}
                          bonusCode={bonusData?.bonusCode}
                          bonusType={bonusData?.bonusSlug}
                          description={desc}
                          depositBonusPercent={bonusData?.depositBonusPercent}
                          validFrom={bonusData?.validFrom}
                          validTo={bonusData?.validTo}
                          termCondition={termCondition}
                          bonusImg={bonusData?.bonusImg || '/assets/images/stock-images/offers/offer-thumbnail.png'}
                          from='offer'
                          isClaimed={bonusData?.isClaimed}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>)}
          </Box>
        </Box>

        <Box className='bottom-btn-wrap'>
          <Typography
            variant='button'
            className='back-btn'
            onClick={() => handlePrevious('bonus')}
          >
            <Box className='icon-box'>
              <CaretArrowLeftIcon />
            </Box>
            {paymentType === 'deposit' ? t('deposit') : t('withdraw')}
          </Typography>
        </Box>
      </Box>
    </DepositWrapper>
  )
}

export default Bonus
