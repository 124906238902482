import { lazy } from 'react'
import { ROUTE_PATHS } from 'constants/index'
import VisitedCard from 'pages/Sponsorships/index'
import PromotionsCasino from 'pages/Promotions/Casino/PromotionsCasino'
import { Cms } from 'pages/Cms/index'
import Bonus from 'pages/Bonus/index'
import Home from 'pages/Home/index'

// const Home = lazy(() => import('pages/Home'))
const GamePlayContainer = lazy(() => import('pages/GamePlay'))
const Promotions = lazy(() => import('pages/Promotions/index'))
const ForgotPassword = lazy(() => import('pages/ForgotPassword'))
const Tournament = lazy(() => import('pages/Tournament/'))
const MoreTournament = lazy(() => import('pages/Tournament/MoreTournament'))

const routesList = [
  {
    path: ROUTE_PATHS.HOME,
    component: Home,
    isPrivate: false,
    showHeader: true,
    showFooter: true,
    isFullLayout: false
  },
  {
    path: ROUTE_PATHS.CASINO_PLAYGAME,
    component: GamePlayContainer,
    isPrivate: true,
    showHeader: true,
    showFooter: false,
    isFullLayout: true
  },
  {
    path: ROUTE_PATHS.VERIFY_EMAIL,
    component: Home,
    isPrivate: false,
    showHeader: true,
    showFooter: true,
    isFullLayout: false
  },
  {
    path: ROUTE_PATHS.PROMOTIONS,
    component: Promotions,
    isPrivate: false,
    showHeader: true,
    showFooter: true,
    isFullLayout: false
  },
  {
    path: ROUTE_PATHS.PROMOTIONS_PAGE_ID,
    component: VisitedCard,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.PROMOTIONLIST,
    component: PromotionsCasino,
    isPrivate: false,
    showHeader: true,
    showFooter: true
  },
  {
    path: ROUTE_PATHS.CMS,
    component: Cms,
    isPrivate: true,
    showHeader: true,
    showFooter: false,
    isFullLayout: true
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    component: ForgotPassword,
    isPrivate: false,
    showHeader: true,
    showFooter: false,
    isFullLayout: true
  },
  {
    path: ROUTE_PATHS.TOURNAMENT,
    component: Tournament,
    isPrivate: true,
    showHeader: true,
    showFooter: true,
    isFullLayout: true
  },
  {
    path: ROUTE_PATHS.MORETOURNAMENT,
    component: MoreTournament,
    isPrivate: true,
    showHeader: true,
    showFooter: true,
    isFullLayout: true
  },
  {
    path: ROUTE_PATHS.BONUSES,
    component: Bonus,
    isPrivate: true,
    showHeader: true,
    showFooter: true,
    isFullLayout: true
  }
]

export default routesList
