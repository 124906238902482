import React from 'react'
import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { MainLoaderWrapper } from './style'
import Lottie from 'lottie-react'
import cashLoaderAnimation from '../../../assets/lottie-animation-json/cash-loader.json'

const MainLoader = () => {
  const theme = useTheme()

  return (
    <MainLoaderWrapper theme={theme}>
      <Box className='loader-box'>
        <Lottie
          animationData={cashLoaderAnimation}
          loop
          autoplay
        />
      </Box>
    </MainLoaderWrapper>
  )
}

export default MainLoader
