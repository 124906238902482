import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  generalDataLoading: false,
  generalData: null,
  siteData: null,
  settingData: null
}

const {
  actions: {
    getGeneralDataSuccess,
    getSiteDataSuccess,
    getSettingDataSuccess
  },
  reducer
} = createSlice({
  name: 'general',
  initialState,
  reducers: {
    getGeneralDataSuccess: (state, { payload }) => {
      return ({
        ...state,
        generalDataLoading: false,
        generalData: payload
      })
    },
    getSiteDataSuccess: (state, { payload }) => {
      return ({
        ...state,
        siteData: payload
      })
    },
    getSettingDataSuccess: (state, { payload }) => {
      return ({
        ...state,
        settingData: payload
      })
    }
  }
})

export default reducer
export {
  getGeneralDataSuccess,
  getSiteDataSuccess,
  getSettingDataSuccess
}
