import React, { useEffect, useState } from 'react'
import _PaymentIQCashier from 'paymentiq-cashier-bootstrapper'
import { CircularProgress, Box } from '@mui/material'

const PIQIframe = ({ paymentIdDetails, providerType, amount, method, noDataMessage }) => {
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line
    paymentIdDetails?.userId && paymentIdDetails?.sessionId &&
    new _PaymentIQCashier('#cashier',
      {
        merchantId: process.env.REACT_APP_MERCHANT_ID,
        userId: paymentIdDetails?.userId,
        sessionId: paymentIdDetails?.sessionId,
        environment: 'test', // if not set, defaults to production
        method: method, // if not set, defaults to deposit
        providerType: providerType,
        amount: amount
      },
      (api) => {
        console.log('Cashier intialized and ready to take down the empire')
        setLoader(true)
        // register callbacks
        api.on({
          cashierInitLoad: () => {
            console.log('Cashier init load')
          },
          update: data => console.log('The passed in data was set', data),
          success: data => console.log('Transaction was completed successfully', data),
          failure: data => console.log('Transaction failed', data),
          pending: data => console.log('Transaction is pending', data),
          unresolved: data => console.log('Transaction is unresolved', data),
          isLoading: data => {
            console.log('Data is loading', data)
          },
          doneLoading: data => {
            console.log('Data has been successfully downloaded', data)
            setLoader(false)
          },
          newProviderWindow: data => console.log('A new window / iframe has opened', data),
          paymentMethodSelect: data => console.log('Payment method was selected', data),
          paymentMethodPageEntered: data => console.log('New payment method page was opened', data),
          navigate: data => console.log('Path navigation triggered', data),
          cancelledPendingWD: data => console.log('A pending withdrawal has been cancelled', data),
          validationFailed: data => console.log('Transaction attempt failed at validation', data),
          cancelled: data => console.log('Transaction has been cancelled by user', data),
          onLoadError: data => console.log('Cashier could not load properly', data),
          transactionInit: data => console.log('A new transaction has been initiated', data)
        })
      })
  }, [])

  return (
    <>
      {loader && <Box sx={{ m: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>}
      {(!paymentIdDetails?.userId || !paymentIdDetails?.sessionId) && <Box sx={{ m: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{noDataMessage}</Box>}
      <div id='cashier' />
    </>
  )
}

export default PIQIframe
