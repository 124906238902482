import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import MainLoader from 'components/common-ui/MainLoader/index'

const NotFoundComponent = () => {
  const { t } = useTranslation()
  return (
    <Suspense
      fallback={<MainLoader />}
    >
      <div className='pt-5 static-root h-100 page-not-found bg-container' style={{ color: '#FFF' }}>
        <h2 className='text-center'>
          <i className='pr-2 fa fa-exclamation-triangle' aria-hidden='true' />
          {t('404')}
        </h2>
        <div className='mt-3 notFound text-center'>
          <p>
            {t('pageNotFound')}
          </p>
        </div>
      </div>
    </Suspense>
  )
}
export default NotFoundComponent
