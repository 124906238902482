
import { styled } from '@mui/styles'
import { Box } from '@mui/material'

export const PromotionsCasinoStyle = styled(Box)(({ theme }) => {
  return {
    '& .promotions-casino-container': {
      width: '71vw',
      maxWidth: theme.spacing(85.25),
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .main-heading': {
        fontSize: 25,
        fontWeight: 700,
        paddingLeft: '0.5rem'
      },
      '& .game-loader': {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
      },
      '& .nothing-found': {
        fontSize: 25,
        fontWeight: 700,
        paddingLeft: '0.5rem',
        justifyContent: 'center'
      }

    }
  }
})
