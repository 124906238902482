import { Menu, styled } from '@mui/material'

export const CustomMenuDropdownWrapper = styled(Menu)(({ theme }) => {
  return {
    '& .MuiMenu-paper': {
      '& .MuiList-root': {
        maxHeight: `${theme.spacing(20)} !important`,
        overflowY: 'auto !important'
      }
    },
    '&.games-dropdown-menu': {
      '& .MuiMenu-paper': {
        minWidth: `${theme.spacing(12.875)} !important`,
        backgroundColor: `${theme.palette.solidColor.transparent} !important`,
        background: 'none !important',
        border: 'none !important',
        padding: `${theme.spacing(0, 1.5)} !important`,
        marginTop: `${theme.spacing(-0.125)} !important`,
        '& .MuiList-root': {
          backgroundColor: `${theme.palette.solidColor.light} !important`,
          border: `1px solid ${theme.palette.border.light} !important`,
          borderRadius: `${theme.spacing(0, 0, 0.813, 0.813)} !important`,
          '& .MuiMenuItem-root': {
            color: `${theme.palette.typographyText.textMain} !important`,
            fontSize: `${theme.spacing(1)} !important`,
            fontWeight: '700 !important'
          }
        }
      }
    }
  }
})
