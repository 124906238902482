import { Dialog, styled } from '@mui/material'

export const DepositWithdrawalDialogWrapper = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiBackdrop-root': {
      // backdropFilter: 'blur(4px)'
    },
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: theme.spacing(80.375),
      height: `calc(100% - ${theme.spacing(2)})`,
      maxHeight: '100%',
      background: 'none',
      borderRadius: theme.spacing(0),
      overflow: 'hidden',
      '& .dialog-close-btn': {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        zIndex: '2',
        position: 'absolute',
        top: theme.spacing(6.25),
        right: theme.spacing(1.25),
        '& svg': {
          fill: theme.palette.typographyText.textMain,
          filter: `drop-shadow(1px 2px 2px ${theme.palette.darkShade.dark_04})`
        }
      },
      '& .MuiDialogContent-root': {
        width: '100%',
        padding: theme.spacing(5, 0, 0, 0),
        '& .modal-bg-border': {
          width: '100%',
          height: `calc(100% - ${theme.spacing(5)})`,
          pointerEvents: 'none',
          position: 'absolute',
          inset: theme.spacing(5, 0, 0, 0)
        },
        '& .atm-animation-img-box': {
          width: theme.spacing(17.375),
          height: theme.spacing(17.375),
          pointerEvents: 'none',
          position: 'absolute',
          top: theme.spacing(-1),
          left: theme.spacing(0),
          '& svg': {
            width: '100%',
            height: '100%',
            transform: 'scale(1.3) !important'
          }
        },
        '& .tab-content-wrap': {
          width: '100%',
          height: '100%',
          background: theme.palette.gradientColor.DialogBg,
          maskImage: 'url("/assets/images/stock-images/payment-modal-bg.png")',
          maskRepeat: 'no-repeat',
          maskSize: '100% 100%',
          overflow: 'hidden',
          zIndex: '1'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper': {
        maxWidth: '100%',
        height: '100%',
        maxHeight: `calc(100% - ${theme.spacing(0)})`,
        margin: theme.spacing(0),
        '& .modal-bg-border, & .atm-animation-img-box': {
          display: 'none'
        },
        '& .dialog-close-btn': {
          width: theme.spacing(2.25),
          height: theme.spacing(2.25),
          top: theme.spacing(0.938),
          right: theme.spacing(0.875)
        },
        '& .MuiDialogContent-root': {
          padding: theme.spacing(0),
          '& .tab-content-wrap': {
            width: '100%',
            maxWidth: '100%',
            background: theme.palette.gradientColor.DialogBg,
            maskImage: 'none',
            padding: theme.spacing(0)
          }
        }
      }
    }
  }
})
