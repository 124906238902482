import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tab: 'lobby',
  favGame: '',
  gameTab: [],
  searchGame: '',
  provider: [],
  gameCategory: '',
  providerName: ''
}

const {
  actions: {
    setTab,
    setViewFavGame,
    setGameTab,
    setSearchGame,
    setProvider,
    setGameCategory,
    setProviderName
  },
  reducer
} = createSlice({
  name: 'lobby',
  initialState: initialState,
  reducers: {
    setTab: (state, { payload }) => ({
      ...state,
      tab: payload
    }),
    setViewFavGame: (state, { payload }) => ({
      ...state,
      favGame: payload
    }),
    setGameTab: (state, { payload }) => ({
      ...state,
      gameTab: payload
    }),
    setSearchGame: (state, { payload }) => ({
      ...state,
      searchGame: payload
    }),
    setProvider: (state, { payload }) => ({
      ...state,
      provider: payload
    }),
    setGameCategory: (state, { payload }) => ({
      ...state,
      gameCategory: payload
    }),
    setProviderName: (state, { payload }) => ({
      ...state,
      providerName: payload
    })
  }
})

export default reducer
export {
  setTab,
  setViewFavGame,
  setGameTab,
  setSearchGame,
  setProvider,
  setGameCategory,
  setProviderName
}
