import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'translation/index'
import ErrorBoundary from 'components/layout/ErrorBoundary/index'
import { Provider } from 'react-redux'
import store from 'redux-thunk/store'
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
