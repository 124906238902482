import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useMediaQuery,
  CircularProgress
} from '@mui/material'
import { DepositWrapper } from './style'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import {
  CaretArrowLeftIcon,
  ChatIcon,
  CheckboxCheckIcon,
  CheckboxUncheckFillIcon,
  MailIcon,
  MinusIcon,
  PlusIcon,
  GiftIcon
} from 'assets/icons/index'
import { CustomTextField } from 'components/common-ui/CustomFormInputs/CustomFormInputs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PIQIframe from '../PIQ/index'
import { Form, Formik } from 'formik'
import { DEFAULT_MAX_PAYMENT_AMOUNT, DEFAULT_MIN_PAYMENT_AMOUNT, depositSchema, withdrawSchema } from './schema'
import ErrorMessage from 'components/layout/ErrorMessage/index'
import Bonus from './Bonus'

const Payment = ({ step, handleNext, handlePrevious, paymentType }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { t } = useTranslation()
  const { userWallet } = useSelector((state) => state.user)
  const { paymentProviderType, loading, paymentIdDetails } = useSelector((state) => state.payment)

  const [amount, setAmount] = useState(0)
  const [selectedProvider, setSelectedProvider] = useState(null)

  useEffect(() => {
    if (step === 2) {
      setAmount(0)
    }
  }, [step])

  const handleAmountChange = (e) => {
    setAmount(e?.target?.value)
  }

  const handleProviderSelect = (e, providerType) => {
    setSelectedProvider(providerType)
    handleNext()
  }

  const handleAmountSubmit = (formValues) => {
    setAmount(formValues?.amount)
    handleNext()
  }

  return (
    <>
      {loading
        ? <Box className='loader-wraper'><CircularProgress /></Box>
        : (
          <DepositWrapper theme={theme} className='content-wrapper'>
            {/* STEP 1 START */}
            {step === 1 && (
              <Box className='form-steps step-1'>
                <Box className='form-box'>
                  <Typography variant='h1' className='title-text'>
                    {t('yourRealBalance')} <Typography>{userWallet?.amount} {userWallet?.currency?.code}</Typography>
                  </Typography>
                  <Typography variant='h2' className='subtitle-text'>
                    {t('chooseYourPaymentMethod')}
                  </Typography>

                  <Box className='payment-section'>
                    <Grid container spacing={{ md: 1.25, xs: 0.75 }}>
                      {paymentProviderType?.map((providerType, i) => (
                        <Grid item xs={6} sm={3} md={3} key={i}>
                          <Button className='payment-logo-btn' onClick={(e) => handleProviderSelect(e, providerType)}>
                            <img src={providerType.imageUrl} alt={providerType?.name} loading='lazy' />
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
                {/* <Grid xs={12} lg={12}>
                  <Box className='form-btn-box' onClick={handleNext}>
                    <CustomPrimaryButton>{t('balanceHistory')}</CustomPrimaryButton>
                  </Box>
                </Grid> */}

                <Box className='bottom-btn-wrap'>
                  <Box className='btn-grp'>
                    <Typography variant='button' className='chat-mail-btn' onClick={(e) => handleNext('bonus')}>
                      <Box className='icon-box'>
                        <GiftIcon />
                      </Box>
                      {t('depositBonus')}
                    </Typography>
                    <Typography variant='button' className='chat-mail-btn'>
                      <Box className='icon-box'>
                        <ChatIcon />
                      </Box>
                      {t('liveChat')}
                    </Typography>
                    <Typography variant='button' className='chat-mail-btn'>
                      <Box className='icon-box'>
                        <MailIcon />
                      </Box>
                      {t('mail')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {/* STEP 2 START */}
            {step === 2 && (
              <Box className='form-steps step-2'>
                {isDesktop
                  ? (null)
                  : (
                    <Button className='mobile-back-btn' onClick={handlePrevious}>
                      <Box className='icon-box'>
                        <CaretArrowLeftIcon />
                      </Box>
                      {paymentType === 'deposit' ? t('deposit') : t('withdraw')}
                    </Button>)}
                <Box className='form-box'>
                  <Typography variant='h1' className='title-text'>
                    {t('yourRealBalance')} <Typography>{userWallet?.amount} {userWallet?.currency?.code}</Typography>
                  </Typography>

                  <Box component='form' noValidate>
                    <Box className='payment-section'>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={6} sm={3} md={3}>
                          <Box className='payment-logo-box'>
                            {selectedProvider?.imageUrl
                              ? <img src={selectedProvider?.imageUrl} alt={selectedProvider?.name} />
                              : <img
                                  src='/assets/images/stock-images/payment-options-logos/paymsystem_visa.png'
                                  alt='Payment Logo' loading='lazy'
                                />}
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <Typography variant='h2' className='subtitle-text'>
                            {t('chooseEnterYourDepositAmounts')}
                          </Typography>
                        </Grid>
                        <Formik
                          enableReinitialize
                          validateOnMount
                          validateOnBlur
                          validateOnChange
                          initialValues={{ amount: 0, termscheck: false }}
                          validationSchema={paymentType === 'deposit' ? depositSchema(t, userWallet) : withdrawSchema(t, userWallet)}
                          onSubmit={(formValues) =>
                            handleAmountSubmit(formValues)}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            setFieldValue
                          }) => {
                            return (
                              <Form autoComplete='off'>
                                <Grid item xs={12} lg={12}>
                                  <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                                    <Grid item xs={12} lg={6}>
                                      <InputLabel className='amount-input-label'>{t('minimum')} {userWallet?.currency?.code} {DEFAULT_MIN_PAYMENT_AMOUNT} - {userWallet?.currency?.code} {DEFAULT_MAX_PAYMENT_AMOUNT}</InputLabel>
                                      <CustomTextField
                                        fullWidth
                                        type='number'
                                        name='amount'
                                        placeholder='Amount'
                                        className='amount-input'
                                        onBlur={handleBlur}
                                        value={values.amount}
                                        onChange={(e) => {
                                          handleChange(e)
                                          handleAmountChange(e)
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position='start'>
                                              <IconButton onClick={(e) => {
                                                setFieldValue('amount', Number(values?.amount) - 1)
                                                setAmount(amount - 1)
                                              }}
                                              >
                                                <MinusIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton onClick={(e) => {
                                                setFieldValue('amount', Number(values?.amount) + 1, true)
                                                setAmount(amount + 1)
                                              }}
                                              >
                                                <PlusIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                      <ErrorMessage
                                        component='p'
                                        name='amount'
                                        className='error-msg'
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                                    <Grid item xs={12} lg={6}>
                                      <FormGroup className='checkbox-wrap'>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name='termscheck'
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.termscheck}
                                              icon={<CheckboxUncheckFillIcon />}
                                              checkedIcon={<CheckboxCheckIcon />}
                                              inputProps={{ 'aria-label': 'Star checkbox' }}
                                            />
                                          }
                                          label={t('iHaveReadAndAgreePaymentSystemRestrictions')}
                                        />
                                      </FormGroup>
                                      <ErrorMessage
                                        component='p'
                                        name='termscheck'
                                        className='error-msg'
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                  <Box className='form-btn-box' mt={1.5}>
                                    <CustomPrimaryButton onClick={handleSubmit} type='submit'>{t('addInfo')}</CustomPrimaryButton>
                                  </Box>
                                </Grid>
                              </Form>
                            )
                          }}
                        </Formik>
                      </Grid>
                    </Box>
                  </Box>
                </Box>

                <Box className='bottom-btn-wrap'>
                  {isDesktop
                    ? (
                      <Typography
                        variant='button'
                        className='back-btn'
                        onClick={handlePrevious}
                      >
                        <Box className='icon-box'>
                          <CaretArrowLeftIcon />
                        </Box>
                        {paymentType === 'deposit' ? t('deposit') : t('withdraw')}
                      </Typography>)
                    : null}
                  <Box className='btn-grp'>
                    <Typography variant='button' className='chat-mail-btn'>
                      <Box className='icon-box'>
                        <ChatIcon />
                      </Box>
                      {t('liveChat')}
                    </Typography>
                    <Typography variant='button' className='chat-mail-btn'>
                      <Box className='icon-box'>
                        <MailIcon />
                      </Box>
                      {t('mail')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {/* STEP 3 START */}
            {step === 3 && (
              <Box className='form-steps step-2'>
                <Box className='form-box'>
                  <PIQIframe paymentIdDetails={paymentIdDetails} providerType={selectedProvider?.providerType} amount={amount} method={paymentType} noDataMessage={t('signinTryAgain')} />
                </Box>
                <Box>
                  <Typography>{t('paymentNote')}</Typography>
                </Box>

                <Box className='bottom-btn-wrap'>
                  {isDesktop
                    ? (
                      <Typography
                        variant='button'
                        className='back-btn'
                        onClick={handlePrevious}
                      >
                        <Box className='icon-box'>
                          <CaretArrowLeftIcon />
                        </Box>
                        {paymentType === 'deposit' ? t('deposit') : t('withdraw')}
                      </Typography>)
                    : null}
                  <Box className='btn-grp'>
                    <Typography variant='button' className='chat-mail-btn'>
                      <Box className='icon-box'>
                        <ChatIcon />
                      </Box>
                      {t('liveChat')}
                    </Typography>
                    <Typography variant='button' className='chat-mail-btn'>
                      <Box className='icon-box'>
                        <MailIcon />
                      </Box>
                      {t('mail')}
                    </Typography>
                  </Box>
                </Box>
              </Box>)}

            {step === 'bonus' && (
              <Bonus paymentType={paymentType} handlePrevious={handlePrevious} />
            )}

            {/* STEP 3 START */}
            {/* {step === 3 && (
            <Box className='form-steps step-3'>
              {isDesktop
                ? (
                  null
                )
                : (
                  <Button className='mobile-back-btn' onClick={handlePrevious}>
                    <Box className='icon-box'>
                      <CaretArrowLeftIcon />
                    </Box>
                    {t('deposit')}
                  </Button>
                )}
              <Box className='form-box'>
                <Typography variant='h1' className='title-text' style={{ textTransform: 'uppercase' }}>
                  {t('addInfo')}
                </Typography>

                <Box className='payment-section'>
                  <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <CustomFormLabel>{t('profileFName')}</CustomFormLabel>
                          <CustomTextField fullWidth type='text' placeholder={t('profileFName')} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <CustomFormLabel>{t('profileLname')}</CustomFormLabel>
                          <CustomTextField fullWidth type='text' placeholder={t('profileLname')} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <CustomFormLabel>{t('profilePhone')}</CustomFormLabel>
                          <CustomTextField fullWidth type='text' placeholder='Phone number' />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box className='form-btn-box' mt={1.5} onClick={handleNext}>
                        <CustomPrimaryButton>{t('deposit')}</CustomPrimaryButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box className='bottom-btn-wrap'>
                {isDesktop
                  ? (
                    <Typography
                      variant='button'
                      className='back-btn'
                      onClick={handlePrevious}
                    >
                      <Box className='icon-box'>
                        <CaretArrowLeftIcon />
                      </Box>
                      {t('deposit')}
                    </Typography>
                  )
                  : null}
                <Box className='btn-grp'>
                  <Typography variant='button' className='chat-mail-btn'>
                    <Box className='icon-box'>
                      <ChatIcon />
                    </Box>
                    {t('liveChat')}
                  </Typography>
                  <Typography variant='button' className='chat-mail-btn'>
                    <Box className='icon-box'>
                      <MailIcon />
                    </Box>
                    {t('mail')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )} */}

            {/* STEP 4 START */}
            {/* {step === 4 && (
            <Box className='form-steps step-4'>
              {isDesktop
                ? (
                  null
                )
                : (
                  <Button className='mobile-back-btn' onClick={handlePrevious}>
                    <Box className='icon-box'>
                      <CaretArrowLeftIcon />
                    </Box>
                    {t('deposit')}
                  </Button>
                )}
              <Box className='form-box'>
                <Typography variant='h1' className='title-text' sx={{ marginTop: '3rem' }}>
                  {t('yourRealBalance')} <Typography>€99.99</Typography>
                </Typography>

                <Box className='payment-section'>
                  <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                    <Grid item xs={6} sm={3} md={3}>
                      <Box className='payment-logo-box'>
                        <img
                          src='/assets/images/stock-images/payment-options-logos/paymsystem_visa.png'
                          alt='Payment Logo' loading='lazy'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Typography variant='h2' className='subtitle-text'>
                        {t('chooseEnterYourDepositAmounts')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <InputLabel className='amount-input-label'>{t('minimum')} €10-€10,000</InputLabel>
                          <CustomTextField
                            fullWidth
                            type='text'
                            placeholder='Amount'
                            className='amount-input'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <IconButton>
                                    <MinusIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton>
                                    <PlusIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <FormGroup className='checkbox-wrap'>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<CheckboxUncheckFillIcon />}
                                  checkedIcon={<CheckboxCheckIcon />}
                                  inputProps={{ 'aria-label': 'Star checkbox' }}
                                />
                              }
                              label={t('iHaveReadAndAgreePaymentSystemRestrictions')}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box className='form-btn-box' mt={1.5} onClick={handleNext}>
                        <CustomPrimaryButton>{t('deposit')}</CustomPrimaryButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box className='bottom-btn-wrap'>
                {isDesktop
                  ? (
                    <Typography
                      variant='button'
                      className='back-btn'
                      onClick={handlePrevious}
                    >
                      <Box className='icon-box'>
                        <CaretArrowLeftIcon />
                      </Box>
                      {t('deposit')}
                    </Typography>
                  )
                  : null}
                <Box className='btn-grp'>
                  <Typography variant='button' className='chat-mail-btn'>
                    <Box className='icon-box'>
                      <ChatIcon />
                    </Box>
                    {t('liveChat')}
                  </Typography>
                  <Typography variant='button' className='chat-mail-btn'>
                    <Box className='icon-box'>
                      <MailIcon />
                    </Box>
                    {t('mail')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )} */}

            {/* STEP 5 START */}
            {/* {step === 5 && (
            <Box className='form-steps step-5'>
              {isDesktop
                ? (
                  null
                )
                : (
                  <Button className='mobile-back-btn' onClick={handlePrevious}>
                    <Box className='icon-box'>
                      <CaretArrowLeftIcon />
                    </Box>
                    {t('deposit')}
                  </Button>
                )}
              <Box className='form-box'>
                <Typography variant='h1' className='title-text'>
                  {t('yourRealBalance')} <Typography>€99.99</Typography>
                </Typography>

                <Box className='payment-section'>
                  <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                    <Grid item xs={6} sm={3} md={3}>
                      <Box className='payment-logo-box'>
                        <img
                          src='/assets/images/stock-images/payment-options-logos/paymsystem_visa.png'
                          alt='Payment Logo' loading='lazy'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Typography variant='h2' className='subtitle-text'>
                        {t('chooseEnterYourDepositAmounts')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <InputLabel className='amount-input-label'>{t('minimum')} €10-€10,000</InputLabel>
                          <CustomTextField
                            fullWidth
                            type='text'
                            placeholder='Amount'
                            className='amount-input'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <IconButton>
                                    <MinusIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton>
                                    <PlusIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={{ md: 1.25, xs: 0.75 }} justifyContent='center'>
                        <Grid item xs={12} lg={6}>
                          <FormGroup className='checkbox-wrap'>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<CheckboxUncheckFillIcon />}
                                  checkedIcon={<CheckboxCheckIcon />}
                                  inputProps={{ 'aria-label': 'Star checkbox' }}
                                />
                              }
                              label='I have read and agree with the payment systems restrictions*'
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box className='form-btn-box' mt={1.5}>
                        <CustomPrimaryButton>{t('deposit')}</CustomPrimaryButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box className='bottom-btn-wrap'>
                {isDesktop
                  ? (
                    <Typography
                      variant='button'
                      className='back-btn'
                      onClick={handlePrevious}
                    >
                      <Box className='icon-box'>
                        <CaretArrowLeftIcon />
                      </Box>
                      {t('deposit')}
                    </Typography>
                  )
                  : null}
                <Box className='btn-grp'>
                  <Typography variant='button' className='chat-mail-btn'>
                    <Box className='icon-box'>
                      <ChatIcon />
                    </Box>
                    {t('liveChat')}
                  </Typography>
                  <Typography variant='button' className='chat-mail-btn'>
                    <Box className='icon-box'>
                      <MailIcon />
                    </Box>
                    {t('mail')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )} */}
          </DepositWrapper>)}
    </>
  )
}

export default React.memo(Payment)
