import React, { useState, useEffect } from 'react'
import { StyledAnimatedButton, StyledIconButton, StyledMainButton, StyledPrimaryButton, StyledQuaternaryButton, StyledSecondaryButton, StyledTertiaryButton } from './style'
import {
  Box,
  Typography
} from '@mui/material'

// MAIN BUTTON START
export const CustomMainButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  fontSize,
  ...otherProps
}) => {
  return (
    <StyledMainButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <Typography className={`text ${fontSize || 'md'}`}>{children}</Typography>
      <Box className='left-img-box'>
        <img src='/assets/images/stock-images/main-btn-left.png' className='bg-img-left' alt='BG Left Img' />
        <img src='/assets/images/stock-images/quaternary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
      </Box>
      <Box className='center-img-box'>
        <img src='/assets/images/stock-images/main-btn-center.png' className='bg-img-center' alt='BG Center Img' />
        <img src='/assets/images/stock-images/quaternary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
      </Box>
      <Box className='right-img-box'>
        <img src='/assets/images/stock-images/main-btn-right.png' className='bg-img-right' alt='BG Right Img' />
        <img src='/assets/images/stock-images/quaternary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
      </Box>
    </StyledMainButton>
  )
}

// PRIMARY BUTTON START
export const CustomPrimaryButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  fontSize,
  ...otherProps
}) => {
  return (
    <StyledPrimaryButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <Typography className={`text ${fontSize || 'md'}`}>{children}</Typography>
      <Box className='left-img-box'>
        <img src='/assets/images/stock-images/primary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
        <img src='/assets/images/stock-images/quaternary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
      </Box>
      <Box className='center-img-box'>
        <img src='/assets/images/stock-images/primary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
        <img src='/assets/images/stock-images/quaternary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
      </Box>
      <Box className='right-img-box'>
        <img src='/assets/images/stock-images/primary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
        <img src='/assets/images/stock-images/quaternary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
      </Box>
    </StyledPrimaryButton>
  )
}

// SECONDARY BUTTON START
export const CustomSecondaryButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  fontSize,
  ...otherProps
}) => {
  return (
    <StyledSecondaryButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <Typography className={`text ${fontSize || 'md'}`}>{children}</Typography>
      <Box className='left-img-box'>
        <img src='/assets/images/stock-images/secondary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
        <img src='/assets/images/stock-images/quaternary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
      </Box>
      <Box className='center-img-box'>
        <img src='/assets/images/stock-images/secondary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
        <img src='/assets/images/stock-images/quaternary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
      </Box>
      <Box className='right-img-box'>
        <img src='/assets/images/stock-images/secondary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
        <img src='/assets/images/stock-images/quaternary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
      </Box>
    </StyledSecondaryButton>
  )
}

// TERTIARY BUTTON START
export const CustomTertiaryButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledTertiaryButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <Typography className='text'>{children}</Typography>
      <Box className='left-img-box'>
        <img src='/assets/images/stock-images/tertiary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
        <img src='/assets/images/stock-images/quaternary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
      </Box>
      <Box className='center-img-box'>
        <img src='/assets/images/stock-images/tertiary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
        <img src='/assets/images/stock-images/quaternary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
      </Box>
      <Box className='right-img-box'>
        <img src='/assets/images/stock-images/tertiary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
        <img src='/assets/images/stock-images/quaternary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
      </Box>
    </StyledTertiaryButton>
  )
}

// QUATERNARY BUTTON START
export const CustomQuaternaryButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledQuaternaryButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <Typography className='text'>{children}</Typography>
      <Box className='left-img-box'>
        <img src='/assets/images/stock-images/quaternary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
        <img src='/assets/images/stock-images/quaternary-btn-left.png' className='bg-img-left' alt='BG Left Img' />
      </Box>
      <Box className='center-img-box'>
        <img src='/assets/images/stock-images/quaternary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
        <img src='/assets/images/stock-images/quaternary-btn-center.png' className='bg-img-center' alt='BG Center Img' />
      </Box>
      <Box className='right-img-box'>
        <img src='/assets/images/stock-images/quaternary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
        <img src='/assets/images/stock-images/quaternary-btn-right.png' className='bg-img-right' alt='BG Right Img' />
      </Box>
    </StyledQuaternaryButton>
  )
}

// ICON BUTTON START
export const CustomIconButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  return (
    <StyledIconButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <img src='/assets/images/stock-images/icon-btn.png' className='bg-img' alt='BG Img' />
      {children}
    </StyledIconButton>
  )
}

// ANIMATED BUTTON START
export const CustomAnimatedButton = ({
  type,
  variant,
  color,
  size,
  children,
  disabled,
  fullWidth,
  onClick,
  startIcon,
  endIcon,
  disableElevation,
  isLoading,
  ...otherProps
}) => {
  const imagesLeft = [
    '/assets/images/stock-images/main-btn-left.png',
    '/assets/images/stock-images/primary-btn-left.png',
    '/assets/images/stock-images/secondary-btn-left.png',
    '/assets/images/stock-images/tertiary-btn-left.png',
    '/assets/images/stock-images/quaternary-btn-left.png'
  ]

  const imagesCenter = [
    '/assets/images/stock-images/main-btn-center.png',
    '/assets/images/stock-images/primary-btn-center.png',
    '/assets/images/stock-images/secondary-btn-center.png',
    '/assets/images/stock-images/tertiary-btn-center.png',
    '/assets/images/stock-images/quaternary-btn-center.png'
  ]

  const imagesRight = [
    '/assets/images/stock-images/main-btn-right.png',
    '/assets/images/stock-images/primary-btn-right.png',
    '/assets/images/stock-images/secondary-btn-right.png',
    '/assets/images/stock-images/tertiary-btn-right.png',
    '/assets/images/stock-images/quaternary-btn-right.png'
  ]

  const [currentImageIndexLeft, setCurrentImageIndexLeft] = useState(0)
  const [currentImageIndexCenter, setCurrentImageIndexCenter] = useState(0)
  const [currentImageIndexRight, setCurrentImageIndexRight] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndexLeft((prevIndex) => (prevIndex + 1) % imagesLeft.length)
      setCurrentImageIndexCenter((prevIndex) => (prevIndex + 1) % imagesCenter.length)
      setCurrentImageIndexRight((prevIndex) => (prevIndex + 1) % imagesRight.length)
    }, 1000) // Change image every 1 second

    return () => clearInterval(intervalId)
  }, [imagesLeft.length, imagesCenter.length, imagesRight.length])

  return (
    <StyledAnimatedButton
      type={type}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
      {...otherProps}
    >
      <Typography className='text md'>{children}</Typography>
      <Box className='left-img-box'>
        {imagesLeft.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`BG Left Img ${index + 1}`}
            className={`bg-img-left ${index === currentImageIndexLeft ? 'visible' : 'hidden'}`}
          />
        ))}
      </Box>
      <Box className='center-img-box'>
        {imagesCenter.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`BG Center Img ${index + 1}`}
            className={`bg-img-center ${index === currentImageIndexCenter ? 'visible' : 'hidden'}`}
          />
        ))}
      </Box>
      <Box className='right-img-box'>
        {imagesRight.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`BG Right Img ${index + 1}`}
            className={`bg-img-right ${index === currentImageIndexRight ? 'visible' : 'hidden'}`}
          />
        ))}
      </Box>
    </StyledAnimatedButton>
  )
}
