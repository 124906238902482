import React from 'react'
import { useTheme } from '@emotion/react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import { MobileMyAccountWrapper } from './style'
import { useTranslation } from 'react-i18next'
import { ChevronDownIcon } from 'assets/icons/index'
import EditProfile from '../EditProfile/index'
import ChangePassword from '../ChangePassword/index'
import { CustomPrimaryButton } from 'components/common-ui/CustomButton/CustomButton'
import TabCash from './TabCash'
import BetsHistory from '../BetsHistory/index'
import TournamentsHistory from '../TournamentsHistory/index'

const MobileMyAccount = ({ handleLogout }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <MobileMyAccountWrapper theme={theme}>
      <Box className='mobile-my-account-wrap'>
        <Box className='header-box'>
          <Typography variant='h4' className='title-text'>
            {t('myAccount')}
          </Typography>
        </Box>
        <Box className='body-box'>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              {t('editProfile')}
            </AccordionSummary>
            <AccordionDetails>
              <EditProfile />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
            >
              {t('profileSideNavigatorChangePassword')}
            </AccordionSummary>
            <AccordionDetails>
              <ChangePassword />
            </AccordionDetails>
          </Accordion>

          {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel3bh-content'
              id='panel3bh-header'
            >
              Bonuses
            </AccordionSummary>
            <AccordionDetails>
              <TabBonuses />
            </AccordionDetails>
          </Accordion> */}

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel4bh-content'
              id='panel4bh-header'
            >
              {t('cash')}
            </AccordionSummary>
            <AccordionDetails>
              <TabCash />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel5bh-content'
              id='panel5bh-header'
            >
              {t('gamlings')}
            </AccordionSummary>
            <AccordionDetails>
              <BetsHistory />
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary
              expandIcon={<ChevronDownIcon />}
              aria-controls='panel6bh-content'
              id='panel6bh-header'
            >
              {t('tournamentsHistory')}
            </AccordionSummary>
            <AccordionDetails>
              <TournamentsHistory />
            </AccordionDetails>
          </Accordion>

          <Box className='logout-btn-box'>
            <CustomPrimaryButton onClick={handleLogout}>{t('accountMenuLogout')}</CustomPrimaryButton>
          </Box>
        </Box>
      </Box>
    </MobileMyAccountWrapper>
  )
}

export default React.memo(MobileMyAccount)
