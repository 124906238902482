import React from 'react'
import { useTheme } from '@emotion/react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { CustomTableWrapper } from './style'

const CustomTable = ({ columns, data }) => {
  const theme = useTheme()

  return (
    <>
      <CustomTableWrapper theme={theme} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns?.map((column) => (
                  <TableCell key={column.id}>{row[column.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomTableWrapper>
    </>
  )
}

export default React.memo(CustomTable)
