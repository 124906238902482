import { Box, styled } from '@mui/material'

export const BonusWrapper = styled(Box)(({ theme }) => {
  return {
    '& .spinner-wrap': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .bonus-wrap': {
      width: '100%',
      maxWidth: theme.spacing(87.125),
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .section-heading-main': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.typographyText.textDark,
        '-webkit-text-fill-color': theme.palette.typographyText.textDark,
        '-webkit-text-stroke-width': theme.spacing(0.313),
        '-webkit-text-stroke-color': theme.palette.border.border_03,
        fontSize: theme.spacing(7.125),
        fontWeight: '400',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        textShadow: theme.textShadows[2],
        textAlign: 'center',
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
      },
      '& .MuiTabs-root': {
        width: '100%',
        maxWidth: theme.spacing(55),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(5.75),
        '& .MuiTabs-scroller': {
          '& .MuiTabs-flexContainer': {
            gap: theme.spacing(1.5),
            '& .MuiTab-root': {
              minWidth: 'fit-content',
              background: theme.palette.button.quinaryGradient,
              borderRadius: theme.spacing(4.188),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(0.5),
              transition: '300ms all',
              '& .MuiTouchRipple-root': {
                zIndex: '2'
              },
              '& .inner-box': {
                width: '100%',
                border: `1px solid ${theme.palette.lightShade.light_05}`,
                borderRadius: theme.spacing(3.188),
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.button.contrastTextMain,
                fill: theme.palette.button.contrastTextMain,
                fontSize: theme.spacing(1.625),
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textShadow: theme.shadows[2],
                overflow: 'hidden',
                zIndex: '2',
                padding: theme.spacing(0.5, 0.75)
              },
              '&:active': {
                transform: 'scale(0.95)'
              },
              '&.Mui-selected': {
                background: theme.palette.button.mainGradient,
                '& .inner-box': {
                  background: theme.palette.button.mainGradientInner
                }
              }
            }
          },
          '& .MuiTabs-indicator': {
            display: 'none'
          }
        }
      }
    },
    '& .bonus-heading': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.typographyText.text_03,
      fontSize: theme.spacing(2.25),
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      textAlign: 'center',
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(3.5)
    },
    '& .view-btn': {
      marginTop: theme.spacing(0.8),
      marginBottom: theme.spacing(0.2)
    },
    '& .marquee-text': {
      fontSize: '0.8rem',
      fontFamily: theme.typography.fontFamily,
      marginBottom: theme.spacing(-1)
    },
    '& .note-msg-wrap': {
      marginTop: theme.spacing(-2)
    },
    '& .btn-wrap': {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100%'
    },
    '& .about-text-wrap': {
      width: '100%',
      maxWidth: theme.spacing(95.75),
      marginTop: theme.spacing(10),
      marginLeft: 'auto',
      marginRight: 'auto',
      '& .heading-text': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.typographyText.textMain,
        fontSize: theme.spacing(2.25),
        fontWeight: '400',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        textAlign: 'center',
        marginBottom: theme.spacing(5)
      },
      '& .text-box': {
        '& .MuiTypography-root': {
          fontFamily: 'Poppins, sans-serif',
          color: theme.palette.typographyText.textMain,
          fontSize: theme.spacing(1.25),
          fontWeight: '500',
          lineHeight: 'normal',
          marginBottom: theme.spacing(1),
          '& .MuiTypography-caption': {
            color: theme.palette.warning.main,
            fontWeight: '700',
            display: 'inline-block'
          },
          '& .MuiLink-root': {
            color: theme.palette.typographyText.textMain,
            fontWeight: '500',
            textDecoration: 'underline',
            display: 'inline-block'
          }
        }
      },
      '& .read-more-btn': {
        fontFamily: 'Poppins, sans-serif',
        color: theme.palette.warning.dark,
        fontSize: theme.spacing(1.25),
        fontWeight: '700',
        lineHeight: 'normal',
        '& svg': {
          width: theme.spacing(1),
          height: theme.spacing(1),
          fill: theme.palette.warning.dark,
          marginLeft: theme.spacing(0.25)
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .bonus-wrap': {
        '& .section-heading-main': {
          '-webkit-text-stroke-width': theme.spacing(0.25),
          fontSize: theme.spacing(4.125)
        },
        '& .MuiTabs-root': {
          marginBottom: theme.spacing(3),
          '& .MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
              gap: theme.spacing(0.75),
              overflow: 'auto hidden',
              '&::-webkit-scrollbar': {
                width: theme.spacing(0),
                height: theme.spacing(0)
              },
              '& .MuiTab-root': {
                borderRadius: theme.spacing(1.5),
                padding: theme.spacing(0.375),
                '& .inner-box': {
                  borderRadius: theme.spacing(1.125),
                  fontSize: theme.spacing(1),
                  padding: theme.spacing(0.375, 1)
                }
              }
            }
          }
        }
      },
      '& .bonus-heading': {
        fontSize: theme.spacing(1.75),
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
      },
      '& .about-text-wrap': {
        marginTop: theme.spacing(5),
        '& .heading-text': {
          fontSize: theme.spacing(1.5),
          marginBottom: theme.spacing(3)
        },
        '& .text-box': {
          '& .MuiTypography-root': {
            fontSize: theme.spacing(1)
          }
        },
        '& .read-more-btn': {
          fontSize: theme.spacing(1)
        }
      },
      '& .marquee-text': {
        marginBottom: theme.spacing(0),
        fontSize: theme.spacing(0.6)
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .bonus-wrap': {
        '& .section-heading-main': {
          '-webkit-text-stroke-width': theme.spacing(0.188),
          fontSize: theme.spacing(3.125)
        }
      },
      '& .bonus-heading': {
        fontSize: theme.spacing(1.75)
      },
      '& .marquee-text': {
        marginBottom: theme.spacing(0),
        fontSize: theme.spacing(0.6)
      }
    },
    [theme.breakpoints.down('sd')]: {
      '& .bonus-wrap': {
        '& .section-heading-main': {
          '-webkit-text-stroke-width': theme.spacing(0.125),
          fontSize: theme.spacing(2.125)
        }
      },
      '& .bonus-heading': {
        fontSize: theme.spacing(1.25)
      }
    }
  }
})

export const BonusCardWrapper = styled(Box)(({ theme }) => {
  return {
    '& .bonus-heading': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.typographyText.text_03,
      fontSize: theme.spacing(2.25),
      fontWeight: '400',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      textAlign: 'center',
      marginBottom: theme.spacing(2.5),
      marginTop: theme.spacing(3.5)
    },
    '& .bonus-card-wrap': {
      width: '100%',
      maxWidth: theme.spacing(41.25),
      aspectRatio: '330/157',
      zIndex: '1',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      '& .label-img': {
        width: theme.spacing(9.5),
        maxWidth: '100%',
        height: 'auto',
        zIndex: '1',
        position: 'absolute',
        top: theme.spacing(-1),
        left: theme.spacing(-1)
      },
      '& .distribution-label-img': {
        width: theme.spacing(9.5),
        maxWidth: '100%',
        height: 'auto',
        zIndex: '1',
        position: 'absolute',
        top: theme.spacing(-2),
        left: theme.spacing(-2)
      },
      '& .bonus-img-box': {
        width: '100%',
        maxWidth: theme.spacing(21.625),
        background: theme.palette.gradientColor.DialogBg,
        boxShadow: theme.shadows[5],
        aspectRatio: '173/157',
        maskImage: 'url("/assets/images/stock-images/bonus-img-mask-overlay.png")',
        maskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: theme.spacing(2),
        zIndex: '1',
        padding: theme.spacing(2.5, 1.5, 1.5),
        position: 'absolute',
        top: theme.spacing(0),
        left: theme.spacing(0),
        '& .img-box': {
          width: '75%',
          position: 'relative',
          '&::before': {
            content: '""',
            display: 'block',
            paddingTop: '100%'
          },
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            position: 'absolute',
            inset: theme.spacing(0)
          }
        },
        '& .text-box': {
          width: '100%',
          padding: theme.spacing(0, 1),
          position: 'absolute',
          left: '50%',
          bottom: theme.spacing(1.5),
          transform: 'translateX(-50%)',
          '& .MuiTypography-body1': {
            fontFamily: 'Heavitas',
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1.5),
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'center',
            textShadow: theme.textShadows[6]
          },
          '& .MuiTypography-body2': {
            fontFamily: 'Heavitas',
            color: theme.palette.warning.main,
            fontSize: theme.spacing(2.5),
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'center',
            textShadow: theme.textShadows[7],
            '& span': {
              fontFamily: 'Poppins, sans-serif',
              color: theme.palette.typographyText.textMain,
              fontSize: theme.spacing(1.5),
              fontWeight: '500',
              lineHeight: 'normal',
              textShadow: theme.textShadows[0]
            }
          }
        }
      },
      '& .bonus-text-box': {
        width: '100%',
        maxWidth: theme.spacing(41.25),
        background: theme.palette.solidColor.solidColor_03,
        aspectRatio: '330/157',
        maskImage: 'url("/assets/images/stock-images/bonus-img-mask.png")',
        maskRepeat: 'no-repeat',
        maskSize: '100% 100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: theme.spacing(2, 1),
        '& .bonus-img-border': {
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          position: 'absolute',
          inset: '0'
        },
        '& .content-wrap': {
          width: '100%',
          height: '100%',
          maxWidth: theme.spacing(18),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          gap: theme.spacing(1),
          '& .text-1': {
            fontFamily: 'Heavitas',
            color: theme.palette.warning.main,
            fontSize: theme.spacing(1.5),
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'center'
          },
          '& .text-2': {
            fontFamily: 'Poppins, sans-serif',
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1.25),
            fontWeight: '500',
            lineHeight: 'normal',
            textAlign: 'center'
          },
          '& .info-icon': {
            position: 'absolute',
            right: theme.spacing(0.5),
            bottom: theme.spacing(0.5),
            fill: theme.palette.primary.main
          }
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .bonus-card-wrap': {
        width: '100%',
        maxWidth: theme.spacing(21.625),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& .bonus-img-box': {
        },
        '& .bonus-text-box': {
          maxWidth: theme.spacing(21.625),
          aspectRatio: '157/300',
          maskImage: 'url("/assets/images/stock-images/bonus-img-mask-mobile.png")',
          justifyContent: 'center',
          alignItems: 'flex-end',
          '& .content-wrap': {
            maxWidth: 'unset',
            maxHeight: theme.spacing(18)
          }
        }
      }
    }
  }
})

export const BonusDialogWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    overflow: 'scroll',
    '& .dialog-content-wrap': {
      height: '100%'
    },
    '& .bonus-details-wrap': {
      padding: theme.spacing(4.125, 1.5, 0),
      '& .heading-text': {
        borderBottom: `1px solid ${theme.palette.border.active}`,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.typographyText.textMain,
        fontSize: theme.spacing(1.5),
        fontWeight: '400',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(1),
        margin: theme.spacing(0, 1.5, 1, 0)
      },
      '& .text-6, .text-7': {
        fontSize: theme.spacing(1.125),
        '& p': {
          fontSize: theme.spacing(1.125),
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(1)
        }
      },
      '& .bonus-code-wrap': {
        width: ' 35%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      '& .bonus-code-box': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        position: 'relative',
        '& .copy-icon': {
          width: theme.spacing(1),
          height: theme.spacing(1),
          cursor: 'pointer'
        },
        '& .copy-text': {
          color: theme.palette.warning.main,
          fontSize: theme.spacing(0.75),
          position: 'absolute',
          top: theme.spacing(1),
          left: theme.spacing(0)
        }
      }
    },
    '& .active-bonus-details-wrap': {
      padding: theme.spacing(1.5, 1.5),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& .info-tooltip': {
        fill: theme.palette.typographyText.textWhite,
        cursor: 'pointer',
        float: 'right'
      },
      '& .marquee-div': {
        width: theme.spacing(40)
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& .marquee-div': {
          width: theme.spacing(20)
        }
      }
    },
    '& .more-bonus-card-wrap': {
      height: '100%',
      maxHeight: `calc(100% - ${theme.spacing(17.5)})`,
      padding: theme.spacing(1, 1.5, 0),
      '& .more-bonus-card-container': {
        width: '100%',
        height: '100%',
        background: theme.palette.gradientColor.languageBgBox,
        borderRadius: theme.spacing(2.125),
        gap: theme.spacing(2),
        padding: theme.spacing(1.5),
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        '& .more-bonus-card-box': {
          height: '100%',
          overflowY: 'auto',
          padding: theme.spacing(1.5),
          '&::-webkit-scrollbar': {
            width: '0.25rem',
            height: '0.25rem',
            background: theme.palette.darkShade.dark_03
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.warning.light
          },
          '& .text-6, .text-7': {
            fontSize: theme.spacing(1.125),
            '& p': {
              fontSize: theme.spacing(1.125),
              marginTop: theme.spacing(0),
              marginBottom: theme.spacing(1)
            }
          }
        }
      }
    },
    '& .MuiTabs-root': {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(1.5),
      '& .MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
          gap: theme.spacing(1.5),
          '& .MuiTab-root': {
            minWidth: 'fit-content',
            minHeight: 'unset',
            background: theme.palette.button.quinaryGradient,
            borderRadius: theme.spacing(4.188),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(0.5),
            transition: '300ms all',
            '& .MuiTouchRipple-root': {
              zIndex: '2'
            },
            '& .inner-box': {
              width: '100%',
              border: `1px solid ${theme.palette.lightShade.light_05}`,
              borderRadius: theme.spacing(3.188),
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.button.contrastTextMain,
              fill: theme.palette.button.contrastTextMain,
              fontSize: theme.spacing(1.625),
              fontWeight: '400',
              lineHeight: 'normal',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              textShadow: theme.shadows[2],
              overflow: 'hidden',
              zIndex: '2',
              padding: theme.spacing(0.5, 0.75)
            },
            '&:active': {
              transform: 'scale(0.95)'
            },
            '&.Mui-selected': {
              background: theme.palette.button.mainGradient,
              '& .inner-box': {
                background: theme.palette.button.mainGradientInner
              }
            }
          }
        },
        '& .MuiTabs-indicator': {
          display: 'none'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .bonus-details-wrap': {
        padding: theme.spacing(4.125, 1, 0)
      },
      '& .more-bonus-card-wrap': {
        padding: theme.spacing(1, 1, 0),
        '& .more-bonus-card-container': {
          padding: theme.spacing(1.5, 1),
          '& .more-bonus-card-box': {
            padding: theme.spacing(1)
          }
        }
      },
      '& .MuiTabs-root': {
        '& .MuiTabs-scroller': {
          '& .MuiTabs-flexContainer': {
            gap: theme.spacing(0.75),
            overflow: 'auto hidden',
            '&::-webkit-scrollbar': {
              width: theme.spacing(0),
              height: theme.spacing(0)
            },
            '& .MuiTab-root': {
              borderRadius: theme.spacing(1.5),
              padding: theme.spacing(0.375),
              '& .inner-box': {
                borderRadius: theme.spacing(1.125),
                fontSize: theme.spacing(1),
                padding: theme.spacing(0.375, 1)
              }
            }
          }
        }
      }
    }
  }
})
