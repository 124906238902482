
import { styled } from '@mui/styles'
import { Box } from '@mui/material'

export const MediaCardStyle = styled(Box)(({ color }) => {
  return {
    '& .media-card-container': {
      background: color,
      // color: theme.palette.primary.contrastText,
      width: '100%',
      margin: '10px',
      boxShadow: 'none',
      cursor: 'pointer',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '1rem',
      transition: 'transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
      // background: '#201C27',
      minWidth: '100%',
      '& .MuiCardMedia-root': {
        width: '100%'
      },
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)'
      },
      '& .card-content': {
        color: '#57535E'
      }

    }
  }
})
