import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import AuthDialog from 'components/common-ui/AuthDialog/AuthDialog'
import { AuthModalWrapper } from './style'
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import ResetPasswordForm from './ResetPasswordForm'

const AuthModal = ({ dialogOpen, handleClose }) => {
  const theme = useTheme()

  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [maxWidth] = React.useState('customModal_lg')

  const [showLoginForm, setShowLoginForm] = useState(dialogOpen)
  useEffect(() => {
    setShowLoginForm(dialogOpen)
  }, [dialogOpen])

  return (
    <>
      <AuthDialog
        maxWidth={maxWidth}
        // fullScreen={fullScreen}
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        aria-labelledby='customized-dialog-title'
      >
        <AuthModalWrapper theme={theme}>
          {showLoginForm === 'login' && (
            <LoginForm setShowLoginForm={setShowLoginForm} handleClose={handleClose} />
          )}

          {showLoginForm === 'signup' && (
            <SignupForm setShowLoginForm={setShowLoginForm} handleClose={handleClose} />
          )}

          {showLoginForm === 'forgot-pass' && (
            <ForgotPasswordForm setShowLoginForm={setShowLoginForm} />
          )}

          {showLoginForm === 'reset-pass' && (
            <ResetPasswordForm setShowLoginForm={setShowLoginForm} />
          )}

          {/* {showLoginForm && <LoginForm setShowLoginForm={setShowLoginForm} handleClose={handleClose} />}

          {!showLoginForm && <SignupForm setShowLoginForm={setShowLoginForm} handleClose={handleClose} />} */}
        </AuthModalWrapper>
      </AuthDialog>
    </>
  )
}

export default React.memo(AuthModal)
