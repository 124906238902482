import { Dialog, styled } from '@mui/material'

export const AuthDialogWrapper = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiBackdrop-root': {
      background: `url("${theme.themeImages.layoutBgImage}") ${theme.palette.solidColor.layoutBg}`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundAttachment: 'fixed'
      // backdropFilter: 'blur(4px)'
    },
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: theme.spacing(85.625),
      background: 'none',
      borderRadius: theme.spacing(0),
      overflowY: 'visible',
      '& .bg-img': {
        width: '100%',
        maxWidth: '100%',
        height: 'auto'
      },
      '& .brand-logo': {
        width: '100%',
        maxWidth: theme.spacing(25.75),
        height: 'auto',
        position: 'absolute',
        top: theme.spacing(1.125),
        left: theme.spacing(2.625)
      },
      '& .currency-note-animation-container': {
        width: '100%',
        maxWidth: theme.spacing(40),
        backgroundColor: theme.palette.formInput.dark,
        border: `2px solid ${theme.palette.formInput.borderDark}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1.032, 0.875),
        position: 'absolute',
        top: theme.spacing(0.875),
        right: theme.spacing(4),
        '& .currency-note-animation-wrap': {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          pointerEvents: 'none',
          padding: theme.spacing(0, 4.625, 0, 2.25),
          '& .inner-box': {
            width: '100%',
            height: theme.spacing(1),
            backgroundColor: theme.palette.solidColor.dark,
            borderRadius: theme.spacing(1),
            position: 'relative',
            '& .animation-img-box': {
              width: `calc(100% - ${theme.spacing(1)})`,
              aspectRatio: '162/36',
              overflow: 'hidden',
              position: 'absolute',
              top: theme.spacing(0.5),
              left: '50%',
              transform: 'translateX(-50%)',
              '& img': {
                width: '100%',
                maxWidth: '100%',
                height: 'auto',
                transform: 'translateY(-150%)',
                transition: '500ms all',
                '&.animated-img': {
                  animationName: 'currencyNoteDialog',
                  animationDuration: '5s',
                  animationTimingFunction: 'linear',
                  animationIterationCount: 'infinite',
                  animationDirection: 'alternate',
                  '@keyframes currencyNoteDialog': {
                    '0%': {
                      transform: 'translateY(-150%)'
                    },
                    '50%': {
                      transform: 'translateY(-60%)'
                    },
                    '100%': {
                      transform: 'translateY(-150%)'
                    }
                  }
                }
              }
            }
          }
        }
      },
      '& .atm-card-animation-container': {
        width: '100%',
        maxWidth: theme.spacing(34.75),
        backgroundColor: theme.palette.formInput.dark,
        border: `2px solid ${theme.palette.formInput.borderDark}`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1.032, 0.875),
        position: 'absolute',
        left: '50%',
        bottom: theme.spacing(0.5),
        transform: 'translateX(-50%)',
        '& .atm-card-animation-wrap': {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          pointerEvents: 'none',
          padding: theme.spacing(0, 4.5, 0, 4.5),
          transition: '300ms all',
          '& .inner-box': {
            width: '100%',
            height: theme.spacing(1),
            backgroundColor: theme.palette.solidColor.dark,
            borderRadius: theme.spacing(1),
            position: 'relative',
            '& .animation-img-box': {
              width: `calc(100% - ${theme.spacing(1)})`,
              aspectRatio: '162/91',
              overflow: 'hidden',
              position: 'absolute',
              top: theme.spacing(0.5),
              left: '50%',
              transform: 'translateX(-50%)',
              '& img': {
                width: '100%',
                maxWidth: '100%',
                height: 'auto',
                transform: 'translateY(-150%)',
                transition: '500ms all',
                '&.animated-img': {
                  animationName: 'cardATM',
                  animationDuration: '5s',
                  animationTimingFunction: 'linear',
                  animationIterationCount: 'infinite',
                  animationDirection: 'alternate',
                  '@keyframes cardATM': {
                    '0%': {
                      transform: 'translateY(-150%) scale(0.4)'
                    },
                    '50%': {
                      transform: 'translateY(-3%) scale(1)'
                    },
                    '100%': {
                      transform: 'translateY(-150%) scale(0.4)'
                    }
                  }
                }
              }
            }
          }
        }
      },
      '& .atm-machine-animation-img-box': {
        width: theme.spacing(19.813),
        height: theme.spacing(21.313),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(-1.25),
        position: 'absolute',
        right: theme.spacing(-4.5),
        bottom: theme.spacing(-6),
        transform: 'scale(0.95) !important',
        '& svg': {
          width: '100%',
          height: '100%'
        }
      },
      '& .dialog-close-btn': {
        width: theme.spacing(2.75),
        height: theme.spacing(2.75),
        zIndex: '2',
        padding: theme.spacing(0.5),
        position: 'absolute',
        // top: '50%',
        top: theme.spacing(1),
        right: theme.spacing(2.875),
        // transform: 'translateY(-50%)',
        '& svg': {
          fill: theme.palette.formInput.contrastTextMain,
          filter: `drop-shadow(1px 2px 2px ${theme.palette.darkShade.dark_04})`
        }
      },
      '& .MuiDialogContent-root': {
        width: '100%',
        maxWidth: '85.5%',
        aspectRatio: '584/207',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 58%)',
        overflow: 'hidden',
        zIndex: '1',
        padding: theme.spacing(1.5, 4),
        '& .content-wrap': {
          height: '100%',
          overflowY: 'auto',
          padding: theme.spacing(0, 1)
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper': {
        maxWidth: '100%',
        height: '100%',
        maxHeight: `calc(100% - ${theme.spacing(0)})`,
        margin: theme.spacing(0),
        '& .bg-img, & .brand-logo, & .atm-machine-animation-img-box': {
          display: 'none'
        },
        '& .dialog-close-btn': {
          width: theme.spacing(2.25),
          height: theme.spacing(2.25),
          top: theme.spacing(0.75),
          right: theme.spacing(0.875)
        },
        '& .MuiDialogContent-root': {
          width: '100%',
          maxWidth: '100%',
          backgroundColor: theme.palette.solidColor.authModalContentBg,
          aspectRatio: 'unset',
          position: 'static',
          left: 'unset',
          transform: 'unset',
          padding: theme.spacing(4, 0, 1.5),
          '& .content-wrap': {
            height: '100%',
            overflowY: 'auto',
            padding: theme.spacing(0, 1.5)
          }
        }
      }
    }
  }
})
