import { createSlice } from '@reduxjs/toolkit'
import { getActiveBonuses, getAllBonus, getPromotions, getUserBonus, getUserBonusHistory } from 'redux-thunk/thunk/bonus.thunk'

const initialState = {
  allBonus: null,
  userBonus: null,
  activeBonus: null,
  loyaltyLevel: null,
  bonusDetails: null,
  promotionsLoader: false,
  promotionsData: [],
  userBonusHistory: null,
  bonusLoading: false
}

const {
  actions: {
    setUserBonus,
    getLoyaltyLevelStart,
    getBonusDetails
  },
  reducer
} = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
    setUserBonus: (state, action) => ({
      ...state,
      userBonus: action.payload
    }),
    getBonusDetails: (state, action) => ({
      ...state,
      bonusDetails: action.payload
    }),
    getLoyaltyLevelStart: (state, action) => ({
      ...state,
      loyaltyLevel: action.payload
    }),
    setPromotionsLoader: (state, action) => ({
      ...state,
      promotionsLoader: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBonus.fulfilled, (state, action) => {
        return {
          ...state,
          allBonus: action.payload
        }
      })
      .addCase(getUserBonus.fulfilled, (state, action) => {
        return {
          ...state,
          userBonus: action.payload,
          bonusLoading: false
        }
      })
      .addCase(getUserBonus.rejected, (state, action) => {
        return {
          ...state,
          bonusLoading: false,
          userBonus: action.payload
        }
      })
      .addCase(getUserBonus.pending, (state, action) => {
        return {
          ...state,
          bonusLoading: true,
          userBonus: action.payload
        }
      })
      .addCase(getUserBonusHistory.fulfilled, (state, action) => {
        return {
          ...state,
          userBonusHistory: action.payload
        }
      })
      .addCase(getPromotions.fulfilled, (state, action) => {
        return {
          ...state,
          promotionsLoader: false,
          promotionsData: action.payload
        }
      })
      .addCase(getPromotions.rejected, (state, action) => {
        return {
          ...state,
          promotionsLoader: false,
          promotionsData: action.payload
        }
      })
      .addCase(getPromotions.pending, (state, action) => {
        return {
          ...state,
          promotionsLoader: true,
          promotionsData: action.payload
        }
      })
      .addCase(getActiveBonuses.fulfilled, (state, action) => {
        return {
          ...state,
          activeBonus: action.payload,
          bonusLoading: false
        }
      })
      .addCase(getActiveBonuses.rejected, (state, action) => {
        return {
          ...state,
          bonusLoading: false,
          activeBonus: action.payload
        }
      })
      .addCase(getActiveBonuses.pending, (state, action) => {
        return {
          ...state,
          bonusLoading: true,
          activeBonus: action.payload
        }
      })
  }
})

export default reducer
export { setUserBonus, getLoyaltyLevelStart, getBonusDetails, getActiveBonuses }
