import React, { useState } from 'react'
import { Box, Link, Tab, Tabs, Typography } from '@mui/material'
import { BonusWrapper } from './style'
import { useSelector } from 'react-redux'
import { CustomMainButton } from 'components/common-ui/CustomButton/CustomButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'constants/index'
import { useTranslation } from 'react-i18next'
// import OfferBonusesOldDesign from 'components/layout/MyAccount/Bonuses/OffersBonuses/OfferBonusesOldDesign'
// import ActiveBonusesOldDesign from 'components/layout/MyAccount/Bonuses/ActiveBonuses/ActiveBonusesOldDesign'
import VoucherBonuses from 'components/layout/MyAccount/Bonuses/VoucherBonuses/index'
import HistoryBonuses from 'components/layout/MyAccount/Bonuses/HistoryBonuses/index'
import OffersBonuses from 'components/layout/MyAccount/Bonuses/OffersBonuses/index'
import ActiveBonuses from 'components/layout/MyAccount/Bonuses/ActiveBonuses/index'

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const Bonus = () => {
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isAuthenticated } = useSelector((state) => state.gameSetting)
  const { bonusLoading } = useSelector((state) => state?.bonus)

  const handleChange = (event, newValue) => {
    sessionStorage.setItem('tab', newValue)
    setValue(newValue)
  }

  const handleBack = () => {
    navigate(ROUTE_PATHS.HOME)
  }

  return (
    <BonusWrapper>
      {isAuthenticated
        ? (
          <Box className='bonus-wrap'>
            <CustomMainButton onClick={handleBack} mb={2}>
              {t('back')}
            </CustomMainButton>
            <Typography className='section-heading-main'>
              {t('tabsBonus')}
            </Typography>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='fullWidth'
              aria-label='scrollable force tabs example'
            >
              <Tab
                label={
                  <Box className='inner-box' onClick={handleChange}>
                    {t('offers')}
                  </Box>
              }
              />
              <Tab label={<Box className='inner-box'>{t('active')}</Box>} />
              <Tab label={<Box className='inner-box'>{t('voucher')}</Box>} />
              <Tab label={<Box className='inner-box'>{t('history')}</Box>} />
            </Tabs>

            {/* Offers */}
            <CustomTabPanel value={value} index={0}>
              {/* <OfferBonusesOldDesign /> */}
              <OffersBonuses from='offer' />
            </CustomTabPanel>

            {/* Active */}
            <CustomTabPanel value={value} index={1}>
              {/* <ActiveBonusesOldDesign /> */}
              <ActiveBonuses />
            </CustomTabPanel>

            {/* Voucher */}
            <CustomTabPanel value={value} index={2}>
              <VoucherBonuses />
            </CustomTabPanel>

            {/* history */}
            <CustomTabPanel value={value} index={3}>
              <HistoryBonuses />
            </CustomTabPanel>
          </Box>
          )
        : (
          <Box className='bonus-wrap'>
            <CustomMainButton onClick={handleBack} mb={2}>
              {t('back')}
            </CustomMainButton>
            <Typography className='section-heading-main'>
              {t('tabsBonus')}
            </Typography>
            <OffersBonuses from='default' />
          </Box>
          )}

      {!bonusLoading && (
        <Box className='about-text-wrap'>
          <Typography className='heading-text'>
            {t('bonusInfoOfHeading')}
          </Typography>

          <Box className='text-box'>
            <Typography variant='body1'>
              {t('bonusInfoPara0')}
              <br />
              <Typography variant='caption'>CASH MACHINE</Typography>
              {t('isTheTop')}
              <Link to='#'>{t('onlineCasino')}</Link>
              {t('bonusInfoPara1')}
            </Typography>

            <Typography variant='body1'>{t('bonusInfoPara2')}</Typography>

            <Typography variant='body1'>{t('bonusInfoPara3')}</Typography>

            <Typography variant='body1'>{t('bonusInfoPara4')}</Typography>

            {/* <Box display='flex' justifyContent='center' mt={2}>
              <Button className='read-more-btn'>
                Read More <CaretArrowRightIcon />
              </Button>
            </Box> */}
          </Box>
        </Box>
      )}
    </BonusWrapper>
  )
}

export default Bonus
