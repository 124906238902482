import { Box, styled } from '@mui/material'

export const MyAccountWrapper = styled(Box)(({ theme }) => {
  return {
    height: '100%',
    display: 'flex',
    '& .tabs-nav-box': {
      width: '100%',
      height: '100%',
      maxWidth: theme.spacing(17.5),
      borderRight: `3px solid ${theme.palette.darkShade.dark_03}`,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3.125, 0, 1.5, 0),
      '&::-webkit-scrollbar': {
        width: theme.spacing(0),
        height: theme.spacing(0)
      },
      '& .title-text': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.typographyText.textMain,
        fontSize: theme.spacing(1.5),
        fontWeight: '400',
        lineHeight: 'normal',
        textTransform: 'uppercase',
        padding: theme.spacing(0, 1.5, 0, 3.125)
      },
      '& .MuiTabs-root.MuiTabs-vertical': {
        maxHeight: '62dvh',
        marginBottom: theme.spacing(0.5),
        '& .MuiTabScrollButton-root': {
          height: theme.spacing(1.5),
          backgroundColor: theme.palette.darkShade.dark_03,
          '& svg': {
            width: theme.spacing(2),
            height: theme.spacing(2),
            fill: theme.palette.typographyText.textMain
          }
        },
        '& .MuiTabs-flexContainer': {
          '& .MuiTab-root': {
            minHeight: theme.spacing(2.5),
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1.125),
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'capitalize',
            textAlign: 'left',
            alignItems: 'flex-start',
            zIndex: '1',
            padding: theme.spacing(0.5, 3.125),
            transition: '300ms all',
            '&.Mui-selected': {
              color: theme.palette.typographyText.textActive,
              fontWeight: '800'
            }
          },
          '& .heading-text': {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.typographyText.textMain,
            fontSize: theme.spacing(1.25),
            fontWeight: '400',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            padding: theme.spacing(0.5, 3.125)
          },
          '& .MuiDivider-root': {
            borderColor: theme.palette.border.dark,
            margin: theme.spacing(0.75, 3.125)
          }
        },
        '& .MuiTabs-indicator': {
          width: '100%',
          background: theme.palette.gradientColor.tabsActive
        }
      },
      '& .cash-machine-img': {
        width: '100%',
        maxWidth: theme.spacing(10),
        height: 'auto',
        display: 'block',
        margin: `auto auto ${theme.spacing(0)}`
      }
    },
    '& .tabpanel-wrap': {
      width: '100%',
      padding: theme.spacing(3.125, 1.5, 3.125, 3.125),
      '& .tabpanel-box': {
        width: '100%',
        height: '100%',
        '& .content-title-text': {
          borderBottom: `1px solid ${theme.palette.border.active}`,
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.typographyText.textMain,
          fontSize: theme.spacing(1.5),
          fontWeight: '400',
          lineHeight: 'normal',
          textTransform: 'uppercase',
          paddingBottom: theme.spacing(1),
          margin: theme.spacing(0, 1.5, 1, 0)
        }
      }
    },
    '& .content-wrapper': {
      height: `calc(100% - ${theme.spacing(2.5)})`,
      overflowY: 'auto',
      paddingRight: theme.spacing(1.5),
      '&::-webkit-scrollbar': {
        width: '0.25rem',
        height: '0.25rem',
        background: theme.palette.darkShade.dark_03
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.warning.light
      },
      '& .form-btn-box': {
        marginTop: theme.spacing(0.5),
        '& .MuiButtonBase-root': {
          width: '100%'
        }
      }
    }
  }
})
