import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  casinoProviderList: [],
  bannerList: null
}

const {
  actions: {
    // setLiveData,
    setCasinoProviderListData,
    setCasinoBannerListData
  },
  reducer
} = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    setCasinoProviderListData: (state, action) => ({
      ...state,
      casinoProviderList: action?.payload
    }),
    setCasinoBannerListData: (state, action) => {
      return {
        ...state,
        bannerList: action?.payload
      }
    }
  },
  extraReducers: (builder) => {

  }
})

export {
  // setLiveData,
  setCasinoProviderListData,
  setCasinoBannerListData
}
export default reducer
