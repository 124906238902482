import { Box, styled } from '@mui/material'

export const OffersBonusesWrapper = styled(Box)(({ theme }) => {
  return {
    '& .offer-wrap': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: theme.spacing(4.5)
    },
    [theme.breakpoints.down('md')]: {
      '& .offer-wrap': {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .offer-wrap': {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    [theme.breakpoints.down('sd')]: {
      '& .offer-wrap': {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    }
  }
})

export const OfferBonusesOldDesignWrapper = styled(Box)(({ theme }) => {
  return {
    '& .offer-wrap': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.625)
    },
    [theme.breakpoints.down('md')]: {
      '& .offer-wrap': {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: '1fr'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& .offer-wrap': {
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },
    [theme.breakpoints.down('sd')]: {
      '& .offer-wrap': {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    }
  }
})
