import { createSlice } from '@reduxjs/toolkit'
import {
  getAllGames,
  getCasinoGamesList,
  getJackpotPrice,
  getSearchedGames
} from 'redux-thunk/thunk/game.thunk'
import { getDeviceType } from 'utils/getDeviceType'

const scrollToView = () => {
  if (getDeviceType() === 'MOBILE') {
    window.scrollTo({ top: 230, behavior: 'smooth' })
  } else {
    window.scrollTo({ top: 500, behavior: 'smooth' })
  }
}

const initialState = {
  providers: null,
  subCategoryId: null,
  casinoSubCategories: null,
  favoriteGames: null,
  initGameFailure: null,
  initGameData: null,
  categoryWiseGamesList: [],
  activeGameData: null,
  filteredGames: [],
  searchedGames: [],
  randomGames: [],
  count: 0,
  search: '',
  jackpotPrice: 0,
  casinoTagsGameList: []
}

const {
  actions: {
    setFilteredGames,
    clearFilteredGames,
    setGameCategoryId,
    setCasinoSubCategories,
    setAllCasinoGameList,
    removeFavoriteCasinoGamesFav,
    getGameDataSucces,
    getGameDataFailure,
    setActiveGameData,
    clearActiveGameData,
    setSearchedGames,
    setRandomGames,
    clearRandomGames,
    clearSearchedGames,
    setSearch,
    setCategoryWiseGamesList,
    setFavoriteGame,
    setCasinoTagGamesList
  },
  reducer
} = createSlice({
  name: 'games',
  initialState: initialState,
  reducers: {
    setFilteredGames: (state, action) => ({
      ...state,
      filteredGames: action?.payload?.games,
      count: action?.payload?.count
    }),
    clearFilteredGames: (state, action) => ({
      ...state,
      filteredGames: [],
      count: 0
    }),
    setGameCategoryId: (state, action) => {
      scrollToView()
      return ({
        ...state,
        subCategoryId: action?.payload
      })
    },
    setSearchedGames: (state, action) => {
      scrollToView()
      return ({
        ...state,
        searchedGames: action?.payload?.games,
        count: action?.payload?.count
      })
    },
    clearSearchedGames: (state, action) => ({
      ...state,
      searchedGames: [],
      count: 0
    }),
    setRandomGames: (state, action) => ({
      ...state,
      randomGames: action?.payload?.games,
      count: action?.payload?.count
    }),
    clearRandomGames: (state) => ({
      ...state,
      randomGames: [],
      count: 0
    }),
    setSearch: (state, action) => ({
      ...state,
      search: action?.payload
    }),
    setFavoriteGame: (state, action) => ({
      ...state,
      favoriteGames: action?.payload
    }),
    removeFavoriteCasinoGamesFav: (state, { payload }) => {
      const { casinoGameId } = payload
      const newfilteredGames = {
        rows: state.favoriteGames.rows?.filter(
          (item) => item.casinoGameId !== casinoGameId
        ),
        count: state.favoriteGames.count
      }
      return { ...state, favoriteGames: { ...newfilteredGames } }
    },
    setCasinoSubCategories: (state, action) => ({
      ...state,
      casinoSubCategories: action.payload?.subCategoryList,
      favoriteGames: action.payload?.favoriteGames
    }),
    setAllCasinoGameList: (state, action) => ({
      ...state,
      casinoGameList: action?.payload
    }),
    getGameDataSucces: (state, action) => ({
      ...state,
      initGameData: action?.payload
    }),
    getGameDataFailure: (state, action) => ({
      ...state,
      initGameFailure: action?.payload
    }),
    setActiveGameData: (state, action) => ({
      ...state,
      activeGameData: action?.payload
    }),
    clearActiveGameData: (state, action) => ({
      ...state,
      activeGameData: null
    }),
    setCategoryWiseGamesList: (state, action) => ({
      ...state,
      categoryWiseGamesList: action?.payload
    }),
    setCasinoTagGamesList: (state, action) => ({
      ...state,
      casinoTagsGameList: action?.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCasinoGamesList.fulfilled, (state, action) => {
        return {
          ...state,
          categoryWiseGamesList: action?.payload
        }
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        return {
          ...state,
          viewAllGames: action?.payload
        }
      })
      .addCase(getSearchedGames.fulfilled, (state, action) => {
        if (action?.payload?.popupGames) {
          return {
            ...state,
            searchPopupGames: action?.payload
          }
        } else {
          return {
            ...state,
            filteredGames: action?.payload
          }
        }
      })
      .addCase(getJackpotPrice.fulfilled, (state, action) => {
        return {
          ...state,
          jackpotPrice: action.payload
        }
      })
  }
})

export default reducer
export {
  setFilteredGames,
  clearFilteredGames,
  setGameCategoryId,
  setCasinoSubCategories,
  setAllCasinoGameList,
  removeFavoriteCasinoGamesFav,
  getGameDataSucces,
  getGameDataFailure,
  setActiveGameData,
  clearActiveGameData,
  setSearchedGames,
  setRandomGames,
  clearRandomGames,
  clearSearchedGames,
  setSearch,
  setCategoryWiseGamesList,
  setFavoriteGame,
  setCasinoTagGamesList
}
