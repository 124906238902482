import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getGameDataSucces,
  setActiveGameData,
  setAllCasinoGameList,
  setCasinoSubCategories,
  setCategoryWiseGamesList,
  setFavoriteGame,
  setFilteredGames,
  setSearchedGames,
  setRandomGames,
  setCasinoTagGamesList
} from '../redux/slices/games.slice'
import {
  getGamesListService,
  removeFavouriteGameService,
  addFavouriteGameService,
  getGameStartService,
  getFavoriteGameStartService
} from 'network/services/sportsBook.service'
import {
  getCasinoGameByTags,
  getCasinoSubCategories,
  getFilteredGames,
  getGameDataServices,
  getJackpotPrices,
  playCasinoGame
} from 'network/services/casino.service'
import { getUsersCasinoTransactionsService } from 'network/services/users.service'
import { openSuccessToaster } from 'helpers/toaster.helpers'

export const getCasinoGamesList = createAsyncThunk(
  'fetch/casino-category-games',
  async ({ data }, thunkApi) => {
    try {
      const res = await getGamesListService({ ...data })
      const updatedList = [
        ...res?.casinoCategories?.rows
        // { type: data?.type, ...res?.categoryGames?.rows }
      ]
      return (updatedList)
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getAllGames = createAsyncThunk(
  'fetch/games-list',
  async ({ data }, thunkApi) => {
    try {
      console.log(window.location.href)
      let res = await getGamesListService({ ...data })
      if (data?.offset > 0) {
        const { viewAllGames } = thunkApi?.getState()?.games
        res = {
          count: res?.count,
          rows: [...viewAllGames?.rows, ...res?.rows]
        }
      }
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getSearchedGames = createAsyncThunk(
  'fetch/searched-games-list',
  async ({ data, hasMore = false, popupGames = false }, thunkApi) => {
    try {
      const res = await getGamesListService({ ...data }, hasMore)
      if (hasMore) {
        const { filteredGames, searchPopupGames } = thunkApi?.getState()?.games
        const gameData = popupGames ? searchPopupGames : filteredGames
        res.casinoGames = {
          count: res?.casinoGames?.count,
          rows: [
            ...gameData?.casinoGames?.rows,
            ...res?.casinoGames?.rows
          ]
        }
      }
      res.popupGames = popupGames
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getUsersCasinoTransactions = createAsyncThunk(
  'fetch/users-casino-transactions',
  async (data, thunkApi) => {
    try {
      const res = await getUsersCasinoTransactionsService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const favoriteGameHandlerGenric = createAsyncThunk(
  'casino/favorite',
  async ({ gameData, isAllGame = false, isCustomGame = false }, thunkApi) => {
    const { id, isFavourite = false, gameSubCategoryId, gameTag } = gameData
    const { filteredGames, categoryWiseGamesList, count, search, searchedGames, randomGames, favoriteGames, casinoTagsGameList } =
    thunkApi?.getState()?.games
    console.log(isCustomGame)
    const { showFavoriteGames, showRandomGames } = thunkApi?.getState()?.gameSetting
    const casinoGameId = id
    try {
      const res = (!isFavourite)
        ? await addFavouriteGameService({ casinoGameId })
        : await removeFavouriteGameService({ casinoGameId: favoriteGames ? gameData?.casinoGameId : casinoGameId })

      if (isAllGame) {
        const newAllfilteredGames = categoryWiseGamesList?.map((item) =>
          item.id === gameSubCategoryId
            ? {
                ...item,
                games: item.games.map((cData) =>
                  cData.id === casinoGameId
                    ? { ...cData, isFavourite: !isFavourite }
                    : cData
                )
              }
            : item
        )
        thunkApi.dispatch(setCategoryWiseGamesList(newAllfilteredGames))
      } else {
        if (search) {
          const newfilteredGames = searchedGames?.map((item) =>
            item.id === casinoGameId
              ? { ...item, isFavourite: !isFavourite }
              : item
          )

          const newfilteredGamesData = { games: newfilteredGames, count }
          thunkApi?.dispatch(setSearchedGames(newfilteredGamesData))
        } else if (showRandomGames) {
          const newfilteredGames = randomGames?.map((item) =>
            item.id === casinoGameId
              ? { ...item, isFavourite: !isFavourite }
              : item
          )

          const newfilteredGamesData = { games: newfilteredGames, count }
          thunkApi?.dispatch(setRandomGames(newfilteredGamesData))
        } else if (showFavoriteGames) {
          const newfilteredGames = favoriteGames?.rows?.filter((item) => item.id !== casinoGameId)

          const filterredGameData = {
            rows: newfilteredGames,
            count: newfilteredGames?.length
          }

          thunkApi?.dispatch(setFavoriteGame(filterredGameData))
        } else if (isCustomGame) {
          const newfilteredGames = {
            ...casinoTagsGameList,
            [gameTag]: casinoTagsGameList[gameTag]?.map((item) =>
              item.id === casinoGameId
                ? { ...item, isFavourite: !isFavourite }
                : item
            )
          }
          console.log(casinoTagsGameList[gameTag]?.map((item) =>
            item.id === casinoGameId
              ? { ...item, isFavourite: !isFavourite }
              : item
          ))
          thunkApi.dispatch(setCasinoTagGamesList(newfilteredGames))
        } else {
          const newfilteredGames = filteredGames?.map((item) =>
            item.id === casinoGameId
              ? { ...item, isFavourite: !isFavourite }
              : item
          )

          const newfilteredGamesData = { games: newfilteredGames, count }
          thunkApi?.dispatch(setFilteredGames(newfilteredGamesData))
        }
      }

      openSuccessToaster({ message: res.message })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const removeFavouriteGame = createAsyncThunk(
  'fetch/remove-favourite-game',
  async ({ gameId }, thunkApi) => {
    try {
      const res = await removeFavouriteGameService({ casinoGameId: gameId })
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getGameStart = createAsyncThunk(
  'getGameStart',
  async (data, thunkApi) => {
    try {
      const res = await getGameStartService(data)
      thunkApi.dispatch(setAllCasinoGameList(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getFavoriteGameStart = createAsyncThunk(
  'getGameStart',
  async (data, thunkApi) => {
    try {
      const res = await getFavoriteGameStartService(data)
      thunkApi.dispatch(setFavoriteGame(res?.favoriteGames))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getCasinoCategoryGame = createAsyncThunk(
  '/getCasinoSubCategories',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoSubCategories(data)
      thunkApi.dispatch(setCasinoSubCategories(res?.response))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getGameData = createAsyncThunk(
  '/getGameData',
  async (data, thunkApi) => {
    try {
      const res = await getGameDataServices(data)
      thunkApi.dispatch(getGameDataSucces(res))
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const playGame = createAsyncThunk('/game-lauch',
  async ({ data, openGame, fromRandomGames }, thunkApi) => {
    try {
      const res = await playCasinoGame(data)
      const { showRandomGames } = thunkApi?.getState()?.gameSetting
      thunkApi.dispatch(setActiveGameData({ ...res?.data, funMode: data?.funMode }))
      if (fromRandomGames) showRandomGames && openGame(data?.gameCode, res?.data?.game_url)
      else openGame(data?.gameCode, res?.data?.game_url)
      // return res?.data
    } catch (error) {
      console.error(error)
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getFilteredGameData = createAsyncThunk(
  '/getFilteredGameData',
  async ({ data, navigate, hasMore = false }, thunkApi) => {
    try {
      if (window.location?.pathname !== '/') {
        navigate('/')
      }
      const { filteredGames } = thunkApi?.getState()?.games
      const res = await getFilteredGames({ data, hasMore })
      thunkApi.dispatch(setFilteredGames({ games: [...filteredGames, ...res?.categoryGames?.rows], count: res?.categoryGames?.count }))
      return res
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getSearchedGameData = createAsyncThunk(
  '/getSearchedGameData',
  async (data, thunkApi) => {
    try {
      const res = await getFilteredGames({ data })
      const { searchedGames } = thunkApi?.getState()?.games
      thunkApi.dispatch(setSearchedGames({ games: [...searchedGames, ...res?.categoryGames?.rows], count: res?.categoryGames?.count }))
      return res
    } catch (error) {
      console.log(error, 'errr')
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getProviderGameData = createAsyncThunk(
  '/getProviderGameData',
  async (data, thunkApi) => {
    try {
      const res = await getFilteredGames({ data })
      thunkApi.dispatch(setFilteredGames({ games: [...res?.categoryGames?.rows], count: res?.categoryGames?.count }))
      return res
    } catch (error) {
      console.log(error, 'errr')
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getRandomGameData = createAsyncThunk(
  '/getRandomGameData',
  async ({ data, openRandomGame }, thunkApi) => {
    try {
      const res = await getFilteredGames(data)
      thunkApi.dispatch(setRandomGames({ games: [...res?.categoryGames?.rows], count: res?.categoryGames?.count }))
      openRandomGame(res?.categoryGames?.rows[0])
      return res
    } catch (error) {
      console.log(error, 'errr')
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getJackpotPrice = createAsyncThunk(
  '/getJackpotPrice',
  async (data, thunkApi) => {
    try {
      const res = await getJackpotPrices(data)
      return res?.prize
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)

export const getCasinoGameByTag = createAsyncThunk(
  '/getCasinoGameByTag',
  async (data, thunkApi) => {
    try {
      const res = await getCasinoGameByTags({ })

      const obj = {}
      res.results.forEach((item) => {
        if (item.tag === 'gameOfMonth') {
          obj[item.tag] = item.games?.map((game) => {
            return { ...game, gameTag: item.tag, thumbnailUrl: game?.thumbnailUrl?.replace('_3_4', '_3_2') }
          })
        } else {
          obj[item.tag] = item.games?.map((game) => {
            return { ...game, gameTag: item.tag }
          })
        }
      })
      thunkApi.dispatch(setCasinoTagGamesList(obj))
    } catch (error) {
      return thunkApi.rejectWithValue(
        error && error[0] && error[0]?.description
      )
    }
  }
)
